import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { wasteData } from "./WasteManagementChart";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "2019",
    preparedReuse: 5,
    recycled: 15,
    otherRecovery: 20,
    incinerationEnergy: 10,
    incinerationNoEnergy: 8,
    landfill: 5,
  },
  {
    name: "2020",
    preparedReuse: 4,
    recycled: 14,
    otherRecovery: 19,
    incinerationEnergy: 9,
    incinerationNoEnergy: 7,
    landfill: 6,
  },
  {
    name: "2021",
    preparedReuse: 3,
    recycled: 13,
    otherRecovery: 18,
    incinerationEnergy: 11,
    incinerationNoEnergy: 9,
    landfill: 5,
  },
  {
    name: "2022",
    preparedReuse: 6,
    recycled: 15,
    otherRecovery: 21,
    incinerationEnergy: 12,
    incinerationNoEnergy: 8,
    landfill: 7,
  },
  {
    name: "2023",
    preparedReuse: 5,
    recycled: 14,
    otherRecovery: 20,
    incinerationEnergy: 10,
    incinerationNoEnergy: 8,
    landfill: 6,
  },
];

const OverviewHazardous = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px",
          }}
        >
          Hazardous waste by disposal modes - %
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Percentage of Hazardous waste through different disposal modes
          </div>
        </div>
        <div style={{ margin: "18px 10px", display: "flex" }}>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => setActiveMode(false)}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => setActiveMode(true)}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => setActiveMode(true)}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            barSize={80}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="preparedReuse"
              stackId="a"
              fill="#00876C"
              name="Prepared Reuse"
            />
            <Bar
              dataKey="recycled"
              stackId="a"
              fill="#7A5195"
              name="Recycled"
            />
            <Bar
              dataKey="otherRecovery"
              stackId="a"
              fill="#EF5675"
              name="Other Recovery"
            />
            <Bar
              dataKey="incinerationEnergy"
              stackId="a"
              fill="#FFA600"
              name="Incineration with Energy"
            />
            <Bar
              dataKey="incinerationNoEnergy"
              stackId="a"
              fill="#003F5C"
              nem="Incineration without Energy"
            />
            <Bar
              dataKey="landfill"
              stackId="a"
              fill="#D45087"
              name="Landfill"
            />
          </BarChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={wasteData[2023]}
            tableClassName="font-lato"
          >
            <Column
              header="Reused %"
              style={{ minWidth: "8%" }}
              field="preparedReuse"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Recycled %"
              style={{ minWidth: "8%" }}
              field="recycled"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Composed %"
              style={{ minWidth: "8%" }}
              field="incineration"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Energy Recovery %"
              style={{ minWidth: "8%" }}
              field="incinerationEnergy"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Landfill %"
              style={{ minWidth: "8%" }}
              field="incinerationEnergy"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default OverviewHazardous;
