import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomLegend } from "./Dashboard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const CustomFuelLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 1 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,

                marginRight: "5px",
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export const HaNData = {
  2019: [
    { month: "Jan", ltifStaff: 120, ltifContractors: 85 },
    { month: "Feb", ltifStaff: 150, ltifContractors: 60 },
    { month: "Mar", ltifStaff: 95, ltifContractors: 110 },
    { month: "Apr", ltifStaff: 160, ltifContractors: 45 },
    { month: "May", ltifStaff: 140, ltifContractors: 80 },
    { month: "Jun", ltifStaff: 90, ltifContractors: 130 },
    { month: "Jul", ltifStaff: 130, ltifContractors: 70 },
    { month: "Aug", ltifStaff: 180, ltifContractors: 40 },
    { month: "Sep", ltifStaff: 85, ltifContractors: 125 },
    { month: "Oct", ltifStaff: 145, ltifContractors: 60 },
    { month: "Nov", ltifStaff: 95, ltifContractors: 110 },
    { month: "Dec", ltifStaff: 110, ltifContractors: 95 },
  ],
  2020: [
    { month: "Jan", ltifStaff: 170, ltifContractors: 50 },
    { month: "Feb", ltifStaff: 140, ltifContractors: 90 },
    { month: "Mar", ltifStaff: 160, ltifContractors: 65 },
    { month: "Apr", ltifStaff: 120, ltifContractors: 100 },
    { month: "May", ltifStaff: 155, ltifContractors: 75 },
    { month: "Jun", ltifStaff: 130, ltifContractors: 85 },
    { month: "Jul", ltifStaff: 140, ltifContractors: 95 },
    { month: "Aug", ltifStaff: 115, ltifContractors: 110 },
    { month: "Sep", ltifStaff: 130, ltifContractors: 105 },
    { month: "Oct", ltifStaff: 150, ltifContractors: 90 },
    { month: "Nov", ltifStaff: 95, ltifContractors: 140 },
    { month: "Dec", ltifStaff: 100, ltifContractors: 125 },
  ],
  2021: [
    { month: "Jan", ltifStaff: 110, ltifContractors: 120 },
    { month: "Feb", ltifStaff: 130, ltifContractors: 95 },
    { month: "Mar", ltifStaff: 125, ltifContractors: 115 },
    { month: "Apr", ltifStaff: 165, ltifContractors: 55 },
    { month: "May", ltifStaff: 135, ltifContractors: 105 },
    { month: "Jun", ltifStaff: 95, ltifContractors: 130 },
    { month: "Jul", ltifStaff: 150, ltifContractors: 70 },
    { month: "Aug", ltifStaff: 140, ltifContractors: 80 },
    { month: "Sep", ltifStaff: 155, ltifContractors: 85 },
    { month: "Oct", ltifStaff: 100, ltifContractors: 130 },
    { month: "Nov", ltifStaff: 90, ltifContractors: 160 },
    { month: "Dec", ltifStaff: 110, ltifContractors: 145 },
  ],
  2022: [
    { month: "Jan", ltifStaff: 130, ltifContractors: 95 },
    { month: "Feb", ltifStaff: 110, ltifContractors: 125 },
    { month: "Mar", ltifStaff: 160, ltifContractors: 85 },
    { month: "Apr", ltifStaff: 145, ltifContractors: 95 },
    { month: "May", ltifStaff: 125, ltifContractors: 135 },
    { month: "Jun", ltifStaff: 170, ltifContractors: 55 },
    { month: "Jul", ltifStaff: 155, ltifContractors: 75 },
    { month: "Aug", ltifStaff: 140, ltifContractors: 110 },
    { month: "Sep", ltifStaff: 130, ltifContractors: 120 },
    { month: "Oct", ltifStaff: 115, ltifContractors: 105 },
    { month: "Nov", ltifStaff: 95, ltifContractors: 140 },
    { month: "Dec", ltifStaff: 100, ltifContractors: 125 },
  ],
  2023: [
    { month: "Jan", ltifStaff: 180, ltifContractors: 55 },
    { month: "Feb", ltifStaff: 145, ltifContractors: 75 },
    { month: "Mar", ltifStaff: 135, ltifContractors: 110 },
    { month: "Apr", ltifStaff: 120, ltifContractors: 140 },
    { month: "May", ltifStaff: 155, ltifContractors: 85 },
    { month: "Jun", ltifStaff: 105, ltifContractors: 130 },
    { month: "Jul", ltifStaff: 135, ltifContractors: 105 },
    { month: "Aug", ltifStaff: 115, ltifContractors: 130 },
    { month: "Sep", ltifStaff: 130, ltifContractors: 115 },
    { month: "Oct", ltifStaff: 90, ltifContractors: 160 },
    { month: "Nov", ltifStaff: 125, ltifContractors: 120 },
    { month: "Dec", ltifStaff: 145, ltifContractors: 95 },
  ],
  2024: [
    { month: "Jan", ltifStaff: 100, ltifContractors: 140 },
    { month: "Feb", ltifStaff: 125, ltifContractors: 115 },
    { month: "Mar", ltifStaff: 165, ltifContractors: 95 },
    { month: "Apr", ltifStaff: 155, ltifContractors: 110 },
    { month: "May", ltifStaff: 95, ltifContractors: 135 },
    { month: "Jun", ltifStaff: 140, ltifContractors: 115 },
    { month: "Jul", ltifStaff: 175, ltifContractors: 80 },
    { month: "Aug", ltifStaff: 160, ltifContractors: 85 },
    { month: "Sep", ltifStaff: 120, ltifContractors: 135 },
    { month: "Oct", ltifStaff: 90, ltifContractors: 165 },
    { month: "Nov", ltifStaff: 130, ltifContractors: 120 },
    { month: "Dec", ltifStaff: 150, ltifContractors: 110 },
  ],
};

const SupplyChainChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  // Combine data for the selected years
  const combinedData = HaNData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} ltifStaff`]: item.ltifStaff,
    [`${year1} ltifContractors`]: item.ltifContractors,
    [`${year2} ltifStaff`]: HaNData[year2][index].ltifStaff,
    [`${year2} ltifContractors`]: HaNData[year2][index].ltifContractors,
  }));
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Number of Suppliers Assessed on Environment and Social Aspects
        </div>

        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(HaNData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(HaNData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100.1%" height={400}>
          <BarChart
            data={combinedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barGap={5}
            barSize={30}
          >
            <XAxis dataKey="month" />
            <YAxis
              label={{
                value: "Number of Local / International Suppliers",
                angle: -90,
                position: "insideLeft",
                offset: 0,
                style: { textAnchor: "middle", fill: "#555" },
              }}
            />{" "}
            <Tooltip />
            <Legend content={CustomFuelLegend} />
            <Bar
              dataKey={`${year1} ltifStaff`}
              stackId="a"
              fill="#d6a7df"
              name="Local Suppliers"
            ></Bar>
            <Bar
              dataKey={`${year1} ltifContractors`}
              stackId="a"
              fill="#9d74b0"
              name="International suppliers"
            ></Bar>
            <Bar
              dataKey={`${year2} ltifStaff`}
              stackId="b"
              fill="#d6a7df"
              t
            ></Bar>
            <Bar
              dataKey={`${year2} ltifContractors`}
              stackId="b"
              fill="#9d74b0"
            ></Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={HaNData[year1].map((item, index) => ({
              ...item,
              [`ltifStaff_${year2}`]: HaNData[year2][index].ltifStaff,
              [`ltifContractors_${year2}`]:
                HaNData[year2][index].ltifContractors,
            }))}
            tableClassName="font-lato"
            style={{ width: "100%" }}
          >
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Local Suppliers - ${year1}`}
              style={{ minWidth: "8%" }}
              field="ltifStaff"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`International Suppliers - ${year1}`}
              style={{ minWidth: "8%" }}
              field="ltifContractors"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Local Suppliers - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`ltifStaff_${year2}`}
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`International Suppliers - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`ltifContractors_${year2}`}
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};
export default SupplyChainChart;
