import React, { useState, useEffect } from "react";
import "../reports/toc_style.css";
import { useHeadsObserver } from "./hooks";
import * as XLSX from "xlsx";
import useForceUpdate from "use-force-update";
import $ from "jquery";
import Axios from "axios";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from "file-saver";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Dropdown } from "primereact/dropdown";
import APIServices from "../../service/APIService";
import { getEFCalculatedDataByFY } from "./reporthelper/EFCalcHelper";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = {
//     Roboto: {
//         normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//         bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//         italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//         bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//       }
// }
const { DateTime } = require("luxon");

window.jQuery = $;
window.$ = $;
const dcf_id = [11, 10, 15, 72, 16, 36];
const GRIReport = () => {
  const [headings, setHeadings] = useState([]);
  const { activeId } = useHeadsObserver();
  const [tableData, setTableData] = useState([]);
  const [workbook, setWorkbook] = useState(null);
  const [firstSheet, setFirstSheet] = useState(null);
  const [year, setYear] = useState(2022);
  const [rfData, setRFData] = useState({});
  const selector = useSelector((state) => state.user.userdetail);
  const emissionFactor = useSelector(
    (state) => state.emissionfactor.emissionFactor
  );
  const locationList = useSelector((state) => state.sitelist.locationList);
  const siteList = useSelector((state) => state.sitelist.siteList);
  const rflibrary = useSelector((state) => state.library.rf);
  const { fymonth } = useSelector((state) => state.user.fyStartMonth);
  const [dcfass, setDCFAss] = useState([]);
  const [dcflist, setDcfList] = useState([]);
  const [response, setResponse] = useState([]);
  const [report, setReport] = useState([]);
  const [reportEF, setReportEF] = useState([]);
  const [dpreport, setDpReport] = useState([]);
  let months_ = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [subcat, setSubCat] = useState({
    one: [],
    two: [],
    three: [],
    four: [],
  });
  const [apief, setApiEF] = useState([]);
  const forceUpdate = useForceUpdate();
  function formatSubscript__(inputString, findArray, replaceArray) {
    let result = [];
    let currentIndex = 0;

    for (let i = 0; i < findArray.length; i++) {
      const findText = findArray[i];
      const replaceValue = replaceArray[i];
      const index = inputString.toLowerCase().indexOf(findText, currentIndex);

      if (index === -1) {
        // If the findText is not found, add the remaining text and break
        result.push(inputString.substring(currentIndex));
        break;
      }

      // Add the text before the found substring
      result.push(inputString.substring(currentIndex, index));

      // Add the subscripted replaceValue as an object
      result.push(...getResult(findText, replaceValue));

      // Update the currentIndex to continue searching
      currentIndex = index + findText.length;
    }

    // Add any remaining text after the last replacement
    if (currentIndex < inputString.length) {
      result.push(inputString.substring(currentIndex));
    }

    // Filter out empty strings
    result = result.filter((item) => item !== "");

    return result;
  }
  const handleNaNAndInfinity = (value) => {
    if (typeof value === "number" && isFinite(value)) {
      return value.toFixed(0); // Return the original value if it's a valid number
    } else {
      return 0; // Return 0 for NaN or Infinity
    }
  };
  const getDataByDPArray = (dparr, yr) => {
    let dpreport_ = JSON.parse(JSON.stringify(dpreport));
    let d = 0;
    dparr.forEach((dpid) => {
      dpreport_
        .filter((i) => {
          return i.dp === dpid;
        })
        .forEach((k) => {
          if (checkYear(k.rp, yr) !== 0) {
            d = d + k.value;
          }
        });
    });

    return isNaN(d) ? 0 : d.toFixed(0);
  };
  function formatSubscript(inputString, findArray, replaceArray) {
    return inputString;
  }
  function getResult(str, str2) {
    let arr = str.split(str2.toString());
    arr.splice(1, 0, { text: str2, fontSize: 7, baseline: -5 });
    return arr;
  }
  function ulOrOlToPdfMake(element) {
    const result = [];
    const listItems = Array.from(element.querySelectorAll("li"));

    listItems.forEach((li) => {
      const text = li.textContent.trim();
      if (text !== "") {
        result.push({ text });
      }
    });

    return result;
  }
  function renderRows(row) {
    let result = [];
    row.forEach((item, index) => {
      result.push([
        { text: index + 1, alignment: "center" },
        { text: item.work_activity, alignment: "center" },
        { text: item.hazard, alignment: "center" },
        { text: item.injury, alignment: "center" },
        { text: item.risk_control, alignment: "center" },
        { text: item.res, alignment: "center" },
        { text: item.rel, alignment: "center" },
        { text: item.relrpn, alignment: "center" },
        { text: item.addnl_control, alignment: "center" },

        { text: item.rcs, alignment: "center" },
        { text: item.rcl, alignment: "center" },
        { text: item.rclrpn, alignment: "center" },
        { text: item.implementation, alignment: "center" },
        { text: item.due_date, alignment: "center" },
        { text: item.remarks, alignment: "center" },
      ]);
    });
    return result;
  }
  async function exportRA(ra_data) {
    var dd = {
      content: [
        { text: "Risk Assessment Form", style: "header" },
        {
          //   layout: 'headerLineOnly',
          table: {
            // headers are automatically repeated if the table spans over multiple pages 770
            // you can declare how many rows should be treated as headers

            widths: [320, 230, 160, 65],

            body: [
              [
                {
                  columns: [
                    { text: "Department:", bold: true, width: "25%" },
                    { text: ra_data.department, width: "75%" },
                  ],
                },
                {
                  columns: [
                    { text: "RA Leader:", bold: true, width: "30%" },
                    { text: ra_data.ra_leader, width: "70%" },
                  ],
                },
                {
                  rowSpan: 3,
                  columns: [
                    { text: "Approved by:", bold: true, width: "50%" },
                    { text: ra_data.approved_by, width: "50%" },
                  ],
                },
                {
                  rowSpan: 6,
                  columns: [
                    {
                      text: "Reference # " + ra_data.ref_id,
                      bold: true,
                      width: "100%",
                    },
                  ],
                },
              ],
              [
                {
                  columns: [
                    { text: "Process:", bold: true, width: "20%" },
                    { text: ra_data.process, width: "80%" },
                  ],
                },
                {
                  columns: [
                    { text: "RA Member 1:", bold: true, width: "35%" },
                    { text: ra_data.ra_member1, width: "65%" },
                  ],
                },
              ],
              [
                {
                  columns: [
                    {
                      text: "Process/Activity Location:",
                      bold: true,
                      width: "45%",
                    },
                    { text: ra_data.location, bold: true, width: "55%" },
                  ],
                },
                {
                  columns: [
                    { text: "RA Member 2:", bold: true, width: "35%" },
                    { text: ra_data.ra_member2, width: "65%" },
                  ],
                },
              ],
              [
                {
                  columns: [
                    {
                      text: "Original Assessment date:",
                      bold: true,
                      width: "45%",
                    },
                    { text: ra_data.assessment_date, bold: true, width: "55%" },
                  ],
                },
                {
                  columns: [
                    { text: "RA Member 3:", bold: true, width: "35%" },
                    { text: ra_data.ra_member3, width: "65%" },
                  ],
                },
                {
                  columns: [
                    { text: "Name:", bold: true, width: "25%" },
                    { text: ra_data.name, width: "75%" },
                  ],
                },
              ],
              [
                {
                  columns: [
                    { text: "Last review date:", bold: true, width: "30%" },
                    {
                      text: ra_data.last_review_date,
                      bold: true,
                      width: "70%",
                    },
                  ],
                },
                {
                  columns: [
                    { text: "RA Member 4:", bold: true, width: "35%" },
                    { text: ra_data.ra_member4, width: "7650%" },
                  ],
                },
                {
                  columns: [
                    { text: "Designation:", bold: true, width: "45%" },
                    { text: ra_data.designation, width: "55%" },
                  ],
                },
              ],
              [
                {
                  columns: [
                    { text: "Next review date:", bold: true, width: "30%" },
                    {
                      text: ra_data.next_review_date,
                      bold: true,
                      width: "70%",
                    },
                  ],
                },
                {
                  columns: [
                    { text: "RA Member 5:", bold: true, width: "35%" },
                    { text: ra_data.ra_member1, width: "65%" },
                  ],
                },
                {
                  columns: [
                    { text: "Date:", bold: true, width: "25%" },
                    { text: ra_data.date, width: "75%" },
                  ],
                },
              ],
            ],
          },
        },
        { text: "", margin: [0, 2] },
        {
          //   layout: 'headerLineOnly',
          table: {
            // headers are automatically repeated if the table spans over multiple pages 770

            headerRows: 2,

            body: [
              [
                {
                  text: "HAZARD IDENTIFICATION",
                  bold: true,
                  alignment: "center",
                  colSpan: 4,
                },
                {},
                {},
                {},
                {
                  text: "RISK EVALUATION",
                  bold: true,
                  alignment: "center",
                  colSpan: 4,
                },
                {},
                {},
                {},
                {
                  text: "RISK CONTROL",
                  bold: true,
                  alignment: "center",
                  colSpan: 7,
                },
                {},
                {},
                {},
                {},
                {},
                {},
              ],
              [
                { text: "Ref", bold: true, alignment: "center" },
                { text: "Work Activity", bold: true, alignment: "center" },
                { text: "Hazard", bold: true, alignment: "center" },
                {
                  text: "Possible injury/ill-health",
                  bold: true,
                  alignment: "center",
                },
                {
                  text: "Existing risk controls",
                  bold: true,
                  alignment: "center",
                },
                {
                  text: "S",
                  bold: true,
                  alignment: "center",
                  alignment: "center",
                },
                {
                  text: "L",
                  bold: true,
                  alignment: "center",
                  alignment: "center",
                },
                { text: "RPN", bold: true, alignment: "center" },
                {
                  text: "Additional Controls",
                  bold: true,
                  alignment: "center",
                },
                {
                  text: "S",
                  bold: true,
                  alignment: "center",
                  alignment: "center",
                },
                {
                  text: "L",
                  bold: true,
                  alignment: "center",
                  alignment: "center",
                },
                { text: "RPN", bold: true, alignment: "center" },
                {
                  text: "Implementation Person",
                  bold: true,
                  alignment: "center",
                },

                { text: "Due Date", bold: true, alignment: "center" },
                { text: "Remarks", bold: true, alignment: "center" },
              ],
              ...renderRows(ra_data.table_data),
            ],
          },
        },
      ],
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [15, 15, 15, 20],
      footer: function (currentPage, pageCount) {
        return {
          alignment: "right",
          margin: [5, 0],
          text:
            "Page " + currentPage.toString() + " of " + pageCount + " pages(s)",
        };
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: "center",
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };
    const pdf = pdfMake.createPdf(dd);
    pdf.download("RA.pdf");
  }
  async function exportTable2Excel(type) {
    let initialData = [
        {
          alignment: "center", // Center the text horizontally
          margin: [0, 250], // Adjust the top margin to vertically center the text
          text: [
            {
              text: "GRI REPORT" + "\n",
              fontSize: 40,
              color: "#315874",
              bold: true,
            },
            {
              text:
                "FY " +
                year +
                " - " +
                (year + 1).toString().substr(2, 3) +
                "\n",
              fontSize: 20,
              color: "#315874",
            },
            {
              text: DateTime.local().toFormat("MMMM dd, yyyy"),
              fontSize: 20,
              color: "blue",
            }, // Customize the font size and color
          ],
          pageBreak: "after",
        },
        {
          toc: {
            id: "sectionHeader",
            title: { text: "Table of Content", style: "tdHead" },
          },
          pageBreak: "after",
        },
      ],
      data = [];
    const div = document.getElementById("main");
    for (let i = 0; i < div.children.length; i++) {
      if (div.childNodes[i].tagName.toLowerCase() === "sectionheader") {
        data.push({
          table: {
            widths: ["*"],
            body: [
              [
                {
                  tocItem: "sectionHeader",
                  text: formatSubscript(
                    div.childNodes[i].textContent,
                    ["tco2e", "n2o", "ch4", "co2"],
                    ["2", "2", "4", "2"]
                  ),
                  style: "secHead",
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        });
        data.push({
          text: "", // Empty text

          margin: [10, 10], // Adjust the margin for horizontal space
        });
      } else if (div.childNodes[i].tagName.toLowerCase() === "sectionheader1") {
        data.push({
          text: formatSubscript(
            div.childNodes[i].textContent,
            ["tco2e", "n2o", "ch4", "co2"],
            ["2", "2", "4", "2"]
          ),
          style: "text-under",
        });
        data.push({
          text: "", // Empty text

          margin: [5, 5], // Adjust the margin for horizontal space
        });
      } else {
        if (div.childNodes[i].children.length !== 0) {
          for (
            let child = 0;
            child < div.childNodes[i].children.length;
            child++
          ) {
            let tag = div.childNodes[i].childNodes[child].tagName;

            if (tag) {
              if (tag === "OL") {
                data.push({
                  ol: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]),
                });
                data.push({
                  text: "", // Empty text

                  margin: [0, 5], // Adjust the margin for horizontal space
                });
              } else if (tag === "UL") {
                data.push({
                  ul: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]),
                });
                data.push({
                  text: "", // Empty text

                  margin: [0, 5], // Adjust the margin for horizontal space
                });
              } else if (tag === "TABLE") {
                let content = generatePdfMakeContentFromTable(
                  div.childNodes[i].childNodes[child]
                );

                data.push({
                  table: {
                    headerRows: 1,
                    widths: JSON.parse(JSON.stringify(content))[0].map(
                      (a, b) => {
                        return b === 0 ? "*" : "auto";
                      }
                    ),
                    body: content,
                    style: "tableStyle",
                  },
                });
                data.push({
                  text: "", // Empty text

                  margin: [0, 10], // Adjust the margin for horizontal space
                });
              } else if (tag === "IMG") {
                data.push({ image: div.childNodes[i].childNodes[child].src });

                data.push({
                  text: "", // Empty text

                  margin: [0, 5], // Adjust the margin for horizontal space
                });
              } else if (tag === "BR") {
                let txt = `Definition: Operational Boundaries requires choosing the scope of emissions that will be reported. There are three scopes of emissions that can be reported:
                       \n Scope 1: Direct GHG Emissions from company owned or controlled sources
                       \n Scope 2: Indirect GHG Emissions from purchased electricity or steam.
                       \n According the GHG Protocol Corporate Reporting Standard, Scope 1 and Scope 2 emissions must be reported. Scope 3 emissions are voluntary`;
                data.push({ text: txt });
                data.push({
                  text: "", // Empty text

                  margin: [0, 5], // Adjust the margin for horizontal space
                });
              } else {
                data.push({
                  text: formatSubscript(
                    div.childNodes[i].childNodes[child].textContent,
                    ["tco2e", "n2o", "ch4", "co2"],
                    ["2", "2", "4", "2"]
                  ),
                });
                data.push({
                  text: "", // Empty text

                  margin: [0, 5], // Adjust the margin for horizontal space
                });
              }
            }
          }
        } else {
          data.push({
            text: formatSubscript(
              div.childNodes[i].textContent,
              ["tco2e", "n2o", "ch4", "co2"],
              ["2", "2", "4", "2"]
            ),
          });
          data.push({
            text: "", // Empty text

            margin: [5, 5], // Adjust the margin for horizontal space
          });
        }
      }
    }

    data.forEach((k) => {
      if (k.table) {
        if (!haveSameSubarrayLengths(k?.table?.body)) {
          console.log(k?.table?.body);
        }
      }
    });

    let images = {
      clientlogo: document.getElementById("clientlogo").src,
    };

    const header = (currentPage, pageCount, pageSize) => {
      if (currentPage !== 1) {
        return {
          columns: [
            {
              text: `GRI Report FY` + year.toString().substr(2, 3),
              style: "headerText",
              margin: [30, 20],
              fit: [40, 40],
              alignment: "left",
            },
            {
              image: "clientlogo",
              fit: [40, 40],
              margin: [0, 5, 15, 0],
              alignment: "right",
            },
          ],
          // Add margins to the header
        };
      }
    };
    const documentDefinition = {
      info: {
        title: "GRI Report - " + DateTime.local().toFormat("MMMM dd, yyyy"),
        author: "Navigos",
        subject: "GRI Report",
        keywords: "Dont share unless people within same organization",
        producer: "EiSqr",
      },
      // userPassword: 'Report@',
      // ownerPassword: '123456',
      permissions: {
        printing: "highResolution", //'lowResolution'
        modifying: false,
        copying: false,
        annotating: true,
        fillingForms: true,
        contentAccessibility: true,
        documentAssembly: true,
      },
      pageSize: "A4",
      pageMargins: [30, 70, 30, 30], // [left, top, right, bottom] margins
      header,
      footer: function (currentPage, pageCount) {
        return {
          text: "Page " + currentPage + " of " + pageCount,
          alignment: "center",
          fontSize: 8,
        };
      },
      content: [...initialData, ...data],
      // defaultStyle: {
      //     font: 'Roboto'
      //   },

      images,
      styles: {
        tdHead: {
          bold: true,
          alignment: "center",
          valign: "middle",
          fillColor: "#315874",
          color: "white",
        },

        secHead: {
          bold: true,
          fillColor: "#315874",
          alignment: "center",
          padding: [10, 10],
          color: "white",
        },
        headerText: {
          fontSize: 14,
          bold: true,
          color: "para", // Text color
        },
        "text-under": {
          decoration: "underline",
          color: "#315874",
          bold: true,
        },
        boldBlue: {
          color: "#315874",
          bold: true,
        },
      },
    };
    if (type === 0) {
      const pdf = await pdfMake.createPdf(documentDefinition);
      console.log("inside if 758");
      pdf.download("GRIReport.pdf");
    } else {
      console.log("inside else 761");

      await pdfMake
        .createPdf(documentDefinition)
        .open({}, window.open("", "_blank"));
    }
  }
  function haveSameSubarrayLengths(data) {
    if (data.length < 2) {
      // If there are fewer than 2 subarrays, they are considered to have the same length.
      return true;
    }

    const firstSubarrayLength = data[0].length;

    for (let i = 1; i < data.length; i++) {
      if (data[i].length !== firstSubarrayLength) {
        return false;
      }
    }

    return true;
  }
  function generatePdfMakeContentFromTable(table) {
    if (!table) {
      console.error(`Table  not found.`);
      return [];
    }

    let contentArray = [],
      maxCol = 0;
    for (let i = 0; i < table.rows.length; i++) {
      if (maxCol <= table.rows[i].cells.length) {
        maxCol = table.rows[i].cells.length;
      }
    }

    for (let i = 0; i < table.rows.length; i++) {
      const rowArray = [];

      for (let j = 0; j < table.rows[i].cells.length; j++) {
        const cell = table.rows[i].cells[j];
        const colSpan = cell.getAttribute("colspan");
        const rowSpan = cell.getAttribute("rowspan");
        const cellText = cell.textContent.trim();

        const cellObject = { text: cellText };
        cellObject.colSpan = parseInt(colSpan);
        cellObject.rowSpan = parseInt(rowSpan);
        cellObject.style = cell.getAttribute("class");
        rowArray.push(cellObject);
        if (parseInt(colSpan) > 1) {
          for (let j = 0; j < parseInt(colSpan) - 1; j++) {
            rowArray.push({});
          }
        }
      }

      contentArray.push(rowArray);
    }
    contentArray.forEach((i, index) => {
      if (i.length !== maxCol) {
        if (contentArray[index - 1]) {
          contentArray[index - 1].forEach((k, ind) => {
            if (k.rowSpan) {
              if (k.rowSpan > 1) {
                if (k.colSpan === 1) {
                  i.splice(ind, 0, {
                    text: "",
                    colSpan: k.colSpan,
                    rowSpan: k.rowSpan - 1,
                  });
                } else {
                  let newind = ind;
                  for (let j = 0; j < parseInt(k.colSpan); j++) {
                    i.splice(newind, 0, {});
                    newind++;
                  }
                }
              }
            } else {
              for (let j = 0; j < maxCol - Object.keys(i).length; j++) {
                // i.push({id:1});
              }
            }
          });
        }
      }
    });
    contentArray.forEach((i, index) => {
      if (i.length !== maxCol) {
        let len = Object.keys(i).length;
        for (let j = 0; j < maxCol - len; j++) {
          i.push({});
        }
      }
    });
    return contentArray;
  }

  const isMergedCell = (merge, rowIndex, colIndex) => {
    return merge.some(
      (range) =>
        rowIndex >= range.s.r &&
        rowIndex <= range.e.r &&
        colIndex >= range.s.c &&
        colIndex <= range.e.c
    );
  };
  const getSum = (subset) => {
    let i = 0;
    subset.forEach((item) => {
      i = i + item[1];
    });
    return i;
  };
  const checkReportingPeriod = (rp, filter) => {
    let count = 0,
      rps = [];
    filter.forEach((item) => {
      if (rp.includes(item)) {
        count = count + 1;
        rps.push(item);
      }
    });
    return { result: count === rp.length, count: count, rps };
  };
  function getObjectsWithEmptyString(arr) {
    return arr
      .map((obj) => {
        const updatedObj = { ...obj };
        Object.keys(updatedObj).forEach((key) => {
          if (
            typeof updatedObj[key] === "string" &&
            updatedObj[key].trim() === ""
          ) {
            delete updatedObj[key];
          }
        });
        return updatedObj;
      })
      .filter((obj) => Object.values(obj).some((value) => value !== ""));
  }
  const getCellColSpan = (rowIndex, colIndex) => {
    const merge = workbook.Sheets[firstSheet]["!merges"] || [];
    for (const range of merge) {
      if (
        rowIndex >= range.s.r &&
        rowIndex <= range.e.r &&
        colIndex >= range.s.c &&
        colIndex <= range.e.c
      ) {
        return range.e.c - range.s.c + 1;
      }
    }
    return 1;
  };

  const getCellRowSpan = (rowIndex, colIndex) => {
    const merge = workbook.Sheets[firstSheet]["!merges"] || [];
    for (const range of merge) {
      if (
        rowIndex >= range.s.r &&
        rowIndex <= range.e.r &&
        colIndex >= range.s.c &&
        colIndex <= range.e.c
      ) {
        return range.e.r - range.s.r + 1;
      }
    }
    return 1;
  };
  function findValueByKey(object, key) {
    if (key in object) {
      return object[key];
    } else {
      return null; // or any other default value you want to return if the key is not found
    }
  }
  const getLastResponseByRFID = (key, uid) => {
    let locData = JSON.parse(JSON.stringify(rfData));
    let result = findValueByKey(locData, key);
    console.log(key, result);
    if (result) {
      if (result[0].type === 0) {
        if (typeof uid === "string") {
          let index = result[0].response.findIndex((k) => {
            return k.name === uid;
          });
          if (index !== -1) {
            if (result[0].response[index].type === 2) {
              let value_2 = result[0].response[index].value
                .replace(/(<([^>]+)>)/gi, "")
                .replace(/\n/g, " ")
                .replace(/&nbsp;/g, " ");
              return value_2 === null
                ? ""
                : result[0].response[index].value
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/\n/g, " ")
                    .replace(/&nbsp;/g, " ");
            } else if (
              result[0].response[index].type === 6 ||
              result[0].response[index].type === 4
            ) {
              let rflib = rflibrary.findIndex((m) => {
                return m.id === key;
              });
              console.log(rflib, key, uid);
              if (rflib !== -1) {
                let field = rflibrary[rflib].data1.findIndex((s) => {
                  return s.name === uid;
                });
                if (field !== -1) {
                  if (typeof result[0].response[index].value[0] === "number") {
                    return rflibrary[rflib].data1[field].values[
                      result[0].response[index].value[0]
                    ].label;
                  } else if (
                    typeof result[0].response[index].value[0] === "string"
                  ) {
                    let rgloc = rflibrary[rflib].data1[field].values.findIndex(
                      (l) => {
                        return l.value === result[0].response[index].value[0];
                      }
                    );
                    if (rgloc !== -1) {
                      return rflibrary[rflib].data1[field].values[rgloc].label;
                    }
                  } else {
                    return null;
                  }
                } else {
                  return "Field Not Found";
                }
              } else {
                return "RF Not Found";
              }
            } else if (result[0].response[index].type === 3) {
              let rflib = rflibrary.findIndex((m) => {
                return m.id === key;
              });
              console.log(rflib, key, uid);
              if (rflib !== -1) {
                let field = rflibrary[rflib].data1.findIndex((s) => {
                  return s.name === uid;
                });
                if (field !== -1) {
                  if (typeof result[0].response[index].value[0] === "number") {
                    return null;
                  } else if (
                    typeof result[0].response[index].value[0] === "string"
                  ) {
                    let rgloc = rflibrary[rflib].data1[field].values.findIndex(
                      (l) => {
                        return l.value === result[0].response[index].value;
                      }
                    );
                    if (rgloc !== -1) {
                      return rflibrary[rflib].data1[field].values[rgloc].label;
                    }
                  } else {
                    return null;
                  }
                } else {
                  return "Field Not Found";
                }
              } else {
                return "RF Not Found";
              }
            } else if (result[0].response[index].type === 9) {
              return DateTime.fromISO(result[0].response[index].value, {
                zone: "utc",
              }).toFormat("dd-MM-yyyy");
            } else {
              return result[0].response[index].value;
            }
          } else {
            return "NA";
          }
        } else {
          let str = "";
          uid.forEach((id) => {
            let index = result[0].response.findIndex((k) => {
              return k.name === id;
            });
            if (index !== -1) {
              str = str + " " + result[0].response[index].value;
            }
          });
          if (str.trim().length !== 0) {
            return str;
          } else {
            return "NA";
          }
        }
      } else {
        return result[0].data2
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/\n/g, " ")
          .replace(/&nbsp;/g, " ");
      }
    }
    return "";
  };
  const updateDataByYear = (val) => {
    setYear(val);
    forceUpdate();
  };

  useEffect(() => {
    const elements = Array.from(
      document.querySelectorAll("sectionheader,sectionheader1,sectionheader2")
    ).map((elem) => ({
      id: elem.id,
      text: elem.childNodes[0].textContent.trim(),
      level: Number(elem.nodeName.charAt(13)),
    }));

    let as = [],
      indx = 0;
    elements.forEach((item, ind) => {
      if (item.level === 0) {
        as[indx] = item;
        indx = indx + 1;
      } else if (elements[ind - 1].level === 0) {
        as[indx] = { item: [item], level: 1 };
        if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
          indx = indx + 1;
        }
      } else {
        as[indx].item.push(item);
        if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
          indx = indx + 1;
        }
      }
    });

    setHeadings(as);
  }, []);
  const groupArrayObject = (array, obj) => {
    return array.reduce(
      (group, arr) => {
        let key = arr[obj];

        group[key] = group[key] ?? [];

        group[key].push(arr);

        return group;
      },

      {}
    );
  };
  useEffect(() => {
    let gtaString = {
      include: ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"],
    };
    let dcf_list = [],
      dcf_submitted = [],
      locloc = [];
    let category_string = {
      include: [
        {
          relation: "newTopics",
          scope: {
            include: [
              {
                relation: "newMetrics",
                scope: { include: [{ relation: "newDataPoints" }] },
              },
            ],
          },
        },
      ],
    };

    console.log(getFiscalYearsFromStartDate(selector.information.startdate));
    let ef_complete =
      API.EF_Std +
      `?filter=%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfDates%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfs%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfItems%22%20%7D%5D%20%7D%20%7D%5D%20%7D%20%7D%5D%7D`;

    const promise1 = APIServices.get(API.DCF_Title_Only);
    const promise2 = APIServices.get(API.QN_Submit_UP(selector.id));
    const promise3 = APIServices.get(API.QNDP_Report_UP(selector.id));
    const promise4 = APIServices.get(API.RF_Submit_UP(selector.id));
    const promise5 = APIServices.get(ef_complete);
    const promise6 = APIServices.get(API.EF_SC1);
    const promise7 = APIServices.get(API.EF_SC2);
    const promise8 = APIServices.get(API.EF_SC3);
    const promise9 = APIServices.get(API.EF_SC4);

    Promise.all([
      promise1,
      promise2,
      promise3,
      promise4,
      promise5,
      promise6,
      promise7,
      promise8,
      promise9,
    ]).then(function (values) {
      dcf_list = values[0].data;
      setDcfList(values[0].data);
      dcf_submitted = values[1].data;
      setDpReport(values[2].data);
      let val = [],
        filterarr = groupArrayObject(values[2].data, "submitId");
      let loc_subcat = subcat;
      loc_subcat.one = values[5].data;
      loc_subcat.two = values[6].data;
      loc_subcat.three = values[7].data;
      loc_subcat.four = values[8].data;
      setSubCat(loc_subcat);
      setApiEF(values[4].data);
      console.log(val, "val");
      setResponse(dcf_submitted.filter((i) => i.type === 3));
      let rfid_group = groupArrayObject(values[3].data, "rfid");
      Object.keys(rfid_group).forEach((key) => {
        rfid_group[key].sort((a, b) => {
          return b.id - a.id;
        });
      });

      setRFData(rfid_group);

      console.log(rfid_group);
      forceUpdate();
    });
  }, []);

  function getFiscalYearsFromStartDate(start_date) {
    console.log(start_date);
    const startDate = DateTime.fromISO(start_date, { zone: "utc" }).plus({
      month: 1,
    });
    const currentDate = DateTime.now();

    const startFiscalYear =
      startDate.month >= fymonth ? startDate.year : startDate.year - 1;
    const currentFiscalYear =
      currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

    const fiscalYears = [];

    for (let year = startFiscalYear; year < currentFiscalYear + 1; year++) {
      const label = `${year}-${(year + 1).toString().slice(-2)}`;
      fiscalYears.push({ name: year + 1, label });
    }

    // Include the current fiscal year only if the current month is April or later
    if (currentDate.month < fymonth) {
      fiscalYears.pop();
    }

    return fiscalYears;
  }
  useEffect(() => {
    if (response.length !== 0) {
      let report_ = renderData(
        {
          location: {
            a: { name: "All", id: 0 },
            b: { name: "All", id: 0 },
            c: { name: "All", id: 0 },
          },
          year: { name: "All", id: 0 },
          month: null,
          indicator: { id: 0 },
          timeline: [],
          from: getDateObjectByMonth_Year(4, year),
          to: getDateObjectByMonth_Year(3, year + 1),
        },
        1
      );

      let reportEF_ = getEFCalculatedDataByFY({
        apief: apief,
        subcat: subcat,
        response: response,
        year: year,
        fymonth: 4,
      });

      // report_[`${year - 1}`] = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year - 1), to: getDateObjectByMonth_Year(3, year) }, 1)
      setReport(report_);
      setReportEF(reportEF_);
      forceUpdate();
    }
  }, [response, year]);

  const getScopeData = (year, area) => {
    if (report[year]) {
      return report[year]
        .filter((k) => {
          return k.scope === area;
        })
        .map((j) => {
          return j.ghg;
        })
        .reduce((a, b) => {
          return a + b;
        }, 0)
        .toFixed(2);
    }
    return 0;
  };
  const checkYear = (rp, yr) => {
    let betweenMonths = [];

    let endDate = moment.utc(getDateObjectByMonth_Year(3, yr + 1)).local();
    let startDate = moment.utc(getDateObjectByMonth_Year(4, yr)).local();
    while (startDate.startOf("month") <= endDate.startOf("month")) {
      betweenMonths.push(startDate.format("MM-YYYY"));
      startDate.add(1, "month");
    }

    return betweenMonths.filter((i) => {
      return rp.includes(i);
    }).length === rp.length
      ? 1
      : betweenMonths.filter((i) => {
          return rp.includes(i);
        }).length;
  };
  const getDataByDP = (dpid, yr) => {
    let dpreport_ = JSON.parse(JSON.stringify(dpreport));
    let d = 0;

    dpreport_
      .filter((i) => {
        return i.dp === dpid;
      })
      .forEach((k) => {
        if (checkYear(k.reporting_period, yr) !== 0) {
          d = d + k.value;
        }
      });

    return d;
  };
  const checkScope = (arr) => {
    let index = dcfass.findIndex((l) => {
      return arr.includes(l.dcfId);
    });
    return index !== -1 ? true : false;
  };
  function concatenateArrayWithAnd(array) {
    if (array.length === 1) {
      return array[0];
    } else if (array.length > 1) {
      const lastElement = array.pop(); // Remove the last element
      return `${array.join(", ")} and ${lastElement}`;
    } else {
      return "NA"; // Return an empty string if the array is empty
    }
  }
  const getMCFuelUsed = () => {
    let result = [];

    report
      .filter((l) => {
        return l.dcfId === 15;
      })
      .forEach((i, j) => {
        !result.includes(i.mode) && result.push(i.mode);
      });

    return concatenateArrayWithAnd(result);
  };
  const getSCFuelUsed = () => {
    let result = [];

    report
      .filter((l) => {
        return l.dcfId === 11;
      })
      .forEach((i, j) => {
        !result.includes(i.fuel_type) && result.push(i.fuel_type);
      });
    return concatenateArrayWithAnd(result);
  };
  const getFEGasUsed = () => {
    let result = [];

    report
      .filter((l) => {
        return l.dcfId === 10;
      })
      .forEach((i, j) => {
        result.push(i.gastype);
      });
    return concatenateArrayWithAnd(result);
  };
  function removeDuplicatesByProperties(arr, keys) {
    const seen = new Set();
    return arr.filter((item) => {
      const key = JSON.stringify(keys.map((key) => item[key]));
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    });
  }
  const renderFEGas = () => {
    let result = [];

    reportEF
      .filter((l) => {
        return l.dcfId === 10;
      })
      .forEach((i, j) => {
        result.push({
          type: i.gastype,
          ghg: i.co2e_.toFixed(2) + " kg CO2e/kg",
        });
      });

    return result.length === 0 ? [{ type: "Not Found", ghg: 0 }] : result;
  };
  const renderSCFuel = () => {
    let result = [];

    reportEF
      .filter((l) => {
        return l.dcfId === 11;
      })
      .forEach((i, j) => {
        result.push({
          type: i.fuel_type + "-" + i.unit,
          co2_: i.co2_.toFixed(2) + " kg CO2e/kg",
          n2o_: i.n2o_.toFixed(2) + " kg CO2e/kg",
          ch4_: i.ch4_.toFixed(2) + " kg CO2e/kg",
        });
      });
    return result.length === 0
      ? [{ type: "Not Found", co2_: 0, ch4_: 0, n2o_: 0 }]
      : removeDuplicatesByProperties(result, [
          "co2_",
          "n2o_",
          "ch4",
          "unit",
          "fuel_type",
        ]);
  };

  const renderMCFuel = () => {
    let result = [];

    reportEF
      .filter((l) => {
        return l.dcfId === 15;
      })
      .forEach((i, j) => {
        console.log("i", i);
        result.push({
          type: i.mode + " - " + i.fuel_cat,
          ghg: i.co2e_.toFixed(2) + " kg CO2e /litre",
        });
      });

    return result.length === 0 ? [{ type: "Not Found", ghg: 0 }] : result;
  };
  const getScopeDataByDCF = (id) => {
    console.log(
      JSON.parse(JSON.stringify(report)).filter((i) => {
        return id.includes(i.dcfId);
      }),
      "report",
      id
    );
    let report_ = JSON.parse(JSON.stringify(report))
      .filter((i) => {
        return id.includes(i.dcfId);
      })
      .map((i) => {
        return i.ghg;
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);
    return report_.toFixed(2);
  };
  const renderEmissionScope1 = (text) => {
    return (
      <>
        {checkScope([11, 10, 15]) && (
          <>
            {checkScope([11]) && (
              <tr>
                <td colspan="1" rowspan="1">
                  Stationary Combustion Emission
                </td>
                <td colspan="1" rowspan="1">
                  
                  {getScopeDataByDCF([11])}
                </td>
              </tr>
            )}
            {checkScope([15]) && (
              <tr>
                <td colspan="1" rowspan="1">
                  Mobile Combustion Emission
                </td>
                <td colspan="1" rowspan="1">
                  
                  {getScopeDataByDCF([15])}
                </td>
              </tr>
            )}
            {checkScope([10]) && (
              <tr>
                <td colspan="1" rowspan="1">
                  Fugitive Emissions
                </td>
                <td colspan="1" rowspan="1">
                  
                  {getScopeDataByDCF([10])}
                </td>
              </tr>
            )}
          </>
        )}
      </>
    );
  };
  const renderEmissionScope3 = (text) => {
    return (
      <>
        {checkScope([16, 36]) && (
          <>
            {checkScope([16]) && (
              <tr>
                <td colspan="1" rowspan="1">
                  Category 1 - Purchased goods and services
                </td>
                <td colspan="1" rowspan="1">
                  {getScopeDataByDCF([16])}
                </td>
              </tr>
            )}
            {checkScope([36]) && (
              <tr>
                <td colspan="1" rowspan="1">
                  Category 6 - Business travel
                </td>
                <td colspan="1" rowspan="1">
                  {getScopeDataByDCF([36])}
                </td>
              </tr>
            )}
          </>
        )}
      </>
    );
  };
  const renderEmissionByScopeTable = (text) => {
    return (
      <div class="gridlines-container">
        <table class="gridlines">
          <tbody>
            <tr>
              <td colspan="1" rowspan="1" className="tdHead">
                Scope of Emission
              </td>
              <td colspan="1" rowspan="1" className="tdHead">
                tCO2e
              </td>
            </tr>
            {checkScope([11, 10, 15]) && (
              <>
                <tr>
                  <td colspan="1" rowspan="1">
                    Scope 1- Direct Emissions
                  </td>
                  <td colspan="1" rowspan="1">
                    {getScopeDataByDCF([11, 10, 15])}
                  </td>
                </tr>
              </>
            )}
            {checkScope([72]) && (
              <tr>
                <td colspan="1" rowspan="1">
                  Scope 2- Indirect Emission
                </td>
                <td colspan="1" rowspan="1">
                  
                  {getScopeDataByDCF([72])}
                </td>
              </tr>
            )}
            {checkScope([16, 36]) && (
              <tr>
                <td colspan="1" rowspan="1">
                  Scope 3- Indirect emissions
                </td>
                <td colspan="1" rowspan="1">
                  {getScopeDataByDCF([16, 36])}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const renderEmissionByCategoryTable = () => {
    return (
      <div class="gridlines-container">
        <table class="gridlines">
          <tbody>
            <tr>
              <td colspan="1" rowspan="1" className="tdHead">
                Emission Profile
              </td>
              <td colspan="1" rowspan="1" className="tdHead">
                tCO2e
              </td>
            </tr>
            {checkScope([11, 10, 15]) && (
              <>
                {checkScope([11]) && (
                  <tr>
                    <td colspan="1" rowspan="1">
                      Stationary Combustion (Fuel Used)
                    </td>
                    <td colspan="1" rowspan="1">
                      
                      {getScopeDataByDCF([11])}
                    </td>
                  </tr>
                )}
                {checkScope([15]) && (
                  <tr>
                    <td colspan="1" rowspan="1">
                      Mobile Combustion (Owned Vehicles)
                    </td>
                    <td colspan="1" rowspan="1">
                      
                      {getScopeDataByDCF([15])}
                    </td>
                  </tr>
                )}
                {checkScope([10]) && (
                  <tr>
                    <td colspan="1" rowspan="1">
                      Fugitive Emissions (Refrigerants)
                    </td>
                    <td colspan="1" rowspan="1">
                      
                      {getScopeDataByDCF([10])}
                    </td>
                  </tr>
                )}
              </>
            )}
            {checkScope([72]) && (
              <>
                <tr>
                  <td colspan="1" rowspan="1">
                    Emissions from purchased energy (Grid Electricity)
                  </td>
                  <td colspan="1" rowspan="1">
                    
                    {getScopeDataByDCF([72])}
                  </td>
                </tr>
              </>
            )}
            {checkScope([16, 36]) && (
              <>
                {checkScope([16]) && (
                  <tr>
                    <td colspan="1" rowspan="1">
                      Purchased Goods and Services
                    </td>
                    <td colspan="1" rowspan="1">
                      {getScopeDataByDCF([16])}
                    </td>
                  </tr>
                )}
                {checkScope([36]) && (
                  <tr>
                    <td colspan="1" rowspan="1">
                      Business Travel
                    </td>
                    <td colspan="1" rowspan="1">
                      {getScopeDataByDCF([36])}
                    </td>
                  </tr>
                )}
                {/* <tr>
                            <td colspan="1" rowspan="1" >Employee Commute
                            </td>
                            <td colspan="1" rowspan="1" >
                            </td>
                        </tr> */}
              </>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const getDataByDCFDPID = (dcfid, dpid, yr) => {
    let response_ = JSON.parse(JSON.stringify(response));
    let d = 0;

    response_.forEach((k) => {
      if (k.dcf === dcfid) {
        if (checkYear(k.rp, yr) !== 0) {
          let result = k.response.filter((k) => {
            return k.name === dpid;
          });
          if (result.length !== 0) {
            d = d + parseInt(result[0].value.match(/\d+/)[0]);
          }
        }
      }
    });

    return d;
  };

  function getDateObjectByMonth_Year(month, year) {
    if (isNaN(month) || isNaN(year)) {
      throw new Error("Invalid month or year");
    }

    const normalizedMonth = Math.max(1, Math.min(12, month));

    const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

    return date.toJSDate();
  }
  const getRP_ = (rp) => {
    if (rp.length === 1) {
      return (
        months_[parseInt(rp[0].split("-")[0]) - 1] +
        "-" +
        rp[0].split("-")[1].slice(-2)
      );
    } else {
      return (
        months_[parseInt(rp[0].split("-")[0]) - 1] +
        "-" +
        rp[0].split("-")[1].slice(-2) +
        "to" +
        months_[parseInt(rp[rp.length - 1].split("-")[0]) - 1] +
        "-" +
        rp[rp.length - 1].split("-")[1].slice(-2)
      );
    }
  };
  const getClassName = (level) => {
    switch (level) {
      case 1:
        return "head1";
      case 2:
        return "head2";
      case 3:
        return "head3";
      default:
        return null;
    }
  };

  const checkSite = (id, filter) => {
    let idlist = [];

    siteList.forEach((country) => {
      if (filter.a.id === 0 || filter.a.id === country.id) {
        country.locationTwos.forEach((city) => {
          if (filter.b.id === 0 || filter.b.id === city.id) {
            city.locationThrees.forEach((loc) => {
              if (filter.c.id == 0 || filter.c.id === loc.id) {
                idlist.push(loc.id);
              }
            });
          }
        });
      }
    });

    return idlist.includes(id);
  };
  const groupArrayObject_3_Keys = (array, obj1, obj2, obj3) => {
    return array.reduce((result, arr) => {
      let key1 = arr[obj1];
      let key2 = arr[obj2];
      let key3 = arr[obj3];

      const key = `${key1}-${key2}-${key3}`;

      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(arr);

      return result;
    }, {});
  };
  const renderData = (search) => {
    let betweenMonths = [],
      betweenYears = [];
    let monthly_fg = [];

    let endDate = moment.utc(search.to).local();
    let startDate = moment.utc(search.from).local();
    let year = moment(moment.utc()).format("YYYY");

    if (search.to !== null && search.from !== null) {
      while (startDate.startOf("month") <= endDate.startOf("month")) {
        betweenMonths.push(startDate.format("MM-YYYY"));
        !betweenYears.includes(startDate.format("YYYY")) &&
          betweenYears.push(startDate.format("YYYY"));

        startDate.add(1, "month");
      }

      let res = JSON.parse(JSON.stringify(response));

      res.forEach((report, rind) => {
        let sc_total = 0,
          fg_total = 0;

        if (checkSite(report.site, search.location)) {
          report.sitename = locationList.filter((loc) => {
            return loc.id === report.site;
          })[0].name;
          if (
            report.dcf === 16 &&
            (search.indicator.id === 0 || search.indicator.id === 122)
          ) {
            report.response.forEach((fg, ind) => {
              let date = fg.filter((i) => {
                return i.dp === "DPA0285";
              })[0].value;
              let yearind = monthly_fg.findIndex((i) => {
                return i.year === moment(date).format("YYYY");
              });
              let monthind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY")
                );
              });
              let dcfind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf
                );
              });
              let siteind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf &&
                  i.site === report.site
                );
              });

              if (
                checkReportingPeriod(
                  [moment(date).format("MM-YYYY")],
                  betweenMonths
                ).result
              ) {
                let item_class = fg.filter((i) => {
                  return i.dp === "DPA0287";
                })[0].value.name;
                let item_qty = fg.filter((i) => {
                  return i.dp === "DPA0288";
                })[0].value;
                let price_per_item = fg.filter((i) => {
                  return i.dp === "DPA0289";
                })[0].value;

                let total =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return k.item_classification === item_class;
                    }
                  )[0]["co2e_in_kg"] /
                    1000) *
                  item_qty *
                  price_per_item;

                fg_total = total + fg_total;

                let updateind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(date).format("YYYY") &&
                    i.month === moment(date).format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site &&
                    i.item_class === item_class
                  );
                });

                if (updateind === -1) {
                  monthly_fg.push({
                    scope: 3,
                    year: moment(date).format("YYYY"),
                    month: moment(date).format("MMM-YYYY"),
                    ghg: total,
                    dcf: report.dcf,
                    site: report.site,
                    item_class: item_class,
                  });
                } else {
                  monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                }
              }
            });
          }
          if (
            report.dcf === 36 &&
            (search.indicator.id === 0 || search.indicator.id === 123)
          ) {
            report.response.forEach((fg, ind) => {
              console.log(fg);
              let date = fg.filter((i) => {
                return i.dp === "DPA0290";
              })[0].value;
              let yearind = monthly_fg.findIndex((i) => {
                return i.year === moment(date).format("YYYY");
              });
              let monthind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY")
                );
              });
              let dcfind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf
                );
              });
              let siteind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf &&
                  i.site === report.site
                );
              });

              if (
                checkReportingPeriod(
                  [moment(date).format("MM-YYYY")],
                  betweenMonths
                ).result
              ) {
                let total = 0;

                let travel_mode = fg.filter((i) => {
                  return i.dp === "DPA0291";
                })[0].value.name;
                let passenger = fg.filter((i) => {
                  return i.dp === "DPA0292";
                })[0].value;

                if (travel_mode.toLowerCase() === "air") {
                  total =
                    fg.filter((i) => {
                      return i.dp === "DP_co2e_mt";
                    })[0].value * passenger;

                  let updateind = monthly_fg.findIndex((i) => {
                    return (
                      i.year === moment(date).format("YYYY") &&
                      i.month === moment(date).format("MMM-YYYY") &&
                      i.dcf === report.dcf &&
                      i.site === report.site &&
                      i.travel_mode === travel_mode
                    );
                  });

                  if (updateind === -1) {
                    monthly_fg.push({
                      scope: 3,
                      year: moment(date).format("YYYY"),
                      month: moment(date).format("MMM-YYYY"),
                      ghg: total,
                      dcf: report.dcf,
                      site: report.site,
                      travel_mode: travel_mode,
                    });
                  } else {
                    monthly_fg[updateind].ghg =
                      monthly_fg[updateind].ghg + total;
                  }

                  fg_total = total + fg_total;
                } else if (travel_mode.toLowerCase() === "road") {
                  let veh_cat = fg.filter((i) => {
                    return i.dp === "DPA0295";
                  })[0].value.name;
                  let veh_type = fg.filter((i) => {
                    return i.dp === "DPA0337";
                  })[0].value;
                  let fuel = fg.filter((i) => {
                    return i.dp === "DPA0338";
                  })[0].value;
                  let km = fg.filter((i) => {
                    return i.dp === "DP_KM";
                  })[0].value;
                  if (veh_cat.includes("Cars") || veh_cat.includes("Motor")) {
                    if (veh_cat.includes("Cars")) {
                      total =
                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter(
                          (k) => {
                            return (
                              k.vehicle_category === veh_cat &&
                              k.vehicle_type === veh_type.name &&
                              k.fuel_type === fuel.name &&
                              k.unit === "km"
                            );
                          }
                        )[0]["co2e_in_kg"] /
                          1000) *
                        km *
                        passenger;
                    } else {
                      total =
                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter(
                          (k) => {
                            return (
                              k.vehicle_category === veh_cat &&
                              k.vehicle_type === veh_type.name &&
                              k.unit === "km"
                            );
                          }
                        )[0]["co2e_in_kg"] /
                          1000) *
                        km *
                        passenger;
                    }
                  } else {
                    total =
                      (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter(
                        (k) => {
                          return (
                            k.vehicle_category === veh_cat &&
                            k.unit === "passenger.km"
                          );
                        }
                      )[0]["co2e_in_kg"] /
                        1000) *
                      km *
                      passenger;
                  }

                  let updateind = monthly_fg.findIndex((i) => {
                    return (
                      i.year === moment(date).format("YYYY") &&
                      i.month === moment(date).format("MMM-YYYY") &&
                      i.dcf === report.dcf &&
                      i.site === report.site &&
                      i.travel_mode === travel_mode
                    );
                  });

                  if (updateind === -1) {
                    monthly_fg.push({
                      scope: 3,
                      year: moment(date).format("YYYY"),
                      month: moment(date).format("MMM-YYYY"),
                      ghg: total,
                      dcf: report.dcf,
                      site: report.site,
                      travel_mode: travel_mode,
                    });
                  } else {
                    monthly_fg[updateind].ghg =
                      monthly_fg[updateind].ghg + total;
                  }

                  fg_total = total + fg_total;
                } else if (travel_mode.toLowerCase() === "rail") {
                  total =
                    fg.filter((i) => {
                      return i.dp === "DP_KM";
                    })[0].value *
                    passenger *
                    0.00116;

                  let updateind = monthly_fg.findIndex((i) => {
                    return (
                      i.year === moment(date).format("YYYY") &&
                      i.month === moment(date).format("MMM-YYYY") &&
                      i.dcf === report.dcf &&
                      i.site === report.site &&
                      i.travel_mode === travel_mode
                    );
                  });

                  if (updateind === -1) {
                    monthly_fg.push({
                      scope: 3,
                      year: moment(date).format("YYYY"),
                      month: moment(date).format("MMM-YYYY"),
                      ghg: total,
                      dcf: report.dcf,
                      site: report.site,
                      travel_mode: travel_mode,
                    });
                  } else {
                    monthly_fg[updateind].ghg =
                      monthly_fg[updateind].ghg + total;
                  }

                  fg_total = total + fg_total;
                }
              }
            });
          }

          if (
            report.dcf === 11 &&
            (search.indicator.id === 0 || search.indicator.id === 93)
          ) {
            let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

            let total = 0,
              co2 = 0,
              ch4 = 0,
              n2o = 0,
              sc_data = [];
            report.response.forEach((fg, ind) => {
              let fuel_cat = fg.filter((i) => {
                return i.dp === "DPA0130";
              })[0].value.name;
              let fuel_type = fg.filter((i) => {
                return i.dp === "DPA0131";
              })[0].value.name;
              let unit = fg.filter((i) => {
                return i.dp === "DPA0132";
              })[0].value.name;
              let consumed = fg.filter((i) => {
                return i.dp === "DPA0336";
              })[0].value;
              let fuel_cat_ind = sc_data.findIndex((k) => {
                return k.fuel_type === fuel_type;
              });

              if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                let carbon = fg.filter((i) => {
                  return i.dp === "DPA0134";
                })[0].value;
                let cv = fg.filter((i) => {
                  return i.dp === "DPA0133";
                })[0].value;
                if (carbon > 0 && cv > 0) {
                  let gj = (carbon * 3.664 * 1000) / cv;
                  total = (gj * 0.000004184 * consumed) / 1000;
                  fg["value"] = [
                    "Emission Factor-",
                    3.664,
                    " ,EnergyProduced-",
                    0.000004184,
                  ];
                  co2 = 0;
                  ch4 = 0;
                  n2o = 0;
                  if (fuel_cat_ind === -1) {
                    sc_data.push({
                      fuel_type: fuel_type,
                      ghg: total,
                      co2,
                      n2o,
                      ch4,
                    });
                  } else {
                    sc_data[fuel_cat_ind].ghg =
                      sc_data[fuel_cat_ind].ghg + total;
                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                  }
                } else {
                  total =
                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["co2e_in_kg"] /
                      1000) *
                    consumed;
                  co2 =
                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["co2_in_kg"] /
                      1000) *
                    consumed;
                  ch4 =
                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["ch4_in_kg"] /
                      1000) *
                    consumed;
                  n2o =
                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["n2o_in_kg"] /
                      1000) *
                    consumed;
                  fg["value"] = [
                    "co2-",
                    co2 / consumed,
                    " ,ch4-",
                    ch4 / consumed,
                    " ,n2o-",
                    n2o / consumed,
                  ];
                  if (fuel_cat_ind === -1) {
                    sc_data.push({
                      fuel_type: fuel_type,
                      ghg: total,
                      co2,
                      n2o,
                      ch4,
                    });
                  } else {
                    sc_data[fuel_cat_ind].ghg =
                      sc_data[fuel_cat_ind].ghg + total;
                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                  }
                }
              } else {
                total =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["co2e_in_kg"] /
                    1000) *
                  consumed;
                co2 =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["co2_in_kg"] /
                    1000) *
                  consumed;
                ch4 =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["ch4_in_kg"] /
                    1000) *
                  consumed;
                n2o =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["n2o_in_kg"] /
                    1000) *
                  consumed;
                fg["value"] = [
                  "co2-",
                  co2 / consumed,
                  " ,ch4-",
                  ch4 / consumed,
                  " ,n2o-",
                  n2o / consumed,
                ];
                if (fuel_cat_ind === -1) {
                  sc_data.push({
                    fuel_type: fuel_type,
                    ghg: total,
                    co2,
                    n2o,
                    ch4,
                  });
                } else {
                  sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                  sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                  sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                  sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                }
              }
            });
            addedMonth.rps.forEach((fm) => {
              let date = moment(fm, "MM-YYYY").toDate();
              let yearind = monthly_fg.findIndex((i) => {
                return i.year === moment(date).format("YYYY");
              });
              let monthind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY")
                );
              });
              let dcfind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf
                );
              });
              let siteind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf &&
                  i.site === report.site
                );
              });

              sc_data.forEach((item) => {
                let updateind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(date).format("YYYY") &&
                    i.month === moment(date).format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site &&
                    i.fuel_type === item.fuel_type
                  );
                });

                if (updateind === -1) {
                  monthly_fg.push({
                    scope: 1,
                    year: moment(date).format("YYYY"),
                    month: moment(date).format("MMM-YYYY"),
                    ghg: item.ghg / addedMonth.count,
                    dcf: report.dcf,
                    site: report.site,
                    fuel_type: item.fuel_type,
                    co2: (item.co2 * 1000) / addedMonth.count,
                    n2o: (item.n20 * 1000) / addedMonth.count,
                    ch4: (item.ch4 * 1000) / addedMonth.count,
                  });
                } else {
                  monthly_fg[updateind].co2 =
                    ((item.co2 * 1000) / addedMonth.count) * 1000 +
                    monthly_fg[updateind].co2;
                  monthly_fg[updateind].n2o =
                    ((item.n2o * 1000) / addedMonth.count) * 1000 +
                    monthly_fg[updateind].n2o;
                  monthly_fg[updateind].ch4 =
                    ((item.ch4 * 1000) / addedMonth.count) * 1000 +
                    monthly_fg[updateind].ch4;
                  monthly_fg[updateind].ghg =
                    monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                }
              });
            });
          }
          if (
            report.dcf === 10 &&
            (search.indicator.id === 0 || search.indicator.id === 116)
          ) {
            let add = [];

            report.response.forEach((fg, ind) => {
              let date = fg.filter((i) => {
                return i.dp === "DPA0137";
              })[0].value;
              let yearind = monthly_fg.findIndex((i) => {
                return i.year === moment(date).format("YYYY");
              });
              let monthind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY")
                );
              });
              let dcfind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf
                );
              });
              let siteind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf &&
                  i.site === report.site
                );
              });

              if (
                checkReportingPeriod(
                  [moment(date).format("MM-YYYY")],
                  betweenMonths
                ).result
              ) {
                let gastype = fg.filter((i) => {
                  return i.dp === "DPA0136";
                })[0].value.name;
                let gasrefilled = fg.filter((i) => {
                  return i.dp === "DPA0138";
                })[0].value;
                let total =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter(
                    (k) => {
                      return k.gas_type === gastype;
                    }
                  )[0]["co2e_in_kg"] /
                    1000) *
                  gasrefilled;

                fg_total = total + fg_total;

                fg["gas"] = gastype;
                fg["gasfilled"] = gasrefilled;
                fg["value"] = ["EmissionFactor-", total / gasrefilled];
                let updateind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(date).format("YYYY") &&
                    i.month === moment(date).format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site &&
                    i.gastype === gastype
                  );
                });

                if (updateind === -1) {
                  monthly_fg.push({
                    scope: 1,
                    year: moment(date).format("YYYY"),
                    month: moment(date).format("MMM-YYYY"),
                    ghg: 0,
                    dcf: report.dcf,
                    site: report.site,
                    gastype: gastype,
                    ghg: total,
                  });
                } else {
                  monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                }
              }
            });
          }
          if (
            report.dcf === 72 &&
            (search.indicator.id === 0 || search.indicator.id === 121)
          ) {
            report.rp.forEach((i) => {
              if (checkReportingPeriod([i], betweenMonths).result) {
                let yearind = monthly_fg.findIndex((i) => {
                  return i.year === moment(i, "MM-YYYY").format("YYYY");
                });
                let monthind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(i, "MM-YYYY").format("YYYY") &&
                    i.month === moment(i, "MM-YYYY").format("MMM-YYYY")
                  );
                });
                let dcfind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(i, "MM-YYYY").format("YYYY") &&
                    i.month === moment(i, "MM-YYYY").format("MMM-YYYY") &&
                    i.dcf === report.dcf
                  );
                });
                let siteind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(i, "MM-YYYY").format("YYYY") &&
                    i.month === moment(i, "MM-YYYY").format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site
                  );
                });

                let ef =
                  emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1]
                    .stdTopics[0].data1[0].importedData[0]["kwh_in_tco2e"];

                let renewable =
                  report.response[0].filter((i) => {
                    return i.dp === "DPA0156";
                  })[0].value / report.rp.length;
                let nonrenewable =
                  report.response[0].filter((i) => {
                    return i.dp === "DPA0157";
                  })[0].value / report.rp.length;

                if (yearind === -1) {
                  monthly_fg.push({
                    scope: 2,
                    year: moment(i, "MM-YYYY").format("YYYY"),
                    month: moment(i, "MM-YYYY").format("MMM-YYYY"),
                    ghg: nonrenewable * ef,
                    dcf: report.dcf,
                    site: report.site,
                    renewable,
                    nonrenewable,
                  });
                } else if (monthind === -1) {
                  monthly_fg.push({
                    scope: 2,
                    year: moment(i, "MM-YYYY").format("YYYY"),
                    month: moment(i, "MM-YYYY").format("MMM-YYYY"),
                    ghg: nonrenewable * ef,
                    dcf: report.dcf,
                    site: report.site,
                    renewable,
                    nonrenewable,
                  });
                } else if (dcfind === -1) {
                  monthly_fg.push({
                    scope: 2,
                    year: moment(i, "MM-YYYY").format("YYYY"),
                    month: moment(i, "MM-YYYY").format("MMM-YYYY"),
                    ghg: nonrenewable * ef,
                    dcf: report.dcf,
                    site: report.site,
                    renewable,
                    nonrenewable,
                  });
                } else if (siteind === -1) {
                  monthly_fg.push({
                    scope: 2,
                    year: moment(i, "MM-YYYY").format("YYYY"),
                    month: moment(i, "MM-YYYY").format("MMM-YYYY"),
                    ghg: nonrenewable * ef,
                    dcf: report.dcf,
                    site: report.site,
                    renewable,
                    nonrenewable,
                  });
                }
              }
            });
          }
          if (
            report.dcf === 15 &&
            (search.indicator.id === 0 || search.indicator.id === 118)
          ) {
            let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

            let ef_by_fuel =
              emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0]
                .stdTopics[4].data1[0].importedData;
            let ef_by_distance =
              emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0]
                .stdTopics[1].data1[0].importedData;

            let total = 0,
              co2 = 0,
              ch4 = 0,
              n2o = 0,
              mc_data = [];

            report.response.forEach((fg, ind) => {
              let mode = fg.filter((i) => {
                return i.dp === "DP_MODE";
              })[0].value
                ? "by distance"
                : "by fuel";
              let fuel_cat = fg.filter((i) => {
                return i.dp === "DPA0140";
              })[0].value;
              let fuel_type = fg.filter((i) => {
                return i.dp === "DPA0139";
              })[0].value;
              let fuel = fg.filter((i) => {
                return i.dp === "DPA0141";
              })[0].value.name;
              let unit = fg.filter((i) => {
                return i.dp === "DPA0339";
              })[0].value;
              let km = fg.filter((i) => {
                return i.dp === "DPA0144";
              })[0].value;
              let fuel_filled = fg.filter((i) => {
                return i.dp === "DPA0143";
              })[0].value;
              let fuel_cat_ind = mc_data.findIndex((k) => {
                return k.fuel === fuel;
              });

              if (mode === "by distance") {
                total = ef_by_distance.filter((k) => {
                  return (
                    k.vehicle_category === fuel_cat.name &&
                    k.vehicle_type === fuel_type.name &&
                    k.fuel_type === fuel &&
                    k.unit === unit.name
                  );
                })[0]["co2e_in_kg"];

                if (fuel_cat_ind === -1) {
                  mc_data.push({
                    mode: fuel,
                    ghg: total * km,
                    fuel_cat: fuel_cat.name,
                  });
                } else {
                  mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                }
              } else {
                total = ef_by_fuel.filter((k) => {
                  return k.fuel.trim() === fuel.trim();
                })[0]["co2e_in_kg"];

                if (fuel_cat_ind === -1) {
                  mc_data.push({ mode: fuel, ghg: total * fuel_filled });
                } else {
                  mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                }
              }
            });
            addedMonth.rps.forEach((fm) => {
              let date = moment(fm, "MM-YYYY").toDate();

              mc_data.forEach((item) => {
                let updateind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(date).format("YYYY") &&
                    i.month === moment(date).format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site &&
                    i.mode === item.mode
                  );
                });

                if (updateind === -1) {
                  let updateind2 = monthly_fg.findIndex((i) => {
                    return (
                      i.year === moment(date).format("YYYY") &&
                      i.month === moment(date).format("MMM-YYYY") &&
                      i.dcf === report.dcf &&
                      i.site === report.site &&
                      i.mode === item.mode
                    );
                  });
                  if (updateind2 !== -1) {
                    monthly_fg[updateind2].ghg =
                      item.ghg / addedMonth.count + monthly_fg[updateind2].ghg;
                  } else {
                    monthly_fg.push({
                      scope: 1,
                      year: moment(date).format("YYYY"),
                      month: moment(date).format("MMM-YYYY"),
                      ghg: 0,
                      dcf: report.dcf,
                      site: report.site,
                      ghg: item.ghg / addedMonth.count,
                      mode: item.mode,
                      fuel_cat: item.fuel_cat,
                    });
                  }
                } else {
                  monthly_fg[updateind].ghg =
                    monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                }
              });
            });
          }
        }
      });

      let scope12_array = groupArrayObject(
        monthly_fg.filter((i) => {
          return i.scope === 1 || i.scope === 2;
        }),
        "month"
      );
      let scope3_array = groupArrayObject(
        monthly_fg.filter((i) => {
          return i.scope === 3;
        }),
        "month"
      );
      let scope1_array = groupArrayObject(
        monthly_fg.filter((i) => {
          return i.scope === 1;
        }),
        "month"
      );
      let scope2_array = groupArrayObject(
        monthly_fg.filter((i) => {
          return i.scope === 2;
        }),
        "month"
      );
      let pie = [];
      monthly_fg
        .filter((i) => {
          return i.scope === 1;
        })
        .forEach((item) => {
          let index = pie.findIndex((j) => {
            return j.name === "Scope " + item.scope;
          });
          if (index === -1) {
            pie.push({ name: "Scope " + item.scope, y: item.ghg });
          } else {
            pie[index].y = pie[index].y + item.ghg;
          }
        });
      monthly_fg
        .filter((i) => {
          return i.scope === 2;
        })
        .forEach((item) => {
          let index = pie.findIndex((j) => {
            return j.name === "Scope " + item.scope;
          });
          if (index === -1) {
            pie.push({ name: "Scope " + item.scope, y: item.ghg });
          } else {
            pie[index].y = pie[index].y + item.ghg;
          }
        });
      monthly_fg
        .filter((i) => {
          return i.scope === 3;
        })
        .forEach((item) => {
          let index = pie.findIndex((j) => {
            return j.name === "Scope " + item.scope;
          });
          if (index === -1) {
            pie.push({ name: "Scope " + item.scope, y: item.ghg });
          } else {
            pie[index].y = pie[index].y + item.ghg;
          }
        });

      let index1 = pie.findIndex((j) => {
        return j.name === "Scope 1";
      });
      let index2 = pie.findIndex((j) => {
        return j.name === "Scope 2";
      });
      let index3 = pie.findIndex((j) => {
        return j.name === "Scope 3";
      });

      if (index1 === -1) {
        pie.push({ name: "Scope 1", y: 0 });
      }
      if (index2 === -1) {
        pie.push({ name: "Scope 2", y: 0 });
      }
      if (index3 === -1) {
        pie.push({ name: "Scope 3", y: 0 });
      }

      if (scope1_array.length !== 0) {
        let scope1_tier2 = [],
          final = [];
        Object.keys(scope1_array).forEach((key) => {
          scope1_array[key].forEach((item) => {
            item.dcfId = item.dcf;
            item.dcf =
              dcflist.filter((l) => {
                return l.id === item.dcf;
              }).length === 0
                ? item.dcf
                : dcflist.filter((l) => {
                    return l.id === item.dcf;
                  })[0].title;

            if (
              scope1_tier2.findIndex((i) => {
                return i.name === item.dcf;
              }) === -1
            ) {
              scope1_tier2.push({
                name: item.dcf,
                drilldown: true,
                color: "red",
                y: item.ghg,

                subset: [[key, item.ghg]],
              });
            } else {
              let ind = scope1_tier2.findIndex((i) => {
                return i.name === item.dcf;
              });
              let subind = scope1_tier2[ind].subset.findIndex((j) => {
                return j[0] === key;
              });
              scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
              if (subind === -1) {
                scope1_tier2[ind].subset.push([key, item.ghg]);
              } else {
                scope1_tier2[ind].subset[subind][1] =
                  scope1_tier2[ind].subset[subind][1] + item.ghg;
              }
            }
          });
        });

        betweenYears.forEach((j) => {
          let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => {
            i.subset.sort((a, b) => {
              return (
                moment(a[0], "MMM-YYYY").toDate() -
                moment(b[0], "MMM-YYYY").toDate()
              );
            });
            i.subset = i.subset.filter((k) => {
              return k[0].split("-")[1] === j;
            });
            i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
            i.incomplete = false;
            return i;
          });

          final.push(dataset);
        });
      }
      if (scope2_array.length !== 0) {
        let scope2_tier2 = [],
          final = [];
        Object.keys(scope2_array).forEach((key) => {
          scope2_array[key].forEach((item) => {
            item.dcfId = item.dcf;
            item.dcf =
              dcflist.filter((l) => {
                return l.id === item.dcf;
              }).length === 0
                ? item.dcf
                : dcflist.filter((l) => {
                    return l.id === item.dcf;
                  })[0].title;
            if (
              scope2_tier2.findIndex((i) => {
                return i.name === item.dcf;
              }) === -1
            ) {
              scope2_tier2.push({
                name: item.dcf,
                drilldown: true,
                color: "red",
                y: item.ghg,
                subset: [[key, item.ghg]],
              });
            } else {
              let ind = scope2_tier2.findIndex((i) => {
                return i.name === item.dcf;
              });
              let subind = scope2_tier2[ind].subset.findIndex((j) => {
                return j[0] === key;
              });
              scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
              if (subind === -1) {
                scope2_tier2[ind].subset.push([key, item.ghg]);
              } else {
                scope2_tier2[ind].subset[subind][1] =
                  scope2_tier2[ind].subset[subind][1] + item.ghg;
              }
            }
          });
        });
        betweenYears.forEach((j) => {
          let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => {
            i.subset.sort((a, b) => {
              return (
                moment(a[0], "MMM-YYYY").toDate() -
                moment(b[0], "MMM-YYYY").toDate()
              );
            });
            i.subset = i.subset.filter((k) => {
              return k[0].split("-")[1] === j;
            });
            i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
            i.incomplete = false;
            return i;
          });

          final.push(dataset);
        });
      }
      if (scope3_array.length !== 0) {
        let scope3_tier2 = [],
          final = [];
        Object.keys(scope3_array).forEach((key) => {
          scope3_array[key].forEach((item) => {
            item.dcfId = item.dcf;
            item.dcf =
              dcflist.filter((l) => {
                return l.id === item.dcf;
              }).length === 0
                ? item.dcf
                : dcflist.filter((l) => {
                    return l.id === item.dcf;
                  })[0].title;
            if (
              scope3_tier2.findIndex((i) => {
                return i.name === item.dcf;
              }) === -1
            ) {
              scope3_tier2.push({
                name: item.dcf,
                drilldown: true,
                color: "red",
                y: item.ghg,
                subset: [[key, item.ghg]],
              });
            } else {
              let ind = scope3_tier2.findIndex((i) => {
                return i.name === item.dcf;
              });
              let subind = scope3_tier2[ind].subset.findIndex((j) => {
                return j[0] === key;
              });
              scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
              if (subind === -1) {
                scope3_tier2[ind].subset.push([key, item.ghg]);
              } else {
                scope3_tier2[ind].subset[subind][1] =
                  scope3_tier2[ind].subset[subind][1] + item.ghg;
              }
            }
          });
        });
        betweenYears.forEach((j) => {
          let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => {
            i.subset.sort((a, b) => {
              return (
                moment(a[0], "MMM-YYYY").toDate() -
                moment(b[0], "MMM-YYYY").toDate()
              );
            });
            i.subset = i.subset.filter((k) => {
              return k[0].split("-")[1] === j;
            });
            i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
            i.incomplete = false;
            return i;
          });

          final.push(dataset);
        });
      }
    }

    return monthly_fg;
  };
  const renderDataEF = (search) => {
    let betweenMonths = [],
      betweenYears = [];
    let monthly_fg = [];

    let endDate = moment.utc(search.to).local();
    let startDate = moment.utc(search.from).local();
    let year = moment(moment.utc()).format("YYYY");

    if (search.to !== null && search.from !== null) {
      while (startDate.startOf("month") <= endDate.startOf("month")) {
        betweenMonths.push(startDate.format("MM-YYYY"));
        !betweenYears.includes(startDate.format("YYYY")) &&
          betweenYears.push(startDate.format("YYYY"));

        startDate.add(1, "month");
      }

      let res = JSON.parse(JSON.stringify(response));

      res.forEach((report, rind) => {
        let sc_total = 0,
          fg_total = 0;

        if (checkSite(report.site, search.location)) {
          report.sitename = locationList.filter((loc) => {
            return loc.id === report.site;
          })[0].name;
          if (
            report.dcf === 16 &&
            (search.indicator.id === 0 || search.indicator.id === 122)
          ) {
            report.response.forEach((fg, ind) => {
              let date = fg.filter((i) => {
                return i.dp === "DPA0285";
              })[0].value;
              let yearind = monthly_fg.findIndex((i) => {
                return i.year === moment(date).format("YYYY");
              });
              let monthind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY")
                );
              });
              let dcfind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf
                );
              });
              let siteind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf &&
                  i.site === report.site
                );
              });

              if (
                checkReportingPeriod(
                  [moment(date).format("MM-YYYY")],
                  betweenMonths
                ).result
              ) {
                let item_class = fg.filter((i) => {
                  return i.dp === "DPA0287";
                })[0].value.name;
                let item_qty = fg.filter((i) => {
                  return i.dp === "DPA0288";
                })[0].value;
                let price_per_item = fg.filter((i) => {
                  return i.dp === "DPA0289";
                })[0].value;

                let total =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return k.item_classification === item_class;
                    }
                  )[0]["co2e_in_kg"] /
                    1000) *
                  item_qty *
                  price_per_item;

                fg_total = total + fg_total;

                let updateind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(date).format("YYYY") &&
                    i.month === moment(date).format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site &&
                    i.item_class === item_class
                  );
                });

                if (updateind === -1) {
                  monthly_fg.push({
                    scope: 3,
                    year: moment(date).format("YYYY"),
                    month: moment(date).format("MMM-YYYY"),
                    ghg: total,
                    dcf: report.dcf,
                    site: report.site,
                    item_class: item_class,
                  });
                } else {
                  monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                }
              }
            });
          }
          if (
            report.dcf === 36 &&
            (search.indicator.id === 0 || search.indicator.id === 123)
          ) {
            report.response.forEach((fg, ind) => {
              let date = fg.filter((i) => {
                return i.dp === "DPA0290";
              })[0].value;
              let yearind = monthly_fg.findIndex((i) => {
                return i.year === moment(date).format("YYYY");
              });
              let monthind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY")
                );
              });
              let dcfind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf
                );
              });
              let siteind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf &&
                  i.site === report.site
                );
              });

              if (
                checkReportingPeriod(
                  [moment(date).format("MM-YYYY")],
                  betweenMonths
                ).result
              ) {
                let total = 0;

                let travel_mode = fg.filter((i) => {
                  return i.dp === "DPA0291";
                })[0].value.name;
                let passenger = fg.filter((i) => {
                  return i.dp === "DPA0292";
                })[0].value;

                if (travel_mode.toLowerCase() === "air") {
                  total =
                    fg.filter((i) => {
                      return i.dp === "DP_co2e_mt";
                    })[0].value * passenger;

                  let updateind = monthly_fg.findIndex((i) => {
                    return (
                      i.year === moment(date).format("YYYY") &&
                      i.month === moment(date).format("MMM-YYYY") &&
                      i.dcf === report.dcf &&
                      i.site === report.site &&
                      i.travel_mode === travel_mode
                    );
                  });

                  if (updateind === -1) {
                    monthly_fg.push({
                      scope: 3,
                      year: moment(date).format("YYYY"),
                      month: moment(date).format("MMM-YYYY"),
                      ghg: total,
                      dcf: report.dcf,
                      site: report.site,
                      travel_mode: travel_mode,
                    });
                  } else {
                    monthly_fg[updateind].ghg =
                      monthly_fg[updateind].ghg + total;
                  }

                  fg_total = total + fg_total;
                } else if (travel_mode.toLowerCase() === "road") {
                  let veh_cat = fg.filter((i) => {
                    return i.dp === "DPA0295";
                  })[0].value.name;
                  let veh_type = fg.filter((i) => {
                    return i.dp === "DPA0337";
                  })[0].value;
                  let fuel = fg.filter((i) => {
                    return i.dp === "DPA0338";
                  })[0].value;
                  let km = fg.filter((i) => {
                    return i.dp === "DP_KM";
                  })[0].value;
                  if (veh_cat.includes("Cars") || veh_cat.includes("Motor")) {
                    if (veh_cat.includes("Cars")) {
                      total =
                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter(
                          (k) => {
                            return (
                              k.vehicle_category === veh_cat &&
                              k.vehicle_type === veh_type.name &&
                              k.fuel_type === fuel.name &&
                              k.unit === "km"
                            );
                          }
                        )[0]["co2e_in_kg"] /
                          1000) *
                        km *
                        passenger;
                    } else {
                      total =
                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter(
                          (k) => {
                            return (
                              k.vehicle_category === veh_cat &&
                              k.vehicle_type === veh_type.name &&
                              k.unit === "km"
                            );
                          }
                        )[0]["co2e_in_kg"] /
                          1000) *
                        km *
                        passenger;
                    }
                  } else {
                    total =
                      (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter(
                        (k) => {
                          return (
                            k.vehicle_category === veh_cat &&
                            k.unit === "passenger.km"
                          );
                        }
                      )[0]["co2e_in_kg"] /
                        1000) *
                      km *
                      passenger;
                  }

                  let updateind = monthly_fg.findIndex((i) => {
                    return (
                      i.year === moment(date).format("YYYY") &&
                      i.month === moment(date).format("MMM-YYYY") &&
                      i.dcf === report.dcf &&
                      i.site === report.site &&
                      i.travel_mode === travel_mode
                    );
                  });

                  if (updateind === -1) {
                    monthly_fg.push({
                      scope: 3,
                      year: moment(date).format("YYYY"),
                      month: moment(date).format("MMM-YYYY"),
                      ghg: total,
                      dcf: report.dcf,
                      site: report.site,
                      travel_mode: travel_mode,
                    });
                  } else {
                    monthly_fg[updateind].ghg =
                      monthly_fg[updateind].ghg + total;
                  }

                  fg_total = total + fg_total;
                } else if (travel_mode.toLowerCase() === "rail") {
                  total =
                    fg.filter((i) => {
                      return i.dp === "DP_KM";
                    })[0].value *
                    passenger *
                    0.00116;

                  let updateind = monthly_fg.findIndex((i) => {
                    return (
                      i.year === moment(date).format("YYYY") &&
                      i.month === moment(date).format("MMM-YYYY") &&
                      i.dcf === report.dcf &&
                      i.site === report.site &&
                      i.travel_mode === travel_mode
                    );
                  });

                  if (updateind === -1) {
                    monthly_fg.push({
                      scope: 3,
                      year: moment(date).format("YYYY"),
                      month: moment(date).format("MMM-YYYY"),
                      ghg: total,
                      dcf: report.dcf,
                      site: report.site,
                      travel_mode: travel_mode,
                    });
                  } else {
                    monthly_fg[updateind].ghg =
                      monthly_fg[updateind].ghg + total;
                  }

                  fg_total = total + fg_total;
                }
              }
            });
          }

          if (
            report.dcf === 11 &&
            (search.indicator.id === 0 || search.indicator.id === 93)
          ) {
            let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

            let total = 0,
              co2 = 0,
              ch4 = 0,
              n2o = 0,
              sc_data = [];
            report.response.forEach((fg, ind) => {
              let fuel_cat = fg.filter((i) => {
                return i.dp === "DPA0130";
              })[0].value.name;
              let fuel_type = fg.filter((i) => {
                return i.dp === "DPA0131";
              })[0].value.name;
              let unit = fg.filter((i) => {
                return i.dp === "DPA0132";
              })[0].value.name;
              let consumed = fg.filter((i) => {
                return i.dp === "DPA0336";
              })[0].value;
              let fuel_cat_ind = sc_data.findIndex((k) => {
                return k.fuel_type === fuel_type && k.unit === unit;
              });

              if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                let carbon = fg.filter((i) => {
                  return i.dp === "DPA0134";
                })[0].value;
                let cv = fg.filter((i) => {
                  return i.dp === "DPA0133";
                })[0].value;
                if (carbon > 0 && cv > 0) {
                  let gj = (carbon * 3.664 * 1000) / cv;
                  total = (gj * 0.000004184 * consumed) / 1000;
                  fg["value"] = [
                    "Emission Factor-",
                    3.664,
                    " ,EnergyProduced-",
                    0.000004184,
                  ];
                  co2 = 0;
                  ch4 = 0;
                  n2o = 0;
                  if (fuel_cat_ind === -1) {
                    sc_data.push({
                      fuel_type: fuel_type,
                      ghg: total,
                      co2,
                      n2o,
                      ch4,
                      unit,
                      co2_: 0,
                      n2o_: 0,
                      ch4_: 0,
                    });
                  } else {
                    sc_data[fuel_cat_ind].ghg =
                      sc_data[fuel_cat_ind].ghg + total;
                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                  }
                } else {
                  total =
                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["co2e_in_kg"] /
                      1000) *
                    consumed;
                  co2 =
                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["co2_in_kg"] /
                      1000) *
                    consumed;
                  ch4 =
                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["ch4_in_kg"] /
                      1000) *
                    consumed;
                  n2o =
                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["n2o_in_kg"] /
                      1000) *
                    consumed;
                  let co2_ =
                    emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["co2_in_kg"];
                  let ch4_ =
                    emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["ch4_in_kg"];
                  let n2o_ =
                    emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                      (k) => {
                        return (
                          k.fuel_type === fuel_cat &&
                          k.fuel === fuel_type &&
                          k.unit === unit
                        );
                      }
                    )[0]["n2o_in_kg"];
                  fg["value"] = [
                    "co2-",
                    co2 / consumed,
                    " ,ch4-",
                    ch4 / consumed,
                    " ,n2o-",
                    n2o / consumed,
                  ];
                  if (fuel_cat_ind === -1) {
                    sc_data.push({
                      fuel_type: fuel_type,
                      ghg: total,
                      unit,
                      co2,
                      n2o,
                      ch4,
                      co2_,
                      n2o_,
                      ch4_,
                    });
                  } else {
                    sc_data[fuel_cat_ind].ghg =
                      sc_data[fuel_cat_ind].ghg + total;
                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                  }
                }
              } else {
                total =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["co2e_in_kg"] /
                    1000) *
                  consumed;
                co2 =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["co2_in_kg"] /
                    1000) *
                  consumed;
                ch4 =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["ch4_in_kg"] /
                    1000) *
                  consumed;
                n2o =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["n2o_in_kg"] /
                    1000) *
                  consumed;
                let co2_ =
                  emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["co2_in_kg"];
                let ch4_ =
                  emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["ch4_in_kg"];
                let n2o_ =
                  emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter(
                    (k) => {
                      return (
                        k.fuel_type === fuel_cat &&
                        k.fuel === fuel_type &&
                        k.unit === unit
                      );
                    }
                  )[0]["n2o_in_kg"];

                fg["value"] = [
                  "co2-",
                  co2 / consumed,
                  " ,ch4-",
                  ch4 / consumed,
                  " ,n2o-",
                  n2o / consumed,
                ];
                if (fuel_cat_ind === -1) {
                  sc_data.push({
                    fuel_type: fuel_type,
                    ghg: total,
                    co2,
                    n2o,
                    ch4,
                    unit,
                    co2_,
                    n2o_,
                    ch4_,
                  });
                } else {
                  sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                  sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                  sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                  sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                }
              }
            });
            addedMonth.rps.forEach((fm) => {
              let date = moment(fm, "MM-YYYY").toDate();
              let yearind = monthly_fg.findIndex((i) => {
                return i.year === moment(date).format("YYYY");
              });
              let monthind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY")
                );
              });
              let dcfind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf
                );
              });
              let siteind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf &&
                  i.site === report.site
                );
              });

              sc_data.forEach((item) => {
                let updateind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(date).format("YYYY") &&
                    i.month === moment(date).format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site &&
                    i.fuel_type === item.fuel_type &&
                    i.unit === item.unit
                  );
                });

                if (updateind === -1) {
                  monthly_fg.push({
                    scope: 1,
                    year: moment(date).format("YYYY"),
                    month: moment(date).format("MMM-YYYY"),
                    ghg: item.ghg / addedMonth.count,
                    dcf: report.dcf,
                    site: report.site,
                    fuel_type: item.fuel_type,
                    unit: item.unit,
                    co2: (item.co2 * 1000) / addedMonth.count,
                    n2o: (item.n20 * 1000) / addedMonth.count,
                    ch4: (item.ch4 * 1000) / addedMonth.count,
                    co2_: item.co2_,
                    ch4_: item.ch4_,
                    n2o_: item.n2o_,
                  });
                } else {
                  monthly_fg[updateind].co2 =
                    ((item.co2 * 1000) / addedMonth.count) * 1000 +
                    monthly_fg[updateind].co2;
                  monthly_fg[updateind].n2o =
                    ((item.n2o * 1000) / addedMonth.count) * 1000 +
                    monthly_fg[updateind].n2o;
                  monthly_fg[updateind].ch4 =
                    ((item.ch4 * 1000) / addedMonth.count) * 1000 +
                    monthly_fg[updateind].ch4;
                  monthly_fg[updateind].ghg =
                    monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                }
              });
            });
          }
          if (
            report.dcf === 10 &&
            (search.indicator.id === 0 || search.indicator.id === 116)
          ) {
            let add = [];

            report.response.forEach((fg, ind) => {
              let date = fg.filter((i) => {
                return i.dp === "DPA0137";
              })[0].value;
              let yearind = monthly_fg.findIndex((i) => {
                return i.year === moment(date).format("YYYY");
              });
              let monthind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY")
                );
              });
              let dcfind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf
                );
              });
              let siteind = monthly_fg.findIndex((i) => {
                return (
                  i.year === moment(date).format("YYYY") &&
                  i.month === moment(date).format("MMM-YYYY") &&
                  i.dcf === report.dcf &&
                  i.site === report.site
                );
              });

              if (
                checkReportingPeriod(
                  [moment(date).format("MM-YYYY")],
                  betweenMonths
                ).result
              ) {
                let gastype = fg.filter((i) => {
                  return i.dp === "DPA0136";
                })[0].value.name;
                let gasrefilled = fg.filter((i) => {
                  return i.dp === "DPA0138";
                })[0].value;
                let total =
                  (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter(
                    (k) => {
                      return k.gas_type === gastype;
                    }
                  )[0]["co2e_in_kg"] /
                    1000) *
                  gasrefilled;
                let co2e_ =
                  emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter(
                    (k) => {
                      return k.gas_type === gastype;
                    }
                  )[0]["co2e_in_kg"];
                fg_total = total + fg_total;

                fg["gas"] = gastype;
                fg["gasfilled"] = gasrefilled;
                fg["value"] = ["EmissionFactor-", total / gasrefilled];
                let updateind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(date).format("YYYY") &&
                    i.month === moment(date).format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site &&
                    i.gastype === gastype
                  );
                });

                if (updateind === -1) {
                  monthly_fg.push({
                    scope: 1,
                    year: moment(date).format("YYYY"),
                    month: moment(date).format("MMM-YYYY"),
                    ghg: 0,
                    dcf: report.dcf,
                    site: report.site,
                    gastype: gastype,
                    ghg: total,
                    co2e_,
                  });
                } else {
                  monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                }
              }
            });
          }
          if (
            report.dcf === 72 &&
            (search.indicator.id === 0 || search.indicator.id === 121)
          ) {
            report.rp.forEach((i) => {
              if (checkReportingPeriod([i], betweenMonths).result) {
                let yearind = monthly_fg.findIndex((i) => {
                  return i.year === moment(i, "MM-YYYY").format("YYYY");
                });
                let monthind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(i, "MM-YYYY").format("YYYY") &&
                    i.month === moment(i, "MM-YYYY").format("MMM-YYYY")
                  );
                });
                let dcfind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(i, "MM-YYYY").format("YYYY") &&
                    i.month === moment(i, "MM-YYYY").format("MMM-YYYY") &&
                    i.dcf === report.dcf
                  );
                });
                let siteind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(i, "MM-YYYY").format("YYYY") &&
                    i.month === moment(i, "MM-YYYY").format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site
                  );
                });

                let ef =
                  emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1]
                    .stdTopics[0].data1[0].importedData[0]["kwh_in_tco2e"];

                let renewable =
                  report.response[0].filter((i) => {
                    return i.dp === "DPA0156";
                  })[0].value / report.rp.length;
                let nonrenewable =
                  report.response[0].filter((i) => {
                    return i.dp === "DPA0157";
                  })[0].value / report.rp.length;

                if (yearind === -1) {
                  monthly_fg.push({
                    scope: 2,
                    year: moment(i, "MM-YYYY").format("YYYY"),
                    month: moment(i, "MM-YYYY").format("MMM-YYYY"),
                    ghg: nonrenewable * ef,
                    co2e_: ef,
                    dcf: report.dcf,
                    site: report.site,
                    renewable,
                    nonrenewable,
                  });
                } else if (monthind === -1) {
                  monthly_fg.push({
                    scope: 2,
                    year: moment(i, "MM-YYYY").format("YYYY"),
                    month: moment(i, "MM-YYYY").format("MMM-YYYY"),
                    ghg: nonrenewable * ef,
                    co2e_: ef,
                    dcf: report.dcf,
                    site: report.site,
                    renewable,
                    nonrenewable,
                  });
                } else if (dcfind === -1) {
                  monthly_fg.push({
                    scope: 2,
                    year: moment(i, "MM-YYYY").format("YYYY"),
                    month: moment(i, "MM-YYYY").format("MMM-YYYY"),
                    ghg: nonrenewable * ef,
                    co2e_: ef,
                    dcf: report.dcf,
                    site: report.site,
                    renewable,
                    nonrenewable,
                  });
                } else if (siteind === -1) {
                  monthly_fg.push({
                    scope: 2,
                    year: moment(i, "MM-YYYY").format("YYYY"),
                    month: moment(i, "MM-YYYY").format("MMM-YYYY"),
                    ghg: nonrenewable * ef,
                    dcf: report.dcf,
                    co2e_: ef,
                    site: report.site,
                    renewable,
                    nonrenewable,
                  });
                }
              }
            });
          }
          if (
            report.dcf === 15 &&
            (search.indicator.id === 0 || search.indicator.id === 118)
          ) {
            let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

            let ef_by_fuel =
              emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0]
                .stdTopics[4].data1[0].importedData;
            let ef_by_distance =
              emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0]
                .stdTopics[1].data1[0].importedData;

            let total = 0,
              co2 = 0,
              ch4 = 0,
              n2o = 0,
              mc_data = [];

            report.response.forEach((fg, ind) => {
              let mode = fg.filter((i) => {
                return i.dp === "DP_MODE";
              })[0].value
                ? "by distance"
                : "by fuel";
              let fuel_cat = fg.filter((i) => {
                return i.dp === "DPA0140";
              })[0].value;
              let fuel_type = fg.filter((i) => {
                return i.dp === "DPA0139";
              })[0].value;
              let fuel = fg.filter((i) => {
                return i.dp === "DPA0141";
              })[0].value.name;
              let unit = fg.filter((i) => {
                return i.dp === "DPA0339";
              })[0].value;
              let km = fg.filter((i) => {
                return i.dp === "DPA0144";
              })[0].value;
              let fuel_filled = fg.filter((i) => {
                return i.dp === "DPA0143";
              })[0].value;
              let fuel_cat_ind = mc_data.findIndex((k) => {
                return k.fuel === fuel;
              });

              if (mode === "by distance") {
                total = ef_by_distance.filter((k) => {
                  return (
                    k.vehicle_category === fuel_cat.name &&
                    k.vehicle_type === fuel_type.name &&
                    k.fuel_type === fuel &&
                    k.unit === unit.name
                  );
                })[0]["co2e_in_kg"];

                if (fuel_cat_ind === -1) {
                  mc_data.push({
                    mode: fuel,
                    ghg: total * km,
                    co2e_: total,
                    fuel_cat: fuel_cat.name,
                  });
                } else {
                  mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                }
              } else {
                total = ef_by_fuel.filter((k) => {
                  return k.fuel.trim() === fuel.trim();
                })[0]["co2e_in_kg"];

                if (fuel_cat_ind === -1) {
                  mc_data.push({
                    mode: fuel,
                    ghg: total * fuel_filled,
                    co2e_: total,
                    fuel_cat: "",
                  });
                } else {
                  mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                }
              }
            });
            addedMonth.rps.forEach((fm) => {
              let date = moment(fm, "MM-YYYY").toDate();

              mc_data.forEach((item) => {
                let updateind = monthly_fg.findIndex((i) => {
                  return (
                    i.year === moment(date).format("YYYY") &&
                    i.month === moment(date).format("MMM-YYYY") &&
                    i.dcf === report.dcf &&
                    i.site === report.site &&
                    i.mode === item.mode
                  );
                });

                if (updateind === -1) {
                  let updateind2 = monthly_fg.findIndex((i) => {
                    return (
                      i.year === moment(date).format("YYYY") &&
                      i.month === moment(date).format("MMM-YYYY") &&
                      i.dcf === report.dcf &&
                      i.site === report.site &&
                      i.mode === item.mode
                    );
                  });
                  if (updateind2 !== -1) {
                    monthly_fg[updateind2].ghg =
                      item.ghg / addedMonth.count + monthly_fg[updateind2].ghg;
                    // monthly_fg[updateind2]['fuel_cat'] = item.fuel_cat
                  } else {
                    console.log("EF", item);
                    monthly_fg.push({
                      scope: 1,
                      year: moment(date).format("YYYY"),
                      month: moment(date).format("MMM-YYYY"),
                      ghg: 0,
                      dcf: report.dcf,
                      site: report.site,
                      ghg: item.ghg / addedMonth.count,
                      mode: item.mode,
                      fuel_cat: item.fuel_cat,
                      co2e_: item.co2e_,
                    });
                  }
                } else {
                  // monthly_fg[updateind]['fuel_cat'] = item.fuel_cat
                  monthly_fg[updateind].ghg =
                    monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                }
              });
            });
          }
        }
      });

      let scope12_array = groupArrayObject(
        monthly_fg.filter((i) => {
          return i.scope === 1 || i.scope === 2;
        }),
        "month"
      );
      let scope3_array = groupArrayObject(
        monthly_fg.filter((i) => {
          return i.scope === 3;
        }),
        "month"
      );
      let scope1_array = groupArrayObject(
        monthly_fg.filter((i) => {
          return i.scope === 1;
        }),
        "month"
      );
      let scope2_array = groupArrayObject(
        monthly_fg.filter((i) => {
          return i.scope === 2;
        }),
        "month"
      );
      let pie = [];
      monthly_fg
        .filter((i) => {
          return i.scope === 1;
        })
        .forEach((item) => {
          let index = pie.findIndex((j) => {
            return j.name === "Scope " + item.scope;
          });
          if (index === -1) {
            pie.push({ name: "Scope " + item.scope, y: item.ghg });
          } else {
            pie[index].y = pie[index].y + item.ghg;
          }
        });
      monthly_fg
        .filter((i) => {
          return i.scope === 2;
        })
        .forEach((item) => {
          let index = pie.findIndex((j) => {
            return j.name === "Scope " + item.scope;
          });
          if (index === -1) {
            pie.push({ name: "Scope " + item.scope, y: item.ghg });
          } else {
            pie[index].y = pie[index].y + item.ghg;
          }
        });
      monthly_fg
        .filter((i) => {
          return i.scope === 3;
        })
        .forEach((item) => {
          let index = pie.findIndex((j) => {
            return j.name === "Scope " + item.scope;
          });
          if (index === -1) {
            pie.push({ name: "Scope " + item.scope, y: item.ghg });
          } else {
            pie[index].y = pie[index].y + item.ghg;
          }
        });

      let index1 = pie.findIndex((j) => {
        return j.name === "Scope 1";
      });
      let index2 = pie.findIndex((j) => {
        return j.name === "Scope 2";
      });
      let index3 = pie.findIndex((j) => {
        return j.name === "Scope 3";
      });

      if (index1 === -1) {
        pie.push({ name: "Scope 1", y: 0 });
      }
      if (index2 === -1) {
        pie.push({ name: "Scope 2", y: 0 });
      }
      if (index3 === -1) {
        pie.push({ name: "Scope 3", y: 0 });
      }

      if (scope1_array.length !== 0) {
        let scope1_tier2 = [],
          final = [];
        Object.keys(scope1_array).forEach((key) => {
          scope1_array[key].forEach((item) => {
            item.dcfId = item.dcf;
            item.dcf =
              dcflist.filter((l) => {
                return l.id === item.dcf;
              }).length === 0
                ? item.dcf
                : dcflist.filter((l) => {
                    return l.id === item.dcf;
                  })[0].title;

            if (
              scope1_tier2.findIndex((i) => {
                return i.name === item.dcf;
              }) === -1
            ) {
              scope1_tier2.push({
                name: item.dcf,
                drilldown: true,
                color: "red",
                y: item.ghg,

                subset: [[key, item.ghg]],
              });
            } else {
              let ind = scope1_tier2.findIndex((i) => {
                return i.name === item.dcf;
              });
              let subind = scope1_tier2[ind].subset.findIndex((j) => {
                return j[0] === key;
              });
              scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
              if (subind === -1) {
                scope1_tier2[ind].subset.push([key, item.ghg]);
              } else {
                scope1_tier2[ind].subset[subind][1] =
                  scope1_tier2[ind].subset[subind][1] + item.ghg;
              }
            }
          });
        });

        betweenYears.forEach((j) => {
          let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => {
            i.subset.sort((a, b) => {
              return (
                moment(a[0], "MMM-YYYY").toDate() -
                moment(b[0], "MMM-YYYY").toDate()
              );
            });
            i.subset = i.subset.filter((k) => {
              return k[0].split("-")[1] === j;
            });
            i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
            i.incomplete = false;
            return i;
          });

          final.push(dataset);
        });
      }
      if (scope2_array.length !== 0) {
        let scope2_tier2 = [],
          final = [];
        Object.keys(scope2_array).forEach((key) => {
          scope2_array[key].forEach((item) => {
            item.dcfId = item.dcf;
            item.dcf =
              dcflist.filter((l) => {
                return l.id === item.dcf;
              }).length === 0
                ? item.dcf
                : dcflist.filter((l) => {
                    return l.id === item.dcf;
                  })[0].title;
            if (
              scope2_tier2.findIndex((i) => {
                return i.name === item.dcf;
              }) === -1
            ) {
              scope2_tier2.push({
                name: item.dcf,
                drilldown: true,
                color: "red",
                y: item.ghg,
                subset: [[key, item.ghg]],
              });
            } else {
              let ind = scope2_tier2.findIndex((i) => {
                return i.name === item.dcf;
              });
              let subind = scope2_tier2[ind].subset.findIndex((j) => {
                return j[0] === key;
              });
              scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
              if (subind === -1) {
                scope2_tier2[ind].subset.push([key, item.ghg]);
              } else {
                scope2_tier2[ind].subset[subind][1] =
                  scope2_tier2[ind].subset[subind][1] + item.ghg;
              }
            }
          });
        });
        betweenYears.forEach((j) => {
          let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => {
            i.subset.sort((a, b) => {
              return (
                moment(a[0], "MMM-YYYY").toDate() -
                moment(b[0], "MMM-YYYY").toDate()
              );
            });
            i.subset = i.subset.filter((k) => {
              return k[0].split("-")[1] === j;
            });
            i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
            i.incomplete = false;
            return i;
          });

          final.push(dataset);
        });
      }
      if (scope3_array.length !== 0) {
        let scope3_tier2 = [],
          final = [];
        Object.keys(scope3_array).forEach((key) => {
          scope3_array[key].forEach((item) => {
            item.dcfId = item.dcf;
            item.dcf =
              dcflist.filter((l) => {
                return l.id === item.dcf;
              }).length === 0
                ? item.dcf
                : dcflist.filter((l) => {
                    return l.id === item.dcf;
                  })[0].title;
            if (
              scope3_tier2.findIndex((i) => {
                return i.name === item.dcf;
              }) === -1
            ) {
              scope3_tier2.push({
                name: item.dcf,
                drilldown: true,
                color: "red",
                y: item.ghg,
                subset: [[key, item.ghg]],
              });
            } else {
              let ind = scope3_tier2.findIndex((i) => {
                return i.name === item.dcf;
              });
              let subind = scope3_tier2[ind].subset.findIndex((j) => {
                return j[0] === key;
              });
              scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
              if (subind === -1) {
                scope3_tier2[ind].subset.push([key, item.ghg]);
              } else {
                scope3_tier2[ind].subset[subind][1] =
                  scope3_tier2[ind].subset[subind][1] + item.ghg;
              }
            }
          });
        });
        betweenYears.forEach((j) => {
          let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => {
            i.subset.sort((a, b) => {
              return (
                moment(a[0], "MMM-YYYY").toDate() -
                moment(b[0], "MMM-YYYY").toDate()
              );
            });
            i.subset = i.subset.filter((k) => {
              return k[0].split("-")[1] === j;
            });
            i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
            i.incomplete = false;
            return i;
          });

          final.push(dataset);
        });
      }
    }

    return monthly_fg;
  };
  const showSite = () => {
    let site_ids = [],
      site_names = [];
    dcfass.forEach((i) => {
      !site_ids.includes(i.site) && site_ids.push(i.site);
    });

    site_ids.forEach((i, j) => {
      site_names.push(
        locationList.find((k) => {
          return k.id === i;
        }).title
      );
    });

    return site_names;
  };
  return (
    <div>
      <div
        className="col-12"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          className="col-3 p-card"
          style={{
            margin: 5,
            height: "calc(100vh - 9rem)",
            overflow: "scroll",
          }}
        >
          <nav>
            {headings.map((heading, ind) => {
              let indexes = [];
              return (
                <>
                  {heading.level === 0 ? (
                    <label
                      key={heading.id}
                      style={{
                        display: "flex",
                        margin: 5,
                        fontWeight: activeId === heading.id ? "bold" : "normal",
                        textDecoration:
                          heading.text.includes("SECTION") && "underline",
                      }}
                      className={getClassName(heading.level)}
                    >
                      <a
                        href={`#${heading.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          document
                            .querySelector(`#${heading.id}`)
                            .scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "nearest",
                            });
                        }}
                        style={{
                          fontWeight:
                            activeId === heading.id ? "bold" : "normal",
                        }}
                      >
                        {heading.text}
                      </a>
                    </label>
                  ) : (
                    <ul>
                      {heading.item.map((item, ind2) => {
                        return (
                          <li
                            key={item.id}
                            className={getClassName(item.level)}
                          >
                            <a
                              href={`#${item.id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                document
                                  .querySelector(`#${item.id}`)
                                  .scrollIntoView({
                                    behavior: "smooth",
                                  });
                              }}
                              style={{
                                fontWeight:
                                  activeId === item.id ? "bold" : "normal",
                              }}
                            >
                              {item.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </>
              );
            })}
          </nav>
        </div>
        <div
          className="col-9 p-card"
          style={{
            margin: 5,
            height: "calc(100vh - 9rem)",
            overflow: "scroll",
            color: "white",
          }}
        >
          <div
            className="col-12"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              icon="pi pi-eye"
              style={{ marginRight: 10 }}
              rounded
              text
              raised
              aria-label="Filter"
              onClick={() => {
                exportTable2Excel(1);
              }}
            >
              
            </Button>

            <Button
              icon="pi pi-cloud-download"
              rounded
              text
              raised
              aria-label="Filter"
              onClick={() => {
                exportTable2Excel(0);
              }}
            >
              
            </Button>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <img id='ass' src='https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/hazards/1690426362525Pushing%20and%20Pulling.png' width={'100%'} /> */}
            <div>
              <div
                className="col-12 grid"
                style={{ margin: 5, color: "white" }}
              >
                <div>
                  <label
                    style={{
                      color: "black",
                      padding: 15,
                      justifyContent: "flex-start",
                      display: "flex",
                    }}
                  >
                    Select Year :
                  </label>
                </div>

                <div className="col-4">
                  <Dropdown
                    options={[{ name: 2022 }, { name: 2023 }]}
                    value={year}
                    optionLabel="name"
                    optionValue="name"
                    onChange={(e) => {
                      setYear(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              id="main"
              className="gri"
              style={{ flexDirection: "column", display: "flex" }}
            >
              <sectionheader id={"GRI1"} className="secHead">
                1.The organization and its reporting practices
              </sectionheader>

              <sectionheader1 id={"GRI2-1"}>
                Disclosure 2-1 Organizational details
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        report its legal name
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        report its nature of ownership and legal form
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        report the location of its headquarters
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        report its countries of operation
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI2-2"}>
                Disclosure 2-2 Entities included in the organization’s
                sustainability reporting
              </sectionheader1>
              <div className="m-3 para"> The organization shall:</div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        List all the entities included in its sustainability
                        reporting
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  b) if the organization has audited consolidated financial
                  statements or financial information filed on public record,
                  specify the differences between the list of entities included
                  in its financial reporting and the list included in its
                  sustainability reporting
                </label>
                <label>
                  c) if the organization consists of multiple entities, explain
                  the approach used for consolidating the information,
                  including:
                </label>
                <label>
                  i. whether the approach involves adjustments to information
                  for minority interests
                </label>

                <label>
                  ii. how the approach takes into account mergers, acquisitions,
                  and disposal of entities or parts of entities
                </label>
                <label>
                  iii. whether and how the approach differs across the
                  disclosures in this Standard and across material topics
                </label>
              </div>

              <sectionheader1 id={"GRI2-3"}>
                Disclosure 2-3 Reporting period, frequency and contact point
              </sectionheader1>
              <div className="m-3 para"> The organization shall:</div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Specify the reporting period for its sustainability
                        reporting
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Specify the frequency of its sustainability reporting
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Specify the reporting period for its financial reporting
                        and, if it does not align with the period for its
                        sustainability reporting, explain the reason for this
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Report the publication date of the report or reported
                        information
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Specify the contact point for questions about the report
                        or reported information
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI2-4"}>
                Disclosure 2-4 Restatements of information
              </sectionheader1>
              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) Report restatements of information made from previous
                  reporting periods
                </label>

                <label>b) Explain the reasons for the restatements</label>

                <label>c) Explain the effect of the restatements</label>
              </div>

              <sectionheader1 id={"GRI2-5"}>
                Disclosure 2-5 External assurance
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) Description of its policy and practice for seeking external
                  assurance, including whether and how the highest governance
                  body and senior executives are involved
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        if the organization’s sustainability reporting has been
                        externally assured: i. Provide a link or reference to
                        the external assurance report or assurance statement
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  ii. describe what has been assured and on what basis,
                  including the assurance standards used, the level of assurance
                  obtained, and any limitations of the assurance process;
                </label>

                <label>
                  iii. describe the relationship between the organization and
                  the assurance provider.
                </label>
              </div>

              <sectionheader id={"GRI2"} className="secHead">
                2. Activities and workers
              </sectionheader>

              <sectionheader1 id={"GRI2-6"}>
                Disclosure 2-6 Activities, value chain and other business
                relationships
              </sectionheader1>
              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>a) Report the sectors in which it is active.</label>

                <label>b) Description of its value chain, including</label>

                <label>
                  i. The organization’s activities, products, services, and
                  markets served, and significant changes compared to the
                  previous reporting period
                </label>

                <label>
                  ii. The organization’s supply chain and significant changes
                  compared to the previous reporting period
                </label>

                <label>
                  iii. The entities downstream from the organization and their
                  activities and significant changes compared to the previous
                  reporting period
                </label>

                <label>c) Report other relevant business relationships</label>
              </div>

              <sectionheader1 id={"GRI2-7"}>
                Disclosure 2-7 Employees
              </sectionheader1>
              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) report the total number of employees and a breakdown of
                  this total by gender and by region;
                </label>
              </div>

              <div className="m-3 para">
                
                Report the total number of employees gender-wise
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Employee Category
                      </td>
                      <td colSpan="1" className="tdHead">
                        Male
                      </td>
                      <td colSpan="1" className="tdHead">
                        Female
                      </td>
                      <td colSpan="1" className="tdHead">
                        Total
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Number of employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Permanent employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Temporary employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Non-guaranteed hours employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Full-time employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Part-time Employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                
                Report the total number of employees region-wise
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Employee Category
                      </td>
                      <td colSpan="1" className="tdHead">
                        Region 1
                      </td>
                      <td colSpan="1" className="tdHead">
                        Region 2
                      </td>
                      <td colSpan="1" className="tdHead">
                        Total
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Number of employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Permanent employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Temporary employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Non-guaranteed hours employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Full-time employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Part-time Employees (head count / FTE)
                      </td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>

                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  c) Describe the methodologies and assumptions used to compile
                  the data, including whether the numbers are reported:
                </label>
                <label>
                  i. in head count, full-time equivalent (FTE), or using another
                  methodology
                </label>
                <label>
                  ii. At the end of the reporting period, as an average across
                  the reporting period, or using another methodology
                </label>
                d) Report contextual information necessary to understand the
                data of gender and region-wise breakup of employees
                <label>
                  e) describe significant fluctuations in the number of
                  employees during the reporting period and between reporting
                  periods
                </label>
              </div>

              <sectionheader1 id={"GRI2-8"}>
                Disclosure 2-8 Workers who are not employees
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        a) Report the total number of workers who are not
                        employees and whose work is controlled by the
                        organization and describe
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        i. the most common types of worker and their contractual
                        relationship with the organization
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        ii. the type of work they perform
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  b) Describe the methodologies and assumptions used to compile
                  the data, including whether the number of workers who are not
                  employees is reported.
                </label>

                <label>
                  i. in head count, full-time equivalent (FTE), or using another
                  methodology
                </label>

                <label>
                  ii. At the end of the reporting period, as an average across
                  the reporting period, or using another methodology
                </label>

                <label>
                  c) Describe significant fluctuations in the number of workers
                  who are not employees during the reporting period and between
                  reporting periods.
                </label>
              </div>

              <sectionheader id={"GRI3"} className="secHead">
                3. Governance
              </sectionheader>

              <sectionheader1 id={"GRI2-9"}>
                Disclosure 2-9 Governance structure and composition
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>
              <div className="list">
                <label>
                  a) describe its governance structure, including committees of
                  the highest governance body;
                </label>

                <label>
                  b) List the committees of the highest governance body that are
                  responsible for decision-making on and overseeing the
                  management of the organization’s impacts on the economy,
                  environment, and people
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        List of Committees
                      </td>
                      <td colSpan="1" className="tdHead">
                        Impacts on the economy, environment, and people
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className=""></td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  c) describe the composition of the highest governance body and
                  its committees by:
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        executive and non-executive members
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Independence
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        tenure of members on the governance body
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        number of other significant positions and commitments
                        held by each member, and the nature of the commitments;
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        gender
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        under-represented social groups;
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        competencies relevant to the impacts of the
                        organization;
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        stakeholder representation.
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI2-10"}>
                
                Disclosure 2-10 Nomination and selection of the highest
                governance body
              </sectionheader1>
              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) describe the nomination and selection processes for the
                  highest governance body and its committees
                </label>
                <label>
                  
                  b) describe the criteria used for nominating and selecting
                  highest governance body members, including whether and how the
                  following are taken into consideration:
                </label>
                <label>
                  
                  i. views of stakeholders (including shareholders);
                </label>
                <label> ii. diversity;</label>
                <label>iii. independence; </label>
                <label>
                  iv. competencies relevant to the impacts of the organization
                </label>
              </div>

              <sectionheader1 id={"GRI2-11"}>
                
                Disclosure 2-11 Chair of the highest governance body
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) report whether the chair of the highest governance body is
                  also a senior executive in the organization
                </label>

                <label>
                  
                  b) if the chair is also a senior executive, explain their
                  function within the organization’s management, the reasons for
                  this arrangement, and how conflicts of interest are prevented
                  and mitigated.
                </label>
              </div>

              <sectionheader1 id={"GRI2-12"}>
                
                Disclosure 2-12 Role of the highest governance body in
                overseeing the management of impacts
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) describe the role of the highest governance body and of
                  senior executives in developing, approving, and updating the
                  organization’s purpose, value or mission statements,
                  strategies, policies, and goals related to sustainable
                  development;
                </label>

                <label>
                  b) describe the role of the highest governance body in
                  overseeing the organization’s due diligence and other
                  processes to identify and manage the organization’s impacts on
                  the economy, environment, and people, including:
                </label>

                <label>
                  i. whether and how the highest governance body engages with
                  stakeholders to support these processes;
                </label>

                <label>
                  
                  ii. how the highest governance body considers the outcomes of
                  these processes;
                </label>

                <label>
                  c) describe the role of the highest governance body in
                  reviewing the effectiveness of the organization’s processes
                </label>
              </div>

              <sectionheader1 id={"GRI2-13"}>
                
                Disclosure 2-13 Delegation of responsibility for managing
                impacts
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  
                  a) describe how the highest governance body delegates
                  responsibility for managing the organization’s impacts on the
                  economy, environment, and people, including:
                </label>

                <label>
                  
                  i. whether it has appointed any senior executives with
                  responsibility for the management of impacts;
                </label>

                <label>
                  
                  ii. whether it has delegated responsibility for the management
                  of impacts to other employees;
                </label>
              </div>

              <sectionheader1 id={"GRI2-14"}>
                
                Disclosure 2-14 Role of the highest governance body in
                sustainability reporting
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) report whether the highest governance body is responsible
                  for reviewing and approving the reported information,
                  including the organization’s material topics, and if so,
                  describe the process for reviewing and approving the
                  information
                </label>
                <label>
                  b) if the highest governance body is not responsible for
                  reviewing and approving the reported information, including
                  the organization’s material topics, explain the reason for
                  this
                </label>
              </div>

              <sectionheader1 id={"GRI2-15"}>
                
                Disclosure 2-15 Conflicts of interest
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  
                  a) describe the processes for the highest governance body to
                  ensure that conflicts of interest are prevented and mitigated
                </label>

                <label>
                  b) report whether conflicts of interest are disclosed to
                  stakeholders, including, at a minimum, conflicts of interest
                  relating to
                </label>

                <label> i. cross-board membership;</label>
                <label>
                  
                  ii. cross-shareholding with suppliers and other stakeholders;
                </label>
                <label> iii. existence of controlling shareholders;</label>
                <label>
                  
                  iv. related parties, their relationships, transactions, and
                  outstanding balances
                </label>
              </div>

              <sectionheader1 id={"GRI2-16"}>
                
                Disclosure 2-16 Communication of critical concerns
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  
                  a) describe whether and how critical concerns are communicated
                  to the highest governance body;
                </label>

                <label>
                  
                  b) report the total number and the nature of critical concerns
                  that were communicated to the highest governance body during
                  the reporting period
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        total number of critical concerns
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        nature of critical concerns that were communicated to
                        the highest governance body during the reporting period
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI2-17"}>
                
                Disclosure 2-17 Collective knowledge of the highest governance
                body
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  
                  a) report measures taken to advance the collective knowledge,
                  skills, and experience of the highest governance body on
                  sustainable development.
                </label>
              </div>

              <sectionheader1 id={"GRI2-18"}>
                
                Disclosure 2-18 Collective knowledge of the highest governance
                body
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  
                  a) describe the processes for evaluating the performance of
                  the highest governance body in overseeing the management of
                  the organization’s impacts on the economy, environment, and
                  people;
                </label>

                <label>
                  
                  b) report whether the evaluations are independent or not, and
                  the frequency of the evaluations
                </label>

                <label>
                  c) describe actions taken in response to the evaluations,
                  including changes to the composition of the highest governance
                  body and organizational practices.
                </label>
              </div>

              <sectionheader1 id={"GRI2-19"}>
                
                Disclosure 2-19 Remuneration policies
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  
                  a) describe the remuneration policies for members of the
                  highest governance body and senior executives, including:
                </label>

                <label>i. fixed pay and variable pay; </label>

                <label>
                  
                  ii. sign-on bonuses or recruitment incentive payments;
                </label>

                <label> iii. termination payments; </label>

                <label> iv. clawbacks; </label>

                <label> v. retirement benefits; </label>

                <label>
                  
                  b) describe how the remuneration policies for members of the
                  highest governance body and senior executives relate to their
                  objectives and performance in relation to the management of
                  the organization’s impacts on the economy, environment, and
                  people.
                </label>
              </div>

              <sectionheader1 id={"GRI2-20"}>
                
                Disclosure 2-20 Process to determine remuneration
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  
                  a) describe the process for designing its remuneration
                  policies and for determining remuneration, including
                </label>

                <label>
                  i. Whether independent highest governance body members or an
                  independent remuneration committee oversees the process for
                  determining remuneration;
                </label>

                <label>
                  ii. how the views of stakeholders (including shareholders)
                  regarding remuneration are sought and taken into
                  consideration;
                </label>

                <label>
                  
                  iii. whether remuneration consultants are involved in
                  determining remuneration and, if so, whether they are
                  independent of the organization, its highest governance body
                  and senior executives
                </label>

                <label>
                  b) report the results of votes of stakeholders (including
                  shareholders) on remuneration policies and proposals, if
                  applicable
                </label>
              </div>

              <sectionheader1 id={"GRI2-21"}>
                
                Disclosure 2-21 Annual total compensation ratio
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>
              <div className="list">
                <label>
                  
                  i. Report the ratio of the annual total compensation for the
                  organization’s highest-paid individual to the median annual
                  total compensation for all employees (excluding the
                  highest-paid individual);
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        ratio of the annual total compensation for the
                        organization’s highest-paid individual to the median
                        annual total compensation for all employees
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  ii. Report the ratio of the percentage increase in annual
                  total compensation for the organization’s highest-paid
                  individual to the median percentage increase in annual total
                  compensation for all employees (excluding the highest-paid
                  individual);
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        percentage increase in annual total compensation for the
                        organization’s highest-paid individual to the median
                        percentage increase in annual total compensation for all
                        employees
                      </td>
                      <td colSpan="1" className=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  iii.Report contextual information necessary to understand the
                  data and how the data has been compiled
                </label>
              </div>

              <sectionheader id={"GRI4"} className="secHead">
                4. Strategy, policies and practices
              </sectionheader>

              <sectionheader1 id={"GRI2-22"}>
                
                Disclosure 2-22 Statement on sustainable development strategy
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) report a statement from the highest governance body or most
                  senior executive of the organization about the relevance of
                  sustainable development to the organization and its strategy
                  for contributing to sustainable development.
                </label>
              </div>

              <sectionheader1 id={"GRI2-23"}>
                
                Disclosure 2-23 Policy commitments
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) Describe its policy commitments for responsible business
                  conduct, including:
                </label>

                <label>
                  
                  i. the authoritative intergovernmental instruments that the
                  commitments reference;
                </label>

                <label>
                  ii. whether the commitments stipulate conducting due
                  diligence;
                </label>

                <label>
                  iii. whether the commitments stipulate applying the
                  precautionary principle;
                </label>

                <label>
                  
                  iv. whether the commitments stipulate respecting human rights;
                </label>

                <label>
                  
                  b) describe its specific policy commitment to respect human
                  rights, including:
                </label>

                <label>
                  i. the internationally recognized human rights that the
                  commitment covers;
                </label>

                <label>
                  
                  ii. the categories of stakeholders, including at-risk or
                  vulnerable groups, that the organization gives particular
                  attention to the commitment;
                </label>

                <label>
                  c) provide links to the policy commitments if publicly
                  available, or, if the policy commitments are not publicly
                  available, explain the reason for this;
                </label>

                <label>
                  
                  d) report the level at which each of the policy commitments
                  was approved within the organization, including whether this
                  is the most senior level;
                </label>

                <label>
                  e) report the extent to which the policy commitments apply to
                  the organization’s activities and to its business
                  relationships;
                </label>

                <label>
                  f) describe how the policy commitments are communicated to
                  workers, business partners, and other relevant parties.
                </label>
              </div>

              <sectionheader1 id={"GRI2-24"}>
                
                Disclosure 2-24 Embedding policy commitments
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) describe how it embeds each of its policy commitments for
                  responsible business conduct throughout its activities and
                  business relationships, including:
                </label>

                <label>
                  i. How it allocates responsibility to implement the
                  commitments across different levels within the organization;
                </label>

                <label>
                  
                  ii. how it integrates the commitments into organizational
                  strategies, operational policies, and operational procedures;
                </label>

                <label>
                  iii. how it implements its commitments with and through its
                  business relationships;
                </label>

                <label>
                  
                  iv. training that the organization provides on implementing
                  the commitments.
                </label>
              </div>

              <sectionheader1 id={"GRI2-25"}>
                
                Disclosure 2-25 Processes to remediate negative impacts
              </sectionheader1>

              <div className="list">
                <label>
                  a) describe its commitments to provide for or cooperate in the
                  remediation of negative impacts that the organization
                  identifies it has caused or contributed to;
                </label>

                <label>
                  
                  b) describe its approach to identifying and address
                  grievances, including the grievance mechanisms that the
                  organization has established or participates in;
                </label>

                <label>
                  c) describe other processes by which the organization provides
                  for or cooperates in the remediation of negative impacts that
                  it identifies it has caused or contributed to;
                </label>

                <label>
                  
                  d) describe how the stakeholders who are the intended users of
                  the grievance mechanisms are involved in the design, review,
                  operation, and improvement of these mechanisms;
                </label>

                <label>
                  e) describe how the organization tracks the effectiveness of
                  the grievance mechanisms and other remediation processes, and
                  report examples of their effectiveness, including stakeholder
                  feedback.
                </label>
              </div>

              <sectionheader1 id={"GRI2-26"}>
                
                Disclosure 2-26 Mechanisms for seeking advice and raising
                concerns
              </sectionheader1>

              <div className="list">
                <label> a) describe the mechanisms for individuals to: </label>

                <label>
                  i. seek advice on implementing the organization’s policies and
                  practices for responsible business conduct;
                </label>

                <label>
                  ii. raise concerns about the organization’s business conduct
                </label>
              </div>

              <sectionheader1 id={"GRI2-27"}>
                
                Disclosure 2-27 Compliance with laws and regulations
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        the total number of significant instances of
                        non-compliance with laws and regulations during the
                        reporting period,
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Number of instances for which fines were incurred;
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Number of instances for which non-monetary sanctions
                        were incurred;
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  b) report the total number and the monetary value of fines for
                  instances of noncompliance with laws and regulations that were
                  paid during the reporting period, and a breakdown of this
                  total by
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        total number and the monetary value of fines for
                        instances of noncompliance with laws and regulations
                        that were paid during the reporting period
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Number of instances for fines for instances of
                        non-compliance with laws and regulations that occurred
                        in the current reporting period;
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        Number of instances for fines for instances of
                        non-compliance with laws and regulations that occurred
                        in previous reporting periods;
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  c) describe the significant instances of non-compliance;
                </label>

                <label>
                  
                  d) describe how it has determined significant instances of
                  non-compliance
                </label>
              </div>

              <sectionheader1 id={"GRI2-28"}>
                
                Disclosure 2-28 Membership associations
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) Report industry associations, other membership
                  associations, and national or international advocacy
                  organizations in which it participates in a significant role.
                </label>
              </div>

              <sectionheader id={"GRI5"} className="secHead">
                5. Stakeholder engagement
              </sectionheader>

              <sectionheader1 id={"GRI2-29"}>
                
                Disclosure 2-29 Approach to stakeholder engagement
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  
                  a) describe its approach to engaging with stakeholders,
                  including:
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        i. the categories of stakeholders it engages with,
                        and how they are identified;
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        ii. the purpose of the stakeholder engagement;
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        iii. how the organization seeks to ensure meaningful
                        engagement with stakeholders.
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI2-30"}>
                
                Disclosure 2-30 Collective bargaining agreements
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Response
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="">
                        report the percentage of total employees covered by
                        collective bargaining agreements;
                      </td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  b) for employees not covered by collective bargaining
                  agreements, report whether the organization determines their
                  working conditions and terms of employment based on collective
                  bargaining agreements that cover its other employees or based
                  on collective bargaining agreements from other organizations.
                </label>
              </div>

              <sectionheader id={"GRI2"} className="secHead">
                2. Disclosures on material topics
              </sectionheader>

              <sectionheader1 id={"GRI3-1"}>
                
                Disclosure 3-1 Process to determine material topics
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label>
                  a) Describe the process it has followed to determine its
                  material topics, including:
                </label>

                <label>
                  i. How it has identified actual and potential, negative and
                  positive impacts on the economy, environment, and people,
                  including impacts on their human rights, across its activities
                  and business relationships;
                </label>

                <label>
                  
                  ii. How it has prioritized the impacts for reporting based on
                  their significance
                </label>

                <label>
                  
                  b) Specify the stakeholders and experts whose views have
                  informed the process of determining its material topics
                </label>
              </div>

              <sectionheader1 id={"GRI3-2 "}>
                
                Disclosure 3-2 List of material topics
              </sectionheader1>

              <div className="m-3 para"> The organization shall:</div>

              <div className="list">
                <label> a) list its material topics;</label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        List of Material Topics
                      </td>
                      <td colSpan="1" className="tdHead">
                        Priority
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className=""></td>
                      <td colSpan="1" className="">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  b) report changes to the list of material topics compared to
                  the previous reporting period.
                </label>
              </div>

              <sectionheader1 id={"GRI3-3 "}>
                
                Disclosure 3-3 Management of material topics
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) describe the actual and potential, negative and positive
                  impacts on the economy, environment, and people, including
                  impacts on their human rights;
                </label>

                <label>
                  b) report whether the organization is involved with the
                  negative impacts through its activities or as a result of its
                  business relationships, and describe the activities or
                  business relationships;
                </label>

                <label>
                  
                  c) describe its policies or commitments regarding the material
                  topic
                </label>

                <label>
                  
                  d) describe actions taken to manage the topic and related
                  impacts, including
                </label>

                <label>
                  i. actions to prevent or mitigate potential negative impacts;
                </label>

                <label>
                  ii. actions to address actual negative impacts, including
                  actions to provide for or cooperate in their remediation;
                </label>

                <label>
                  iii. actions to manage actual and potential positive impacts
                </label>

                <label>
                  e) report the following information about tracking the
                  effectiveness of the actions taken:
                </label>

                <label>
                  i. processes used to track the effectiveness of the actions;
                </label>

                <label>
                  
                  ii. goals, targets, and indicators used to evaluate progress;
                </label>

                <label>
                  iii. the effectiveness of the actions, including progress
                  toward the goals and targets;
                </label>

                <label>
                  
                  iv. lessons learned and how these have been incorporated into
                  the organization’s operational policies and procedures
                </label>
              </div>

              <div className="m-3 para">
                
                Describe how engagement with stakeholders has informed the
                actions taken and how it has informed whether the actions have
                been effective.
              </div>

              <sectionheader id={"GRI201"} className="secHead">
                GRI 201 Economic Performance
              </sectionheader>

              <sectionheader1 id={"GRI201-1"}>
                Disclosure 201-1 Direct economic value generated and distributed
              </sectionheader1>
              <div className="m-3 para">
                Direct economic value generated and distributed (EVG&amp;D) on
                an accruals basis and separate at country, regional, or market
                levels, and the criteria used for defining significance
              </div>
              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        FY{year - 1}-{year} (INR Million)
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Net Sales
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          getDataByDP("DPK0039", year) -
                            getDataByDP("DPK0261", year) -
                            getDataByDP("DPK0262", year) -
                            getDataByDP("DPK0263", year)
                        )}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Revenue from financial investments and other sources
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0264", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Economic Value Generated
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          getDataByDP("DPK0264", year) +
                            (getDataByDP("DPK0039", year) -
                              getDataByDP("DPK0261", year) -
                              getDataByDP("DPK0262", year) -
                              getDataByDP("DPK0263", year))
                        )}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Operating Cost
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0040", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Employee wages and benefits
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0041", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Spent on Employee benefit
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0260", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Payments to the providers of capital
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0042", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        % of net profit contributed to the community
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          (getDataByDP("DPK044", year) /
                            handleNaNAndInfinity(
                              handleNaNAndInfinity(
                                getDataByDP("DPK0264", year) +
                                  (getDataByDP("DPK0039", year) -
                                    getDataByDP("DPK0261", year) -
                                    getDataByDP("DPK0262", year) -
                                    getDataByDP("DPK0263", year))
                              ) -
                                handleNaNAndInfinity(
                                  getDataByDPArray(
                                    [
                                      "DPK0040",
                                      "DPK0041",
                                      "DPK0260",
                                      "DPK0042",
                                      "DPK0044",
                                      "DPK0043",
                                      "DPK0045",
                                    ],
                                    year
                                  )
                                )
                            )) *
                            100
                        )}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        The amount contributed to the Community
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0044", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Amount of Payments to the government in the form of Tax
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0043", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Other expenses
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0045", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Economic value distributed
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          getDataByDPArray(
                            [
                              "DPK0040",
                              "DPK0041",
                              "DPK0260",
                              "DPK0042",
                              "DPK0044",
                              "DPK0043",
                              "DPK0045",
                            ],
                            year
                          )
                        )}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Economic value retained
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          handleNaNAndInfinity(
                            getDataByDP("DPK0264", year) +
                              (getDataByDP("DPK0039", year) -
                                getDataByDP("DPK0261", year) -
                                getDataByDP("DPK0262", year) -
                                getDataByDP("DPK0263", year))
                          ) -
                            handleNaNAndInfinity(
                              getDataByDPArray(
                                [
                                  "DPK0040",
                                  "DPK0041",
                                  "DPK0260",
                                  "DPK0042",
                                  "DPK0044",
                                  "DPK0043",
                                  "DPK0045",
                                ],
                                year
                              )
                            )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <sectionheader1 id={"GRI201-2"}>
                Disclosure 201-2 Financial implications and other risks and
                opportunities due to climate change
              </sectionheader1>
              <div className="m-3 para">
                Risks and opportunities posed by climate change that have the
                potential to generate substantive changes in operations,
                revenue, or expenditure, including:
              </div>

              <div className="list">
                <label>
                  1. a description of the risk or opportunity and its
                  classification as either physical, regulatory, or other;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(200, "textarea-1700647068246-0")}
                </label>

                <label>
                  2. a description of the impact associated with the risk or
                  opportunity;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(200, "textarea-1700647120857-0")}
                </label>

                <label>
                  3. the financial implications of the risk or opportunity
                  before action is taken;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(200, "textarea-1700647149851-0")}
                </label>

                <label>
                  4. the methods used to manage the risk or opportunity;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(200, "textarea-1700647163084-0")}
                </label>

                <label>
                  
                  5. the costs of actions taken to manage the risk or
                  opportunity.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(200, "number-1700647429134-0")}
                </label>
              </div>
              <div className="m-3 para">
                If the reporting organization does not have a system in place to
                calculate the financial implications or costs, or to make
                revenue projections, it shall report its plans and timeline to
                develop the necessary systems.
              </div>
              <sectionheader1 id={"GRI201-3"}>
                Disclosure 201-3 Defined benefit plan obligations and other
                retirement plans
              </sectionheader1>
              <div className="list">
                <label>
                  a) If the plan’s liabilities are met by the organization’s
                  general resources, the estimated value of those liabilities.
                </label>
                <label>
                  
                  b) If a separate fund exists to pay the plan’s pension
                  liabilities:
                </label>
                <label>
                  
                  1. the extent to which the scheme’s liabilities are estimated
                  to be covered by the assets that have been set aside to meet
                  them;
                </label>
                <label>
                  
                  2. the basis on which that estimate has been arrived at;
                </label>
                <label> 3. when that estimate was made</label>
                <label>
                  
                  c) If a fund set up to pay the plan’s pension liabilities is
                  not fully covered, explain the strategy, if any, adopted by
                  the employer to work towards full coverage, and the timescale,
                  if any, by which the employer hopes to achieve full coverage.
                </label>
                <label>
                  
                  d) Percentage of salary contributed by employee or employer
                </label>
                <label>
                  
                  e) Level of participation in retirement plans, such as
                  participation in mandatory or voluntary schemes, regional, or
                  country-based schemes, or those with financial impact.
                </label>
              </div>
              <sectionheader1 id={"GRI201-4"}>
                
                Disclosure 201-4 Financial assistance received from government
              </sectionheader1>

              <div className="list">
                <label>
                  a) Total monetary value of financial assistance received by
                  the organization from any government during the reporting
                  period, including:
                </label>
              </div>
              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Value
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        tax relief and tax credits
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        subsidies
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        investment grants, research and development grants, and
                        other relevant types of grant;
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        awards
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        royalty holidays
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        financial assistance from Export Credit Agencies (ECAs);
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        financial incentives;
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        other financial benefits received or receivable from any
                        government for any operation
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="list">
                <label>
                  
                  the extent to which, any government is present in the
                  shareholding structure.
                </label>

                <label>
                  a) When compiling the information, the reporting organization
                  shall identify the monetary value of financial assistance
                  received from the government through consistent application of
                  generally accepted accounting principles.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(72, "textarea-1691603980172-0")}
                </label>

                <label>
                  b) Significance of the indirect economic impacts in the
                  context of external benchmarks and stakeholder priorities,
                  including national and international standards, protocols, and
                  policy agendas.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(72, "textarea-1700656323020")}
                </label>
              </div>

              <sectionheader id={"GRI202"} className="secHead">
                GRI 202 Market Presence
              </sectionheader>

              <sectionheader1 id={"GRI202-1"}>
                Disclosure 202-1 Ratios of standard entry-level wage by gender
                compared to local minimum wage
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) When a significant proportion of employees are compensated
                  based on wages subject to minimum wage rules, report the
                  relevant ratio of the entry-level wage by gender at
                  significant locations of operation to the minimum wage.
                </label>
                <label>
                  
                  b)When a significant proportion of other workers (excluding
                  employees) performing the organization’s activities are
                  compensated based on wages subject to minimum wage rules,
                  describe the actions taken to determine whether these workers
                  are paid above the minimum wage.
                </label>

                <label>
                  
                  c) Whether a local minimum wage is absent or variable at
                  significant locations of operation, by gender. In
                  circumstances in which different minimums can be used as a
                  reference, report which minimum wage is being used.
                </label>
              </div>

              <sectionheader1 id={"GRI202-2"}>
                
                Disclosure 202-2 Proportion of senior management hired from the
                local community
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) Percentage of senior management at significant locations of
                  operation that are hired from the local community.
                </label>
                <label>b) The definition used for ‘senior management’. </label>

                <label>
                  
                  c) The organization’s geographical definition of ‘local’.
                </label>
                <label>
                  
                  d) The definition used for ‘significant locations of
                  operation’
                </label>
              </div>

              <sectionheader id={"GRI203"} className="secHead">
                GRI 203 Indirect Economic Impact
              </sectionheader>

              <sectionheader1 id={"GRI203-1"}>
                
                Disclosure 203-1 Infrastructure investments and services
                supported
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) Extent of development of significant infrastructure
                  investments and services supported.
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(73, "textarea-1691604120256-0")}
                </label>

                <label>
                  b) Current or expected impacts on communities and local
                  economies, including positive and negative impacts where
                  relevant.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(73, "textarea-1691604170067-0")}
                </label>

                <label>
                  
                  c) Whether these investments and services are commercial,
                  in-kind, or pro bono engagements
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(73, "checkbox-group-1691604189164-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI203-2"}>
                Disclosure 203-2 Significant indirect economic impacts
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) Examples of significant identified indirect economic
                  impacts of the organization, including positive and negative
                  impacts
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(72, "textarea-1691603980172-0")}
                </label>

                <label>
                  
                  b) Significance of the indirect economic impacts in the
                  context of external benchmarks and stakeholder priorities,
                  such as national and international standards, protocols, and
                  policy agendas.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(72, "textarea-1700656323020")}
                </label>
              </div>

              <sectionheader id={"GRI204"} className="secHead">
                GRI 204 Procurement Practices
              </sectionheader>

              <sectionheader1 id={"GRI204-1"}>
                Disclosure 204-1 Proportion of spending on local suppliers
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        % of products and services purchased locally (%
                        procurement budget spent on local supplier)
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        {handleNaNAndInfinity(
                          (getDataByDP("DPR0014", year) /
                            getDataByDP("DPR0015", year)) *
                            100
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI205"} className="secHead">
                GRI 205 Anti-Corruption
              </sectionheader>

              <sectionheader1 id={"GRI205-1"}>
                Disclosure 205-1 Operations assessed for risks related to
                corruption
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Total number of operations assessed for risks related to
                        corruption.
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(getDataByDP("DPK0050", year))}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Percentage of operations assessed for risks related to
                        corruption.
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          getDataByDP("DPK0050", year) /
                            getDataByDP("DPR0011", year)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                
                Significant risks related to corruption identified through the
                risk assessment.
              </div>

              <label className="rfres-one">
                
                {getLastResponseByRFID(92, "textarea-1693508153155-0")}
              </label>

              <sectionheader1 id={"GRI205-2"}>
                
                Disclosure 205-2 Communication and training about
                anti-corruption policies and procedures
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="4">
                      <td colSpan="1" className="tdHead">
                        Category
                      </td>
                      <td colSpan="1" className="tdHead">
                        Governance body members
                      </td>
                      <td colSpan="1" className="tdHead">
                        Employees
                      </td>
                      <td colSpan="1" className="tdHead">
                        Business partner
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Total number of whom the organization’s anticorruption
                        policies and procedures have been communicated
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0051", year)}
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0055", year)}
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPR0012", year)}
                      </td>
                    </tr>
                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        % of whom the organization’s anticorruption policies and
                        procedures have been communicated
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          (getDataByDP("DPK0051", year) /
                            getDataByDP("DPK0052", year)) *
                            100
                        )}
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          (getDataByDP("DPK0055", year) /
                            getDataByDPArray(
                              [
                                "DPS0072",
                                "DPS0073",
                                "DPS0075",
                                " DPS0076",
                                "DPKN0013",
                                "DPKN0014",
                                "DPKN0015",
                                "DPKN0016",
                                "DPKN0017",
                                "DPKN0018",
                              ],
                              year
                            )) *
                            100
                        )}
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          (getDataByDP("DPR0012", year) /
                            getDataByDP("DPR0013", year)) *
                            100
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                
                Describe if the organization’s anti-corruption policies and
                procedures have been communicated to any other persons or
                organizations.
              </div>

              <label className="rfres-one">
                
                {getLastResponseByRFID(203, "textarea-1700661800364-0")}
              </label>

              <div className="m-3 para">
                Total number and percentage of governance body members that have
                received training on anti-corruption, broken down by region
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Total number of whom received training on anticorruption
                      </td>
                      <td colSpan="1" className="tdHead">
                        % of whom received training on anticorruption
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPR0017", year)}
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          getDataByDP("DPR0017", year) *
                            getDataByDP("DPK0052", year) *
                            100
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                Total number and percentage of employees that have received
                training on anticorruption, broken down by employee category and
                region.
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Employee Category
                      </td>
                      <td colSpan="1" className="tdHead">
                        Total number of whom received training on anticorruption
                      </td>
                      <td colSpan="1" className="tdHead">
                        % of whom received training on anticorruption
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Senior management
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Mid-level management
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Entry level employees
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI205-3"}>
                
                Disclosure 205-3 Communication and training about
                anti-corruption policies and procedures
              </sectionheader1>

              <div className="list">
                <label> a) Nature of confirmed incidents of corruption </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Total number of confirmed incidents of corruption
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0059", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Total number of confirmed incidents in which employees
                        were dismissed or disciplined for corruption
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0060", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Total number of confirmed incidents when contracts with
                        business partners were terminated or not renewed due to
                        violations related to corruption.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Public legal cases regarding corruption brought against
                        the organization or its employees during the reporting
                        period and the outcomes of such cases.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI206"} className="secHead">
                GRI 206: Anti-competitive Behavior
              </sectionheader>

              <sectionheader1 id={"GRI206-1"}>
                
                Disclosure 206-1 Legal actions for anti-competitive behavior,
                anti-trust, and monopoly practices
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of legal actions pending or completed during the
                        reporting period regarding anti-competitive behavior and
                        violations of anti-trust and monopoly legislation in
                        which the organization has been identified as a
                        participant.
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPK0059", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Number of completed during the reporting period
                        regarding anti-competitive behavior and violations of
                        anti-trust and monopoly legislation in which the
                        organization has been identified as a participant.
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0060", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="list">
                <label>
                  
                  b) Main outcomes of completed legal actions, including any
                  decisions or judgements.
                </label>
              </div>

              <sectionheader id={"GRI207"} className="secHead">
                GRI 207: Tax
              </sectionheader>

              <sectionheader1 id={"GRI207-1"}>
                
                Disclosure 207-1 Approach to tax
              </sectionheader1>

              <div className="m-3 para">
                
                A description of the approach to tax, including:
              </div>
              <div className="list">
                <label>
                  
                  a) whether the organization has a tax strategy and, if so, a
                  link to this strategy if publicly available;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(76, "radio-group-1691605320513-0")}
                  {getLastResponseByRFID(76, "textarea-1691605251895-0")}
                </label>

                <label>
                  
                  b) the governance body or executive-level position within the
                  organization that formally reviews and approves the tax
                  strategy and the frequency of this review;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(76, "text-1691605380847-0")}
                  {getLastResponseByRFID(76, "textarea-1691605423125-0")}
                </label>

                <label>
                  c) the approach to regulatory compliance; how the approach to
                  tax is linked to the business and sustainable development
                  strategies of the organization.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(76, "extarea-1691605445820-0")}
                  {getLastResponseByRFID(76, "textarea-1691605483082-0")}
                  {getLastResponseByRFID(76, "textarea-1691605596400-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI207-2"}>
                
                Disclosure 207-2 Tax governance, control, and risk management
              </sectionheader1>

              <div className="list">
                <label>
                  a) A description of the tax governance and control framework,
                  including the governance body or executive-level position
                  within the organization accountable for compliance with the
                  tax strategy;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(75, "textarea-1691604707129-0")}
                </label>

                <label>
                  b) how the approach to tax is embedded within the
                  organization;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(75, "text-1691604943251-0")}
                </label>

                <label>
                  c) the approach to tax risks, including how risks are
                  identified, managed, and monitored;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(75, "textarea-1691605026940-0")}
                  {getLastResponseByRFID(75, "textarea-1691605076517-0")}
                </label>

                <label>
                  d) how compliance with the tax governance and control
                  framework is evaluated.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(75, "textarea-1691605120278-0")}
                </label>

                <label>
                  e) A description of the mechanisms to raise concerns about the
                  organization’s business conduct and the organization’s
                  integrity in relation to tax.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(75, "textarea-1691605120278-0")}
                </label>

                <label>
                  f) A description of the assurance process for disclosures on
                  tax including, if applicable, a link or reference to the
                  external assurance report(s) or assurance statement(s).
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(75, "textarea-1691605178770-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI207-3"}>
                
                Disclosure 207-3 Stakeholder engagement and management of
                concerns related to tax
              </sectionheader1>
              <div className="list">
                <label>
                  
                  a) A description of the approach to stakeholder engagement and
                  management of stakeholder concerns related to tax, including:
                </label>
                <label>
                  
                  b) the approach to engagement with tax authorities;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(74, "text-1691604515906-0")}
                </label>

                <label>
                  
                  c) the approach to public policy advocacy on tax;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(74, "textarea-1691604542135-0")}
                </label>

                <label>
                  
                  d) the processes for collecting and considering the views and
                  concerns of stakeholders, including external stakeholders.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(75, "textarea-1691604558281-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI207-4"}>
                
                Disclosure 207-4 Country-by-country reporting
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) All tax jurisdictions where the entities included in the
                  organization’s audited consolidated financial statements, or
                  in the financial information filed on public record, are
                  resident for tax purposes.
                </label>

                <label> b) For each tax jurisdiction </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Tax Jurisdiction
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Names of the resident entities
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Primary activities of the organization
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of employees, and the basis of calculation of
                        this number;
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Revenues from third-party sales;
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Revenues from intra-group transactions with other tax
                        jurisdictions;
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Profit/loss before tax
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Tangible assets other than cash and cash equivalents;
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Corporate income tax paid on a cash basis;
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Corporate income tax accrued on profit/loss;
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Reasons for the difference between corporate income tax
                        accrued on profit/loss and the tax due if the statutory
                        tax rate is applied to profit/loss before tax.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  c)The time period covered by the information reported
                </label>
              </div>

              <sectionheader id={"GRI301"} className="secHead">
                GRI 301 Materials
              </sectionheader>

              <sectionheader1 id={"GRI301-1"}>
                Disclosure 301-1 Materials used by weight or volume
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Materials
                      </td>
                      <td colSpan="1" className="tdHead">
                        non-renewable materials
                      </td>
                      <td colSpan="1" className="tdHead">
                        renewable materials
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Weight /Volume of materials that are used to produce
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Weight /Volume of materials that are used to package
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI301-2"}>
                Disclosure 301-2 Recycled input materials used
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Percentage of recycled input materials used to
                        manufacture the organization's primary products and
                        services.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Percentage of recycled input materials
                      </td>

                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI301-3"}>
                
                Disclosure 301-3 Reclaimed products and their packaging
                materials
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Product category
                      </td>
                      <td colSpan="1" className="tdHead">
                        Percentage of reclaimed products and their packaging
                        materials
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  b) How the data for this disclosure have been collected.
                </label>
              </div>

              <sectionheader1 id={"GRI302-1"}>
                
                Disclosure 302-1 Energy consumption within the organization
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="4">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead">
                        Unit of Measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Non-renewable
                      </td>

                      <td colSpan="1" className="tdHead">
                        Renewable
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Total fuel consumption
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Joules
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Unite of Measurement
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        electricity consumption
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Watt-hours or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        heating consumption
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Watt-hours or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        cooling consumption
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Watt-hours or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        steam consumption
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Watt-hours or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        electricity sold
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Watt-hours or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Heating sold
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Watt-hours or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Cooling sold
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Watt-hours or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Steam sold
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Watt-hours or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Total energy consumption in organization
                      </td>
                      <td colSpan="1" className="text-justify">
                        joules or multiples.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                Standards, methodologies, assumptions, and/or calculation tools
                used. Source of the conversion factors used.
              </div>

              <sectionheader1 id={"GRI302-2"}>
                
                Disclosure 302-2 Energy consumption outside of the organization
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Unite of Measurement
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Energy consumption outside the organization
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Joules or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                
                Standards, methodologies, assumptions, and/or calculation tools
                used. Source of the conversion factors used.
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>
                      <td colSpan="1" className="tdHead">
                        Unite of Measurement
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Upstream categories
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        Joules or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Purchased goods and services
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Capital goods
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Fuel- and energy-related activities
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Upstream transportation and distribution
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Waste generated in operations
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Business travel
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Employee commuting
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Upstream leased assets Other upstream
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Downstream categories
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Downstream transportation and distribution
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Processing of sold products
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Use of sold products
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        End-of-life treatment of sold products
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Downstream leased assets
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Franchises
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Investments
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI302-3"}>
                Disclosure 302-3 Energy intensity
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead">
                        Within the Organization
                      </td>
                      <td colSpan="1" className="tdHead">
                        Outside of organization
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Energy intensity ratio for the
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        organization
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  a) Organization-specific metric (the denominator) chosen to
                  calculate the ratio.
                </label>

                <label>
                  b) Types of energy included in the intensity ratio; whether
                  fuel, electricity, heating, cooling, steam, or all.
                </label>

                <label>
                  
                  c) Whether the ratio uses energy consumption within the
                  organization, outside of it, or both.
                </label>
              </div>

              <sectionheader1 id={"GRI302-4"}>
                
                Disclosure 302-4 Reduction of energy consumption
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Amount of reductions in energy consumption (joules or
                        multiples)
                      </td>
                      <td colSpan="1" className="tdHead">
                        Baseline year
                      </td>
                      <td colSpan="1" className="tdHead">
                        FY 2023
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Fuel
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Electricity
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Heating
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Colling
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        steam
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  c) Standards, methodologies, assumptions, and/or calculation
                  tools used
                </label>
              </div>

              <sectionheader1 id={"GRI302-5"}>
                
                Disclosure 302-5 Reductions in energy requirements of products
                and services
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead">
                        Baseline year
                      </td>
                      <td colSpan="1" className="tdHead">
                        FY 2023
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Reductions in energy requirements of sold products and
                        services
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI303"} className="secHead">
                GRI 303 Water & effluents
              </sectionheader>

              <sectionheader1 id={"GRI303-1"}>
                Disclosure 303-1 Interactions with water as a shared resource
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) A description of how the organization interacts with water
                  including how and where water is withdrawn, consumed, and
                  discharged, and the water-related impacts the organization has
                  caused or contributed to, or that are directly linked to its
                  operations, products, or services by its business
                  relationships.
                </label>

                <label>
                  
                  b) A description of the approach used to identify
                  water-related impacts, including the scope of assessments,
                  their timeframe, and any tools or methodologies used.
                </label>

                <label>
                  
                  c) A description of how water-related impacts are addressed,
                  including how the organization works with stakeholders to
                  steward water as a shared resource, and how it engages with
                  suppliers or customers with significant water-related impacts.
                </label>

                <label>
                  d) An explanation of the process for setting any water-related
                  goals and targets that are part of the organization’s approach
                  to managing water and effluents, and how they relate to public
                  policy and the local context of each area with water stress
                </label>
              </div>

              <sectionheader1 id={"GRI303-2"}>
                
                Disclosure 303-2 Management of water discharge-related impacts
              </sectionheader1>

              <div className="m-3 para">
                
                A description of any minimum standards set for the quality of
                effluent discharge, and how these minimum standards were
                determined, including:
              </div>

              <div className="list">
                <label>
                  
                  a) how standards for facilities operating in locations with no
                  local discharge
                </label>

                <label> b) requirements were determined; </label>

                <label>
                  
                  c) any internally developed water quality standards or
                  guidelines;
                </label>

                <label> d) any sector-specific standards considered; </label>
              </div>

              <sectionheader1 id={"GRI303-3"}>
                
                Disclosure 303-3 Water withdrawal
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Water withdrawal [Disclosure 303-3]
                      </td>
                      <td colSpan="1" className="tdHead">
                        ALL AREAS
                      </td>
                      <td colSpan="1" className="tdHead">
                        AREAS WITH WATER STRESS
                      </td>
                    </tr>

                    <tr colSpan="1">
                      <td colSpan="3" className="tdHead">
                        Water withdrawal by source
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Surface water (total)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Freshwater (≤1,000 mg/L Total Dissolved Solids)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        {`Other water (>1,000 mg/L Total Dissolved Solids)`}
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Groundwater (total)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        {`                        Freshwater (≤1,000 mg/L Total Dissolved Solids)`}
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        {`Other water (>1,000 mg/L Total Dissolved Solids)`}
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Seawater (total)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Freshwater (≤1,000 mg/L Total Dissolved Solids)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        {`Other water (>1,000 mg/L Total Dissolved Solids)`}
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Produced water (total)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Freshwater (≤1,000 mg/L Total Dissolved Solids)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Other water ({">"}1,000 mg/L Total Dissolved Solids)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Produced water (total)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Freshwater (≤1,000 mg/L Total Dissolved Solids)
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        {`Other water (>1,000 mg/L Total Dissolved Solids)`}
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="1">
                      <td colSpan="3" className="tdHead">
                        Total third-party water withdrawal by withdrawal source
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Surface water
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Groundwater
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Seawater
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Produced water
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="1">
                      <td colSpan="3" className="tdHead">
                        Total water withdrawal
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Surface water (total) + groundwater (total) + seawater
                        (total) + produced water (total) + third-party water
                        (total)
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI303-4"}>
                
                Disclosure 303-4 Water discharge
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Water discharge [Disclosure 303-4]
                      </td>
                      <td colSpan="1" className="tdHead">
                        ALL AREAS
                      </td>
                      <td colSpan="1" className="tdHead">
                        AREAS WITH WATER STRESS
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="3" className="tdHead">
                        Water discharge by destination
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Surface water
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Groundwater
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Seawater
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Third-party water (total)
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Third-party water sent for use to other organizations
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="3" className="tdHead">
                        Total water discharge
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Surface water + groundwater + seawater + third-party
                        water (total)
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="3" className="tdHead">
                        Water discharge by freshwater and other water
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Freshwater (≤1,000 mg/L Total Dissolved Solids)
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        {`Other water (>1,000 mg/L Total Dissolved Solids)`}
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="3" className="tdHead">
                        
                        Water discharge by level of treatment
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        No treatment
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Treatment level [Provide the title for treatment level]
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Treatment level [Provide the title for treatment level]
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Treatment level [Provide the title for treatment level]
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        X
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  a) Priority substances of concern for which discharges are
                  treated, including:
                </label>

                <label>
                  
                  b) how priority substances of concern were defined, and any
                  international standard, authoritative list, or criteria used;
                </label>

                <label>
                  
                  c) the approach for setting discharge limits for priority
                  substances of concern; number of incidents of non-compliance
                  with discharge limits.
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Water consumption [Disclosure 303-5] Particular
                      </td>
                      <td colSpan="1" className="tdHead">
                        ALL AREAS
                      </td>
                      <td colSpan="1" className="tdHead">
                        AREAS WITH WATER STRESS
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Total water consumption
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Change in water storage, if water storage has been
                        identified as having a significant water-related impact
                      </td>
                      <td colSpan="1" className="text-justify">
                        ML
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        -
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI303-5"}>
                
                Disclosure 303-5 Water consumption
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        FACILITIES IN AREAS WITH WATER STRESS
                      </td>

                      <td colSpan="1" className="tdHead">
                        FACILITY A
                      </td>

                      <td colSpan="1" className="tdHead">
                        FACILITY B
                      </td>

                      <td colSpan="1" className="tdHead">
                        [FACILITY X]
                      </td>
                    </tr>

                    <tr colSpan="1">
                      <td colSpan="4" className="text-justify">
                        
                        Water withdrawal (clause 2.2.1)
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Surface water
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Groundwater
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Seawater
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Produced water
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Third-party water
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>

                    <tr colSpan="1">
                      <td colSpan="4" className="text-justify">
                        
                        Water consumption (clause 2.5.1)
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Total water consumption
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        ML
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI304"} className="secHead">
                GRI 304 Biodiversity
              </sectionheader>

              <sectionheader1 id={"GRI304-1"}>
                Disclosure 304-1 Operational sites owned, leased, managed in, or
                adjacent to, protected areas and areas of high biodiversity
                value outside protected areas.
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>

                      <td colSpan="1" className="tdHead">
                        Remarks
                      </td>

                      <td colSpan="3" className="tdHead">
                        Operational Sites
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead"></td>

                      <td colSpan="1" className="tdHead"></td>

                      <td colSpan="1" className="tdHead">
                        Owned
                      </td>

                      <td colSpan="1" className="tdHead">
                        Leased
                      </td>

                      <td colSpan="1" className="tdHead">
                        Managed
                      </td>
                    </tr>

                    <tr colSpan="5">
                      <td colSpan="1" className="text-justify">
                        
                        Geographic location
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="5">
                      <td colSpan="1" className="text-justify">
                        
                        Subsurface and underground land that may be owned,
                        leased, or managed by the organization
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="5">
                      <td colSpan="1" className="text-justify">
                        
                        Position in relation to the protected area
                      </td>
                      <td colSpan="1" className="text-justify">
                        (in the area, adjacent to, or containing portions of the
                        protected area) or the high biodiversity value area
                        outside protected areas
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="5">
                      <td colSpan="1" className="text-justify">
                        
                        Type of operation
                      </td>
                      <td colSpan="1" className="text-justify">
                        (office, manufacturing or production, or extractive)
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="5">
                      <td colSpan="1" className="text-justify">
                        
                        Size of operational site in km2
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        (or another unit, if appropriate)
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="5">
                      <td colSpan="1" className="text-justify">
                        
                        Biodiversity value characterized by the attribute of the
                        protected area or area of high biodiversity value
                        outside the protected area
                      </td>
                      <td colSpan="1" className="text-justify">
                        (terrestrial, freshwater, or maritime ecosystem)
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="5">
                      <td colSpan="1" className="text-justify">
                        Biodiversity value characterized by listing of protected
                        status
                      </td>
                      <td colSpan="1" className="text-justify">
                        (such as IUCN Protected Area Management Categories,
                        Ramsar Convention, national legislation)
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI304-2"}>
                Disclosure 304-2 Significant impacts of activities, products and
                services on biodiversity
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) Nature of significant direct and indirect impacts on
                  biodiversity with reference to one or more of the following:
                </label>

                <label>
                  
                  a) Construction or use of manufacturing plants, mines, and
                  transport infrastructure;
                </label>

                <label>
                  
                  b) Pollution (introduction of substances that do not naturally
                  occur in the habitat from point and non-point sources);
                </label>

                <label>
                  
                  c) Introduction of invasive species, pests, and pathogens;
                </label>

                <label> d) Reduction of species;</label>

                <label> e) Habitat conversion; </label>

                <label>
                  
                  f) Changes in ecological processes outside the natural range
                  of variation (such as salinity or changes in groundwater
                  level).
                </label>

                <label>
                  2) Significant direct and indirect positive and negative
                  impacts with reference to the following
                </label>

                <label>a) Species affected; </label>

                <label> b) Extent of areas impacted;</label>

                <label>c) Duration of impacts; </label>

                <label>
                  
                  d) Reversibility or irreversibility of the impacts
                </label>
              </div>

              <sectionheader1 id={"GRI304-3"}>
                
                Disclosure 304-3 Habitats protected or restored
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Particulars
                      </td>

                      <td colSpan="1" className="tdHead">
                        Size
                      </td>

                      <td colSpan="1" className="tdHead">
                        Location
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        habitat areas protected
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        habitat areas restored
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  a) Whether the success of the restoration measure was or is
                  approved by independent external professionals.
                </label>

                <label>
                  
                  b) Whether partnerships exist with third parties to protect or
                  restore habitat areas distinct from where the organization has
                  overseen and implemented restoration or protection measures.
                </label>

                <label>
                  
                  c) Status of each area based on its condition at the close of
                  the reporting period.
                </label>

                <label>
                  d) Standards, methodologies, and assumptions used.
                </label>
              </div>

              <sectionheader1 id={"GRI304-4"}>
                Disclosure 304-4 IUCN Red List species and national conservation
                list species with habitats in areas affected by operations
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Level of extinction list
                      </td>

                      <td colSpan="1" className="tdHead">
                        Total number of IUCN Red List species and national
                        conservation list species
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Critically endangered
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Endangered
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Vulnerable
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Near threatened
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Least concern
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI305"} className="secHead">
                GRI 305 Emissions
              </sectionheader>

              <sectionheader1 id={"GRI305-1"}>
                
                Disclosure 305-1 Direct (Scope 1) GHG emissions
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="4">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Baseline year
                      </td>

                      <td colSpan="1" className="tdHead">
                        FY 2023
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Stationary Combustion Emission
                      </td>
                      <td colSpan="1" className="text-justify">
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Mobile Combustion Emission
                      </td>
                      <td colSpan="1" className="text-justify">
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Fugitive Emissions
                      </td>
                      <td colSpan="1" className="text-justify">
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Process Emissions
                      </td>
                      <td colSpan="1" className="text-justify">
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  a) Source of the emission factors and the global warming
                  potential (GWP) rates used, or a reference to the GWP source.
                </label>

                <label>
                  
                  b) Consolidation approach for emissions; whether equity share,
                  financial control, or operational control.
                </label>

                <label>
                  
                  c) Standards, methodologies, assumptions, and/or calculation
                  tools used
                </label>

                <label>
                  
                  d) A breakdown of the direct (Scope 1) GHG emissions by:
                  business unit or facility; country; type of source, type of
                  activity
                </label>
              </div>

              <sectionheader1 id={"GRI305-2"}>
                
                Disclosure 305-2 Energy indirect (Scope 2) GHG emissions
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="4">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Baseline year
                      </td>

                      <td colSpan="1" className="tdHead">
                        FY 2023
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Energy Purchased from Third party sources
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                
                A breakdown of the indirect (Scope 2) GHG emissions by: business
                unit or facility; country; type of source, type of activity
              </div>

              <sectionheader1 id={"GRI305-3"}>
                
                Disclosure 305-3 Other indirect (Scope 3) GHG emissions
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="4">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Baseline year
                      </td>

                      <td colSpan="1" className="tdHead">
                        FY 2023
                      </td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Energy Purchased from Third party sources
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 1 - Purchased goods and services
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 2 - Capital goods
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 3 - Fuel- and energy-related activities
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 4 - Upstream transportation and distribution
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 5 - Waste generated in operations
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 6 - Business travel
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Category 7 - Employee commuting
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        Category 8 - Upstream leased assets
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 9 - Downstream transportation and distribution
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 10 - Processing of sold products
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 11 - Use of sold products
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 12 - End-of-life treatment of sold products
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 13 - Downstream leased assets
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 14 - Franchises
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="4">
                      <td colSpan="1" className="text-justify">
                        
                        Category 15 - Investments
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                
                A breakdown of the other indirect (Scope 3) GHG emissions by:
                business unit or facility; country; type of source, type of
                activity
              </div>

              <sectionheader1 id={"GRI305-4"}>
                Disclosure 305-4 GHG emissions intensity
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions intensity ratio for the organization
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        [Organization-specific metric (the denominator) chosen
                        to calculate the ratio]
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Total Emission Intensity for Scope 1: Direct Emissions
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        (in tCO2e/organization specific metrics)
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Total Emission Intensity for Scope 2: Indirect Emissions
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        (in tCO2e/organization specific metrics)
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Total Emission Intensity for Scope 3: Other Indirect
                        Emissions
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        (in tCO2e/organization specific metrics)
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                A breakdown of the GHG emissions intensity ratio by: business
                unit or facility; country; type of source; type of activity
              </div>

              <sectionheader1 id={"GRI305-5"}>
                
                GRI 305-5 Reduction of GHG emissions
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions reduced as a direct result of reduction
                        initiatives
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions reduced in Scope 1 emissionss
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions reduced in Scope 2 emissions
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions reduced in Scope 3 emissions
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        GHG emissions in metric tons of CO2 equivalent.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI305-6"}>
                
                Disclosure 305-6 Emissions of ozone-depleting substances (ODS)
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data Input
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Production of ODS
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        metric tons of CFC-11 (trichlorofluoromethane)
                        equivalent
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Import of ODS
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        metric tons of CFC-11 (trichlorofluoromethane)
                        equivalent
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Export of ODS
                      </td>
                      <td colSpan="1" className="text-justify">
                        metric tons of CFC-11 (trichlorofluoromethane)
                        equivalent
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI305-7"}>
                
                Disclosure 305-7 Nitrogen oxides (NOx), sulfur oxides (SOx), and
                other significant air emissions
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data Input
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        NOx
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        kilograms or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        SOx
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        kilograms or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Persistent organic pollutants (POP)
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        kilograms or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Volatile organic compounds (VOC)
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        kilograms or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Hazardous air pollutants (HAP)
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        kilograms or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Particulate matter (PM)
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        kilograms or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Other standard categories of air emissions identified in
                        relevant regulations
                      </td>
                      <td colSpan="1" className="text-justify">
                        
                        kilograms or multiples
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI306"} className="secHead">
                GRI 306: Effluents and Waste
              </sectionheader>

              <sectionheader1 id={"GRI306-1"}>
                
                Disclosure 306-1 Waste generation and significant waste-related
                impacts
              </sectionheader1>

              <div className="list">
                <label>
                  a) significant actual and potential waste-related impacts, a
                  description of:
                </label>

                <label>
                  b) the inputs, activities, and outputs that lead or could lead
                  to these impacts;
                </label>

                <label>
                  
                  c) whether these impacts relate to waste generated in the
                  organization’s own activities or to waste generated upstream
                  or downstream in its value chain.
                </label>
              </div>

              <sectionheader1 id={"GRI306-2"}>
                Disclosure 306-2 Management of significant waste related impacts
              </sectionheader1>

              <div className="list">
                <label>
                  
                  a) Actions, including circularity measures, taken to prevent
                  waste generation in the organization’s own activities and
                  upstream and downstream in its value chain, and to manage
                  significant impacts from waste generated.
                </label>

                <label>
                  
                  b) If the waste generated by the organization in its own
                  activities is managed by a third party, a description of the
                  processes used to determine whether the third party manages
                  the waste in line with contractual or legislative obligations.
                </label>

                <label>
                  b) If the waste generated by the organization in its own
                  activities is managed by a third party, a description of the
                  processes used to determine whether the third party manages
                  the waste in line with contractual or legislative obligations.
                </label>
              </div>

              <sectionheader1 id={"GRI306-3"}>
                Disclosure 306-3 Waste generated
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Total weight of waste generated
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Waste category
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Total weight
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Hazardous
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Non-hazardous
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Sector-specific waste
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Total weight
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Material-specific waste
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Total weight
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-center">
                        
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI306-4"}>
                Disclosure 306-4 Waste diverted from disposal
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Quantitative Data
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Total weight of waste diverted from disposal
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Total weight of waste diverted from disposal
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data
                      </td>
                    </tr>

                    <tr colspan="3">
                      <td colSpan="3" className="text-justify">
                        Hazardous waste
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Preparation for reuse
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Recycling
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Other recovery operations
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Onsite
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Offsite
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="3" className="text-justify">
                        
                        Non-hazardous waste
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Preparation for reuse
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Recycling
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Other recovery operations
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Onsite
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        offsite
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI306-5"}>
                Disclosure 306-5 Waste directed to disposal
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Total weight of waste diverted to disposal
                      </td>

                      <td colSpan="1" className="tdHead">
                        Unit of measurement
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data
                      </td>
                    </tr>

                    <tr colspan="3">
                      <td colSpan="3" className="text-justify">
                        Hazardous waste
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Incineration (with energy recovery)
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Incineration (without energy recovery)
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Landfilling
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Other disposal operations
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Onsite
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Offsite
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="3" className="text-justify">
                        
                        Non-hazardous waste
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Incineration (with energy recovery)
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Incineration (without energy recovery)
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Landfilling
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Other disposal operations
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Onsite
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Offsite
                      </td>
                      <td colSpan="1" className="text-justify">
                        Metric tons
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI308"} className="secHead">
                GRI 308 Supplier Environmental Assessment
              </sectionheader>

              <sectionheader1 id={"GRI308-1"}>
                Disclosure 308-1 New suppliers that were screened using
                environmental criteria
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Qualitative Data
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of new suppliers that were screened using
                        environmental criteria
                      </td>

                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI308-2"}>
                
                Disclosure 308-2 Negative environmental impacts in the supply
                chain and actions taken
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Qualitative Data
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of suppliers assessed for environmental impacts.
                      </td>

                      <td colSpan="1" className="text-justify">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="2" className="text-justify">
                        
                        Actual Negative Impacts
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Number of suppliers identified as having significant
                        actual negative environmental impacts.
                      </td>
                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of suppliers identified as having significant
                        actual negative environmental impacts with which
                        improvements were agreed upon as a result of assessment.
                      </td>

                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of suppliers identified as having significant
                        actual negative environmental impacts with which
                        relationships were terminated as a result of assessment,
                        and why.
                      </td>

                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="2" className="text-justify">
                        
                        Potential negative Impacts
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of suppliers identified potential negative
                        environmental impacts.
                      </td>

                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of suppliers identified potential negative
                        environmental impacts with which improvements were
                        agreed upon as a result of assessment.
                      </td>

                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of suppliers identified potential negative
                        environmental impacts with which relationships were
                        terminated as a result of assessment, and why.
                      </td>

                      <td colSpan="1" className="text-center">
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  a) Significant actual and potential negative environmental
                  impacts identified in the supply chain-
                </label>

                <label>
                  b) Percentage of suppliers identified as having significant
                  actual and potential negative environmental impacts with which
                  relationships were terminated as a result of assessment, and
                  why
                </label>
              </div>

              <sectionheader id={"GRI401"} className="secHead">
                GRI 401 Employment
              </sectionheader>

              <sectionheader1 id={"GRI401-1"}>
                Disclosure 401-1 New employee hires and employee turnover
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="7">
                      <td colSpan="1" rowSpan="2" className="tdHead">
                        401-1 New Employee hire
                      </td>

                      <td colSpan="3" className="tdHead">
                        Male
                      </td>
                      <td colSpan="3" className="tdHead">
                        Female
                      </td>
                    </tr>

                    <tr colSpan="7">
                      <td colSpan="1" className="tdHead">
                        Under 30 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        30-50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Above 50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Under 30 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        30-50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Above 50 Years
                      </td>
                    </tr>

                    <tr colspan="7">
                      <td colspan="1" className="text-justify">
                        
                        Permanent employees
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0011", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0014", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0017", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0012", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0015", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0018", year)}
                      </td>
                    </tr>

                    <tr colspan="7">
                      <td colspan="1" className="text-justify">
                        
                        Temporary employees
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN001", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        {getDataByDP("DPKN002", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN003", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN004", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN005", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN006", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="7">
                      <td colSpan="1" rowSpan="2" className="tdHead">
                        401-1 Number of employees left the organization
                      </td>

                      <td colSpan="3" className="tdHead">
                        Male
                      </td>
                      <td colSpan="3" className="tdHead">
                        Female
                      </td>
                    </tr>

                    <tr colSpan="7">
                      <td colSpan="1" className="tdHead">
                        Under 30 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        30-50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Above 50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Under 30 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        30-50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Above 50 Years
                      </td>
                    </tr>

                    <tr colspan="7">
                      <td colspan="1" className="text-justify">
                        
                        Permanent employees
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0284", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0288", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0291", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0286", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0290", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0294", year)}
                      </td>
                    </tr>

                    <tr colspan="7">
                      <td colspan="1" className="text-justify">
                        
                        Temporary employees
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN007", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        {getDataByDP("DPKN009", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN011", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN008", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN010", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN012", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI401-2"}>
                
                Disclosure 401-2 Benefits provided to full-time employees that
                are not provided to temporary or part-time employees
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="5">
                      <td colSpan="1" rowSpan="2" className="tdHead">
                        Number of employees receiving benefits
                      </td>

                      <td colSpan="2" className="tdHead">
                        Full-time Employee
                      </td>
                      <td colSpan="2" className="tdHead">
                        Temporary Employee Female
                      </td>
                    </tr>

                    <tr colSpan="5">
                      <td colSpan="1" className="tdHead">
                        Male
                      </td>

                      <td colSpan="1" className="tdHead">
                        Female
                      </td>

                      <td colSpan="1" className="tdHead">
                        Male
                      </td>

                      <td colSpan="1" className="tdHead">
                        Female
                      </td>
                    </tr>

                    <tr colspan="5">
                      <td colspan="1" className="text-justify">
                        
                        Life insurance
                      </td>
                      <td colspan="1" className="text-center">
                        {getDataByDP("DPS0243", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN054", year)}
                      </td>
                    </tr>

                    <tr colspan="5">
                      <td colspan="1" className="text-justify">
                        Health care
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN077", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN078", year)}
                      </td>
                      <td colspan="1" className="text-center"></td>
                      <td colspan="1" className="text-center"></td>
                    </tr>

                    <tr colspan="5">
                      <td colspan="1" className="text-justify">
                        
                        Disability and invalidity coverage
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0128", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPSF128", year)}
                      </td>
                      <td colspan="1" className="text-center"></td>
                    </tr>

                    <tr colspan="5">
                      <td colspan="1" className="text-justify">
                        
                        Parental leave
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0157", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0150", year)}
                      </td>
                      <td colspan="1" className="text-center"></td>
                      <td colspan="1" className="text-center"></td>
                    </tr>

                    <tr colspan="5">
                      <td colspan="1" className="text-justify">
                        
                        Retirement provision
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0129", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPSF129", year)}
                      </td>
                      <td colspan="1" className="text-center"></td>
                      <td colspan="1" className="text-center"></td>
                    </tr>

                    <tr colspan="5">
                      <td colspan="1" className="text-justify">
                        
                        Stock ownership
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0130", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPSF130", year)}
                      </td>
                      <td colspan="1" className="text-center"></td>
                      <td colspan="1" className="text-center"></td>
                    </tr>

                    <tr colspan="5">
                      <td colspan="1" className="text-justify">
                        
                        Other
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN059", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN060", year)}
                      </td>
                      <td colspan="1" className="text-center"></td>
                      <td colspan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI401-3"}>
                Disclosure 401-3 Parental leave
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" rowSpan="2" className="tdHead">
                        Number of employees
                      </td>

                      <td colSpan="2" className="tdHead">
                        Employee Category
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Male
                      </td>

                      <td colSpan="1" className="tdHead">
                        Female
                      </td>
                    </tr>

                    <tr colspan="3">
                      <td colspan="1" className="text-justify">
                        Number of employees that took parental leave
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0157", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        {getDataByDP("DPS0150", year)}
                      </td>
                      {/* <td colspan="1" className="text-center"> {getDataByDP('DPS0157',year)}   </td>
                                            <td colspan="1" className="text-center"> {getDataByDP('DPS0150',year)}   </td> */}
                    </tr>

                    <tr colspan="3">
                      <td colspan="1" className="text-justify">
                        Number of employees that returned to work in the
                        reporting period after parental leave ended
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0138", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        {getDataByDP("DPS0137", year)}
                      </td>
                      {/* <td colspan="1" className="text-center"> {getDataByDP('DPS0138',year)}  </td>
                                            <td colspan="1" className="text-center"> {getDataByDP('DPS0137',year)}  </td> */}
                    </tr>

                    <tr colspan="3">
                      <td colspan="1" className="text-justify">
                        
                        Total number of employees that returned to work after
                        parental leave ended that were still employed 12 months
                        after their return to work
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPS0142", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        {getDataByDP("DPS0141", year)}
                      </td>
                      {/* <td colspan="1" className="text-center">  {getDataByDP('DPS0142',year)} </td>
                                            <td colspan="1" className="text-center">  {getDataByDP('DPS0141',year)} </td> */}
                    </tr>

                    <tr colspan="3">
                      <td colspan="1" className="text-justify">
                        Return to work
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {handleNaNAndInfinity(
                          (getDataByDP("DPS0138", year) /
                            getDataByDP("DPS0140", year)) *
                            100
                        )}
                      </td>
                      <td colspan="1" className="text-center">
                        {handleNaNAndInfinity(
                          (getDataByDP("DPS0137", year) /
                            getDataByDP("DPS0139", year)) *
                            100
                        )}
                      </td>
                      {/* <td colspan="1" className="text-center">  {handleNaNAndInfinity((getDataByDP('DPS0138',year)/getDataByDP('DPS0140',year))*100 )} </td>
                                            <td colspan="1" className="text-center"> {handleNaNAndInfinity((getDataByDP('DPS0137',year)/getDataByDP('DPS0139',year))*100 )}  </td> */}
                    </tr>
                    <tr colspan="3">
                      <td colspan="1" className="text-justify">
                        Retention rate
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {handleNaNAndInfinity(
                          (getDataByDP("DPS0142", year) /
                            getDataByDP("DPS0144", year)) *
                            100
                        )}
                      </td>
                      <td colspan="1" className="text-center">
                        {handleNaNAndInfinity(
                          (getDataByDP("DPS0141", year) /
                            getDataByDP("DPS0143", year)) *
                            100
                        )}
                      </td>
                      {/* <td colspan="1" className="text-center">   {handleNaNAndInfinity((getDataByDP('DPS0142',year)/getDataByDP('DPS0144',year))*100 )} </td>
                                            <td colspan="1" className="text-center">  {handleNaNAndInfinity((getDataByDP('DPS0141',year)/getDataByDP('DPS0143',year))*100 )} </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI402"} className="secHead">
                GRI 402 Labor Management
              </sectionheader>

              <sectionheader1 id={"GRI402-1"}>
                Disclosure 402-1 Minimum notice periods regarding operational
                changes
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead">
                        Data
                      </td>
                    </tr>

                    <tr colspan="2">
                      <td colspan="1" className="text-justify">
                        
                        Minimum number of weeks’ notice typically provided to
                        employees and their representatives prior to the
                        implementation of significant operational changes that
                        could substantially affect them.
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0025", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  b) For organizations with collective bargaining agreements,
                  report whether the notice period and provisions for
                  consultation and negotiation are specified in collective
                  agreements.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(199, "radio-group-1700552231846-0")}
                </label>
              </div>

              <sectionheader id={"GRI403"} className="secHead">
                GRI 403 Occupational Health and Safety
              </sectionheader>

              <sectionheader1 id={"GRI403-1"}>
                
                Disclosure 403-1 Occupational health and safety management
                system
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) A statement of whether an occupational health and safety
                  management system has been implemented, including whether:
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(37, "radio-group-1701853807768")}
                  {getLastResponseByRFID(37, "textarea-1701853859787-0")}
                </label>

                <label>
                  
                  a) the system has been implemented because of legal
                  requirements and, if so, a list of the requirements;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(37, "radio-group-1701854240428")}
                  {getLastResponseByRFID(37, "textarea-1691513956111")}
                </label>

                <label>
                  b) the system has been implemented based on recognized risk
                  management and/or management system standards/guidelines and,
                  if so, a list of the standards/guidelines.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(37, "radio-group-1701854444591")}
                  {getLastResponseByRFID(37, "radio-group-1701854537226-0")}
                </label>

                <label>
                  2) A description of the scope of workers, activities, and
                  workplaces covered by the occupational health and safety
                  management system, and an explanation of whether and, if so,
                  why any workers, activities, or workplaces are not covered.
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(37, "textarea-1691514002264")}
                  {getLastResponseByRFID(37, "textarea-1691514004752")}
                </label>
              </div>

              <sectionheader1 id={"GRI403-2"}>
                Disclosure 403-2 Hazard identification, risk assessment, and
                incident investigation
              </sectionheader1>

              <div className="list">
                <label>
                  1) A description of the processes used to identify
                  work-related hazards and assess risks on a routine and
                  non-routine basis, and to apply the hierarchy of controls in
                  order to eliminate hazards and minimize risks, including:
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(36, "textarea-1691578696587")}
                  {getLastResponseByRFID(36, "textarea-1700724853996")}
                  {getLastResponseByRFID(36, "textarea-1700725030531-0")}
                  {getLastResponseByRFID(36, "textarea-1700725246907")}
                  {getLastResponseByRFID(36, "textarea-1700725128354-0")}
                  {getLastResponseByRFID(36, "textarea-1700725364964")}
                </label>

                <label>
                  
                  a) how the organization ensures the quality of these
                  processes, including the competency of persons who carry them
                  out;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(36, "textarea-1691578701017")}
                  {getLastResponseByRFID(36, "textarea-1700725729890")}
                </label>

                <label>
                  
                  b) how the results of these processes are used to evaluate and
                  continually improve the occupational health and safety
                  management system.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(36, "textarea-1691578703267")}
                </label>

                <label>
                  2) A description of the processes for workers to report
                  work-related hazards and hazardous situations, and an
                  explanation of how workers are protected against reprisals.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(36, "textarea-1700726225367-0")}
                </label>

                <label>
                  
                  3) A description of the policies and processes for workers to
                  remove themselves from work situations that they believe could
                  cause injury or ill health, and an explanation of how workers
                  are protected against reprisals.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(36, "textarea-1691578732945")}
                  {getLastResponseByRFID(36, "textarea-1691578730917")}
                </label>

                <label>
                  
                  4) A description of the processes used to investigate
                  work-related incidents, including the processes to identify
                  hazards and assess risks relating to the incidents, to
                  determine corrective actions using the hierarchy of controls,
                  and to determine improvements needed in the occupational
                  health and safety management system.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(36, "textarea-1691578728801")}
                  {getLastResponseByRFID(36, "textarea-1691578726669")}
                  {getLastResponseByRFID(36, "textarea-1691578724293")}
                  {getLastResponseByRFID(36, "textarea-1691578722212")}
                </label>
              </div>

              <sectionheader1 id={"GRI403-3"}>
                Disclosure 403-3 Occupational health services
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) A description of the occupational health services’
                  functions that contribute to the identification and
                  elimination of hazards and minimization of risks, and an
                  explanation of how the organization ensures the quality of
                  these services and facilitates workers’ access to them.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(35, "textarea-1703064150574-0")}
                  {getLastResponseByRFID(35, "text-1699679692656-0")}
                  {getLastResponseByRFID(35, "text-1700727171874")}
                  {getLastResponseByRFID(35, "text-1699679750752-0")}
                  {getLastResponseByRFID(35, "text-1700727451250-0")}
                </label>

                <label>
                  
                  2) How the organization maintains the confidentiality of
                  workers’ personal health-related information;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(35, "text-1699679921475-0")}
                </label>

                <label>
                  
                  3) How the organization ensures that workers’ personal
                  health-related information and their participation in any
                  occupational health services is not used for any favourable or
                  unfavourable treatment of workers.
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(35, "text-1699679954611-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI403-4"}>
                Disclosure 403-4 Worker participation, consultation, and
                communication on occupational health and safety
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) A description of the processes for worker participation and
                  consultation in the development, implementation, and
                  evaluation of the occupational health and safety management
                  system, and for providing access to and communicating relevant
                  information on occupational health and safety to workers.
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(34, "textarea-1699680410584-0")}
                  {getLastResponseByRFID(34, "textarea-1699680410589-0")}
                </label>

                <label>
                  2) Where formal joint management-worker health and safety
                  committees exist, a description of their responsibilities,
                  meeting frequency, decision-making authority, and whether and,
                  if so, why any workers are not represented by these
                  committees.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(
                    34,
                    "radio-group-1699680410592-0"
                  )}
                  {getLastResponseByRFID(34, "textarea-1699680410609-0")}
                  {getLastResponseByRFID(34, "textarea-1699680410611-0")}
                  {getLastResponseByRFID(34, "textarea-1699680410613-0")}
                  {getLastResponseByRFID(34, "textarea-1699680410615-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI403-5"}>
                
                Disclosure 403-5 Worker training on occupational health and
                safety
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) A description of any occupational health and safety
                  training provided to workers, including generic training as
                  well as training on specific work-related hazards, hazardous
                  activities, or hazardous situations.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(33, "textarea-1700438999041")}
                  {getLastResponseByRFID(33, "textarea-1700729062957-0")}
                  {getLastResponseByRFID(33, "textarea-1700729248017-0")}
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of trainings provided to workers on occupational
                        health and safety
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0001", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of trainings provided to volunteers on
                        occupational health and safety
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0002", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI403-6"}>
                Disclosure 403-6 Promotion of worker health
              </sectionheader1>

              <div className="list">
                <label>
                  1) An explanation of how the organization facilitates workers’
                  access to non-occupational medical and healthcare services,
                  and the scope of access provided.
                </label>
                <label className="rfres-one">
                  
                  {getLastResponseByRFID(32, "textarea-1699681381127-0")}
                </label>

                <label>
                  
                  2) A description of any voluntary health promotion services
                  and programs offered to workers to address major
                  non-work-related health risks, including the specific health
                  risks addressed, and how the organization facilitates workers’
                  access to these services and programs.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(32, "textarea-1699681381136-0")}
                  {getLastResponseByRFID(32, "textarea-1700729560094")}
                  {getLastResponseByRFID(32, "textarea-1700729547540")}
                  {getLastResponseByRFID(32, "textarea-1699681381139-0")}
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead">
                        Data
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Number of health checkups carried out for workers
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0003", year)}
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of health checkup programs conducted for workers
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0004", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI403-7"}>
                
                Disclosure 403-7 Prevention and mitigation of occupational
                health and safety impacts directly linked by business
                relationships
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) A description of the organization’s approach to preventing
                  or mitigating significant negative occupational health and
                  safety impacts that are directly linked to its operations,
                  products, or services by its business relationships, and the
                  related hazards and risks
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(
                    31,
                    "radio-group-1700730021620-0"
                  )}
                  {getLastResponseByRFID(31, "textarea-1700729910872-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI403-8"}>
                Disclosure 403-8 Workers covered by an occupational health and
                safety management system
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) If the organization has implemented an occupational health
                  and safety management system based on legal requirements
                  and/or recognized standards/guidelines:
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(
                    185,
                    "radio-group-1703069199212-0"
                  )}
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead">
                        Number
                      </td>

                      <td colSpan="1" className="tdHead">
                        Percentage
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Number of employees covered under OHS system
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0008", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Number of workers covered under OHS system
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0009", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Number of employees who are covered by such a system
                        that has been internally audited
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0010", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Number of workers who are covered by such a system that
                        has been internally audited
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0011", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Number of employees who are covered by such a system
                        that has been audited or certified by an external party
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0012", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Number of workers who are covered by such a system that
                        has been audited or certified by an external party
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0013", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Number of internal review/inspections carried out for
                        compliance with occupational health and safety
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0014", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Number of external review/inspections (third-party
                        audits) carried out for compliance with occupational
                        health and safety
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPSN0015", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="list">
                <label>
                  
                  a. Whether and, if so, why any workers have been excluded from
                  this disclosure, including the types of worker excluded.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(185, "text-1699683408317-0")}
                </label>

                <label>
                  
                  b. Any contextual information necessary to understand how the
                  data have been compiled, such as any standards, methodologies,
                  and assumptions used.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(185, "text-1699683425551-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI403-9"}>
                
                Disclosure 403-9 Work-related injuries
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="3" className="tdHead">
                        For Full-time Employees
                      </td>
                    </tr>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead"></td>
                      <td colSpan="1" className="tdHead">
                        Number
                      </td>
                      <td colSpan="1" className="tdHead">
                        Rate
                      </td>
                    </tr>
                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Fatalities as a result of work-related injury
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPS0032", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        High-consequence work-related injuries (excluding
                        fatalities)
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPS0033", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Recordable work-related injuries
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPS0034", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Main types of work-related injury
                      </td>
                      <td colSpan="2" className="text-center">
                        {getLastResponseByRFID(78, "textarea-1703080152370-0")}
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Number of hours worked
                      </td>
                      <td colSpan="2" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="3" className="tdHead">
                        For Workers
                      </td>
                    </tr>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead"></td>
                      <td colSpan="1" className="tdHead">
                        Number
                      </td>
                      <td colSpan="1" className="tdHead">
                        Rate
                      </td>
                    </tr>
                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Fatalities as a result of work-related injury
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPS0039", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        High-consequence work-related injuries (excluding
                        fatalities)
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPS0040", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Recordable work-related injuries
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPS0041", year)}
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Main types of work-related injury
                      </td>
                      <td colSpan="2" className="text-center">
                        {getLastResponseByRFID(78, "textarea-1703080215775-0")}
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Number of hours worked
                      </td>
                      <td colSpan="2" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  2) The work-related hazards that pose a risk of
                  high-consequence injury, including:
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(78, "textarea-1700731952783-0")}
                </label>

                <label>a) how these hazards have been determined;</label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(78, "text-1691663847053-0")}
                </label>

                <label>
                  b) which of these hazards have caused or contributed to
                  high-consequence injuries during the reporting period;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(78, "text-1691663868421")}
                </label>

                <label>
                  c) actions taken or underway to eliminate these hazards and
                  minimize risks using the hierarchy of controls.&nbsp;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(78, "text-1691663888213")}
                </label>

                <label>
                  3) Any actions taken or underway to eliminate other
                  work-related hazards and minimize risks using the hierarchy of
                  controls.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(78, "text-1691665437287")}
                </label>

                <label>
                  4) Whether and, if so, why any workers have been excluded from
                  this disclosure, including the types of workers excluded.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(78, "text-1691665385594")}
                </label>

                <label>
                  5) Any contextual information necessary to understand how the
                  data have been compiled, such as any standards, methodologies,
                  and assumptions used.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(78, "text-1691665399869")}
                </label>
              </div>

              {/* <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Particular ( For Full time employees)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Calculated values
                                            </td>
                                        </tr>

                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-justify">Rate of fatalities as a result of work-related injury </td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-justify"> Rate of recordable work-related injuries</td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>

                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-justify"> Rate of high-consequence work-related injuries</td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Particular ( For Workers)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Calculated values
                                            </td>
                                        </tr>

                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-justify">Rate of fatalities as a result of work-related injury </td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-justify"> Rate of recordable work-related injuries</td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>

                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-justify"> Rate of high-consequence work-related injuries</td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div> */}
              <sectionheader1 id={"GRI403-10"}>
                
                Disclosure 403-10 Work-related ill health
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="2" className="tdHead">
                        For Full-time Employees
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        The number of fatalities as a result of work-related ill
                        health
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPS0050", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        The number of cases of recordable work-related ill
                        health
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPS0051", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        The main types of work-related ill health.
                      </td>
                      <td colSpan="1" className="text-center">
                        {getLastResponseByRFID(77, "textarea-1703070437656-0")}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="2" className="tdHead">
                        For Workers
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        The number of fatalities as a result of work-related ill
                        health
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPS0053", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        The number of cases of recordable work-related ill
                        health
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPS0054", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        The main types of work-related ill health.
                      </td>
                      <td colSpan="1" className="text-center">
                        {getLastResponseByRFID(77, "textarea-1703070481070-0")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  1) The work-related hazards that pose a risk of ill health,
                  including:
                </label>

                <label>a) how these hazards have been determined; </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(77, "textarea-1700732210146-0")}
                  {getLastResponseByRFID(77, "text-1691663054190-0")}
                </label>

                <label>
                  b) which of these hazards have caused or contributed to cases
                  of ill health during the reporting period;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(77, "text-1691663444329-0")}
                </label>

                <label>
                  
                  c) actions taken or underway to eliminate these hazards and
                  minimize risks using the hierarchy of controls.
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(77, "text-1691663464212-0")}
                </label>

                <label>
                  
                  d) Whether and, if so, why any workers have been excluded from
                  this disclosure, including the types of worker excluded
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(77, "text-1691663488034")}
                </label>

                <label>
                  
                  2)Any contextual information necessary to understand how the
                  data have been compiled, such as any standards, methodologies,
                  and assumptions used.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(77, "text-1691663510486")}
                </label>
              </div>

              <sectionheader id={"GRI404"} className="secHead">
                GRI 404 Training and Education
              </sectionheader>

              <sectionheader1 id={"GRI404-1"}>
                
                Disclosure 404-1 Average hours of training per year per employee
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        Average hours of training
                      </td>
                      <td colSpan="1" className="tdHead">
                        Male
                      </td>

                      <td colSpan="1" className="tdHead">
                        Female
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Permanent employees
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPK0023", year)}
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPK0024", year)}
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Temporary employees
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPK0019", year)}
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPK0020", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI404-2"}>
                
                Disclosure 404-2 Programs for upgrading employee skills and
                transition assistance programs
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) Type and scope of programs implemented and assistance
                  provided to upgrade employee skills
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(43, "textarea-1701329826854")}
                </label>

                <label>
                  
                  2) Transition assistance programs provided to facilitate
                  continued employability and the management of career endings
                  resulting from retirement or termination of employment.
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(43, "text-1694510530251-0")}
                </label>
              </div>

              <sectionheader1 id={"GRI404-3"}>
                
                Disclosure 404-3 Percentage of employees receiving regular
                performance and career development reviews
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="3">
                      <td colSpan="1" className="tdHead">
                        % of total employees who received a regular performance
                        and career development review Average hours of training
                      </td>
                      <td colSpan="1" className="tdHead">
                        Male
                      </td>

                      <td colSpan="1" className="tdHead">
                        Female
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        
                        Permanent employees
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Temporary employees
                      </td>
                      <td colSpan="1" className="text-center"></td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI405"} className="secHead">
                GRI 405 Diversity & Inclusion
              </sectionheader>

              <sectionheader1 id={"GRI405-1"}>
                
                Disclosure 405-1 Diversity of governance bodies and employees
              </sectionheader1>

              <div className="m-3 para">
                Percentage of individuals within the organization’s governance
                bodies
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="7">
                      <td colSpan="1" rowSpan="2" className="tdHead">
                        Employee Category
                      </td>

                      <td colSpan="3" className="tdHead">
                        Male
                      </td>
                      <td colSpan="3" className="tdHead">
                        Female
                      </td>
                    </tr>

                    <tr colSpan="7">
                      <td colSpan="1" className="tdHead">
                        Under 30 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        30-50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Above 50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Under 30 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        30-50 Years
                      </td>

                      <td colSpan="1" className="tdHead">
                        Above 50 Years
                      </td>
                    </tr>

                    <tr colspan="7">
                      <td colspan="1" className="text-justify">
                        Senior Management
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0032", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0033", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0034", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0028", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0029", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPK0030", year)}
                      </td>
                    </tr>

                    <tr colspan="7">
                      <td colspan="1" className="text-justify">
                        
                        Middle Management
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN030", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN031", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN032", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN036", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN037", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN038", year)}
                      </td>
                    </tr>

                    <tr colspan="7">
                      <td colspan="1" className="text-justify">
                        
                        Entry-level Employees
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN033", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN034", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN035", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN039", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN040", year)}
                      </td>
                      <td colspan="1" className="text-center">
                        
                        {getDataByDP("DPKN041", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* <div className="m-3 para">
                                Percentage of employees per employee category
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="7">
                                            <td colSpan="1" rowSpan="2" className="tdHead">
                                                Employee Category
                                            </td>

                                            <td colSpan="3" className="tdHead">
                                                Male
                                            </td>
                                            <td colSpan="3" className="tdHead">
                                                Female
                                            </td>

                                        </tr>

                                        <tr colSpan="7">

                                            <td colSpan="1" className="tdHead">
                                                Under 30 Years
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                30-50 Years
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Above 50 Years
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Under 30 Years
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                30-50 Years
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Above 50 Years
                                            </td>

                                        </tr>

                                        <tr colspan="7">
                                            <td colspan="1" className="text-justify">Permanent Employees    </td>
                                            <td colspan="1" className="text-center">   </td>
                                            <td colspan="1" className="text-center"  >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center"  >   </td>
                                        </tr>

                                        <tr colspan="7">
                                            <td colspan="1" className="text-justify">  Temporary Employees  </td>
                                            <td colspan="1" className="text-center">   </td>
                                            <td colspan="1" className="text-center"  >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center"  >   </td>
                                        </tr>

                                        <tr colspan="7">
                                            <td colspan="1" className="text-justify">  Workers   </td>
                                            <td colspan="1" className="text-center">   </td>
                                            <td colspan="1" className="text-center"  >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center" >   </td>
                                            <td colspan="1" className="text-center"  >   </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div> */}

              <sectionheader1 id={"GRI405-2"}>
                
                Disclosure 405-2 Ratio of basic salary and remuneration of women
                to men
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="2" className="tdHead">
                        Ratio of the basic salary and remuneration of women to
                        men
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Employee Category
                      </td>

                      <td colSpan="1" className="tdHead">
                        Ratio of basic salary of women to men
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Senior Management
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          getDataByDP("DPK0253", year) /
                            getDataByDP("DPK0256", year)
                        )}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Middle Management
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          getDataByDP("DPK0254", year) /
                            getDataByDP("DPK0257", year)
                        )}
                      </td>
                    </tr>

                    <tr colSpan="3">
                      <td colSpan="1" className="text-justify">
                        Entry-level employees
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          getDataByDP("DPK0255", year) /
                            getDataByDP("DPK0258", year)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI406"} className="secHead">
                GRI 406 Non-discrimination
              </sectionheader>

              <sectionheader1 id={"GRI406-1"}>
                
                Disclosure 406-1 Incidents of discrimination and corrective
                actions taken
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Total number of incidents of discrimination during the
                        reporting period.
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPK0102", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                Status of the incidents and actions taken with reference to the
                following:
              </div>

              <div className="list">
                <label>a) Incident reviewed by the organization;</label>

                <label className="rfres-one">
                  {getLastResponseByRFID(51, "text-1691421496654-0")}
                </label>

                <label>b) Remediation plans being implemented; </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(51, "text-1691421528864")}
                </label>

                <label>
                  
                  c) Remediation plans that have been implemented, with results
                  reviewed through routine internal management review processes;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(51, "text-1691421545910")}
                </label>

                <label>d) Incident no longer subject to action. </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(51, "text-1691421575435")}
                </label>
              </div>

              <sectionheader id={"GRI407"} className="secHead">
                GRI 407 Freedom of Association and Collective Bargaining
              </sectionheader>

              <sectionheader1 id={"GRI407-1"}>
                
                Disclosure 407-1 Operations and suppliers in which the right to
                freedom of association and collective bargaining may be at risk
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) Operations and suppliers in which workers’ rights to
                  exercise freedom of association or collective bargaining may
                  be violated or at significant risk either in terms of:
                </label>

                <label>
                  
                  a) type of operation (such as manufacturing plant) and
                  supplier;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(46, "text-1691138751857-0")}
                </label>

                <label>
                  b) countries or geographic areas with operations and suppliers
                  considered at risk
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(46, "text-1691138800169")}
                </label>

                <label>
                  2) Measures taken by the organization in the reporting period
                  intended to support rights to exercise freedom of association
                  and collective bargaining
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(46, "text-1691138835821")}
                </label>
              </div>

              <sectionheader id={"GRI408"} className="secHead">
                GRI 408 Child Labor
              </sectionheader>

              <sectionheader1 id={"GRI408-1"}>
                
                Disclosure 408-1 Operations and suppliers at significant risk
                for incidents of child labor
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) Operations and suppliers considered to have significant
                  risk for incidents of:
                </label>

                <label> a) child labor; </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(44, "text-1691067945419-0")}
                </label>

                <label>b) young workers exposed to hazardous work </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(44, "text-1691068002222")}
                </label>

                <label>
                  
                  2) Operations and suppliers considered to have significant
                  risk for incidents of child labor either in terms of:
                </label>

                <label>
                  
                  a) type of operation (such as manufacturing plant) and
                  supplier;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(44, "text-1691068032994")}
                </label>

                <label>
                  
                  b) countries or geographic areas with operations and suppliers
                  considered at risk.
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(44, "text-1691068057516")}
                </label>

                <label>
                  
                  3) Measures taken by the organization in the reporting period
                  intended to contribute to the effective abolition of child
                  labor
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(44, "text-1691068077308")}
                </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of incidences of child labor
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0166", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader id={"GRI409"} className="secHead">
                GRI 409 Forced or Compulsory Labor
              </sectionheader>

              <sectionheader1 id={"GRI409-1"}>
                
                Disclosure 409-1 Operations and suppliers at significant risk
                for incidents of forced or compulsory labor
              </sectionheader1>

              <div className="list">
                <label>
                  1) Operations and suppliers considered to have significant
                  risk for incidents of forced or compulsory labor either in
                  terms of:
                </label>

                <label>
                  
                  a) type of operation (such as manufacturing plant) and
                  supplier;
                </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(45, "text-1700819353707-0")}
                </label>

                <label>
                  
                  b) countries or geographic areas with operations and suppliers
                  considered at risk.
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(45, "text-1691073170226")}
                </label>

                <label>
                  
                  2) Measures taken by the organization in the reporting period
                  intended to contribute to the elimination of all forms of
                  forced or compulsory labor.
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(45, "text-1691073211813")}
                </label>
              </div>

              <sectionheader id={"GRI410"} className="secHead">
                GRI 410: Security Practices
              </sectionheader>

              <sectionheader1 id={"GRI410-1"}>
                
                Disclosure 410-1 Security personnel trained in human rights
                policies or procedures
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of security personnel who have received
                        formal training in the organization’s human rights
                        policies or specific procedures and their application to
                        security.
                      </td>
                      <td colSpan="1" className="text-center">
                        {handleNaNAndInfinity(
                          (getDataByDP("DPK0086", year) /
                            getDataByDP("DPKN073", year)) *
                            100
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                Whether training requirements also apply to third-party
                organizations providing security personnel.
              </div>

              <sectionheader id={"GRI411"} className="secHead">
                GRI 411: Rights of Indigenous Peoples
              </sectionheader>

              <sectionheader1 id={"GRI411-1"}>
                
                Disclosure 411-1 Incidents of violations involving rights of
                indigenous peoples
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Total number of identified incidents of violations
                        involving the rights of indigenous peoples
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0091", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="list">
                <label>
                  
                  1) Status of the incidents and actions taken with reference to
                  the following:
                </label>

                <label>a) Incident reviewed by the organization; </label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(47, "text-1691139410790-0")}
                </label>

                <label> b) Remediation plans being implemented;</label>

                <label className="rfres-one">
                  
                  {getLastResponseByRFID(47, "text-1691139478295")}
                </label>

                <label>
                  c) Remediation plans that have been implemented, with results
                  reviewed through routine internal management review processes;
                </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(47, "text-1691139477958")}
                </label>

                <label> d) Incident no longer subject to action. </label>

                <label className="rfres-one">
                  {getLastResponseByRFID(47, "text-1691139477523")}
                </label>
              </div>

              <sectionheader id={"GRI413"} className="secHead">
                GRI 413: Local Communities
              </sectionheader>

              <sectionheader1 id={"GRI413-1"}>
                Disclosure 413-1 Operations with local community engagement,
                impact assessments, and development programs
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of operations with implemented local
                        community engagement, impact assessments, and/or
                        development programs
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">Including,</div>

              <div className="list">
                <label>
                  
                  a) social impact assessments, including gender impact
                  assessments, based on participatory processes;
                </label>

                <label>
                  b) environmental impact assessments and ongoing monitoring;
                </label>

                <label>
                  
                  c) public disclosure of results of environmental and social
                  impact assessments;
                </label>

                <label>
                  
                  d) local community development programs based on local
                  communities’ needs;
                </label>

                <label>
                  
                  e) stakeholder engagement plans based on stakeholder mapping;
                </label>

                <label>
                  
                  f) broad-based local community consultation committees and
                  processes that include vulnerable groups;
                </label>

                <label>
                  
                  g) works councils, occupational health and safety committees
                  and other worker representation bodies to deal with impacts;
                </label>

                <label>h) formal local community grievance processes.F </label>
              </div>

              <sectionheader1 id={"GRI413-2"}>
                Disclosure 413-2 Operations with significant actual and
                potential negative impacts on local communities
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) Operations with significant actual and potential negative
                  impacts on local communities, including:
                </label>

                <label> a) the location of the operations;</label>

                <label>
                  b) the significant actual and potential negative impacts of
                  operations.
                </label>
              </div>

              <sectionheader id={"GRI414"} className="secHead">
                GRI 414: Supplier Social Assessment
              </sectionheader>

              <sectionheader1 id={"GRI414-1"}>
                Disclosure 414-1 New suppliers that were screened using social
                criteria
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of new suppliers that were screened using
                        social criteria.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI414-2"}>
                Disclosure 414-2 Negative social impacts in the supply chain and
                actions taken
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of suppliers assessed for social impacts
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Number of suppliers identified as having significant
                        actual and potential negative social impacts.
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of suppliers identified as having significant
                        actual and potential negative social impacts with which
                        improvements were agreed upon as a result of assessment
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Percentage of suppliers identified as having significant
                        actual and potential negative social impacts with which
                        relationships were terminated as a result of assessment,
                        and why
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                Significant actual and potential negative social impacts
                identified in the supply chain.
              </div>

              <sectionheader id={"GRI415"} className="secHead">
                GRI 415: Public Policy
              </sectionheader>

              <sectionheader1 id={"GRI415-1"}>
                
                Disclosure 415-1 Political contributions
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        Total monetary value of financial and in-kind political
                        contributions made directly and indirectly by the
                        organization by country and recipient/beneficiary.
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0259", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                If applicable, how the monetary value of in-kind contributions
                was estimated.
                <label className="rfres-one">
                  {getLastResponseByRFID(53, "textarea-1702898506362-0")}
                </label>
              </div>

              <sectionheader id={"GRI416"} className="secHead">
                GRI 416: Customer Health and Safety
              </sectionheader>

              <sectionheader1 id={"GRI416-1"}>
                
                Disclosure 416-1 Assessment of the health and safety impacts of
                product and service categories
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Percentage of significant product and service categories
                        for which health and safety impacts are assessed for
                        improvement
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {handleNaNAndInfinity(
                          (getDataByDP("DPK0064", year) /
                            getDataByDP("DPK0063", year)) *
                            100
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI416-2"}>
                
                Disclosure 416-2 Incidents of non-compliance concerning the
                health and safety impacts of products and services
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="2" className="tdHead">
                        Total number of incidents of non-compliance with
                        regulations and/or voluntary codes concerning the health
                        and safety impacts of products and services
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        incidents of non-compliance with regulations resulting
                        in a fine or penalty
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPK0087", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        incidents of non-compliance with regulations resulting
                        in a warning
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0088", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        incidents of non-compliance with voluntary codes
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0089", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                If the organization has not identified any non-compliance with
                regulations and/or voluntary codes, a brief statement of this
                fact is sufficient.{getDataByDP("DPK0090", year)}
              </div>

              <sectionheader id={"GRI417"} className="secHead">
                GRI 417: Marketing and Labeling
              </sectionheader>

              <sectionheader1 id={"GRI417-1"}>
                
                Disclosure 417-1 Requirements for product and service
                information and labeling
              </sectionheader1>

              <div className="list">
                <label>
                  
                  1) Whether each of the following types of information is
                  required by the organization’s procedures for product and
                  service information and labeling:
                </label>

                <label>
                  
                  a) The sourcing of components of the product or service;
                </label>

                <label>
                  
                  b) Content, particularly with regard to substances that might
                  produce an environmental or social impact;
                </label>

                <label> c) Safe use of the product or service; </label>

                <label>
                  
                  d) Disposal of the product and environmental or social
                  impacts;
                </label>

                <label>e) Other (explain). </label>
              </div>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>

                      <td colSpan="1" className="tdHead"></td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Percentage of significant product or service categories
                        covered by and assessed for compliance with such
                        procedures
                      </td>
                      <td colSpan="1" className="text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <sectionheader1 id={"GRI417-2"}>
                
                Disclosure 417-2 Incidents of non-compliance concerning product
                and service information and labeling
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="2" className="tdHead">
                        Total number of incidents of non-compliance with
                        regulations and/or voluntary codes concerning product
                        and service information and labeling
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        
                        incidents of non-compliance with regulations resulting
                        in a fine or penalty
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPKN063", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        incidents of non-compliance with regulations resulting
                        in a warning
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPKN064", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        incidents of non-compliance with voluntary codes
                      </td>
                      <td colSpan="1" className="text-center">
                        
                        {getDataByDP("DPKN065", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                If the organization has not identified any non-compliance with
                regulations and/or voluntary codes, a brief statement of this
                fact is sufficient.{getDataByDP("DPKN066", year)}
              </div>

              <sectionheader1 id={"GRI417-3"}>
                
                Disclosure 417-3 Incidents of non-compliance concerning
                marketing communications
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="2" className="tdHead">
                        Total number of incidents of non-compliance with
                        regulations and/or voluntary codes concerning marketing
                        communications, including advertising, promotion, and
                        sponsorship
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        incidents of non-compliance with regulations resulting
                        in a fine or penalty
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPKN067", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        incidents of non-compliance with regulations resulting
                        in a warning
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPKN068", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        incidents of non-compliance with voluntary codes
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPKN069", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                If the organization has not identified any non-compliance with
                regulations and/or voluntary codes, a brief statement of this
                fact is sufficient.{getDataByDP("DPKN070", year)}
              </div>

              <sectionheader id={"GRI418"} className="secHead">
                GRI 418: Customer Privacy
              </sectionheader>

              <sectionheader1 id={"GRI418-1"}>
                
                Disclosure 418-1 Substantiated complaints concerning breaches of
                customer privacy and losses of customer data
              </sectionheader1>

              <div class="gridlines-container">
                <table class="gridlines">
                  <tbody>
                    <tr colSpan="2">
                      <td colSpan="1" className="tdHead">
                        Particular
                      </td>
                      <td colSpan="1" className="tdHead"></td>
                    </tr>
                    <tr colSpan="2">
                      <td colSpan="2" className="tdHead">
                        Total number of substantiated complaints received
                        concerning breaches of customer privacy
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        complaints received from outside parties and
                        substantiated by the organization
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPKN071", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        complaints from regulatory bodies
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPKN072", year)}
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td colSpan="1" className="text-justify">
                        Total number of identified leaks, thefts, or losses of
                        customer data
                      </td>
                      <td colSpan="1" className="text-center">
                        {getDataByDP("DPK0076", year)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="m-3 para">
                If the organization has not identified any substantiated
                complaints, a brief statement of this fact is sufficient.
              </div>

              <label className="rfres-one">
                {getLastResponseByRFID(86, "text-1693293060206-0")}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(GRIReport, comparisonFn);
