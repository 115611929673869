import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { HaNData } from "./HealthSafetyChart";

const COLORS = ["#d6a7df", "#9d74b0"];

export const SupplyChainPieChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);

  // Aggregate data for the selected years
  const aggregateData = (year) => {
    const totalLtifStaff = HaNData[year].reduce(
      (sum, item) => sum + item.ltifStaff,
      0
    );
    const totalLtifContractors = HaNData[year].reduce(
      (sum, item) => sum + item.ltifContractors,
      0
    );
    return [
      { name: "Local Suppliers", value: totalLtifStaff },
      { name: "International Suppliers", value: totalLtifContractors },
    ];
  };

  const dataYear1 = aggregateData(year1);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Percentage of Suppliers Assessed on Environment and Social Aspects
        </div>

        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => setYear1(e.target.value)}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(HaNData)?.map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>

          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: !activeMode ? "#FFFFFF" : "transparent",
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => setActiveMode(false)}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: activeMode ? "#FFFFFF" : "transparent",
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => setActiveMode(true)}
            >
              <i className="pi pi-chart-pie fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Tooltip />
            <Legend />
            <Pie
              data={dataYear1}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
            >
              {dataYear1.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={HaNData[year1]} style={{ width: "100%" }}>
            <Column header="Month" style={{ minWidth: "8%" }} field="month" />
            <Column
              header={`Local Suppliers`}
              style={{ minWidth: "8%" }}
              field="ltifStaff"
            />
            <Column
              header={`International Suppliers`}
              style={{ minWidth: "8%" }}
              field="ltifContractors"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};
