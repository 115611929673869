import React, { useState, useEffect, useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { resetOverallPendingData, setOverallPendingData } from "../../RTK/Background/pendingData";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import Axios from "axios";
import { API, baseurl } from "../../constants/api_url";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import moment from "moment";
import { Chart } from "chart.js/dist/chart";
import { registerables } from "chart.js";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import ChartDL from "../../assets/js/chartjsplugin";
import * as chartTrendline from "chartjs-plugin-trendline";

import { DateTime } from "luxon";
import Swal from "sweetalert2";
import APIServices from "../../service/APIService";

Chart.register(chartTrendline);
Chart.register(...registerables);
const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
        },
        {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
        },
    ],
};

const TableReportDashboard = (props) => {
    const [products, setProducts] = useState(null);
    const siteList = useSelector((state) => state.sitelist.siteList)
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const [attdialog, setAttDialog] = useState(false)
    const [attachment, setAttachment] = useState([])
    const [countopt, setCountryOpt] = useState([])
    const [cityopt, setCityOpt] = useState([])
    const [locopt, setLocOpt] = useState([])
    const [indiopt, setIndiOpt] = useState([])
    const [search, setSearch] = useState({ filter: 0, search: '' })
    const [search2, setSearch2] = useState({ filter: 0, search: '' })
    const [search3, setSearch3] = useState({ filter: 0, search: '' })
    const [search4, setSearch4] = useState({ filter: 0, search: '' })
    const [indicators, setInidicators] = useState([])
    const [yearopt, setYearOpt] = useState([])
    const [show, setShow] = useState(false);
    const [report, setReport] = useState({ sc: [] })
    const [dcfasslist, setDCFAssList] = useState([])
    const [aplist, setApList] = useState([])
    const [dslist, setDsList] = useState([])
    const [ddlist, setDdList] = useState([])
    const [odlist, setOdList] = useState([])
    let savedPast = useSelector((state) => state.pendingdata.overallPending)
    let savedPPF = useSelector((state) => state.pendingdata.ppfList)
    const colorsBlue = ["#0010d9", "#0020ff", "#0040ff", "#1D53BF", "#0060ff", "#0080ff", "#009fff", "#00bfff", "#00ffff", "#22a7f0", "#a7d5ed", "#63bff0"];
    const colorsBlueBR = ["#0010d920", "#0020ff20", "#0040ff20", "#1D53BF20", "#0060ff20", "#0080ff20", "#009fff20", "#00bfff20", "#00ffff20", "#22a7f020", , "#a7d5ed20", "#63bff020"];
    const [monthopt, setMonthOpt] = useState([{ name: 'All', id: 0 }, { name: 'Januaury', id: 1 }, { name: 'Febraury', id: 2 }, { name: 'March', id: 3 }, { name: 'April', id: 4 }, { name: 'May', id: 5 },
    { name: 'June', id: 6 }, { name: 'July', id: 7 }, { name: 'August', id: 8 }, { name: 'September', id: 9 }, { name: 'October', id: 10 }, { name: 'November', id: 11 }, { name: 'December', id: 12 }])
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [dcflist, setDcfList] = useState([])
    const [sitelist, setSiteList] = useState([])
    const [sitelistall, setSiteListAll] = useState([])
    const [past, setPast] = useState([])
    const [filter, setFilter] = useState({ country: { name: 'All', id: 0 }, city: { name: 'All', id: 0 }, location: { name: 'All', id: 0 }, indicator: { title: 'All', id: 0 }, year: { name: 'All', id: 0 }, month: { name: 'All', id: 0 }, from: moment(moment.utc()).local().toDate(), to: moment(moment.utc()).local().toDate() })
    const dispatch = useDispatch()
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }]
    const locationList = useSelector(state => state.sitelist.locationList)
    const userList = useSelector(state => state.userlist.userList)
    const [expandedRows, setExpandedRows] = useState(null);
    const [submitteddcf, setSubmittedDcf] = useState([])
    const [lineOptions, setLineOptions] = useState(null);
    const [apidata, setApiData] = useState([]);
    const [activity, setActivity] = useState({
        pending: [],
        approved: [],
    });
    const dataTableRef = useRef(null);
    const selector = useSelector((state) => state.user.userdetail);
    const [pendingFilter, setPendingFilter] = useState({
        month: null,
        scope: {
            name: "All",
        },
        topic: {
            name: "All",
        },
    });
    const [filterData, setFilterData] = useState({
        scope: [],
        topic: [],
    });
    const [rawtopic, setRawTopic] = useState([]);
    const [rawdata, setRawData] = useState({
        topic: [],
        scope: [],
    });
    const forceUpdate = useForceUpdate();

    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(0);
    const dt = useRef(null);
    const [response, setResponse] = useState([])
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    useEffect(() => {


        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let metricString = {
            "include": [{ "relation": "newDataPoints" }]

        }
        let dcf_list = [], dcf_submitted = [], locloc = []
        let copt = [{ name: "All", id: 0 }], ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]
        siteList.forEach((country) => {
            copt.push(country)
            country.locationTwos.forEach((city) => {
                ccopt.push(city)
                city.locationThrees.forEach((loc, ind) => {

                    locopt.push(loc)

                })
            })
        })
        setCityOpt(ccopt)
        setCountryOpt(copt)
        setLocOpt(locopt)
        let sub = selector.id === 140 ?      (baseurl+'user-profiles/' + selector.id + '/submit-dcfs') : API.DCF_Submit_UP(selector.id)
        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(sub)
        const promise3 = APIServices.get(API.LocationThree)
        const promise4 = APIServices.get(API.AssignDCFUser_UP(selector.id))
        const promise5 = APIServices.get(API.Metric + `?filter=${encodeURIComponent(JSON.stringify(metricString))}`)
        const promise6 = APIServices.get(API.AssignDCFClient_UP(selector.id))
        // APIServices.get(API.Metric + `?filter=${encodeURIComponent(JSON.stringify(metricString))}`).then((res) => {
        //     let metrics = res.data
        //     let data = [{ title: 'All', id: 0 }]
        //     APIServices.get(API.AssignDCFClient_UP(selector.id)).then((res) => {
        //         res.data.forEach((item) => {
        //             item.metric_ids.forEach((item2) => {

        //                 let index = metrics.findIndex((k) => { return k.id === item2 })
        //                 if (index !== -1) {
        //                     data.push(metrics[index])
        //                 }
        //             })
        //         })

        //         setIndiOpt(data)
        //         setInidicators(data)
        //     })
        // })
        setTimeout(() => {

            APIServices.get(API.DP_report_UP(selector.id)).then((dpres) => {
                let val = [], filterarr = groupArrayObject(dpres.data, 'submitId')
                Object.keys(filterarr).forEach((item) => {

                    val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], 'form_id')) })

                })
                setResponse(val)

                let yearlist = [{ name: 'All', id: 0 }]
                dpres.data.forEach((report) => {
                    report.year.forEach((yr) => {
                        if (yearlist.findIndex((k) => { return k.name === yr }) === -1) {
                            yearlist.push({ name: yr })
                        }
                    })
                })
                setYearOpt(yearlist)
            })

        }, 2000)


        let site_url = API.LocationOne_UP(selector.clientId) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]).then(function (values) {
            dcf_list = values[0].data; setDcfList(values[0].data)
            dcf_submitted = values[1].data;
            locloc = values[2].data
            setDCFAssList(values[3].data)
            dcf_submitted.forEach((i) => {
                i.dcfItem = dcf_list.filter((l) => { return l.id === i.dcf }).length === 0 ? { title: 'Not Found' } : dcf_list.filter((l) => { return l.id === i.dcf })[0]
                i.site_ = locloc.findIndex((k) => { return k.id === i.site }) === -1 ? 'Site Deleted' : locloc.find((k) => { return k.id === i.site }).name
                i.submitted_by_ = userList.findIndex((k) => { return k.id === i.submitted_by }) === -1 ? 'User Deleted' : userList.find((k) => { return k.id === i.submitted_by }).information.empname
                i.approved_by_ = userList.findIndex((k) => { return k.id === i.approved_by }) === -1 ? 'User Deleted' : userList.find((k) => { return k.id === i.approved_by }).information.empname
            })
            setSubmittedDcf(dcf_submitted)
            setApList(dcf_submitted.filter((i) => { return i.type === 3 }))
            setDdList(dcf_submitted.filter((i) => { return i.type === 0 }))
            setDsList(dcf_submitted.filter((i) => { return i.type === 1 }))
            let metrics = values[4].data
            let data = [{ title: 'All', id: 0 }]
            values[5].data.forEach((item) => {
                item.metric_ids.forEach((item2) => {

                    let index = metrics.findIndex((k) => { return k.id === item2 })
                    if (index !== -1) {
                        data.push(metrics[index])
                    }
                })
            })
            let filter = [], locationFilter = [0], rawData = [], pending = [], count = 0


            values[3].data.forEach((item, i) => {



                if (item.type === 0) {
                    count++

                    if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {

                        item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                        item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]

                        item.start_date = moment.utc(item.start_date).toDate()
                        filter.push(item)
                        if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                            locationFilter.push(item.site[0])
                        }

                        // rawData.push(...getMonths(moment(moment.utc(item.start_date).toDate()), moment.utc(moment.utc()), item.frequency, item, dcf_submitted, count).past_)


                    }

                }
            })

            setIndiOpt(data)
            setInidicators(data)
            forceUpdate()


        })

    }, []);


    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    const getRP_ = (rp) => {
        if (rp.length === 1) {

            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2)
        } else {
            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2) + " to " + months_[parseInt(rp[rp.length - 1].split('-')[0]) - 1] + "-" + rp[rp.length - 1].split('-')[1].slice(-2)
        }
    }
    const showBarChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);

        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }

        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");

        const resetChart = {
            id: "resetchart",
            beforeDraw(chart, args, options) {
                if (scope1c.config.data.datasets[0].label !== custom.name) {
                    count = 0;
                    const {
                        ctx,
                        chartArea: { top, left, right, bottom },
                    } = chart;

                    ctx.save();
                    const text = "Back";
                    const thickness = 3;
                    const textwidth = ctx.measureText(text).width;

                    ctx.fillStyle = "rgba(255,26,104,0.2)";
                    ctx.fillRect(right - (textwidth + 11), 5, textwidth + 10, 20);

                    ctx.fillStyle = "#666";
                    ctx.font = "12px Arial";
                    ctx.fillText(text, right - (textwidth + 6), 15);

                    ctx.lineWidth = thickness + "px";
                    ctx.strokeStyle = "rgba(255,26,104,1)";
                    ctx.strokeRect(right - (textwidth + 11), 5, textwidth + 10, 20);
                    coords.top = 5;
                    coords.left = right - (textwidth + 11);
                    coords.right = right;
                    coords.bottom = 25;

                    ctx.restore();
                }
            },
        };
        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                //Bring in data

                datasets: [
                    {
                        data: dummy,
                        label: custom.name,
                        backgroundColor: [colorsBlue[0], colorsBlue[1], colorsBlue[2], colorsBlue[3], colorsBlue[4], colorsBlue[5], colorsBlue[6], colorsBlue[7], colorsBlue[8], colorsBlue[9], colorsBlue[10], colorsBlue[11]],
                        borderColor: [colorsBlueBR[0], colorsBlueBR[1], colorsBlueBR[2], colorsBlueBR[3], colorsBlueBR[4], colorsBlueBR[5], colorsBlueBR[6], colorsBlueBR[7], colorsBlueBR[8], colorsBlueBR[9], colorsBlueBR[10], colorsBlueBR[11]],
                    },
                ],
            },
            options: {
                onHover: (event, charElement) => {
                    if (scope1c.config.data.datasets[0].label === custom.name) {
                        event.native.target.style.cursor = charElement[0] ? "pointer" : "default";
                    } else {
                        event.native.target.style.cursor = "default";
                    }
                },
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        },
                    },
                },
                legend: {
                    display: false,
                },
                parsing: {
                    xAxisKey: "name",
                    yAxisKey: "y",
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            color: "black",
                            boxWidth: 0,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                },
            },
            plugins: [resetChart, ChartDL],
        });

        function clickHandler(click) {
            if (scope1c.config.data.datasets[0].label === custom.name) {
                const bar = scope1c.getElementsAtEventForMode(
                    click,
                    "nearest",
                    {
                        intersect: true,
                    },
                    true
                );
                if (bar.length) {
                    if (dummy[bar[0].index].drilldown) {
                        changeChart(bar[0].index);
                    }
                }
            }
        }

        function changeChart(ind) {
            scope1c.config.options.parsing.xAxisKey = "subset.x";
            scope1c.config.options.parsing.yAxisKey = "subset.xy";
            const users = [];
            const vlabels = dummy[ind].subset.map((labels) => {
                users.push(labels[1]);

                return labels[0];
            });

            scope1c.config.data.datasets[0].data = users;
            scope1c.config.data.labels = vlabels;
            scope1c.config.options.plugins.legend.display = true;
            scope1c.config.data.datasets[0].label = dummy[ind].name;
            scope1c.update();
        }

        function mouseMoveHandler(canvas, mousemove) {
            const x = mousemove.offsetX;
            const y = mousemove.offsetY;
            if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
                canvas.style.cursor = "pointer";
            } else {
                canvas.style.cursor = "default";
            }
        }

        function handleBackClick() {
            scope1c.config.options.parsing.xAxisKey = "name";
            scope1c.config.options.parsing.yAxisKey = "y";
            const users = [];
            const vlabels = dummy.map((labels) => {
                users.push(labels.y);
                return labels.name;
            });
            scope1c.config.options.plugins.legend.display = true;
            scope1c.config.data.datasets[0].data = users;
            scope1c.config.data.labels = vlabels;

            scope1c.config.data.datasets[0].label = custom.name;

            scope1c.update();
        }

        function mouseClickHandler(click) {
            const x = click.offsetX;
            const y = click.offsetY;

            if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
                handleBackClick();
            }
        }

        chartref.addEventListener("click", (e) => {
            if (count === 0) {
                count = 1;
                mouseClickHandler(e);
            }
        });
        chartref.addEventListener("mousemove", (e) => mouseMoveHandler(chartref, e));
        chartref.onclick = clickHandler;
    }
    const showTrendLineChart = (id, dataset) => {
        console.log(dataset)

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        let ctx = document.getElementById(id);
        let myChart = new Chart(ctx, {
            type: "line",
            data: {
                datasets: [{ data: dataset, fill: true }]
            },
            options: {
                legend: {
                    display: false,
                },
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {

                        beginAtZero: true

                    }
                },
                responsive: true,
                maintainAspectRatio: true
            }
        });
        myChart.update()
    }
    const freezeFilter = () => {
        let stickyElem = document.querySelector(".sticky-div");
        if (stickyElem) {
            let currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
            window.onscroll = function () {
                /* Check if the current Y offset
                is greater than the position of
                the element */
                if (window.pageYOffset > currStickyPos) {
                    stickyElem.style.position = "fixed";
                    stickyElem.style.top = "58px";

                    stickyElem.style.width =
                        document.getElementById("divsize").clientWidth + "px";

                    stickyElem.style.zIndex = 999;
                } else {
                    stickyElem.style.position = "relative";
                    stickyElem.style.top = "initial";
                    stickyElem.style.width = "100%";
                    stickyElem.style.background = "transparent";
                    stickyElem.style.zIndex = 1;
                }
            };
        }
    };
    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#ebedef",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };


    useEffect(() => {
        console.log(savedPast)
        // renderTable()
        if (savedPast.length === 0) {
            renderTable(0)
        } else {
            setShow(true)
            setPast(savedPast)
            setOdList(savedPast)
        }
    }, []);
    useEffect(() => {
        if (props.colorMode === "light") {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);
    const groupArrayObject = (array, obj) => {
        return array.reduce((group, arr) => {

            let key = arr[obj];

            group[key] = group[key] ?? [];

            group[key].push(arr);

            return group;

        },

            {})
    }
    const renderTable = (type) => {
        if (type === 1) {
            console.log('refreshing...')
        }

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let metricString = {
            "include": [{ "relation": "newDataPoints" }]

        }
        let dcf_list = [], dcf_submitted = [], locloc = []
        let copt = [{ name: "All", id: 0 }], ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]
        siteList.forEach((country) => {
            copt.push(country)
            country.locationTwos.forEach((city) => {
                ccopt.push(city)
                city.locationThrees.forEach((loc, ind) => {

                    locopt.push(loc)

                })
            })
        })

        setCityOpt(ccopt)
        setCountryOpt(copt)
        setLocOpt(locopt)


        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.DCF_Submit_UP(selector.id))
        const promise3 = APIServices.get(API.LocationThree)


        let indidata = [{ title: 'All', id: 0 }]
        APIServices.get(API.Metric + `?filter=${encodeURIComponent(JSON.stringify(metricString))}`).then((res) => {
            let metrics = res.data

            APIServices.get(API.AssignDCFClient_UP(selector.id)).then((res) => {
                res.data.forEach((item) => {
                    item.metric_ids.forEach((item2) => {

                        let index = metrics.findIndex((k) => { return k.id === item2 })
                        if (index !== -1) {

                            indidata.push(metrics[index])
                        }
                    })
                })
                // let data = res.data[0].metric_ids.map((i) => { return metrics.filter((k) => { return k.id === i })[0] })
                setIndiOpt(indidata)
                setInidicators(indidata)
            })
        })
        setTimeout(() => {
            if (selector.id === 'hide') {
                APIServices.get(API.DP_report_UP(selector.id)).then((dpres) => {
                    let val = [], filterarr = groupArrayObject(dpres.data, 'submitId')
                    Object.keys(filterarr).forEach((item) => {

                        val.push({ rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], 'form_id')) })

                    })
                    setResponse(val)
                    console.log(val)
                    let yearlist = [{ name: 'All', id: 0 }]
                    dpres.data.forEach((report) => {
                        report.year.forEach((yr) => {
                            if (yearlist.findIndex((k) => { return k.name === yr }) === -1) {
                                yearlist.push({ name: yr })
                            }
                        })
                    })
                    setYearOpt(yearlist)
                })
            }
        }, 2000)


        let site_url = API.LocationOne_UP(selector.clientId) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        Promise.all([promise1, promise2, promise3]).then(function (values) {
            dcf_list = values[0].data; setDcfList(values[0].data)

            dcf_submitted = values[1].data;
            locloc = values[2].data

            forceUpdate()

            APIServices.get(API.AssignDCFUser_UP(selector.id)).then((res) => {
                let filter = [], locationFilter = [0], rawData = [], pending = [], count = 0


                res.data.forEach((item, i) => {



                    if (item.type === 0) {
                        count++

                        if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {

                            item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                            item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]

                            // item.start_date = moment.utc(item.start_date).toDate()
                            filter.push(item)
                            if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                                locationFilter.push(item.site[0])
                            }
                            let ed_date = DateTime.utc()
                            if (typeof item.end_date === 'string') {
                                ed_date = DateTime.fromISO(item.end_date, { zone: 'utc' })
                            }

                            rawData.push(...getMonthsLuxon(DateTime.fromISO(item.start_date, { zone: 'utc' }), ed_date, item.frequency, item, dcf_submitted, count).past_)


                        }

                    }
                })
                setPast(rawData)
                setOdList(rawData)
                dispatch(setOverallPendingData(rawData))
                APIServices.get(site_url).then((res) => {
                    let site_list = [{ name: 'All', id: 0 }]
                    let site_list_all = []
                    res.data.forEach((country) => {
                        if (country.locationTwos !== undefined) {
                            country.locationTwos.forEach((city) => {
                                if (city.locationThrees !== undefined) {
                                    city.locationThrees.forEach((site) => {
                                        filter.forEach((item) => { if (site.id === item.site[0]) { item.site = site } })
                                        site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                                        site_list_all.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })

                                    })
                                }
                            })
                        }
                    })
                    setSiteListAll(site_list_all)
                    setSiteList(site_list.filter((k) => { return locationFilter.includes(k.id) }))
                })


            })

        }).then((a) => { console.log(a); setTimeout(() => { setShow(true) }, 2500) })
    }
    const getMonths = (startDate, endDate, frequency, item, old, count) => {

        var betweenMonths = [];
        let type = 0, past_ = [], present_ = [], future_ = []





        if (startDate.diff(endDate.startOf('month'), 'days') === 0) {
            let endDate_ = startDate

            let endDate__ = moment(moment.utc())
            if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.site, endDate__.format('MMM-YYYY'), old)

                if (check.result) {

                    if (check.data) {
                        past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: endDate__.diff(endDate__, 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                    }
                } else {
                    if (check.data) {

                        past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: 0, site: item.site, type: 6, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })
                    } else {
                        past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: 0, site: item.site, type: 6, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                    }

                }


            }

        }
        else if (startDate.diff(endDate.startOf('month'), 'days') < 31) {
            var date = startDate.startOf('month')

            // let endDate__ = moment('01.03.2023', 'DD.MM.YYYY')
            let endDate__ = moment(moment.utc())
            while (date <= endDate__) {

                if (endDate__.format('MMM-YYYY') !== date.format('MMM-YYYY')) {
                    console.log(date.format('MMM-YYYY'))
                    betweenMonths.push(date.format('MMM-YYYY'));
                }
                date.add(1, 'month');


            }

            if (frequency === 1) {

                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month() === moment(months[0]).month() && endDate__.year() === moment(months[0]).year()) {



                    } else {
                        if (endDate__.diff(moment(months[0]), 'months') === 1) {


                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                            if (check.result) {
                                if (check.data) {

                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                }
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                } else {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                }

                            }

                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                            if (check.result) {
                                if (check.data) {

                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                }
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                } else {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month() === moment(months[1]).month() && endDate__.year() === moment(months[1]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[1]), 'months') === 1) {

                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }

                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month() === moment(months[2]).month() && endDate__.year() === moment(months[2]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[2]), 'months') === 1) {


                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)
                                console.log(check)
                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }



                                }
                            } else {

                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month() === moment(months[11]).month() && endDate__.year() === moment(months[11]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[11]), 'months') === 1) {


                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month() === moment(months[5]).month() && endDate__.year() === moment(months[5]).year()) {


                        } else {
                            if (endDate__.diff(moment(months[5]), 'months') === 1) {


                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                if (check.result) {

                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }

                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                if (check.result) {

                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    if (moment(months[0]).month() === endDate__.month() && moment(months[0]).year() === endDate__.year()) {



                    } else {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                        if (check.result) {
                            if (check.data) {
                                check.list.forEach((list) => {

                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, response: list.response, id: list.id, user: item.user_id, approver: item.approver_id })

                                })
                            }


                        } else {
                            if (check.data) {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                            } else {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                            }

                        }


                    }

                })
            }

        }



        return { type, months: betweenMonths, past_ }
    }
    const getMonthsLuxon = (startDate, endDate, frequency, item, old) => {

        var betweenMonths = [];
        let type = 0, past_ = []
        console.log('asd')
        console.log(startDate, endDate)

        if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days).toFixed(0) === 0) {
            let endDate_ = startDate

            let endDate__ = DateTime.utc()
            if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.site, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {


                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(endDate__, 'days').days), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                } else {
                    if (check.data) {

                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: 0, site: item.site, type: 6, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })
                    }

                }


            }

        }
        else if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days) < 31) {
            var date = startDate
            console.log(startDate.diff(endDate.startOf('month'), 'days').days)


            let endDate__ = DateTime.utc()
            while (date < endDate) {

                betweenMonths.push(date.toFormat('LLL-yyyy'));
                date = date.plus({ months: 1 })

            }
            console.log(betweenMonths)
            if (frequency === 1) {
                console.log(splitArray(betweenMonths, 1), 'SP')
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    console.log(endDate__.month, DateTime.fromFormat(months[0], 'LLL-yyyy').month, endDate__.year, DateTime.fromFormat(months[0], 'LLL-yyyy').year, item.dcf_, ind, months[0])
                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {



                    } else {

                        if (Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'months').months) === 1) {




                        } else {
                            let check = checkSubmission(item.dcf_, item.site, months[0], old)
                            console.log(check, months[0])
                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                            } else {
                                if (check.data) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month === DateTime.fromFormat(months[1], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[1], 'LLL-yyyy').year) {



                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy'), 'months').months) === 1) {




                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[1], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month === DateTime.fromFormat(months[2], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[2], 'LLL-yyyy').year) {



                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy'), 'months').months) === 1) {




                            } else {

                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month === DateTime.fromFormat(months[11], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[11], 'LLL-yyyy').year) {



                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy'), 'months').months) === 1) {




                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month === DateTime.fromFormat(months[5], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[5], 'LLL-yyyy').year) {



                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy'), 'months').months) === 1) {


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {



                    } else {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: selector.id, frequency, response: list.response, id: list.id, user: item.user_id, approver: item.approver_id })

                            })


                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: months[0], overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                            }

                        }


                    }

                })
            }

        }


        return { type, months: betweenMonths, past_ }
    }
    const checkSubmission = (dcf, site, rp, old) => {
        let rps = getRP(rp)
        let result = {}
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 0
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 1
        })
        if (index === -1 && index2 === -1) {

            result = { result: false, data: true }
        } else {
            if (index !== -1) {
                result = {
                    result: true, data: loc[index], list: loc.filter((i) => {
                        return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 0
                    })
                }
            } else {
                result = {
                    result: true, data: loc[index2], list: loc.filter((i) => {
                        return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 1
                    })
                }
            }
        }

        return result
    }
    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray_fg, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray_fg[chunkIndex]) {
                resultArray_fg[chunkIndex] = [] // start a new chunk
            }

            resultArray_fg[chunkIndex].push(item)

            return resultArray_fg
        }, [])
    }
    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }
    //dummy
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const yeartemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Year </span> {rowData.year}
            </>
        );
    };
    const monthtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.month}
            </>
        );
    };
    const countrytemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.country}
            </>
        );
    };
    const citytemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.city}
            </>
        );
    };
    const locationtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.location}
            </>
        );
    };
    const approvedtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Approved </span> {rowData.approved_by}
            </>
        );
    };
    function isValidEmail(email) {
        // A simple regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const sendMail = (data) => {

        console.log(data)
        if (data.type === 2 || data.type === 4) {
            data.subject = data.subject + '- Escalation Mail'
            data.email = data.email2
            delete data.email2
        } else {
            data.subject = data.subject + '- Remainder Mail'
            delete data.email2
        }
        if (isValidEmail(data.email)) {
            Swal.fire({
                title: `Are you sure want to send  ${(data.type === 2 || data.type === 4) ? 'Escalte' : 'Remainder'} mail to ${data.email} ?`,
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'green',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {

                    APIServices.post(API.RemaindEsclate_Mail, data).then((a) => {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Mail sent successfully",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    }).catch((err) => {
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Something went wrong while sending mail, try again. Contact admin if issue persist",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    })
                }
            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Incorrect e-mail ID, check user detail",
                showConfirmButton: false,
                timer: 2000,
            });
        }

    }
    const remindertemplate = (rowData) => {
        console.log(rowData)
        let res = { subject: '', email: '', email2: '', name: '' }
        let type = 0
        let userIndex = userList.findIndex((i) => { return i.id === rowData.user })
        let approverIndex = userList.findIndex((i) => { return i.id === rowData.approver })
        if (rowData.draft === undefined) {

            if (userIndex !== -1) {
                type = 1
                res.subject = 'Data Submissions Not Done'
                res.email = userList[userIndex].email
                res.email2 = userList[userIndex].information.empdesgination
                res.name = userList[userIndex].information.empname
            }

        } else {
            if (rowData.draft.type === 0) {
                type = 2
                res.subject = 'Data Submissions Incomplete'
                res.email = userList[userIndex].email
                res.email2 = userList[userIndex].information.empdesgination
                res.name = userList[userIndex].information.empname
            } else {
                type = 3
                res.subject = 'Data Approval Incomplete'
                res.email = userList[approverIndex].email
                res.email2 = userList[approverIndex].information.empdesgination
                res.name = userList[approverIndex].information.empname
            }
        }
        return (
            <>
                <span className="p-column-title"> Approved </span>
                {rowData.user !== selector.id ? <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <label
                        style={{
                            background: 'cadetblue',
                            padding: '5px',
                            color: 'white',
                            width: '100px',
                            display: 'flex',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            sendMail({ type: (type === 1 || type === 2) ? 1 : 3, form: rowData.dcf.title, site: locationList.find((i) => { return i.id === rowData.site[0] }).title, rp: rowData.rp, date: DateTime.local().toFormat('dd-MM-yyyy'), ...res });
                        }}
                    >

                        Reminder
                    </label>
                    <label
                        style={{
                            background: "indianred",

                            padding: '5px',
                            color: 'white',
                            width: '100px',
                            display: 'flex',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {

                            sendMail({ type: (type === 1 || type === 2) ? 2 : 4, form: rowData.dcf.title, site: locationList.find((i) => { return i.id === rowData.site[0] }).title, rp: rowData.rp, date: DateTime.local().toFormat('dd-MM-yyyy'), ...res });
                            // alert("Remainder Mail send to " + res.supemail);
                        }}
                    >

                        Escalate
                    </label>
                </div> : 'No Actions'}
            </>
        );
    };
    //Dummy end

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    };
    const updatePendingFilter = (obj, val) => {
        let loc = pendingFilter;
        loc[obj] = val;
        if (obj === "scope") {
            if (val.name !== "All") {
                let loc2 = filterData;
                loc["topic"] = {
                    name: "All",
                };

                let ind = rawtopic.findIndex((i) => {
                    return Object.keys(i)[0] === val.name;
                });

                loc2.topic = rawtopic[ind][val.name];
                setFilterData(loc2);
            } else {
                let loc2 = filterData;

                loc2.topic = [
                    {
                        name: "All",
                    },
                ];
                setFilterData(loc2);
            }
        }
        if (obj === "scope") {
            renderFilterValue(pendingFilter.month, pendingFilter.topic.name, val.name);
        } else if (obj === "topic") {
            renderFilterValue(pendingFilter.month, val.name, pendingFilter.scope.name);
        } else {
            renderFilterValue(val, pendingFilter.topic.name, pendingFilter.scope.name);
        }
        setPendingFilter(loc);
        forceUpdate();
    };
    const setPendingFilterValue = () => {
        let module = [];
        let list = rawdata;
        selector.information.config[0].location.countrylist.forEach((i) => {
            //  module.push({country: i.country})
            i.city.forEach((j) => {
                // module.push({country:i.country,city:j.city})
                j.location.forEach((k) => {
                    module.push({
                        country: i.country,
                        city: j.city,
                        location: k.location,
                    });
                });
            });
        });
        let scopelist = [];
        let topiclist = [];
        let count = 0;
        selector.information.config[0].selectedModule.forEach((i) => {
            i.children.forEach((j, a) => {
                if (a == 0) {
                    scopelist.push({
                        name: "All",
                    });
                }
                j.children.forEach((k) => {
                    list.scope.push(k.name);
                    scopelist.push({
                        name: k.name,
                    });
                    topiclist.push({
                        [k.name]: [],
                    });

                    if (k.children !== undefined) {
                        k.children.forEach((l, i) => {
                            list.topic.push(l.name);
                            if (i == 0) {
                                topiclist[count][k.name].push({
                                    name: "All",
                                });
                            }
                            topiclist[count][k.name].push({
                                name: l.name,
                            });
                        });
                        count++;
                    } else {
                        list.topic.push(k.name);
                        topiclist[count][k.name].push({
                            name: "All",
                        });
                        topiclist[count][k.name].push({
                            name: k.name,
                        });
                        count++;
                        // topiclist[count].push({ [k.name]: [{ name: k.name }] })
                    }
                });
            });
        });
        setRawData(list);
        let loc = filterData;
        loc.scope = scopelist;
        loc.topic = topiclist;

        setFilterData(loc);
        setRawTopic(topiclist);
    };
    const showNoDataFound = (id, txt) => {
        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const ctx = document.getElementById(id).getContext("2d");

        // Set the font-size and font-family to mimic our paragraph
        ctx.font = "20px serif";

        // Draw the text
        ctx.fillText(txt, 0, 20);
    };
    const dateFormatting = (date) => {
        if (typeof date === "string") {
            let newDate = new Date(date);

            return newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
        } else {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        }
    };
    const scopeFormat = (name) => {
        if (!name.toLowerCase().includes("general")) {
            let sub1 = name[0].toLowerCase();
            let sub2 = name.split(" ")[0];
            let sub3 = sub1 + name.substr(1, sub2.length - 1) + name.split(" ")[1];
            return sub3;
        } else {
            return "general";
        }
    };
    const renderFilterValue = (month, topic, scope) => {
        apidata.forEach((i) => {
            if (i.month === dateFormatting(month) && (i.scope === scopeFormat(scope) || scope === "All") && (i.topic === scopeFormat(topic) || topic === "All")) {

            }
        });
    };
    const responsibilityTemplate = (rowData) => {
        let res = 'Reporter not exist'
        let res_ = JSON.parse(JSON.stringify(userList))
        res_.push(selector)
        let userIndex = res_.findIndex((i) => { return i.id === rowData.user })
        let approverIndex = res_.findIndex((i) => { return i.id === rowData.approver })
        if (rowData.draft === undefined) {

            if (userIndex !== -1) {
                res = rowData.user === selector.id ? 'You (Reporter)' : res_[userIndex].information.empname + ' (Reporter)'
            }

        } else {
            if (rowData.draft.type === 0) {
                if (userIndex !== -1) {
                    res = rowData.user === selector.id ? 'You (Reporter)' : res_[userIndex].information.empname + ' (Reporter)'
                } else {
                    res = 'Reporter not exist'
                }

            } else {
                if (approverIndex !== -1) {
                    res = rowData.approver === selector.id ? 'You (Reviewer)' : res_[approverIndex].information.empname + ' (Reviewer)'
                } else {
                    res = 'Reviewer not exist'
                }

            }


        }
        return (<span>{res}</span>)
    }
    const checkSite = (id, filter) => {
        let idlist = []

        siteList.forEach((country) => {

            if (filter.a.id === 0 || filter.a.id === country.id) {

                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id)
                            }
                        })
                    }
                })
            }
        })

        return idlist.includes(id)
    }
    // const checkReportingPeriod = (rp, filter) => {

    //     let count = 0
    //     filter.forEach((item) => {

    //         if (rp.includes(item)) {
    //             count = count + 1
    //         }
    //     })
    //     return { result: count === rp.length, count: count }
    // }
    const checkReportingPeriod = (rp, filter) => {

        let count = 0, rps = []
        filter.forEach((item) => {

            if (rp.includes(item)) {
                count = count + 1
                rps.push(item)
            }
        })
        return { result: count === rp.length, count: count, rps }
    }
    const renderGraph = (search) => {
        let sc_report = [], betweenMonths = []
        let monthly_sc = []

        if (search.from !== null && search.to !== null) {
            let endDate = moment(search.to)
            let startDate = moment(search.from)
            while (startDate <= endDate) {
                betweenMonths.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');

            }

        }
        let res = JSON.parse(JSON.stringify(response))
        res.forEach((report, rind) => {
            let sc_total = 0, fg_total = 0

            if (checkSite(report.site, search.location) && (report.year.includes(search.year.name) || search.year.name === 'All')) {
                report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name

                if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {
                    let add = 0
                    report.rp.forEach((i) => {
                        let mn = i.split('-')[0]
                        let yr = i.split('-')[1]
                        let yearind = monthly_sc.findIndex((i) => { return i.year === yr })
                        let monthind = monthly_sc.findIndex((i) => { return i.year === yr && i.month === mn })
                        let dcfind = monthly_sc.findIndex((i) => { return i.year === yr && i.month === mn && i.dcf === report.dcf })
                        let siteind = monthly_sc.findIndex((i) => { return i.year === yr && i.month === mn && i.dcf === report.dcf && i.site === report.site })


                        if (checkReportingPeriod([i], betweenMonths).result) {
                            add = add + 1
                            if (yearind === -1) {
                                monthly_sc.push({ year: yr, month: mn, ghg: 0, dcf: report.dcf, site: report.site })
                            } else if (monthind === -1) {
                                monthly_sc.push({ year: yr, month: mn, ghg: 0, dcf: report.dcf, site: report.site })
                            } else if (dcfind === -1) {
                                monthly_sc.push({ year: yr, month: mn, ghg: 0, dcf: report.dcf, site: report.site })

                            } else if (siteind === -1) {
                                monthly_sc.push({ year: yr, month: mn, ghg: 0, dcf: report.dcf, site: report.site })

                            }
                        }
                    })
                    report.response.forEach((fg, ind) => {



                        let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                        let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                        let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                        let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value * (add / report.rp.length)
                        fg['fuel_cat'] = fuel_cat
                        fg['fuel_type'] = fuel_type
                        fg['unit'] = unit
                        fg['consumed'] = consumed
                        let total = 0, co2 = 0, ch4 = 0, n2o = 0

                        if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                            let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                            let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                            if (carbon > 0 && cv > 0) {
                                let gj = (carbon * 3.664 * 1000) / cv
                                total = (gj * 0.000004184 * consumed) / 1000
                                fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                co2 = 0
                                ch4 = 0
                                n2o = 0
                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                            }

                        } else {
                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                            co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                            ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                            n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                            fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]

                        }

                        sc_total = total + sc_total
                        fg['total'] = total
                        fg['co2'] = co2
                        fg['n2o'] = n2o
                        fg['ch4'] = ch4

                    })

                    if (add !== 0) {

                        report['total'] = sc_total
                        sc_report.push(report)
                    }



                    // report.response.forEach((fg,ind) => {



                    //     let updateind = sc_report.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MM') && i.dcf === report.dcf && i.site === report.site })


                    //     let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                    //     let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                    //     let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                    //     let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                    //     fg['fuel_cat'] = fuel_cat
                    //     fg['fuel_type'] = fuel_type
                    //     fg['unit'] = unit
                    //     fg['consumed'] = consumed
                    //     let total = 0, co2 = 0, ch4 = 0, n2o = 0

                    //     if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                    //         let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                    //         let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                    //         if (carbon > 0 && cv > 0) {
                    //             let gj = (carbon * 3.664 * 1000) / cv
                    //             total = (gj * 0.000004184 * consumed) / 1000
                    //             fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                    //             co2 = 0
                    //             ch4 = 0
                    //             n2o = 0
                    //         } else {
                    //             total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                    //             co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                    //             ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                    //             n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                    //             fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                    //         }

                    //     } else {
                    //         total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                    //         co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                    //         ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                    //         n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                    //         fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]

                    //     }

                    //     sc_total = total + sc_total
                    //     fg['total'] = total
                    //     fg['co2'] = co2
                    //     fg['n2o'] = n2o
                    //     fg['ch4'] = ch4


                    // })
                    // report['total'] = sc_total
                    // sc_report.push(report)
                }

                if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                    let add = 0

                    report.response.forEach((fg, ind) => {

                        let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value


                        if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {

                            let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                            let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                            let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                            add = total + add

                            fg['gas'] = gastype
                            fg['total'] = total
                            fg['gasfilled'] = gasrefilled
                            fg['value'] = ['EmissionFactor-', total / gasrefilled]
                            let updateind = sc_report.findIndex((i) => { return i.year.includes(moment(date).format('YYYY')) && i.rp.includes(moment(date).format('MM-YYYY')) && i.dcf === report.dcf && i.site === report.site })

                            if (updateind === -1) {

                                sc_report.push({ ...report, total: add, response: [fg] })


                            } else {
                                sc_report[updateind].response.push(fg)
                                sc_report[updateind].total = add
                            }

                        }
                    })

                }
                if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {
                    let add = 0
                    let addedMonth = checkReportingPeriod(report.rp, betweenMonths)
                    console.log(report)

                    let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                    let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                    let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = [], ototal = 0

                    report.response.forEach((fg, ind) => {
                        let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                        let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                        let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                        let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                        let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                        let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                        let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                        let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                        fg['mode'] = mode
                        if (mode === 'by distance') {
                            console.log(fuel_cat.name, fuel_type, fuel, unit, ef_by_distance)
                            total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg'] * km
                            fg['value'] = ['Emission Factor-', total / km]
                            fg['total'] = total
                            fg['km'] = km
                            fg['fr'] = 'NA'
                            mc_data.push(fg)

                        } else {
                            total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg'] * fuel_filled

                            fg['value'] = ['Emission Factor-', total / fuel_filled]
                            fg['total'] = total
                            mc_data.push(fg)
                            fg['km'] = 'NA'
                            fg['fr'] = fuel_filled
                        }
                        report['total'] = total
                    })
                    addedMonth.rps.forEach((fm) => {
                        let date = moment(fm, 'MM-YYYY').toDate()
                        console.log(report)
                        mc_data.forEach((item) => {
                            let updateind = sc_report.findIndex((i) => { return i.year.includes(moment(date).format('YYYY')) && i.rp.includes(moment(date).format('MM-YYYY')) && i.dcf === report.dcf && i.site === report.site })


                            item.total = item.total / addedMonth.count
                            if (updateind === -1) {

                                sc_report.push({ ...report, total: item.total, response: [item] })

                            } else {
                                sc_report[updateind].response.push(item)
                                sc_report[updateind].total = sc_report[updateind].total + (item.total / addedMonth.count)
                            }
                        })


                    })

                }
                if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {
                    let add = 0

                    report.response.forEach((fg, ind) => {

                        let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value


                        if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {

                            let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                            let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                            let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value
                            let ef = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000)
                            let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                            add = total + add
                            fg['total'] = total
                            fg['item_qty'] = item_qty

                            fg['itemclass'] = item_class
                            fg['value'] = ['EmissionFactor-', ef]

                            let updateind = sc_report.findIndex((i) => { return i.year.includes(moment(date).format('YYYY')) && i.rp.includes(moment(date).format('MM-YYYY')) && i.dcf === report.dcf && i.site === report.site })

                            if (updateind === -1) {

                                sc_report.push({ ...report, total: add, response: [fg] })


                            } else {
                                sc_report[updateind].response.push(fg)
                                sc_report[updateind].total = add
                            }

                        }
                    })

                }
                if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {

                    let add = 0
                    report.response.forEach((fg, ind) => {

                        let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                        let yearind = sc_report.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                        let monthind = sc_report.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                        let dcfind = sc_report.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                        let siteind = sc_report.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                        if (checkReportingPeriod([moment(date).utc().format('MM-YYYY')], betweenMonths).result) {




                            let total = 0


                            let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                            let from = fg.filter((i) => { return i.dpId === "DPA0293" })[0].value
                            let to = fg.filter((i) => { return i.dpId === "DPA0294" })[0].value

                            let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value
                            fg['mode'] = travel_mode
                            fg['from'] = from
                            fg['to'] = to
                            fg['passenger'] = passenger
                            if (travel_mode.toLowerCase() === 'air') {

                                total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger
                                fg['value'] = ['Emission Factor-', total / passenger]
                                fg['total'] = total
                                add = total + add




                            } else if (travel_mode.toLowerCase() === 'road') {

                                let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                    if (veh_cat.includes('Cars')) {
                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger
                                        fg['value'] = ['Emission Factor-', (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000)]
                                        add = total + add
                                        fg['total'] = total

                                    } else {
                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger
                                        fg['value'] = ['Emission Factor-', (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000)]
                                        add = total + add
                                        fg['total'] = total

                                    }

                                } else {
                                    console.log(veh_cat)
                                    console.log(emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0])
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger
                                    fg['value'] = ['Emission Factor-', (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000)]
                                    add = total + add
                                    fg['total'] = total

                                }






                            } else if (travel_mode.toLowerCase() === 'rail') {



                                total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116
                                fg['value'] = ['Emission Factor-', 0.00116]
                                fg['total'] = total
                                add = total + add



                            }


                            let updateind = sc_report.findIndex((i) => { return i.year.includes(moment(date).utc().format('YYYY')) && i.rp.includes(moment(date).utc().format('MM-YYYY')) && i.dcf === report.dcf && i.site === report.site })
                            console.log(report, moment(date).format('YYYY'), moment(date).utc().format('MM-YYYY'), updateind, date)
                            if (updateind === -1) {

                                sc_report.push({ ...report, total: add, response: [fg] })


                            } else {
                                sc_report[updateind].response.push(fg)
                                sc_report[updateind].total = add
                            }


                        }
                    })

                }
                if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {
                    let add = 0
                    console.log(report)
                    report.rp.forEach((i) => {
                        if (checkReportingPeriod([i], betweenMonths).result) {
                            let date = moment(i, 'MM-YYYY').toDate()
                            let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                            let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                            let nonrenewable = ((report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value) / report.rp.length)
                            let total = ((report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value * ef) / report.rp.length)

                            let updateind = sc_report.findIndex((i) => { return i.year.includes(moment(date).format('YYYY')) && i.rp.includes(moment(date).format('MM-YYYY')) && i.dcf === report.dcf && i.site === report.site })
                            add = add + total

                            report.response[0]['value'] = ['Emission Factor-', ef]
                            report.response[0]['renew'] = renewable
                            report.response[0]['nonrenew'] = nonrenewable
                            if (updateind === -1) {

                                sc_report.push({ ...report, total: total })

                            } else {
                                report.response[0]['renew'] = report.response[0]['renew'] + renewable
                                report.response[0]['nonrenew'] = report.response[0]['nonrenew'] + nonrenewable
                                sc_report[updateind].total = sc_report[updateind].total + total
                            }
                        }
                    })

                }
            }


        })

        let loc = report
        loc.sc = sc_report

        setReport(loc)

        forceUpdate()

        console.log(sc_report, monthly_sc)

    }
    const fillupData = (data, betweenMonths) => {

        betweenMonths.forEach((k, ind) => {

            let index = data.findIndex((l) => { return l.x === months_[k.split('-')[0] - 1] })
            if (index === -1) {

                data.push({ x: months_[k.split('-')[0] - 1] + `'` + k.split('-')[1].substr(2, 3), y: null, z: ind })
            } else {

                data[index].x = months_[k.split('-')[0] - 1] + `'` + k.split('-')[1].substr(2, 3)
                data[index].z = ind
            }
        })

        return data.sort((a, b) => { return a.z - b.z })
    }
    const onFilterChanged = (obj, val) => {
        let loc = filter
        loc[obj] = val
        let ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]


        if (obj === 'country') {
            siteList.forEach((country) => {
                if (val.id === 0 || val.id === country.id) {
                    country.locationTwos.forEach((city) => {
                        ccopt.push(city)
                        city.locationThrees.forEach((loc, ind) => {

                            locopt.push(loc)

                        })
                    })
                }
            })
            loc.city = { name: 'All', id: 0 }
            loc.location = { name: 'All', id: 0 }
            setCityOpt(ccopt)

            setLocOpt(locopt)
            renderGraph({ location: { a: val, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: filter.year, month: filter.month, indicator: filter.indicator, from: filter.from, to: filter.to })
        } else if (obj === 'city') {
            siteList.forEach((country) => {
                if (loc.country.id === 0 || loc.country.id === country.id) {
                    country.locationTwos.forEach((city) => {
                        if (val.id === 0 || val.id === city.id) {
                            city.locationThrees.forEach((loc, ind) => {

                                locopt.push(loc)

                            })
                        }
                    })
                }
            })

            loc.location = { name: 'All', id: 0 }


            setLocOpt(locopt)
            renderGraph({ location: { a: filter.country, b: val, c: { name: 'All', id: 0 } }, year: filter.year, month: filter.month, indicator: filter.indicator, from: filter.from, to: filter.to })

        } else if (obj === 'location') {
            renderGraph({ location: { a: filter.country, b: filter.city, c: val }, year: filter.year, month: filter.month, indicator: filter.indicator, from: filter.from, to: filter.to })

        } else if (obj === 'year') {
            loc.month = { name: 'All', id: 0 }
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: val, month: filter.month, indicator: filter.indicator, from: filter.from, to: filter.to })

        } else if (obj === 'month') {
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: val, indicator: filter.indicator, from: filter.from, to: filter.to })

        } else if (obj === 'indicator') {
            let indi = []

            indiopt.forEach((item) => {

                if (val.id === 0 || val.id === item.id) {
                    indi.push(item)
                }
            })
            setInidicators(indi)
            setTimeout(() => {
                renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: filter.month, indicator: val, from: filter.from, to: filter.to })
                forceUpdate()
            }, 1000)

        } else if (obj === 'from') {
            let to = moment(val).add(11, 'months').toDate()
            if (moment(val).add(11, 'months').diff(moment(moment.utc()), 'months') > 0) {
                to = moment(moment.utc()).toDate()
            }
            loc['to'] = to

            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: filter.month, indicator: filter.indicator, from: val, to: to })

        } else if (obj === 'to') {
            let from = moment(val).subtract(11, 'months').toDate()
            if (moment(val).subtract(11, 'months').diff(moment(moment.utc()), 'months') > 0) {
                from = moment(moment.utc()).toDate()
            }
            loc['from'] = from
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: filter.month, indicator: filter.indicator, from: from, to: val })

        }
        setFilter(loc)
        forceUpdate()

    }

    const supportDocTemplate = (rowData) => {
        if (rowData.dcf === 10 || rowData.dcf === 11) {
            let data = submitteddcf.filter((i) => { return i.id === rowData.id })
            let attachment = []

            data.forEach((i) => {
                i.response.forEach((item) => {
                    if (item.attachment !== null) {

                        item.attachment.forEach((att) => {
                            attachment.push(att)
                        })
                    } else {

                    }
                })


            })

            return (
                <>
                    <span onClick={() => { setAttachment(attachment); setAttDialog(true); }}>View</span>
                </>
            )
        }
    }
    const getReviwerId = (site, dcf) => {

        let id = 0
        let index = dcfasslist.findIndex((i) => { return i.site[0] === site && i.dcfId === dcf })
        if (index !== -1) {
            id = dcfasslist[index].approver_id
        }
        return id
    }
    const getReviwerName = (site, dcf) => {

        let id = 'User Not Found'
        let index = dcfasslist.findIndex((i) => { return i.site[0] === site && i.dcfId === dcf })
        if (index !== -1) {
            let index2 = userList.findIndex((l) => { return l.id === dcfasslist[index].approver_id })
            if (index2 !== -2) {
                id = userList[index2].information.empname
            }
        }
        console.log(id)
        return id
    }
    const renderSubmitted = () => {
        return (<div className="col-12">

            <DataTable value={dslist}
                header={header2}
                paginator
                rows={10}
                scrollable
                style={{

                    overflow: 'auto'
                }}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} indicators"

                dataKey="id" >

                <Column body={(rowData) => { return <>{rowData.dcfItem.title}</> }} header="DCF"></Column>
                <Column body={(rowData) => { return <>{locationList.filter((i) => { return i.id === rowData.site })[0].name}</> }} header="Site"></Column>
                <Column body={(rowData) => { return <>{getRP_(rowData.reporting_period)}</> }} header="Reporting Period"></Column>

                <Column body={(rowData) => { let val = "User Not Found"; let index = userList.findIndex((i) => { return i.id === rowData.submitted_by }); if (index !== -1) { val = userList[index].information.empname } return <>{val}</> }} header="Reported by"></Column>
                <Column body={(rowData) => { let findReviewer = getReviwerId(rowData.site, rowData.dcf); let val = "User Not Found"; let index = userList.findIndex((i) => { return i.id === findReviewer }); if (index !== -1) { val = userList[index].information.empname } return <>{val}</> }} header="Reviewer"></Column>

            </DataTable>

        </div>)

    }
    const renderDrafted = () => {
        return (<div className="col-12">

            <DataTable value={ddlist}
                header={header3}
                paginator
                rows={10}
                scrollable
                style={{

                    overflow: 'auto'
                }}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} indicators"

                dataKey="id" >

                <Column body={(rowData) => { return <>{rowData.dcfItem.title}</> }} header="DCF"></Column>

                <Column body={(rowData) => { return <>{locationList.filter((i) => { return i.id === rowData.site })[0].name}</> }} header="Site"></Column>
                <Column body={(rowData) => { return <>{getRP_(rowData.reporting_period)}</> }} header="Reporting Period"></Column>
                <Column body={(rowData) => { let val = "User Not Found"; let index = userList.findIndex((i) => { return i.id === rowData.submitted_by }); if (index !== -1) { val = userList[index].information.empname } return <>{val}</> }} header="Drafted by"></Column>


            </DataTable>

        </div>)

    }
    const handleSort = (e) => {
        console.log(e)
    };
    const customFilter = (
        <InputText
            type="text"
            className="p-inputtext-sm"
            onChange={(e) => console.log(e.target.value)}
            placeholder="Custom Filter"
        />
    );
    const renderApproved = () => {
        return (<div className="col-12">

            <DataTable header={header1} value={aplist}
                paginator
                rows={10}

                style={{

                    overflow: 'auto'
                }}


                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} indicators"
                scrollable
                dataKey="id"
            >

                <Column body={(rowData) => { return <>{rowData.dcfItem.title}</> }} header="DCF"></Column>
                <Column body={(rowData) => { return <>{locationList.filter((i) => { return i.id === rowData.site })[0].name}</> }} header="Site"></Column>
                <Column body={(rowData) => { return <>{getRP_(rowData.reporting_period)}</> }} header="Reporting Period"></Column>
                <Column body={(rowData) => { let val = "User Not Found"; let index = userList.findIndex((i) => { return i.id === rowData.submitted_by }); if (index !== -1) { val = userList[index].information.empname } return <>{val}</> }} header="Reported by"></Column>
                <Column body={(rowData) => { let val = "User Not Found"; let index = userList.findIndex((i) => { return i.id === rowData.approved_by }); if (index !== -1) { val = userList[index].information.empname } return <>{val}</> }} header="Reviewed by"></Column>

            </DataTable>

        </div>)

    }
    const renderInternalSubmitted = () => {
        return (
            <div className="col-12">
                <DataTable
                    value={odlist}
                    paginator
                    header={header4}
                    rows={10}
                    scrollable
                    style={{

                        overflow: 'auto'
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} indicators"

                    dataKey="id"
                >

                    <Column header="DCF" field='dcf.title'> </Column>
                    <Column header="Site" body={(rowData) => { let site = locationList.filter((i) => { return i.id === rowData.site[0] })[0].title; return <span>{site}</span> }} field='site'> </Column>
                    <Column header="Reporting Period" field='rp'> </Column>
                    <Column header="Overdue Days" field='overdue'> </Column>
                    <Column header="Responsibility" body={responsibilityTemplate} field='responsibility'> </Column>

                    <Column
                        header="Action"
                        body={remindertemplate}

                    >

                    </Column>



                </DataTable>
            </div>
        )
    }
    const renderSupplierSubmitted = () => {
        return (
            <div className="col-12">
                <DataTable
                    value={[]}
                    paginator
                    rows={10}
                    scrollable
                    style={{

                        overflow: 'auto'
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} indicators"

                    dataKey="id"
                >

                    <Column sortable header="DCF" field='dcf.title'> </Column>
                    <Column sortable header="Site" body={(rowData) => { let site = locationList.filter((i) => { return i.id === rowData.site[0] })[0].title; return <span>{site}</span> }} field='site'> </Column>
                    <Column header="Reporting Period" field='rp'> </Column>
                    <Column header="Overdue Days" field='overdue'> </Column>
                    <Column header="Responsibility" body={responsibilityTemplate} field='responsibility'> </Column>

                    <Column
                        header="Action"
                        body={remindertemplate}

                    >

                    </Column>



                </DataTable>
            </div>
        )
    }
    const exportExcel = (type, hint) => {

        let xlsjson = [], actual = []
        if (hint !== undefined) {
            xlsjson = JSON.parse(JSON.stringify(submitteddcf)).filter((i) => { return i.type === hint })
            xlsjson.forEach((k) => {
                let val = "User Not Found"; let index = userList.findIndex((i) => { return i.id === k.submitted_by }); if (index !== -1) { val = userList[index].information.empname }
                let val1 = "User Not Found"
                if (hint === 1 || hint === 0) {
                    let findReviewer = getReviwerId(k.site, k.dcf); let index = userList.findIndex((i) => { return i.id === findReviewer }); if (index !== -1) { val1 = userList[index].information.empname }
                }
                if (hint === 3) {
                    let index1 = userList.findIndex((i) => { return i.id === k.approved_by }); if (index1 !== -1) { val1 = userList[index].information.empname }

                }
                if (hint !== 0) {
                    actual.push({ dcf: k.dcfItem.title, site: locationList.filter((i) => { return i.id === k.site })[0].name, 'reporting period': getRP_(k.reporting_period), user: val, approver: val1 })

                } else {
                    actual.push({ dcf: k.dcfItem.title, site: locationList.filter((i) => { return i.id === k.site })[0].name, 'reporting period': getRP_(k.reporting_period), drafted_by: val })

                }
            })
        } else if (hint === undefined) {
            xlsjson = JSON.parse(JSON.stringify(odlist))
            xlsjson.forEach((k) => {
                console.log(k)
                if (k.draft) {
                    let val = "User Not Found", val1 = "Approver Not Found"
                    let index = userList.findIndex((i) => { return i.id === k.user }); if (index !== -1) { val = userList[index].information.empname }
                    let index2 = userList.findIndex((i) => { return i.id === k.approver }); if (index2 !== -1) { val1 = userList[index2].information.empname }
                    if (k.user === selector.id) {
                        val = 'Admin'
                    } else if (k.approver === selector.id) {
                        val = 'Admin'

                    }
                    if (k.draft.type === 1) {
                        actual.push({ form: k.dcf.title, site: locationList.filter((i) => { return i.id === k.site[0] })[0].name, 'reporting period': k.rp, due_month: k.duedate, overdue: k.overdue, responsibility: val1, status: 'Reviewer not Approved' })

                    } else {
                        actual.push({ form: k.dcf.title, site: locationList.filter((i) => { return i.id === k.site[0] })[0].name, 'reporting period': k.rp, due_month: k.duedate, overdue: k.overdue, responsibility: val, status: 'Reporter drafted & not submitted' })

                    }

                } else {
                    let val = "User Not Found", val1 = "Approver Not Found"
                    let index = userList.findIndex((i) => { return i.id === k.user }); if (index !== -1) { val = userList[index].information.empname }
                    let index2 = userList.findIndex((i) => { return i.id === k.approver }); if (index2 !== -1) { val1 = userList[index2].information.empname }
                    if (k.user === selector.id) {
                        val = 'Admin'
                    } else if (k.approver === selector.id) {
                        val = 'Admin'

                    }
                    actual.push({ form: k.dcf.title, site: locationList.filter((i) => { return i.id === k.site[0] })[0].name, 'reporting period': k.rp, due_month: k.duedate, overdue: k.overdue, responsibility: val, status: 'Reporter not submitted/drafted' })

                }

            })
        }
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(actual);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, type);
        });
    };
    const updateApprovedFilter = (obj, val, type) => {
        let ser = search
        let data = JSON.parse(JSON.stringify(submitteddcf)).filter((i) => { return i.type === type })
        ser[obj] = val
        console.log(data)
        if (obj === 'search') {
            if (search.filter === 1) {
                setApList(data.filter((i) => { return i.dcfItem.title.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search.filter === 2) {

                setApList(data.filter((i) => { return i.site_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search.filter === 3) {
                setApList(data.filter((i) => { return i.submitted_by_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search.filter === 4) {
                setApList(data.filter((i) => { return i.approved_by_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search.filter === 0) {
                setApList(data.filter((i) => { return (getRP_(i.reporting_period).toLowerCase().includes(val.toLowerCase().trim()) || i.dcfItem.title.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.site_.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.submitted_by_.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.approved_by_.toLowerCase().trim().includes(val.toLowerCase().trim())) }))

            }


        }
        setSearch(ser)
        forceUpdate()
    }
    const updateSubmittedFilter = (obj, val, type) => {
        let ser = search2
        let data = JSON.parse(JSON.stringify(submitteddcf)).filter((i) => { return i.type === type })
        ser[obj] = val
        console.log(data)
        if (obj === 'search') {
            if (search2.filter === 1) {
                setDsList(data.filter((i) => { return i.dcfItem.title.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search2.filter === 2) {

                setDsList(data.filter((i) => { return i.site_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search2.filter === 3) {

                setDsList(data.filter((i) => { return i.submitted_by_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search2.filter === 4) {

                setDsList(data.filter((i) => { return getReviwerName(i.site, i.dcf).toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search2.filter === 0) {
                setDsList(data.filter((i) => { return (getRP_(i.reporting_period).toLowerCase().includes(val.toLowerCase().trim()) || i.dcfItem.title.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.site_.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.submitted_by_.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.approved_by_.toLowerCase().trim().includes(val.toLowerCase().trim())) }))

            }


        }
        setSearch2(ser)
        forceUpdate()
    }
    const updateDraftedFilter = (obj, val, type) => {
        let ser = search3
        let data = JSON.parse(JSON.stringify(submitteddcf)).filter((i) => { return i.type === type })
        ser[obj] = val
        console.log(data)
        if (obj === 'search') {
            if (search3.filter === 1) {
                setDdList(data.filter((i) => { return i.dcfItem.title.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search3.filter === 2) {

                setDdList(data.filter((i) => { return i.site_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search3.filter === 3) {

                setDdList(data.filter((i) => { return i.submitted_by_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search3.filter === 0) {
                setDdList(data.filter((i) => { return (getRP_(i.reporting_period).toLowerCase().includes(val.toLowerCase().trim()) || i.dcfItem.title.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.site_.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.submitted_by_.toLowerCase().trim().includes(val.toLowerCase().trim())) }))

            }


        }
        setSearch3(ser)
        forceUpdate()
    }
    const updateOverdueFilter = (obj, val) => {
        let ser = search4
        let data = JSON.parse(JSON.stringify(past))
        ser[obj] = val
        console.log(data)
        if (obj === 'search') {
            if (search4.filter === 1) {
                setOdList(data.filter((i) => { return i.dcf.title.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search4.filter === 2) {
                data.forEach((i) => {
                    console.log(locationList)
                    i.site_ = locationList.findIndex((l) => { return l.id === i.site[0] }) === -1 ? 'Site Not Found' : locationList.find((l) => { return l.id === i.site[0] }).name
                })
                setOdList(data.filter((i) => { return i.site_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search4.filter === 3) {
                let name = 'User Not Found'

                data.forEach((i) => {
                    let userIndex = userList.findIndex((l) => { return l.id === i.user })
                    if (i.draft === undefined) {
                        if (userIndex !== -1) {
                            i.user_ = userList[userIndex].information.empname
                        } else {
                            i.user_ = name
                        }
                    } else {
                        let userIndex = userList.findIndex((l) => { return l.id === i.user })
                        let approverIndex = userList.findIndex((l) => { return l.id === i.approver })
                        if (i.draft.type === 1) {
                            if (approverIndex !== -1) {
                                i.user_ = userList[approverIndex].information.empname
                            } else {
                                i.user_ = name
                            }

                        } else {
                            if (userIndex !== -1) {
                                i.user_ = userList[userIndex].information.empname
                            } else {
                                i.user_ = name
                            }
                        }
                    }
                })
                setOdList(data.filter((i) => { return i.user_.toLowerCase().trim().includes(val.toLowerCase().trim()) }))

            } else if (search4.filter === 0) {
                let name = 'User Not Found'
                data.forEach((i) => {
                    i.site_ = locationList.findIndex((l) => { return l.id === i.site[0] }) === -1 ? 'Site Not Found' : locationList.find((l) => { return l.id === i.site[0] }).name
                    let userIndex = userList.findIndex((l) => { return l.id === i.user })
                    if (i.draft === undefined) {
                        if (userIndex !== -1) {
                            i.user_ = userList[userIndex].information.empname
                        } else {
                            i.user_ = name
                        }
                    } else {
                        let userIndex = userList.findIndex((l) => { return l.id === i.user })
                        let approverIndex = userList.findIndex((l) => { return l.id === i.approver })
                        if (i.draft.type === 1) {
                            if (approverIndex !== -1) {
                                i.user_ = userList[approverIndex].information.empname
                            } else {
                                i.user_ = name
                            }

                        } else {
                            if (userIndex !== -1) {
                                i.user_ = userList[userIndex].information.empname
                            } else {
                                i.user_ = name
                            }
                        }
                    }
                })
                setOdList(data.filter((i) => { return (i.rp.toLowerCase().includes(val.toLowerCase().trim()) || i.dcf.title.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.site_.toLowerCase().trim().includes(val.toLowerCase().trim()) || i.user_.toLowerCase().trim().includes(val.toLowerCase().trim())) }))

            }


        }
        setSearch4(ser)
        forceUpdate()
    }
    const header1 = (
        <div className="flex align-items-center justify-content-between gap-2 ">
            <div className="col-10 grid flex justify-content-center align-items-center">
                <div className="col-6 grid">

                    <span className="p-input-icon-left" style={{ width: '75%' }}>
                        <i className="pi pi-search" />
                        <InputText value={search.search} onChange={(e) => { updateApprovedFilter('search', e.target.value, 3) }} style={{ width: '100%' }} placeholder="Search here by filter" />
                    </span>


                </div>
                <div className="flex  gap-3 col-6 align-items-center" style={{ padding: 10 }}>

                    <label className="col-4" style={{ display: 'flex' }} >Filter by :  </label>


                    <div className="flex align-items-center col-2" style={{ width: '75%' }}>
                        <Dropdown options={[{ name: 'All', id: 0 }, { name: 'DCF', id: 1 }, { name: 'Site', id: 2 }, { name: 'Reporter', id: 3 }, { name: 'Reviewer', id: 4 }]} style={{ width: '100%' }} optionLabel='name' optionValue="id" value={search.filter} onChange={(e) => { updateApprovedFilter('filter', e.value, 3) }} />
                    </div>


                </div>


            </div>
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={() => { exportExcel('approved_logs', 3) }} data-pr-tooltip="XLS" />

        </div>
    );
    const header2 = (
        <div className="flex align-items-center justify-content-between gap-2">
            <div className="col-10 grid flex justify-content-center align-items-center">
                <div className="col-6 grid">

                    <span className="p-input-icon-left" style={{ width: '75%' }}>
                        <i className="pi pi-search" />
                        <InputText value={search2.search} onChange={(e) => { updateSubmittedFilter('search', e.target.value, 1) }} style={{ width: '100%' }} placeholder="Search here by filter" />
                    </span>


                </div>
                <div className="flex  gap-3 col-6 align-items-center" style={{ padding: 10 }}>

                    <label className="col-4" style={{ display: 'flex' }} >Filter by :  </label>


                    <div className="flex align-items-center col-2" style={{ width: '75%' }}>
                        <Dropdown options={[{ name: 'All', id: 0 }, { name: 'DCF', id: 1 }, { name: 'Site', id: 2 }, { name: 'Reporter', id: 3 }, { name: 'Reviewer', id: 4 }]} style={{ width: '100%' }} optionLabel='name' optionValue="id" value={search2.filter} onChange={(e) => { updateSubmittedFilter('filter', e.value, 1) }} />
                    </div>


                </div>


            </div>
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={() => { exportExcel('submitted_logs', 1) }} data-pr-tooltip="XLS" />

        </div>
    );
    const header3 = (
        <div className="flex align-items-center justify-content-between gap-2">
            <div className="col-10 grid flex justify-content-center align-items-center">
                <div className="col-6 grid">

                    <span className="p-input-icon-left" style={{ width: '75%' }}>
                        <i className="pi pi-search" />
                        <InputText value={search3.search} onChange={(e) => { updateDraftedFilter('search', e.target.value, 0) }} style={{ width: '100%' }} placeholder="Search here by filter" />
                    </span>


                </div>
                <div className="flex  gap-3 col-6 align-items-center" style={{ padding: 10 }}>

                    <label className="col-4" style={{ display: 'flex' }} >Filter by :  </label>


                    <div className="flex align-items-center col-2" style={{ width: '75%' }}>
                        <Dropdown options={[{ name: 'All', id: 0 }, { name: 'DCF', id: 1 }, { name: 'Site', id: 2 }, { name: 'User', id: 3 }]} style={{ width: '100%' }} optionLabel='name' optionValue="id" value={search3.filter} onChange={(e) => { updateDraftedFilter('filter', e.value, 0) }} />
                    </div>


                </div>


            </div>
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={() => { exportExcel('drafted_logs', 0) }} data-pr-tooltip="XLS" />

        </div>
    );
    const header4 = (
        <div className="flex align-items-center justify-content-between gap-2">
            <div className="col-10 grid flex justify-content-center align-items-center">
                <div className="col-6 grid">

                    <span className="p-input-icon-left" style={{ width: '75%' }}>
                        <i className="pi pi-search" />
                        <InputText value={search4.search} onChange={(e) => { updateOverdueFilter('search', e.target.value, 0) }} style={{ width: '100%' }} placeholder="Search here by filter" />
                    </span>


                </div>
                <div className="flex  gap-3 col-6 align-items-center" style={{ padding: 10 }}>

                    <label className="col-4" style={{ display: 'flex' }} >Filter by :  </label>


                    <div className="flex align-items-center col-2" style={{ width: '70%' }}>
                        <Dropdown options={[{ name: 'All', id: 0 }, { name: 'DCF', id: 1 }, { name: 'Site', id: 2 }, { name: 'Responsibility', id: 3 }]} style={{ width: '100%' }} optionLabel='name' optionValue="id" value={search4.filter} onChange={(e) => { updateOverdueFilter('filter', e.value, 0) }} />
                    </div>


                </div>


            </div>
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={() => { exportExcel('internal_overdue') }} data-pr-tooltip="XLS" />

        </div>
    );
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    return (
        <div>
            <h4 style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>DCF Submission Status</h4>

            <div className="grid">
                {selector.role === "clientadmin" ? (
                    <>

                        {/* <div className="col-12 lg:col-6 xl:col-3" >
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3"> previous year </span>
                                        <div className="text-900 font-medium text-xl"> </div>
                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                        style={{
                                            width: "2.5rem",
                                            height: "2.5rem",
                                        }}
                                    >
                                        <i className="pi pi-calendar text-blue-500 text-xl" />
                                    </div>
                                </div>
                                <span className="text-green-500 font-medium"> 95 % </span>
                                <span className="text-500"> of data submitted </span>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-3">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3"> September </span> 
                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center bg-orange-100 border-round"
                                        style={{
                                            width: "2.5rem",
                                            height: "2.5rem",
                                        }}
                                    >
                                        <i className="pi pi-calendar-plus text-orange-500 text-xl" />
                                    </div>
                                </div>
                                <span className="text-green-500 font-medium"> 90 % </span>
                                <span className="text-500"> of data submitted </span>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-3">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3"> October </span> 
                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                        style={{
                                            width: "2.5rem",
                                            height: "2.5rem",
                                        }}
                                    >
                                        <i className="pi pi-calendar-plus text-cyan-500 text-xl" />
                                    </div>
                                </div>
                                <span className="text-green-500 font-medium"> 88 % </span> <span className="text-500"> of data submitted </span>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-3">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3"> November </span> 
                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center bg-purple-100 border-round"
                                        style={{
                                            width: "2.5rem",
                                            height: "2.5rem",
                                        }}
                                    >
                                        <i className="pi pi-calendar-plus text-purple-500 text-xl" />
                                    </div>
                                </div>
                                <span className="text-green-500 font-medium"> 85 % </span>
                                <span className="text-500"> of data submitted </span>
                            </div>
                        </div> */}
                        <div className="col-12 xl:col-12" id="divsize">

                        </div>
                        {selector.role === 'clientadmin' && <>
                            <div hidden className="col-12 sticky-div">
                                <div className="card">
                                    <div
                                        style={{
                                            marginBottom: 20,
                                        }}
                                    >
                                        <h5> Filter Section </h5>
                                    </div>
                                    <div className="grid">
                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <h6> Country </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.country}
                                                onChange={(e) => onFilterChanged("country", e.value)}
                                                options={countopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <h6> City </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.city}
                                                onChange={(e) => onFilterChanged("city", e.value)}
                                                options={cityopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <h6> Location </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.location}
                                                onChange={(e) => onFilterChanged("location", e.value)}
                                                options={locopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <h6> Indicator </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.indicator}
                                                onChange={(e) => onFilterChanged("indicator", e.value)}
                                                options={indiopt}
                                                optionLabel="title"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "10%",
                                            }}
                                        >
                                            <h6> Reporting From </h6>
                                            <Calendar id="range" style={{ width: '70%' }} value={filter.from} onChange={(e) => onFilterChanged('from', e.value)} view={'month'} dateFormat={' mm-yy'} />
                                            {/* maxDate={filter.to} */}
                                            {/* <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.year}
                                                onChange={(e) => onFilterChanged("year", e.value)}
                                                options={yearopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            /> */}
                                        </div>
                                        <div
                                            style={{
                                                width: "10%",
                                            }}
                                        >
                                            <h6> Reporting To </h6>
                                            <Calendar id="range" style={{ width: '70%' }} value={filter.to} onChange={(e) => onFilterChanged('to', e.value)} view={'month'} dateFormat={' mm-yy'} />
                                            {/* minDate={filter.from} */}
                                            {/* <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.month}
                                                onChange={(e) => onFilterChanged("month", e.value)}
                                                options={monthopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {show ?
                                <div className="col-12 ">
                                    <div>
                                        <div className="col-12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                            <div className='col-2' >
                                                <div style={{
                                                    height: 80,
                                                    padding: 5,
                                                    background: 'gainsboro',
                                                    borderRadius: '10px',
                                                    flexDirection: 'column',
                                                    display: 'flex',
                                                    color: 'black',
                                                    border: selected === 0 ? '3px solid blue' : 'none'

                                                }} onClick={() => { setSelected(0) }}>

                                                    <label style={{ margin: 5 }}>Approved Logs</label>
                                                    <label style={{ margin: 5 }}>{submitteddcf.filter((i) => { return i.type === 3 }).length}</label>
                                                </div>
                                            </div>
                                            <div className='col-2' >
                                                <div style={{
                                                    height: 80,
                                                    padding: 5,
                                                    background: 'gainsboro',
                                                    borderRadius: '10px',
                                                    flexDirection: 'column',
                                                    display: 'flex',
                                                    color: 'black',
                                                    border: selected === 1 ? '3px solid blue' : 'none'
                                                }} onClick={() => { setSelected(1) }}>
                                                    <label style={{ margin: 5 }}>Data Submitted & Not Approved</label>
                                                    <label style={{ margin: 5 }}>{submitteddcf.filter((i) => { return i.type === 1 }).length}</label>
                                                </div>
                                            </div>
                                            <div className='col-2' >
                                                <div style={{
                                                    height: 80,
                                                    padding: 5,
                                                    background: 'gainsboro',
                                                    borderRadius: '10px',
                                                    flexDirection: 'column',
                                                    display: 'flex',
                                                    color: 'black',
                                                    border: selected === 2 ? '3px solid blue' : 'none'
                                                }} onClick={() => { setSelected(2) }}>
                                                    <label style={{ margin: 5 }}>Data Drafted & Not Submitted</label>
                                                    <label style={{ margin: 5 }}>{submitteddcf.filter((i) => { return i.type === 0 }).length}</label>
                                                </div>
                                            </div>
                                            <div className='col-2' >
                                                <div style={{
                                                    height: 80,
                                                    padding: 5,
                                                    background: 'gainsboro',
                                                    borderRadius: '10px',
                                                    flexDirection: 'column',
                                                    display: 'flex',
                                                    color: 'black',
                                                    border: selected === 3 ? '3px solid blue' : 'none'
                                                }} onClick={() => { setSelected(3) }}>
                                                    <label style={{ margin: 5 }}>Overdue Internal Submissions</label>
                                                    <label style={{ margin: 5 }}>{past.length}</label>
                                                </div>
                                            </div>
                                            <div className='col-2' >
                                                <div style={{
                                                    height: 80,
                                                    padding: 5,
                                                    background: 'gainsboro',
                                                    borderRadius: '10px',
                                                    flexDirection: 'column',
                                                    display: 'flex',
                                                    color: 'black',
                                                    border: selected === 4 ? '3px solid blue' : 'none'
                                                }} onClick={() => { setSelected(4) }}>
                                                    <label style={{ margin: 5 }}>Overdue Supplier Submissions</label>
                                                    <label style={{ margin: 5 }}>{0}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">

                                        {selected === 0 && <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 20 }}>

                                            {renderApproved()}
                                        </div>}
                                        {selected === 1 && <div className="col-12" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                                            {renderSubmitted()}
                                        </div>}
                                        {selected === 2 && <div className="col-12" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                                            {renderDrafted()}
                                        </div>}
                                        {selected === 3 && <div className="col-12" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                                            {renderInternalSubmitted()}
                                        </div>}
                                        {selected === 4 && <div className="col-12" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                                            {renderSupplierSubmitted()}
                                        </div>}

                                    </div>
                                </div>
                                :
                                <div style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginTop: '50px',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}>
                                    <i className="pi pi-spin pi-spinner" style={{ fontSize: 50 }}></i>

                                </div>
                            }
                        </>
                        }



                    </>
                ) : (
                    <div className="col-12 card">You have no rights to access this page </div>
                )}
            </div>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"

                onHide={() => { setAttDialog(false) }}
            >
                <div>
                    {attachment.map((i, index) => {

                        return (<>
                            <div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", marginTop: 20
                                    }}
                                >
                                    <label style={{ width: '80%' }}>{i.name}</label>
                                    <span
                                        style={{
                                            display: "flex", width: '20%',
                                            flexDirection: "row", justifyContent: 'center',
                                            color: "green", cursor: 'pointer',
                                            alignItems: "center",
                                        }}
                                        onClick={(e) => {
                                            window.open(i.url);
                                        }}
                                    >
                                        <i className="material-icons" > visibility </i>

                                    </span>

                                </div>
                            </div>
                        </>)
                    })

                    }
                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(TableReportDashboard, comparisonFn);
