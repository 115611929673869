import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../../../constants/api_url";
import { InputNumber } from "primereact/inputnumber";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";

import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { Slider } from "primereact/slider";

import APIServices from "../../../../service/APIService";

import { DateTime } from "luxon";
import { Sticky, StickyContainer } from "react-sticky";

window.jQuery = $;
window.$ = $;

const TVSSupplierQuestionary = () => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail)
    const supplierList = useSelector(state => state.userlist.supplierList)
    const [assignment, setAssignment] = useState({ title: '' })
    const [submission, setSubmission] = useState({ id: null })
    const [supplierassignment, setSupplierAssignment] = useState({})
    const [data, setData] = useState({ data1: [], title: '', id: null })
    const [show, setShow] = useState(null)
    const [msg, setMsg] = useState('loading...')
    const [submisisonStatus, setSubmissionStatus] = useState(false)
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();


    const userList = useSelector(state => state.userlist.userList)
    const [ass, setAss] = useState([])
    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188', '195', '196','245']
    useEffect(async () => {
        APIServices.post('http://localhost:3005/check-supplier-assessment-assignment/' + id, {}).then((res) => {
            console.log(res.data)
            if (res.data.status) {
                setShow(res.data.submitted)
                console.log(res.data.data1)
                setData(res.data.data)
                setAssignment(res.data.assignment)
                setSupplierAssignment(res.data.supplierAssignment)
                if (res.data.submission) {
                    setSubmission(res.data.submission)

                }
            } else {
                setMsg(res.data.msg)
            }
        })

    }, [login_data]);

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }

    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-4 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i>} </label>
                    <div className="col-4">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox disabled={show} inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>
                    <div className="col-2 fs-14 fw-4" >
                        <InputText disabled={show} style={{ width: '100%' }} value={item.comments} placeholder={'comments'}  onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-4 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <Calendar disabled={show} placeholder={item.placeholder} className="col-4 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                    <div className="col-2 fs-14 fw-4" >
                        <InputText disabled={show} style={{ width: '100%' }} value={item.comments} placeholder={'comments'}  onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-4 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-4 fs-14 fw-4" >
                        <InputNumber disabled={show} min={0} placeholder={item.placeholder} maxFractionDigits={(item.fraction === undefined || item.fraction) ? 0 : item.fraction < 0 ? 0 : item.fraction} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.value) }} />

                    </div>
                    <div className="col-2 fs-14 fw-4" >
                        <InputText disabled={show} style={{ width: '100%' }} value={item.comments} placeholder={'comments'}  onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>} </label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-4 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-4 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton disabled={show} inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>
                    <div className="col-2 fs-14 fw-4" >
                        <InputText disabled={show} style={{ width: '100%' }} value={item.comments} placeholder={'comments'}  onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-4 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>


                    <div className="col-4 fw-4 fs-14">
                        <Dropdown disabled={show} placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>
                    <div className="col-2 fs-14 fw-4" >
                        <InputText disabled={show} style={{ width: '100%' }} value={item.comments} placeholder={'comments'}  onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-4 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-4 fs-14 fw-4" >
                        <InputText disabled={show} style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                    <div className="col-2 fs-14 fw-4" >
                        <InputText disabled={show} style={{ width: '100%' }} value={item.comments} placeholder={'comments'}  onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-4 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}

                        > help</i></span>} </label>
                    <div className="col-4 " >
                        <Editor disabled={show} className="text-area col-5" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>
                    <div className="col-2 fs-14 fw-4" >
                        <InputText disabled={show} style={{ width: '100%' }} value={item.comments} placeholder={'comments'}  onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>

                </div>
            )
        }

    }


    const checkResponse = () => {
        let result = 0
        let fields = ['text', 'textarea', 'select', 'radio-group', 'checkbox-group', 'date', 'number']
        let total = data.data1.filter((i) => { return i.required === true && fields.includes(i.type) }).length
        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && item.value !== null && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined && item.value !== null) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.value) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }



        })
        console.log(total, result)

        return result === total
    }

    const saveDCFAsDraft = async () => {
        let newObj = {}
        let dt = DateTime.utc()



        newObj['response'] = data.data1
        if (submission.id) {
            newObj['type'] = 0
            newObj['modified_by'] = login_data.id
            newObj['modified_on'] = DateTime.utc()
            APIServices.patch(API.SupplierAssessmentSubmission_Edit(submission.id), newObj).then((res) => {



                Swal.fire({
                    title: "Your Responses Have Been Recorded & Saved as Draft",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        } else {
            newObj['srfId'] = assignment.srfId
            newObj['assignmentId'] = assignment.id
            newObj['created_by'] = supplierassignment.supplierId
            newObj['created_on'] = DateTime.utc()
            newObj['supplierId'] = supplierassignment.supplierId
            newObj['type'] = 0
            APIServices.post(API.SupplierAssessmentSubmission(id), newObj).then((res) => {



                Swal.fire({
                    title: "Your Responses Have Been Recorded & Saved as Draft",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        }





        forceUpdate()

    }
    const saveDCF = async () => {
        let newObj = {}
        let dt = DateTime.utc()

        if (checkResponse()) {


            newObj['response'] = data.data1
            if (submission.id) {
                newObj['type'] = 1
                newObj['modified_by'] = login_data.id
                newObj['modified_on'] = DateTime.utc()
                APIServices.patch(API.SupplierAssessmentSubmission_Edit(submission.id), newObj).then((res) => {



                    Swal.fire({
                        title: "Thanks, Your Responses Have Been Recorded & Sent To Respective Auditor",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })
            } else {
                newObj['srfId'] = assignment.srfId
                newObj['assignmentId'] = assignment.id
                newObj['created_by'] = supplierassignment.supplierId
                newObj['created_on'] = DateTime.utc()
                newObj['supplierId'] = supplierassignment.supplierId
                newObj['type'] = 1
                APIServices.post(API.SupplierAssessmentSubmission(id), newObj).then((res) => {



                    Swal.fire({
                        title: "Thanks, Your Responses Have Been Recorded & Sent To Respective Auditor",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })
            }




        }
        forceUpdate()

    }



    return (
        <div className="col-12 font-lato" style={{ margin: 10 }}>
            <StickyContainer>
                <div className="col-12">
                    {(data.data1.length !== 0) ?
                        <div >
                            <Sticky topOffset={0}>
                                {({ style, isSticky }) => (<div style={{ ...style, top: 98, background: isSticky && 'white', zIndex: 100 }}>
                                    <div className="fs-20 fw-7 clr-gray-900">
                                        <h4><span className="mr-2">{'DCF ' + data.id}</span> {data.title}</h4>
                                    </div>


                                    <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }} >
                                        <div className="grid justify-content-between col-12">
                                            <div className="col-4 fs-16" style={{ flexDirection: 'row' }}>         <span className="clr-gray-3 fw-4">Assessment Name:&nbsp;</span> <span className="clr-gray-900 fw-7">{assignment.title}   </span>  </div>
                                        </div>

                                    </div>
                                </div>)}
                            </Sticky>

                            {(data.data1.length !== 0) ?
                                <div>
                                    <div className="bg-white" style={{ borderRadius: 4 }}>

                                        <div style={{ padding: 24 }}>
                                            {data.data1.map((item, index) => {

                                                return renderItems(item, index)
                                            })

                                            }

                                        </div>


                                    </div>
                                    <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                        <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                            <Button label='Close' className="ml-4" onClick={() => { window.close() }} text ></Button>
                                            {!show && <Button label='Save As Draft' onClick={() => { saveDCFAsDraft() }} className="ml-4" ></Button>}

                                            {!show && <Button label='Submit Response' onClick={() => { saveDCF() }} className="ml-4" ></Button>}

                                        </div>
                                    </div>

                                </div>

                                :
                                <div className="col-12 card">{msg}</div>
                                // <div className="col-12 card">You have no rights to access this page </div>

                            }
                        </div> :
                        <div className="col-12 card">You have no rights to access this page </div>}
                </div>
            </StickyContainer>
        </div>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TVSSupplierQuestionary, comparisonFn);
