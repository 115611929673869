import React, { useState, useEffect } from "react";

import { Tab, Row, Col, Nav, Table, Form, Modal, Accordion, Card } from 'react-bootstrap';

// import {_utilities} from '../SupplierScreen';
import  '../SupplierScreen/_utilities.scss';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import Swal from "sweetalert2";
import moment from 'moment'
import questionary from '../SupplierScreen/test'
import { InputTextarea } from 'primereact/inputtextarea'




const AuditPanel = ({ readOnly, auditId, setAuditModal }) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const [headerValues, setHeaderValues] = useState({});
    const [files, setFiles] = useState([]);
    const [activeQuestionId, setActiveQuestionId] = useState(null);
    const [commentText, setCommentText] = useState('');

    //    const [audit, setAudit] = useState({
    //     auditName: 'Sample Audit',
    //     auditDate: moment().format('YYYY-MM-DD'),
    //     // Add any other default fields that your audit object might need
    // });

    const [text, setText] = useState('')
    const [findings, setFindings] = useState('')

    //const [gmsOne, setGmsOne] = useState([])
    const [allGmsTwo, setAllGmsTwo] = useState([])
    // const [gmsTwo, setGmsTwo] = useState([])
    const [allGmsThree, setAllGmsThree] = useState([])
    //const [gmsThree, setGmsThree] = useState([])

    //const [selectedGmsOne, setSelectedGmsOne] = useState('')
    //const [selectedGmsTwo, setSelectedGmsTwo] = useState('')
    const [selectedGmsThree, setSelectedGmsThree] = useState('')


    const [questionaries, setQuestionaries] = useState(questionary)
    const [gmsOne, setGmsOne] = useState([
        { id: 1, name: 'Supplier Environmental Framework' },
        { id: 2, name: 'Supplier Sustainability Ambassadorship Framework' },
        { id: 3, name: 'Supplier Social Stewardship Framework' },
        { id: 4, name: 'Supplier Occupational Health & Safety Framework' },
        { id: 5, name: 'Legal Compliances' },
        { id: 6, name: 'Supplier Governance Framework' },
    ]);
    console.log(questionary)
    // Mock data for GMS Two for each tab in GMS One
    const gmsTwoOptions = [

        { id: 1, name: 'Water', sectionId: 1 },
        { id: 2, name: 'Waste', sectionId: 1 },
        { id: 3, name: 'Energy', sectionId: 1 },
        { id: 4, name: 'Product Stewardship', sectionId: 1 },
        { id: 5, name: 'Water', sectionId: 2 },
        { id: 6, name: 'Energy', sectionId: 2 },
        { id: 7, name: 'General', sectionId: 2 },
        { id: 8, name: 'Waste', sectionId: 2 },
        { id: 9, name: 'Road Safety', sectionId: 2 },
        { id: 10, name: 'BioDiversity', sectionId: 2 },

        { id: 11, name: 'Child Labour', sectionId: 3 },
        { id: 12, name: 'Disciplinary Practices', sectionId: 3 },
        { id: 13, name: 'Discrimination', sectionId: 3 },
        { id: 14, name: 'Freedom of Association', sectionId: 3 },
        { id: 15, name: 'Forced or Compulsary Labour', sectionId: 3 },
        { id: 16, name: 'Remuneration', sectionId: 3 },
        { id: 17, name: 'Working Hours', sectionId: 3 },
        { id: 18, name: 'Maching guarding & controls', sectionId: 4 },
        { id: 19, name: 'Contractor safety & Permit To Work', sectionId: 4 },
        { id: 20, name: 'Road safety', sectionId: 4 },
        { id: 21, name: 'Electricals, Fire safety & Emergency Preparedness', sectionId: 4 },
        { id: 22, name: 'General', sectionId: 5 },
        { id: 23, name: 'Fire Protection', sectionId: 5 },
        { id: 24, name: 'Electrical Installations', sectionId: 5 },
        { id: 25, name: 'General', sectionId: 6 },
        // Add more options for other tabs...
    ];

    const sectionTwo = [
        { id: 1, name: 'Monitoring Water Consumption', subTopic: 1 },
        { id: 2, name: 'ETP Operation & Maintainence', subTopic: 1 },
        { id: 3, name: 'STP Operation & Maintainence', subTopic: 1 },
        { id: 4, name: 'Water Stewardship Playbook', subTopic: 1 },
        { id: 5, name: 'Water Neutrality', subTopic: 1 },
        { id: 6, name: 'Zero Liquid Discharge Status', subTopic: 1 },
        { id: 7, name: 'Hazardous Waste', subTopic: 2 },
        { id: 8, name: 'E- Waste', subTopic: 2 },
        { id: 9, name: 'Bio-Medical Waste', subTopic: 2 },
        { id: 10, name: 'Plastic Waste', subTopic: 2 },
        { id: 11, name: 'Battery Waste', subTopic: 2 },
        { id: 12, name: 'Zero Waste to Landfill', subTopic: 2 },
        { id: 13, name: 'Waste Management Playbook', subTopic: 2 },
        { id: 14, name: 'Waste Intensity', subTopic: 2 },
        { id: 15, name: 'Energy Monitoring', subTopic: 3 },
        { id: 16, name: 'Renewable Energy', subTopic: 3 },
        { id: 17, name: 'Energy Excellence Playbook', subTopic: 3 },
        { id: 18, name: 'Carbon Foot print Scope 1 & Scope 2 Reduction Plan', subTopic: 3 },
        { id: 19, name: 'Scope 3: Mapping ', subTopic: 3 },
        { id: 20, name: 'Scope 3: Reduction Plan ', subTopic: 3 },
        { id: 21, name: 'Projects towards EP 100', subTopic: 3 },
        { id: 22, name: 'Lifecycle Assessments', subTopic: 4 },
        { id: 23, name: 'Product Stewardship Playbook', subTopic: 4 },
        { id: 24, name: 'Recyclability', subTopic: 4 },
        { id: 25, name: 'Recoverability', subTopic: 4 },
        { id: 26, name: 'Zero Hazardous Waste ', subTopic: 4 },
        { id: 27, name: 'Meet Line & Lot Rejection', subTopic: 4 },
        { id: 28, name: 'Use of Alternate Materials ', subTopic: 4 },
        { id: 29, name: 'Alternate to Plastic in Packaging', subTopic: 4 },
        { id: 30, name: 'Eco-Labelling', subTopic: 4 },
        { id: 31, name: 'Product Recalls', subTopic: 4 },
    ]

    const [selectedGmsOne, setSelectedGmsOne] = useState('');
    const [selectedGmsTwo, setSelectedGmsTwo] = useState(null);
    const [expandedGmsTwo, setExpandedGmsTwo] = useState(null);
    const [activeId, setActiveId] = useState(1); // State to hold the active framework ID
    const [labelColor, setLabelColor] = useState("#007bff"); // Default label color (black)

    const handleFileChange = (file) => {
        console.log(file)
        setFiles(file)

    }

    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleSaveComment = () => {
        console.log('Comment saved:', comment);
        setShowModal(false);
    };


    // Filter GMS Two Options by Selected GMS One
    const filteredGmsTwoOptions = gmsTwoOptions.filter(gms2 => gms2.sectionId === selectedGmsOne);

    // Filter Subtopics based on GMS Two ID
    const getSubTopicsForGmsTwo = (gmsTwoId) => {
        return sectionTwo.filter(sub => sub.subTopic === gmsTwoId);
    };

    const getQuestionsForSubTopic = (subSectionId, subTopicId = null) => {
        return questionaries.filter(question => {
            // If subTopicId is provided, match both subSectionId and subTopicId
            // Otherwise, match only subSectionId
            return question.subSectionId === subSectionId &&
                (subTopicId ? question.subTopicId === subTopicId : true);
        });
    };

    const handleClose = () => setAuditModal(false);



    const steps = gmsOne.map((gms, index) => ({
        label: gms.name,
        status: index === activeIndex ? 1 : (index < activeIndex ? 1 : 0), // Set status based on active index
    }));

    useEffect(() => {
        const initialId = gmsOne[0].id; // Get the ID of the first step
        setSelectedGmsOne(initialId); // Set selectedGmsOne to the first step
    }, [gmsOne]); // Dependency array to run effect when gmsOne changes

    useEffect(() => {
        // Reset or update related states here based on active step
        setSelectedGmsTwo(null);
        setExpandedGmsTwo(null);
    }, [activeIndex]);

    
    

    const handleStepClick = (index) => {
        setActiveIndex(index);
        const activeId = gmsOne[index].id;
        setActiveId(activeId); // Update the active framework ID
        const selectedStep = steps[index];
        setSelectedGmsOne(activeId);
        console.log("selectedGmsOne :", selectedGmsOne);
        setSelectedGmsTwo(null);
        setExpandedGmsTwo(null);
        setLabelColor("#007bff");
    };

    const [expandedItems, setExpandedItems] = useState([]); // Track expanded items
    const [expandAll, setExpandAll] = useState(false); // State to toggle expand all items

    const toggleExpandAll = () => {
        if (expandedItems.length === gmsTwoOptions.length) {
            // Collapse all if they are already expanded
            setExpandedItems([]);
        } else {
            // Expand all items and set the selectedGmsTwo to the first subtopic of the first gmsTwoOption
            const allIds = gmsTwoOptions.map(gms2 => gms2.id);
            setExpandedItems(allIds);
            const firstSubTopic = getSubTopicsForGmsTwo(allIds[0])[0]; // Get the first subtopic of the first expanded item
            if (firstSubTopic) {
                setSelectedGmsTwo(firstSubTopic.id);
            }
        }
    };

    const handleSubtopicSelect = (subtopicId) => {
        setSelectedGmsTwo(subtopicId);
    };

    const handleToggleItem = (itemId) => {
        setExpandedItems((prev) =>
            prev.includes(itemId) ? prev.filter(id => id !== itemId) : [...prev, itemId]
        );
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                {auditId && (
                    <div>
                        <p><strong>Audit ID:</strong> {auditId.id}</p>
                    </div>
                )}
                <Button variant="light" onClick={handleClose}>
                    Close
                </Button>
            </div>

            <div className="row mb-3" style={{ padding: '10px' }}>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Audit Start Date</p>
                    <p className="obs-content">
                        17 Jan 2024
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Audit End Date</p>
                    <p className="obs-content">
                        18 Jan 2024
                    </p>
                </div>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier Name</p>
                    <p className="obs-content">
                        New Vendor
                    </p>

                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier Category</p>

                    <p className="obs-content">
                        Automobiles
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Vendor Code</p>
                    <p className="obs-content"> 1003
                    </p>


                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier Location</p>  
                    <p className="obs-content"> Chennai, India</p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier To Location</p>
                    <p className="obs-content">
                        Hyderabad, India
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Audit Team</p>
                    <p className="obs-content">
                        Vincent, Kalpesh
                    </p>
                </div>
            </div>


            <Tab.Container id="left-tabs-example" className="mt-3 audit-tab" activeKey={selectedGmsOne}>
                <Row style={{ marginTop: 20 }}>
                    <div style={{ width: '800px', margin: '0 auto' }}>
                        <Stepper
                            steps={steps}
                            onClickStep={handleStepClick}
                            activeBGColor="green"
                            labelColor={labelColor}
                            activeIndex={activeIndex + 1}
                        />
                    </div>
                    <Col sm={12}>
                    <div className='flex justify-content-end'>
                            <Button text onClick={toggleExpandAll} >
                                {/* {expandAll ? 'Collapse All' : 'Expand All'} */}
                                {expandedItems.length === gmsTwoOptions.length ? 'Collapse All' : 'Expand All'}
                            </Button>
                        </div>
                   
                        <Tab.Content>
                            {gmsOne.map(gms => (
                                <Tab.Pane eventKey={gms.id} key={gms.id}>
                                    <label style={{fontSize:16,fontWeight:'bold'}}>{gms.name}</label>
                                    <Accordion activeKey={expandedItems}>
                                        {gmsTwoOptions.filter(gms2 => gms2.sectionId === gms.id).map(gms2 => {
                                            const subTopics = getSubTopicsForGmsTwo(gms2.id);
                                            return (
                                                <Accordion.Item eventKey={gms2.id} key={gms2.id}>
                                                    {/* <Accordion.Header onClick={() => setExpandedGmsTwo(expandedGmsTwo === gms2.id ? null : gms2.id)}> */}
                                                    <Accordion.Header onClick={() => {
                                            setExpandedItems(prev =>
                                                prev.includes(gms2.id)
                                                    ? prev.filter(id => id !== gms2.id) // Collapse this item
                                                    : [...prev, gms2.id] // Expand this item
                                            );
                                        }}>
                                                        <div className="fs-14 fw-4">{gms2.name}</div>
                                                    </Accordion.Header>

                                                    <Accordion.Body>
                                                        {/* {expandedGmsTwo === gms2.id && subTopics.length > 0 ? ( */}
                                                        {expandedItems.includes(gms2.id) && subTopics.length > 0 ? (
                                                            <>
                                                                <Nav variant="pills" className="flex-row custom-nav">
                                                                    {subTopics.map(subtopic => (
                                                                        <Nav.Item key={subtopic.id}>
                                                                            {/* <Nav.Link eventKey={subtopic.id} onClick={() => setSelectedGmsTwo(subtopic.id)}> */}
                                                                            <Nav.Link eventKey={subtopic.id} active={selectedGmsTwo === subtopic.id} onClick={() => handleSubtopicSelect(subtopic.id)}>
                                                                                {subtopic.name}
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    ))}
                                                                </Nav>

                                                                {selectedGmsTwo && (
                                                                    <div className="row">
                                                                        {/* Display headings only once */}
                                                                        {/* <div className="d-flex justify-content-between" style={{background:'#F0F2F4'}}>
                                                                            <div className="col-6">
                                                                                <h5>Your Response</h5>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <h5>Supplier's Response</h5>
                                                                            </div>
                                                                        </div> */}

<div className="d-flex justify-content-between" style={{ background: '#F0F2F4', height: '100%' }}>
    <div className="col-6 d-flex align-items-center h-100">
        <h5 className="m-0">Your Response</h5>
    </div>
    <div className="col-6 d-flex align-items-center h-100">
        <h5 className="m-0">Supplier's Response</h5>
    </div>
</div>
                                                                        <hr />

                                                                        {/* Questions */}
                                                                        {getQuestionsForSubTopic(gms2.id, selectedGmsTwo).map(question => (
                                                                            <div key={question.id}>
                                                                                <div className="d-flex justify-content-between">
                                                                                    {/* Your Response */}
                                                                                    <div className="col-6">
                                                                                        <p style={{ color: '#374151' }}>{question.label}</p>
                                                                                        {question.type === 'select' ? (
                                                                                            question.values.some(option => option.label === "Yes") &&
                                                                                                question.values.some(option => option.label === "No") &&
                                                                                                question.values.some(option => option.label === "NA") ? (
                                                                                                <div className="radioStyle">
                                                                                                    <label>
                                                                                                        <input type="radio" name={`question-${question.name}`} value="yes" />
                                                                                                        Yes
                                                                                                    </label>
                                                                                                    <label style={{ marginLeft: '10px' }}>
                                                                                                        <input type="radio" name={`question-${question.name}`} value="no" />
                                                                                                        No
                                                                                                    </label>
                                                                                                    <label style={{ marginLeft: '10px' }}>
                                                                                                        <input type="radio" name={`question-${question.name}`} value="na" />
                                                                                                        NA
                                                                                                    </label>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <select className="form-control">
                                                                                                    <option value="">Select an option</option>
                                                                                                    {question.values.map(option => (
                                                                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            )
                                                                                        ) : question.type === 'textarea' ? (
                                                                                            <textarea
                                                                                                className="form-control"
                                                                                                rows="4"
                                                                                                style={{ width: '100%', resize: 'none' }}
                                                                                                placeholder="Enter your response here"
                                                                                            />
                                                                                        ) : null}
                                                                                        <div style={{ color: '#315975', marginTop: '20px' }} onClick={handleOpenModal}>
                                                                                            <i className="pi pi-comment" style={{ marginRight: '5px', cursor: 'pointer' }}></i>
                                                                                            <span>Add Comment</span>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Supplier's Response */}
                                                                                    <div className="col-6">
                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                            <p>YES</p>
                                                                                            <span>Submitted on: 13-06-2023</span>
                                                                                        </div>
                                                                                        <p>Expedita corrupti veniam incidunt molestiae. Sint esse et sed quae commodi a ab. Blanditiis aut voluptatem quidem nihil sint eos. Deleniti molestiae sit aut dolores. Saepe perferendis repellat consequatur sit.</p>
                                                                                    </div>
                                                                                </div>
                                                                                {/* Line separator after each question */}
                                                                                <hr className="p-1 m-0" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div className="row">
                                                                {/* <div className="d-flex justify-content-between" style={{background:'#F0F2F4'}}>
                                                                    <div className="col-6">
                                                                        <h5>Your Response</h5>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>Supplier's Response</h5>
                                                                    </div>
                                                                </div> */}

<div className="d-flex justify-content-between" style={{ background: '#F0F2F4', height: '100%' }}>
    <div className="col-6 d-flex align-items-center h-100">
        <h5 className="m-0">Your Response</h5>
    </div>
    <div className="col-6 d-flex align-items-center h-100">
        <h5 className="m-0">Supplier's Response</h5>
    </div>
</div>

                                                                <hr />

                                                                {getQuestionsForSubTopic(gms2.id).map(question => (
                                                                    <div key={question.id}>
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="col-6">
                                                                                <p>{question.label}</p>
                                                                                {question.type === 'select' ? (
                                                                                    question.values.some(option => option.label === "Yes") &&
                                                                                        question.values.some(option => option.label === "No") &&
                                                                                        question.values.some(option => option.label === "NA") ? (
                                                                                        <div className="radioStyle">
                                                                                            <label>
                                                                                                <input type="radio" name={`question-${question.name}`} value="yes" />
                                                                                                Yes
                                                                                            </label>
                                                                                            <label style={{ marginLeft: '10px' }}>
                                                                                                <input type="radio" name={`question-${question.name}`} value="no" />
                                                                                                No
                                                                                            </label>
                                                                                            <label style={{ marginLeft: '10px' }}>
                                                                                                <input type="radio" name={`question-${question.name}`} value="na" />
                                                                                                NA
                                                                                            </label>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <select className="form-control">
                                                                                            <option value="">Select an option</option>
                                                                                            {question.values.map(option => (
                                                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    )
                                                                                ) : question.type === 'textarea' ? (
                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        rows="4"
                                                                                        style={{ width: '100%', resize: 'none' }}
                                                                                        placeholder="Enter your response here"
                                                                                    />
                                                                                ) : null}
                                                                                <div style={{ color: '#315975', marginTop: '20px' }} onClick={handleOpenModal}>
                                                                                    <i className="pi pi-comment" style={{ marginRight: '5px', cursor: 'pointer' }}></i>
                                                                                    <span>Add Comment</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-6">
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <p>YES</p>
                                                                                    <span>Submitted on: 13-06-2023</span>
                                                                                </div>
                                                                                <p>Expedita corrupti veniam incidunt molestiae. Sint esse et sed quae commodi a ab. Blanditiis aut voluptatem quidem nihil sint eos. Deleniti molestiae sit aut dolores. Saepe perferendis repellat consequatur sit.</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="p-1 m-0" />
                                                                    </div>
                                                                ))}

<Modal show={showModal} onHide={handleCloseModal} >
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Add Comment</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <textarea
                                                                            value={comment}
                                                                            onChange={(e) => setComment(e.target.value)}
                                                                            rows={5}
                                                                            style={{
                                                                                width: '100%',
                                                                                borderRadius: '5px',
                                                                                padding: '10px',
                                                                                border: '1px solid #ced4da'
                                                                            }}
                                                                            placeholder="Enter your comment here..."
                                                                        />
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="primary" onClick={handleSaveComment}>
                                                                            Save
                                                                        </Button>
                                                                        <Button variant="secondary" onClick={handleCloseModal}>
                                                                            Close
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </div>
                                                        )}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            );

                                        })}
                                    </Accordion>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>

                        <div style={{display:'flex',float:'right',marginTop:'20px'}}>
                            <Button style={{marginRight:'10px'}}>
                                Save Section
                            </Button>
                            <Button>
                                Submit Audit Report
                            </Button>

                        </div>

                    </Col>
                </Row>
            </Tab.Container>
        </>
    );





};

const Stepper = ({ steps, onClickStep, labelColor = 'black', activeBGColor = '#6c757d', activeIndex = 0 }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <div style={{ position: 'relative' }}>
                        <div
                            onClick={() => onClickStep && onClickStep(index)}
                            style={{
                                display: 'flex',
                                position: 'relative',
                                flexDirection: 'column',
                                zIndex: 1,
                                alignItems: 'center',
                                cursor: onClickStep ? 'pointer' : 'default',
                                textAlign: 'center',
                                width: '170px', // Fixed width to keep all steps aligned
                            }}
                        >
                            <div
                                style={{
                                    //backgroundColor: (step.status === 1 ? activeBGColor : step.status === 0 ? 'rgb(255, 165, 0)' : 'gray'),
                                    backgroundColor: 'gray',
                                    color: '#fff',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    transition: 'background-color 0.3s ease', // Smooth color transition
                                }}
                            >
                                {index + 1} {/* Display step number starting from 1 */}
                            </div>
                            <div
                                style={{
                                    fontSize: 12,
                                    marginTop: '8px',
                                    fontWeight: activeIndex === index + 1 ? 700 : 'normal', // Adjusted for 1-based index
                                    color: activeIndex === index + 1 ? labelColor : 'black',
                                    textDecoration: activeIndex === index + 1 ? 'underline' : 'none',
                                }}
                            >
                                {step.label}
                            </div>
                        </div>

                        {/* Add line between steps, except for the last step */}
                        {index < steps.length - 1 && (
                            <hr
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    left: '50%',
                                    top: '10px',
                                    width: '100%',
                                    position: 'absolute',
                                    zIndex: 0,
                                }}
                            />
                        )}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default AuditPanel;