import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import { CustomLegend } from "./Dashboard";

const data = [
  {
    year: "2018",
    category1: 0.7,
    category11: 10,
    category12: 81.3,
    total: 92.0,
  },
  {
    year: "2019",
    category1: 0.7,
    category11: 9.8,
    category12: 80.5,
    total: 91.0,
  },
  {
    year: "2020",
    category1: 0.6,
    category11: 9.3,
    category12: 78.4,
    total: 88.3,
  },
  {
    year: "2021",
    category1: 0.6,
    category11: 8.5,
    category12: 62.6,
    total: 71.7,
  },
  {
    year: "2022",
    category1: 0.5,
    category11: 8.3,
    category12: 61.6,
    total: 70.4,
  },
  {
    year: "2023",
    category1: 0.6,
    category11: 8.0,
    category12: 52.2,
    total: 60.8,
  },
];

const OverviewScope3 = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Scope 3 Emissions
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Indirect emissions occur due to an organization’s activities, but
            from sources it does not own or directly control.
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              barSize={60}
            >
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "MtCO2e",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend content={CustomLegend} />

              {/* Stacked Bars */}
              <Bar
                dataKey="category1"
                stackId="a"
                fill="#00008B"
                name="Category 1 - Purchase of Raw Materials and Services"
              />
              <Bar
                dataKey="category11"
                stackId="a"
                fill="#ADD8E6"
                name="Category 11 - Use of Solid Products"
              />
              <Bar
                dataKey="category12"
                stackId="a"
                fill="#003f5c"
                name="Category 12 - Final disposal of solid products"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data} tableClassName="font-lato">
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Category 1 (MtCO2e)"
              style={{ minWidth: "8%" }}
              field="category1"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Category 11 (MtCO2e)"
              style={{ minWidth: "8%" }}
              field="category11"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Category 12 (MtCO2e)"
              style={{ minWidth: "8%" }}
              field="category12"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Total"
              style={{ minWidth: "8%" }}
              field="total"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default OverviewScope3;
