import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomLegend } from "./Dashboard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

export const scopeData = {
  2019: [
    { month: "Jan", locationBased: 30, marketBased: 50 },
    { month: "Feb", locationBased: 140, marketBased: 60 },
    { month: "Mar", locationBased: 50, marketBased: 30 },
    { month: "Apr", locationBased: 80, marketBased: 20 },
    { month: "May", locationBased: 100, marketBased: 30 },
    { month: "Jun", locationBased: 30, marketBased: 90 },
    { month: "Jul", locationBased: 30, marketBased: 50 },
    { month: "Aug", locationBased: 50, marketBased: 60 },
    { month: "Sep", locationBased: 20, marketBased: 70 },
    { month: "Oct", locationBased: 40, marketBased: 40 },
    { month: "Nov", locationBased: 10, marketBased: 20 },
    { month: "Dec", locationBased: 10, marketBased: 100 },
  ],
  2020: [
    { month: "Jan", locationBased: 75, marketBased: 43 },
    { month: "Feb", locationBased: 32, marketBased: 34 },
    { month: "Mar", locationBased: 45, marketBased: 64 },
    { month: "Apr", locationBased: 23, marketBased: 54 },
    { month: "May", locationBased: 10, marketBased: 34 },
    { month: "Jun", locationBased: 42, marketBased: 75 },
    { month: "Jul", locationBased: 34, marketBased: 54 },
    { month: "Aug", locationBased: 23, marketBased: 42 },
    { month: "Sep", locationBased: 15, marketBased: 42 },
    { month: "Oct", locationBased: 65, marketBased: 30 },
    { month: "Nov", locationBased: 23, marketBased: 43 },
    { month: "Dec", locationBased: 77, marketBased: 32 },
  ],
  2021: [
    { month: "Jan", locationBased: 30, marketBased: 80 },
    { month: "Feb", locationBased: 28, marketBased: 62 },
    { month: "Mar", locationBased: 38, marketBased: 52 },
    { month: "Apr", locationBased: 60, marketBased: 10 },
    { month: "May", locationBased: 45, marketBased: 85 },
    { month: "Jun", locationBased: 30, marketBased: 50 },
    { month: "Jul", locationBased: 45, marketBased: 45 },
    { month: "Aug", locationBased: 60, marketBased: 30 },
    { month: "Sep", locationBased: 35, marketBased: 45 },
    { month: "Oct", locationBased: 25, marketBased: 35 },
    { month: "Nov", locationBased: 40, marketBased: 40 },
    { month: "Dec", locationBased: 28, marketBased: 72 },
  ],
  2022: [
    { month: "Jan", locationBased: 42, marketBased: 23 },
    { month: "Feb", locationBased: 42, marketBased: 12 },
    { month: "Mar", locationBased: 42, marketBased: 33 },
    { month: "Apr", locationBased: 66, marketBased: 12 },
    { month: "May", locationBased: 20, marketBased: 64 },
    { month: "Jun", locationBased: 25, marketBased: 22 },
    { month: "Jul", locationBased: 60, marketBased: 32 },
    { month: "Aug", locationBased: 33, marketBased: 25 },
    { month: "Sep", locationBased: 55, marketBased: 40 },
    { month: "Oct", locationBased: 20, marketBased: 67 },
    { month: "Nov", locationBased: 45, marketBased: 33 },
    { month: "Dec", locationBased: 25, marketBased: 55 },
  ],
  2023: [
    { month: "Jan", locationBased: 23, marketBased: 50 },
    { month: "Feb", locationBased: 42, marketBased: 52 },
    { month: "Mar", locationBased: 53, marketBased: 45 },
    { month: "Apr", locationBased: 76, marketBased: 0 },
    { month: "May", locationBased: 32, marketBased: 85 },
    { month: "Jun", locationBased: 62, marketBased: 40 },
    { month: "Jul", locationBased: 54, marketBased: 35 },
    { month: "Aug", locationBased: 65, marketBased: 20 },
    { month: "Sep", locationBased: 21, marketBased: 35 },
    { month: "Oct", locationBased: 64, marketBased: 15 },
    { month: "Nov", locationBased: 23, marketBased: 50 },
    { month: "Dec", locationBased: 53, marketBased: 27 },
  ],
  2024: [
    { month: "Jan", locationBased: 43, marketBased: 45 },
    { month: "Feb", locationBased: 75, marketBased: 48 },
    { month: "Mar", locationBased: 42, marketBased: 40 },
    { month: "Apr", locationBased: 75, marketBased: 20 },
    { month: "May", locationBased: 34, marketBased: 70 },
    { month: "Jun", locationBased: 65, marketBased: 35 },
    { month: "Jul", locationBased: 23, marketBased: 30 },
    { month: "Aug", locationBased: 54, marketBased: 15 },
    { month: "Sep", locationBased: 22, marketBased: 30 },
    { month: "Oct", locationBased: 75, marketBased: 10 },
    { month: "Nov", locationBased: 12, marketBased: 45 },
    { month: "Dec", locationBased: 65, marketBased: 15 },
  ],
};

const CustomScopeLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 1 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,

                marginRight: "5px",
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export const Scope2Chart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  const combinedData = scopeData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} LocationBased`]: item.locationBased,
    [`${year1} MarketBased`]: item.marketBased,
    [`${year2} LocationBased`]: scopeData[year2][index].locationBased,
    [`${year2} MarketBased`]: scopeData[year2][index].marketBased,
  }));
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          Scope 2 Emissions
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Emissions due to unintentional release of gases from pressurised
            equipments{" "}
          </div>
        </div>

        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(scopeData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(scopeData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={combinedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="month" />
            <YAxis
              label={{
                value: "MtCO2e",
                angle: -90,
                position: "insideLeft",
                offset: 0,
                style: { textAnchor: "middle", fill: "#555" },
              }}
            />
            <Tooltip />
            <Legend content={CustomScopeLegend} />
            <Bar
              dataKey={`${year1} LocationBased`}
              stackId="a"
              fill="#17664B"
              barSize={30}
              name="Location Based"
            >
              {" "}
            </Bar>
            <Bar
              dataKey={`${year1} MarketBased`}
              stackId="a"
              fill="#73C3B3"
              barSize={30}
              name="Market Based"
            ></Bar>
            <Bar
              dataKey={`${year2} LocationBased`}
              stackId="b"
              fill="#17664B"
              barSize={30}
            ></Bar>
            <Bar
              dataKey={`${year2} MarketBased`}
              stackId="b"
              fill="#73C3B3"
              barSize={30}
            ></Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={scopeData[year2]}
            tableClassName="font-lato"
          >
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Consumption Emission"
              style={{ minWidth: "8%" }}
              field="locationBased"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Refrigerant Emission"
              style={{ minWidth: "8%" }}
              field="marketBased"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};
