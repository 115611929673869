import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const womenData = {
  2019: [
    {
      month: "Jan",
      leaders: 50,
      newhires: 45,
    },
    {
      month: "Feb",
      leaders: 20,
      newhires: 45,
    },
    {
      month: "Mar",
      leaders: 60,
      newhires: 25,
    },
    {
      month: "Apr",
      leaders: 54,
      newhires: 55,
    },
    {
      month: "Jun",
      leaders: 30,
      newhires: 55,
    },
    {
      month: "Jul",
      leaders: 20,
      newhires: 35,
    },
    {
      month: "Aug",
      leaders: 30,
      newhires: 45,
    },
    {
      month: "Sep",
      leaders: 30,
      newhires: 25,
    },
    {
      month: "Oct",
      leaders: 40,
      newhires: 15,
    },
    {
      month: "Nov",
      leaders: 50,
      newhires: 15,
    },
    {
      month: "Dec",
      leaders: 60,
      newhires: 35,
    },
  ],
  2020: [
    { month: "Jan", leaders: 45, newhires: 40 },
    { month: "Feb", leaders: 35, newhires: 30 },
    { month: "Mar", leaders: 55, newhires: 40 },
    { month: "Apr", leaders: 50, newhires: 50 },
    { month: "May", leaders: 45, newhires: 55 },
    { month: "Jun", leaders: 40, newhires: 50 },
    { month: "Jul", leaders: 35, newhires: 45 },
    { month: "Aug", leaders: 45, newhires: 40 },
    { month: "Sep", leaders: 50, newhires: 35 },
    { month: "Oct", leaders: 55, newhires: 45 },
    { month: "Nov", leaders: 60, newhires: 50 },
    { month: "Dec", leaders: 65, newhires: 55 },
  ],
  2021: [
    { month: "Jan", leaders: 50, newhires: 45 },
    { month: "Feb", leaders: 40, newhires: 35 },
    { month: "Mar", leaders: 55, newhires: 50 },
    { month: "Apr", leaders: 60, newhires: 55 },
    { month: "May", leaders: 50, newhires: 60 },
    { month: "Jun", leaders: 45, newhires: 55 },
    { month: "Jul", leaders: 40, newhires: 50 },
    { month: "Aug", leaders: 55, newhires: 45 },
    { month: "Sep", leaders: 60, newhires: 55 },
    { month: "Oct", leaders: 65, newhires: 60 },
    { month: "Nov", leaders: 70, newhires: 65 },
    { month: "Dec", leaders: 75, newhires: 70 },
  ],

  2022: [
    { month: "Jan", leaders: 55, newhires: 50 },
    { month: "Feb", leaders: 45, newhires: 40 },
    { month: "Mar", leaders: 65, newhires: 55 },
    { month: "Apr", leaders: 70, newhires: 60 },
    { month: "May", leaders: 60, newhires: 65 },
    { month: "Jun", leaders: 55, newhires: 60 },
    { month: "Jul", leaders: 50, newhires: 55 },
    { month: "Aug", leaders: 60, newhires: 50 },
    { month: "Sep", leaders: 65, newhires: 60 },
    { month: "Oct", leaders: 70, newhires: 65 },
    { month: "Nov", leaders: 75, newhires: 70 },
    { month: "Dec", leaders: 80, newhires: 75 },
  ],

  2023: [
    { month: "Jan", leaders: 60, newhires: 55 },
    { month: "Feb", leaders: 50, newhires: 45 },
    { month: "Mar", leaders: 70, newhires: 60 },
    { month: "Apr", leaders: 75, newhires: 65 },
    { month: "May", leaders: 65, newhires: 70 },
    { month: "Jun", leaders: 60, newhires: 65 },
    { month: "Jul", leaders: 55, newhires: 60 },
    { month: "Aug", leaders: 65, newhires: 55 },
    { month: "Sep", leaders: 70, newhires: 65 },
    { month: "Oct", leaders: 75, newhires: 70 },
    { month: "Nov", leaders: 80, newhires: 75 },
    { month: "Dec", leaders: 85, newhires: 80 },
  ],
};

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color, marginRight: 20 }}
        >
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          <span style={{ color: "#555", fontSize: "16px" }}>
            {entry.value === "leaders"
              ? "Percentage of women in leadership positions"
              : "Percentage of women in relation to new hires"}
          </span>
        </li>
      ))}
    </ul>
  );
};

export const WomenRatioChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  const combinedData = womenData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} leaders`]: item.leaders,
    [`${year1} newhires`]: item.newhires,
    [`${year2} leaders`]: womenData[year2][index].leaders,
    [`${year2} newhires`]: womenData[year2][index].newhires,
  }));

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Employees by Contract Type and Gender{" "}
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(womenData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(womenData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "101%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={combinedData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend content={CustomLegend} />
              <Bar
                dataKey={`${year1} leaders`}
                fill="#FEDBD0"
                barSize={30}
                stackId="a"
              />
              <Bar
                dataKey={`${year1} newhires`}
                fill="#FF9D7C"
                barSize={30}
                stackId="a"
              />
              <Bar
                dataKey={`${year2} leaders`}
                fill="#FEDBD0"
                barSize={30}
                stackId="b"
              />
              <Bar
                dataKey={`${year2} newhires`}
                fill="#FF9D7C"
                barSize={30}
                stackId="b"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={womenData[year1]}
            tableClassName="font-lato"
          >
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Male Full Time"
              style={{ minWidth: "8%" }}
              field="leaders"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Female Full Time"
              style={{ minWidth: "8%" }}
              field="newhires"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};
