import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const data = {
  India: [
    { year: "2019", Women: 40, Men: 60 },
    { year: "2020", Women: 50, Men: 70 },
    { year: "2021", Women: 30, Men: 50 },
    { year: "2022", Women: 55, Men: 65 },
    { year: "2023", Women: 45, Men: 55 },
  ],
  Indonesia: [
    { year: "2019", Women: 35, Men: 65 },
    { year: "2020", Women: 45, Men: 60 },
    { year: "2021", Women: 40, Men: 55 },
    { year: "2022", Women: 50, Men: 60 },
    { year: "2023", Women: 38, Men: 52 },
  ],
  UnitedKingdom: [
    { year: "2019", Women: 30, Men: 70 },
    { year: "2020", Women: 42, Men: 65 },
    { year: "2021", Women: 35, Men: 55 },
    { year: "2022", Women: 48, Men: 60 },
    { year: "2023", Women: 40, Men: 50 },
  ],
  Total: [
    { year: "2019", Women: 183, Men: 317 },
    { year: "2020", Women: 227, Men: 328 },
    { year: "2021", Women: 182, Men: 273 },
    { year: "2022", Women: 250, Men: 307 },
    { year: "2023", Women: 208, Men: 265 },
  ],
};

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color, marginRight: 20 }}
        >
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          <span style={{ color: "#555", fontSize: "16px" }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

const SocialChart2 = () => {
  const [activeMode, setActiveMode] = useState(true);
  const [region, setRegion] = useState("India");

  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Employees by Location
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Number of Male nd Female Employees across different location
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {Object.keys(data).map((regionName) => (
          <button
            key={regionName}
            onClick={() => setRegion(regionName)}
            style={{
              padding: "8px 16px",
              backgroundColor: region === regionName ? "#41AA95" : "#F0F0F0",
              color: region === regionName ? "white" : "gray",
              border: "none",
              borderRadius: "8px",
              height: "2.5rem",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            {regionName}
          </button>
        ))}
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data[region]}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideLeft",
                  offset: 0,
                  style: { textAnchor: "middle", fill: "#555" },
                }}
              />{" "}
              <Tooltip />
              <Legend content={CustomLegend} />
              <Bar dataKey="Women" fill="#F28C82" barSize={40} />
              <Bar dataKey="Men" fill="#A7C7E7" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data[region]} tableClassName="font-lato">
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Women"
              style={{ minWidth: "8%" }}
              field="Women"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Female Full Time"
              style={{ minWidth: "8%" }}
              field="Men"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default SocialChart2;
