import React, { useRef, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "react-bootstrap";

// Sample data for the chart
const data = [
  {
    year: "2019",
    Executives: 85,
    Managers: 70,
    Professionals: 90,
    AdminStaff: 65,
    ManualWorkers: 75,
    Total: 80,
  },
  {
    year: "2020",
    Executives: 80,
    Managers: 75,
    Professionals: 85,
    AdminStaff: 60,
    ManualWorkers: 70,
    Total: 78,
  },
  {
    year: "2021",
    Executives: 82,
    Managers: 68,
    Professionals: 88,
    AdminStaff: 63,
    ManualWorkers: 74,
    Total: 79,
  },
  {
    year: "2022",
    Executives: 84,
    Managers: 72,
    Professionals: 87,
    AdminStaff: 67,
    ManualWorkers: 76,
    Total: 81,
  },
  {
    year: "2023",
    Executives: 83,
    Managers: 73,
    Professionals: 89,
    AdminStaff: 64,
    ManualWorkers: 77,
    Total: 82,
  },
];

const GovernanceChart3 = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Percentage of employees receiving regular performance and career
          development reviews.
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              barSize={15}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Executives" fill="#003f5c" />
              <Bar dataKey="Managers" fill="#ffa600" />
              <Bar dataKey="Professionals" fill="#ef5675" />
              <Bar dataKey="AdminStaff" fill="#7a5195" />
              <Bar dataKey="ManualWorkers" fill="#2f4b7c" />
              <Bar dataKey="Total" fill="#ffa600" />{" "}
              {/* Different color for total */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data} tableClassName="font-lato">
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Executives"
              style={{ minWidth: "8%" }}
              field="Executives"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Managers"
              field="Managers"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Professionals"
              field="Professionals"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="AdminStaff"
              field="AdminStaff"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="ManualWorkers"
              field="ManualWorkers"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default GovernanceChart3;
