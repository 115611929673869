
// const baseurl = 'http://localhost:3005/'
const baseurl = 'https://api.eisqr.com/'

export const API = {
    Login: baseurl + 'users/login',
    GetMe: baseurl + 'get-me',
    GetMeNew: baseurl + 'get-me-new',
    TVS_GetMe: baseurl + 'get-me-tvs',
    FilesUpload: baseurl + 'files',
    UserProfile: baseurl + 'user-profiles',
    UserProfile_Edit: (id) => { return baseurl + 'user-profiles/' + id },
    NewUserProfile: baseurl + 'new-user-profiles',
    ResetRequest: baseurl + 'reset-password/init',
    ResetPassword: baseurl + 'reset-password/finish',
    RemaindEsclate_Mail: baseurl + 'send-remainder',
    Docs: baseurl + 'docs/',
    LocationOne_UP: (id) => { return baseurl + 'user-profiles/' + id + "/location-ones" },
    LocationTwo_UP: (id) => { return baseurl + 'location-ones/' + id + "/location-twos" },
    LocationThree_UP: (id) => { return baseurl + 'location-twos/' + id + "/location-threes" },
    LocationOne_Edit: (id) => { return baseurl + 'location-ones/' + id },
    LocationTwo_Edit: (id) => { return baseurl + 'location-twos/' + id },
    LocationThree_Edit: (id) => { return baseurl + 'location-threes/' + id },
    LocationThree: baseurl + 'location-threes',
    DCF: baseurl + 'form-collections',
    DCF_Title_Only : baseurl + 'form-collections?filter%5Bfields%5D%5Btitle%5D=true&filter%5Bfields%5D%5Bid%5D=true&filter%5Bfields%5D%5Btags%5D=true',
    SRF: baseurl + 'consolidate-form-collections',
    DCF_Edit: (id) => { return baseurl + 'form-collections/' + id },
    SRF_Edit: (id) => { return baseurl + 'consolidate-form-collections/' + id },
    Categories: baseurl + 'new-categories',
    Categories_Edit: (id) => { return baseurl + 'new-categories/' + id },
    DataPoint: baseurl + 'new-data-points',
    DataPoint_Edit: (id) => { return baseurl + 'new-data-points/' + id },
    Metric: baseurl + 'new-metrics',
    Metric_Edit: (id) => { return baseurl + 'new-metrics/' + id },
    Metric_Topic: (id) => { return baseurl + 'new-topics/' + id + '/new-metrics' },
    SubmissionMail: baseurl + 'send-email',
    DataPoint_Metric: (id) => { return baseurl + 'new-metrics/' + id + '/new-data-points' },
    Topic: baseurl + 'new-topics',
    Topic_Edit: (id) => { return baseurl + 'new-topics/' + id },
    Topic_Categories: (id) => { return baseurl + 'new-categories/' + id + '/new-topics' },
    AssignDCFClient: baseurl + 'assign-dcf-clients',
    AssignDCFClient_Edit: (id) => { return baseurl + 'assign-dcf-clients/' + id },
    AssignDCFClient_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-clients' },
    Goal_UP: (id) => { return baseurl + 'user-profiles/' + id + '/new-goals' },
    Initiative_Goal: (id) => { return baseurl + 'new-goals/' + id + '/new-initiatives' },
    Target_Goal: (id) => { return baseurl + 'new-goals/' + id + '/new-targets' },
    Target_Goal_2: (id) => { return baseurl + 'new-goals/' + id + '/new-targets-twos' },
    Indicator_Target: (id) => { return baseurl + 'new-targets-twos/' + id + '/new-indicators' },
    Indicator_Goal: (id) => { return baseurl + 'new-goals/' + id + '/new-indicator-twos' },
    Goal_Edit: (id) => { return baseurl + 'new-goals/' + id },
    Target_Edit: (id) => { return baseurl + 'new-targets/' + id },
    Target_Two_Edit: (id) => { return baseurl + 'new-targets-twos/' + id },
    Indicator_Edit: (id) => { return baseurl + 'new-indicator-twos/' + id },
    Initiative_Edit: (id) => { return baseurl + 'new-initiatives/' + id },
    AssignDCFSupplier_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-suppliers' },
    DCF_Supplier_Edit: (id) => { return baseurl + 'assign-dcf-suppliers/' + id },
    STD_Country: baseurl + 'std-countries',
    STD_Country_Edit: (id) => { return baseurl + 'std-countries/' + id },
    STD_Year_Country: (id) => { return baseurl + 'std-countries/' + id + '/std-years' },
    STD_Year_Edit: (id) => { return baseurl + 'std-years/' + id },
    STD_Name_Year: (id) => { return baseurl + 'std-years/' + id + '/std-names' },
    STD_Name_Edit: (id) => { return baseurl + 'std-names/' + id },
    STD_Scope_Name: (id) => { return baseurl + 'std-names/' + id + '/std-scopes' },
    STD_Scope_Edit: (id) => { return baseurl + 'std-scopes/' + id },
    STD_Topic_Scope: (id) => { return baseurl + 'std-scopes/' + id + '/std-topics' },
    STD_Topic_Edit: (id) => { return baseurl + 'std-topics/' + id },
    Report_Name_Ones: baseurl + 'report-name-ones',
    Report_Name_Twos: baseurl + 'report-name-twos',
    Report_Name_Ones_Edit: (id) => { return baseurl + 'report-name-ones/' + id },
    Report_Name_Twos_RNO: (id) => { return baseurl + 'report-name-ones/' + id + '/report-name-twos' },
    Report_Name_Twos_Edit: (id) => { return baseurl + 'report-name-twos/' + id },
    RF: baseurl + 'response-form-collections',
    RF_Edit: (id) => { return baseurl + 'response-form-collections/' + id },
    QL_Listing_Filter_UP: (id) => { return baseurl + 'user-profiles/' + id + '/ql-listing-filters' },
    QL_Listing_Filter_Edit: (id) => { return baseurl + 'ql-listing-filters/' + id },
    RF_User_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-rf-users' },
    RF_User_Edit: (id) => { return baseurl + 'assign-rf-users/' + id },
    EF_Date_Std: (id) => { return baseurl + 'new-ef-stds/' + id + '/new-ef-dates' },
    EF_Category_Std: (id) => { return baseurl + 'new-ef-stds/' + id + '/new-ef-categories' },
    EF_Std: baseurl + 'new-ef-stds',
    EF_SC4: baseurl + 'new-ef-subcategory4s',
    EF_SC3: baseurl + 'new-ef-subcategory3s',
    EF_SC2: baseurl + 'new-ef-subcategory2s',
    EF_SC1: baseurl + 'new-ef-subcategory1s',

    EF_SC1_Edit: (id) => { return baseurl + 'new-ef-subcategory1s/' + id },
    EF_SC2_Edit: (id) => { return baseurl + 'new-ef-subcategory2s/' + id },
    EF_SC3_Edit: (id) => { return baseurl + 'new-ef-subcategory3s/' + id },
    EF_SC4_Edit: (id) => { return baseurl + 'new-ef-subcategory4s/' + id },
    EF_SC1_Cat: (id) => { return baseurl + 'new-ef-categories/' + id + '/new-ef-subcategory1s' },
    EF_SC2_Sc1: (id) => { return baseurl + 'new-ef-subcategory1s/' + id + '/new-ef-subcategory2s' },
    EF_SC3_Sc2: (id) => { return baseurl + 'new-ef-subcategory2s/' + id + '/new-ef-subcategory3s' },
    EF_SC4_Sc3: (id) => { return baseurl + 'new-ef-subcategory3s/' + id + '/new-ef-subcategory4s' },

    EF_Std_Edit: (id) => { return baseurl + 'new-ef-stds/' + id },
    EF_Date_Edit: (id) => { return baseurl + 'new-ef-dates/' + id },
    NEW_EF_Date: (id) => { return baseurl + 'new-ef-dates/' + id + '/new-efs' },
    NEW_EF_Edit: (id) => { return baseurl + 'new-efs/' + id },
    EF_Item_NEW_EF: (id) => { return baseurl + 'new-efs/' + id + '/new-ef-items' },
    EF_Item_Edit: (id) => { return baseurl + 'new-ef-items/' + id },
    EF_Category: baseurl + 'new-ef-categories',
    EF_Category_edit: (id) => { return baseurl + 'new-ef-categories/' + id },
    EF_Item_Import: baseurl + 'new-ef-items/multiple',
    DCF_Recent_Submission: baseurl + 'get-recent-dcf-submission',
    STT_India_User: baseurl + 'india/users',
    STT_Singapore_User: baseurl + 'internal/users',
    STT_User_Role: baseurl + 'ad-users',
    STT_Get_User_Role: baseurl + "ad-users-by-mail",
    
    // New
    Indicator_Clone: baseurl + 'clone-metric-only',
    Topic_Clone: baseurl + 'clone-topic-with-metric',
    Indicator_With_Data_Point_Clone: baseurl + 'clone-with-datapoint',

    DP_report_UP: (id) => { return baseurl + 'user-profiles/' + id + '/dp-report-news' },
    DCF_Submission_Edit: (id) => { return baseurl + 'submit-dcf-news/' + id },
    DCF_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/submit-dcf-news' },
    RF_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/submit-rf-news' },
    AssignDCFUser_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-user-news' },
    DCF_User_Edit: (id) => { return baseurl + 'assign-dcf-user-news/' + id },

    // DP_report_UP: (id) => { return baseurl + 'user-profiles/' + id + '/dp-reports' },
    // DCF_Submission_Edit: (id) => { return baseurl + 'submit-dcfs/' + id },
    // DCF_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/submit-dcfs' },
    // RF_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/submit-rfs' },
    // AssignDCFUser_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-users' },
    // DCF_User_Edit: (id) => { return baseurl + 'assign-dcf-users/' + id },

    AssignSRFUser_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-srf-users' },
    SRF_User_Edit: (id) => { return baseurl + 'assign-srf-users/' + id },
    AssignDFUser_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-df-users' },
    DF_User_Edit: (id) => { return baseurl + 'assign-df-users/' + id },
    RF_Entity_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-rf-entities' },
    RF_Entity_Edit: (id) => { return baseurl + 'assign-rf-entities/' + id },

    DF_Entity_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-df-entities' },
    DF_Entity_Edit: (id) => { return baseurl + 'assign-df-entities/' + id },
    DF_Entity_User_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-df-entity-users' },
    DF_Entity_User_Edit: (id) => { return 'assign-df-entity-users/' + id },

    DCF_Entity_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-entities' },
    DCF_Entity_Edit: (id) => { return 'assign-dcf-entities/' + id },
    DCF_Entity_User_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-entity-users' },
    DCF_Entity_User_Edit: (id) => { return 'assign-dcf-entity-users/' + id },
    DCF_Entity_User_Edit_Custom: (id) => { return 'assign-dcf-entity-users-custom/' + id },
    SRF_Entity_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-srf-entities' },
    SRF_Entity_Edit: (id) => { return baseurl + 'assign-srf-entities/' + id },
    SRF_Entity_User_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-srf-entity-users' },
    SRF_Entity_User_Edit: (id) => { return 'assign-srf-entity-users/' + id },

    Client_EF_Cat_Ass_Up: (id) => { return baseurl + 'user-profiles/' + id + '/client-ef-category-assignments' },
    Client_EF_Cat_Edit: (id) => { return baseurl + '/client-ef-category-assignments/' + id },
    QN_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/quantitative-submissions' },
    QL_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/qualitative-submissions' },
    QL_Recent_RP_Data :  baseurl +'qualitative-previous-submissions',
    UserRole: baseurl + 'user-role-authorizations',
    GetRoleByUserId: (id1,id2)=>{ return  baseurl +`user-role-authorizations?filter%5Bwhere%5D%5BuserProfileId%5D=${id1}&filter%5Bwhere%5D%5Buser_id%5D=${id2}` },
    GetRole_Up: (id)=>{ return  baseurl +`user-role-authorizations?filter%5Bwhere%5D%5BuserProfileId%5D=${id}` },
    AssingRolesToUser:baseurl +'individual-user-location-roles',
    ResetRolesToUser:(id)=> {return  baseurl +'reset-user-role-authorizations/' +id},
    UserRole_Edit: (id) => { return 'user-role-authorizations/' + id },
    QN_Submission_Edit: (id) => { return baseurl + 'quantitative-submissions/' + id },
    QL_Submission_Edit: (id) => { return baseurl + 'qualitative-submissions/' + id },
    Certifcation: baseurl + 'certifications',
    Certifcation_Edit: (id) => { return baseurl + 'certifications/' + id },
    Authority_Certifcation: (id) => { return baseurl + 'certifications/' + id + '/cert-issue-authorities' },
    Cert_Level_Certifcation: (id) => { return baseurl + 'certifications/' + id + '/certification-levels' },
    Authority_Issue_Edit: (id) => { return baseurl + 'cert-issue-authorities/' + id },
    Certifcation_Level_Edit: (id) => { return baseurl + 'certification-levels/' + id },
    Roles_Info: baseurl + 'user-role-authorizations/get-individual-users',
    Application: baseurl + 'application-lists',
    Application_Edit: (id) => { return baseurl + 'application-lists/' + id },
    Role_Application: (id) => { return baseurl + 'application-lists/' + id + '/application-roles' },
    Role_Edit: (id) => { return baseurl + 'application-roles/' + id },
    InitiativeCategory: baseurl + 'initiative-categories',
    Unit_InitCategory: baseurl + 'init-category-units',
    Unit_InitCat: (id) => { return baseurl + 'initiative-categories/' + id + '/init-category-units' },
    ClientIniative_UP: (id) => { return baseurl + 'user-profiles/' + id + '/client-initiatives' },
    ClientIniative_Edit: (id) => { return baseurl + 'client-initiatives/' + id },
    AirPortCode: baseurl + 'air-iata-codes',
    AirPortCode_Edit: (id) => { return baseurl + 'air-iata-codes/' + id },

    Change_Mgmt: baseurl + 'change-managements',
    Client_Certification: baseurl + 'new-client-certifications',
    ChangeMgmt_Edit:  baseurl + 'new-client-certifications',
    Client_Certification_UP: (id) => { return baseurl + 'user-profiles/' + id + '/new-client-certifications' },
    Client_Certification_Edit: (id) => { return baseurl + 'new-client-certifications/' + id },
    QNDP_Report_UP: (id) => { return baseurl + 'user-profiles/' + id + '/quantitative-dp-reports' },
    TVS_Ext_users:  baseurl + 'tvs/external',
    Indicator_Approver_Ass :   baseurl + 'indicator-approver-assignments',
    
    Indicator_Approver_Ass_Custom:  baseurl + 'indicator-approver-assignments-custom' ,

    Indicator_Approver_Ass_UP: (id) => { return baseurl + 'user-profiles/' + id + '/indicator-approver-assignments' },

    // Auditor
    SupplierAssessmentAss_Up: (id) => { return baseurl + 'user-profiles/' + id + '/supplier-assessment-assignments' },
    SupplierAssessmentAss_Edit: (id) => { return baseurl + 'supplier-assessment-assignments/' + id },
    SupplierList_supass: (id) => { return baseurl + 'supplier-assessment-assignments/' + id + '/assessment-supplier-lists' },
    AuditorAssessmentSubmission: (id) => { return baseurl + 'supplier-assessment-assignments/' + id + '/auditor-assignment-submission' },

    SupplierAssessmentSubmission: (id) => { return baseurl + 'supplier-assessment-assignments/' + id + '/supplier-assignment-submission' },
    SupplierAssessmentSubmission_Edit: (id) => { return baseurl + 'supplier-assignment-submissions/'+id },
    PossibleIndicatorLocation : baseurl+'get-possible-selection',
    PolicyProcedure_Up: (id) => { return baseurl + 'user-profiles/' + id + '/policy-procedures' },
    PolicyProcedure_Edit: (id) => { return baseurl + 'policy-procedures/' + id  },
    IndicatorList_Custom_UP: baseurl + 'get-incomplete-indicators-list/' ,
    sectionData_UP: baseurl+'assessment-sections-custom',
    PolicyProcedure: baseurl+'/policy-procedures',

}
export { baseurl }