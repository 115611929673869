import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { waterWithrawaldata } from "./WaterWithdrawal";
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import { CustomLegend } from "./Dashboard";

const OverviewWater = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Water Withdrawn
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Quantity of water withdrawn through different modes
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={waterWithrawaldata}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Tons",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend content={CustomLegend} />

              {/* Bars for Hazardous and Non-Hazardous Waste */}
              <Bar
                dataKey="surface"
                stackId="a"
                fill="#BDD1F7"
                name="Surface water"
                barSize={60}
              />
              <Bar
                dataKey="seawater"
                stackId="a"
                fill="#8FC3E8"
                name="Seawater"
              />
              <Bar
                dataKey="thirdParty"
                stackId="a"
                fill="#5B8FF7"
                name="Third party water"
              />
              <Bar
                dataKey="ownProducedWater"
                stackId="a"
                fill="#4569B2"
                name="Own Produced water"
              />
              <Bar
                dataKey="groundWater"
                stackId="a"
                fill="#C5DAE9"
                name="Ground water"
              />

              <Line
                type="linear"
                dataKey="total"
                stroke="#EE5724"
                fill="#EE5724"
                name="Total"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={waterWithrawaldata}
            tableClassName="font-lato"
          >
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Surface"
              style={{ minWidth: "8%" }}
              field="surface"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Sea Water"
              style={{ minWidth: "8%" }}
              field="seawater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Third Party"
              style={{ minWidth: "8%" }}
              field="thirdParty"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Ground Water"
              style={{ minWidth: "8%" }}
              field="groundWater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Own Produced Water"
              style={{ minWidth: "8%" }}
              field="ownProducedWater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Total"
              style={{ minWidth: "8%" }}
              field="total"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default OverviewWater;
