import React, { useEffect, useState } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import { Calendar } from "primereact/calendar";

const SupplierAssessmentAssignmentTVS = () => {
    const [assignments, setAssignments] = useState([])
    const [asssupplierlist, setAssSupplierList] = useState([])
    const [activeAss, setActiveAss] = useState(null)
    const [asssrflist, setAssSrfList] = useState([])

    const userList = useSelector(state => state.userlist.userList)
    const supplierList = useSelector(state => state.userlist.supplierList)

    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const [assobj, setAssObj] = useState({ supplierId: null, auditor_ids: [], assessmentStartDate: null, assessmentEndDate: null, auditStartDate: null, auditEndDate: null, srfId: null })
    const [asssupplierobj, setAssSupplierObj] = useState({ supplierId: null })


    const [supplierlist, setSupplierList] = useState([])
    const [auditorlist, setAuditorList] = useState([])
    const [adddialog, setAddDialog] = useState(false)
    const [addsupplierdialog, setAddSupplierDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [supplierdialog, setSupplierDialog] = useState(false)

    const forceUpdate = useForceUpdate()


    useEffect(() => {
        let uriString = {

            "include": ["supplierAssignmentSubmission" ]

        };
        const promise0 = APIServices.get(API.SupplierAssessmentAss_Up(admin_data.id))
        const promise1 = APIServices.get(API.SupplierAssessmentAss_Up(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        const promise3 = APIServices.get(API.SRF)
        const promise4 = APIServices.get(API.GetRole_Up(admin_data.id))

        Promise.all([promise0, promise1, promise2, promise3, promise4]).then((values) => {
            let srf_list = values[3].data
            setAssignments(values[1].data)
            if (values[2].data.length && values[2].data[0].cf_ids && values[2].data[0].cf_ids.length) {
                let assSrfList = values[2].data[0].cf_ids.filter(i => srf_list.map(x => x.id).includes(i)).map(i => srf_list.find(x => x.id === i))
                setAssSrfList(assSrfList)
                console.log(assSrfList)
                setSupplierList(supplierList.filter(i => i.role === 'clientsupplier'))
                setAuditorList(userList.filter(i => values[4].data.some(x => x.user_id === i.id && x.roles && x.roles.includes(17))))

            }
        })
    }, [])
    useEffect(() => {
        console.log(supplierlist, auditorlist)
    }, [supplierlist, auditorlist])

    const addfooter = () => {
        return (
            <Button label="Save" onClick={saveAssignment} />
        )
    }
    const addSupplierfooter = () => {
        return (
            <Button label="Save" onClick={saveAssignmentSupplier} />
        )
    }
    const saveAssignmentSupplier = () => {
        setSubmitted(true)
        if (asssupplierobj.supplierId) {
            let newObj = {
                supplierId: asssupplierobj.supplierId
            }
            APIServices.post(API.SupplierList_supass(activeAss.id), { ...newObj, srfId: activeAss.srfId, mailSent: 1, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                let loc = JSON.parse(JSON.stringify(assignments))
                let index = loc.findIndex(i => i.id === activeAss.id)
                if (index !== -1) {
                    if (loc.assessmentSupplierLists) {
                        loc[index].assessmentSupplierLists.push(res.data)
                    } else {
                        loc[index].assessmentSupplierLists = [res.data]
                    }
                }

                setAssignments(loc)

            })
        }

    }
    const saveAssignment = () => {
        setSubmitted(true)
        if (assobj.srfId && assobj.supplierId && assobj.assessmentStartDate && assobj.auditor_ids.length) {
            let newObj = {
                assessmentStartDate: assobj.assessmentStartDate,
                assessmentEndDate: assobj.assessmentEndDate,
                auditStartDate: assobj.auditStartDate,
                auditEndDate: assobj.auditEndDate,
                auditor_ids: assobj.auditor_ids,
                srfId: assobj.srfId,
                supplierId: assobj.supplierId
            }
            console.log(assobj.id)
            if (assobj.id) {
                APIServices.patch(API.SupplierAssessmentAss_Edit(assobj.id), { ...newObj, modified_on: DateTime.utc(), modified_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(assignments))
                    let index = loc.findIndex(i => i.id === assobj.id)
                    if (index !== -1) {
                        loc[index] = { ...assobj, modified_on: DateTime.utc(), modified_by: login_data.id }
                        setAssignments(loc)
                    }

                })
            } else {
                APIServices.post(API.SupplierAssessmentAss_Up(admin_data.id), { ...newObj, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(assignments))
                    loc.push(res.data)
                    setAssignments(loc)
                })

            }

        }
    }
    const addNewAssignment = () => {
        setSubmitted(false)
        setAssObj({ supplierId: null, auditor_ids: [], assessmentStartDate: null, assessmentEndDate: null, auditStartDate: null, auditEndDate: null, srfId: null })
        setAddDialog(true)

    }
    const addNewAssignmentSupplier = () => {
        setSubmitted(false)
        setAssSupplierObj({ supplierId: null })
        setAddSupplierDialog(true)
    }
    const updateAssObj = (obj, val) => {
        let loc = assobj
        loc[obj] = val
        setAssObj(loc)
        forceUpdate()

    }
    const supplierNameTemplate = (rowData) => {

        let txt = [{ name: 'Supplier 1', id: 1 }, { name: 'Supplier 2', id: 2 }, { name: 'Supplier 3', id: 3 }].find(i => rowData.supplierId === i.id)
        console.log(txt)
        return (<>{!txt ? '' : txt.name} </>)

    }
    const getDate = (date, format) => {

        if (!date) {
            return 'Not Set'
        } if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const dateTemplate = (date) => {
        console.log(getDate(date))
        return getDate(date)

    }
    const resendMail = (rowData) => {
        let assessment = assignments.find(i => i.id === activeAss.id)
        let txt = supplierList.find(i => rowData.supplierId === i.id)
        if (assessment) {
            let body = `<p>Hi ${txt.information.empname}</p>  <p>You have received mail in order to fill your Questionary Response, <a href="${window.location.origin}/supplier/assessment/${rowData.id}">click here </a> to open Supplier Assessment Questionary Form</p><hr/><p style='font-style:italic'>This email is an automated notification. Please do not reply to this message</p>`
            APIServices.post(API.SubmissionMail, { email: [txt.email], subject: 'Supplier Assessment Form - ' + assessment.title, body: body }).then(res => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Mail Sent Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })

        }


    }
    const actionTemplate = (rowData) => {
        return (<div className='clr-navy text-underline fw-5' onClick={() => { resendMail(rowData) }}>Send Mail</div>)
    }
    const statusTemplate = (rowData) => {
        return (<div className={rowData.supplierAssignmentSubmission ? 'clr-navy text-underline fw-5' : 'fw-5'}>{rowData.supplierAssignmentSubmission ? 'Submitted' : 'NA'}</div>)
    }
    const questionaryTemplate = (rowData) => {

        let txt = asssrflist.find(i => rowData.srfId === i.id)
        console.log(txt)
        return (<>{!txt ? '' : txt.title} </>)

    }
    const openSupplierList = (rowData) => {
        setActiveAss(rowData)
        setAssSupplierList(rowData.assessmentSupplierLists ? rowData.assessmentSupplierLists : [])
        setSupplierDialog(true)
    }
    const suppliersTemplate = (rowData) => {

        return (
            <div className="clr-navy fw-7 text-underline" onClick={() => { openSupplierList(rowData) }} >{rowData.assessmentSupplierLists ? rowData.assessmentSupplierLists.length : 0}</div>
        )
    }
    return (
        <div className="col-12">
            <div className="col-12">
                <div className="col-12">
                    <div className="flex justify-content-end">
                        <Button label="Add Supplier" onClick={addNewAssignment} />
                    </div>
                </div>
                <div className="col-12">
                    <DataTable value={assignments} >
                        <Column header="Supplier Name" body={supplierNameTemplate} />
                        <Column body={questionaryTemplate} header="Questionary" />
                        <Column header="Assessment Start Date" body={(rowData) => { return dateTemplate(rowData.assessmentStartDate) }} />
                        <Column header="Assessment End Date" body={(rowData) => { return dateTemplate(rowData.assessmentEndDate) }} />
                        <Column header="Audit Start Date" body={(rowData) => { return dateTemplate(rowData.auditStartDate) }} />
                        <Column header="Audit End Date" body={(rowData) => { return dateTemplate(rowData.auditEndDate) }} />



                    </DataTable>
                </div>

                <Dialog style={{ width: '75%' }} visible={adddialog} footer={addfooter} onHide={() => setAddDialog(false)}>
                    <div className="p-fluid grid m-0 p-3">
                        <div className="p-field col-12 ">
                            <label htmlFor="status">Select Supplier<span className="mandatory"> *</span>  </label>
                            <Dropdown id="status" disabled={assobj.id} className='mt-2' value={assobj.supplierId} optionLabel="name" optionValue='id' options={[{ name: 'Supplier 1', id: 1 }, { name: 'Supplier 2', id: 2 }, { name: 'Supplier 3', id: 3 }]} onChange={(e) => { updateAssObj("supplierId", e.target.value) }} placeholder="Select Supplier" />

                            {submitted && !assobj.supplierId && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Select Supplier
                                </small>
                            )}
                        </div>
                        <div className="p-field col-12 ">
                            <label htmlFor="status">Select Questionary Form <span className="mandatory"> *</span>  </label>
                            <Dropdown id="status" disabled={assobj.id} className='mt-2' value={assobj.srfId} optionValue='id' optionLabel="title" options={asssrflist} onChange={(e) => { updateAssObj("srfId", e.target.value) }} placeholder="Select Supplier Questionary Form" />

                            {submitted && assobj.srfId === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Select Supplier Questionary Form
                                </small>
                            )}
                        </div>
                        <div className="p-field col-12 ">
                            <label htmlFor="status">Select Auditors<span className="mandatory"> *</span>  </label>
                            <MultiSelect id="status" className='mt-2' value={assobj.auditor_ids} optionLabel="information.empname" optionValue='id' options={auditorlist} onChange={(e) => { updateAssObj("auditor_ids", e.target.value) }} placeholder="Select Auditor(s)" />

                            {submitted && !assobj.auditor_ids.length && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Select Auditor(s)
                                </small>
                            )}
                        </div>
                        <div className="p-field col-12 ">
                            <label htmlFor="status">Select Assessment Start Date<span className="mandatory"> *</span>  </label>
                            <Calendar id="status" className='mt-2' value={assobj.assessmentStartDate} onChange={(e) => { updateAssObj("assessmentStartDate", e.target.value) }} placeholder="Select Assessment Start Date" />

                            {submitted && !assobj.assessmentStartDate && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Select Supplier
                                </small>
                            )}
                        </div>
                        <div className="p-field col-12 ">
                            <label htmlFor="status">Select Assessment End Date </label>
                            <Calendar id="status" className='mt-2' value={assobj.assessmentEndDate} onChange={(e) => { updateAssObj("assessmentEndDate", e.target.value) }} placeholder="Select Assessment End Date" />

                        </div>
                        <div className="p-field col-12 ">
                            <label htmlFor="status">Select Audit Start Date </label>
                            <Calendar id="status" className='mt-2' value={assobj.auditStartDate} onChange={(e) => { updateAssObj("auditStartDate", e.target.value) }} placeholder="Select Audit Start Date" />

                        </div>
                        <div className="p-field col-12 ">
                            <label htmlFor="status">Select Audit End Date </label>
                            <Calendar id="status" className='mt-2' value={assobj.auditEndDate} onChange={(e) => { updateAssObj("auditEndDate", e.target.value) }} placeholder="Select Audit End Date" />

                        </div>
                    </div>
                </Dialog>
                <Dialog style={{ width: '75%' }} visible={supplierdialog} onHide={() => setSupplierDialog(false)}>
                    <div className="col-12">
                        <div className="flex justify-content-end">
                            <Button label="Add Supplier" onClick={addNewAssignmentSupplier} />
                        </div>
                    </div>
                    <DataTable value={asssupplierlist} >
                        <Column header="Supplier Name" body={supplierNameTemplate} />
                        <Column header="Assessment Start Date" body={(rowData) => { dateTemplate(rowData.assessmentStartDate) }} />
                        <Column header="Assessment End Date" body={(rowData) => { dateTemplate(rowData.assessmentEndDate) }} />
                        <Column header="Assessment Status" />                        
                        <Column header="Supplier Score" />
                        <Column header="Audit Start Date" body={(rowData) => { dateTemplate(rowData.auditStartDate) }} />
                        <Column header="Audit End Date" body={(rowData) => { dateTemplate(rowData.auditEndDate) }} />
                        <Column header="Audit Score" />
                        <Column header="Audit Report" />

                    </DataTable>
                </Dialog>
                <Dialog style={{ width: '75%' }} visible={addsupplierdialog} footer={addSupplierfooter} onHide={() => setAddSupplierDialog(false)}>
                    <div className="p-fluid grid m-0 p-3">
                        <div className="p-field col-12 ">
                            <label htmlFor="status">Select Suppliers<span className="mandatory"> *</span>  </label>
                            <Dropdown id="status" className='mt-2' value={asssupplierobj.supplierId} optionLabel="information.empname" optionValue='id' options={supplierlist} onChange={(e) => { setAssSupplierObj((prev) => ({ ...prev, supplierId: e.target.value })) }} placeholder="Select Supplier" />

                            {submitted && !asssupplierobj.supplierId && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Select Supplier
                                </small>
                            )}
                        </div>
                    </div>
                </Dialog>

            </div>

        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SupplierAssessmentAssignmentTVS, comparisonFn);