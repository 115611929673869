import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  ResponsiveContainer,
} from "recharts";

const data = [
  { year: "2019", hazardousWaste: 51455, nonHazardousWaste: 243134 },
  { year: "2020", hazardousWaste: 34449, nonHazardousWaste: 84578 },
  { year: "2021", hazardousWaste: 61378, nonHazardousWaste: 141645 },
  { year: "2022", hazardousWaste: 60781, nonHazardousWaste: 92104 },
  { year: "2023", hazardousWaste: 43059, nonHazardousWaste: 119745 },
];

const OverviewWaste = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Hazardous Waste Generated
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Quantity of Hazardous waste generated across the organization
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Tons",
                  angle: -90,
                  position: "insideLeft",
                }}
                tick={{
                  fontSize: 10, // Adjust the font size as needed
                }}
              />
              <Tooltip />
              <Legend />

              {/* Bars for Hazardous and Non-Hazardous Waste */}
              <Bar
                dataKey="hazardousWaste"
                fill="#003f5c"
                name="Hazardous waste"
              />
              <Bar
                dataKey="nonHazardousWaste"
                fill="#ffa600"
                name="Non-hazardous waste"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data} tableClassName="font-lato">
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Hazardous Waste"
              style={{ minWidth: "8%" }}
              field="hazardousWaste"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Non Hazardous Waste"
              style={{ minWidth: "8%" }}
              field="nonHazardousWaste"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default OverviewWaste;
