import React, { useState, useEffect, useRef } from "react";
import './componentstyle.css';

export function ReadMoreComponent({
  title = null,
  content = null,
  percent = 40,
  containerStyle = {},
  containerClassname = '',
  titleClassname = '',
  contentClassname = '',
  titleStyle = {},
  contentStyle = {},
  boldwords = [], 
  lineBreaks = [],
  readMoreComponent = 'read more',
  readLessComponent = 'read less'
}) {
  const [expanded, setExpanded] = useState(false);
  const [visibleChars, setVisibleChars] = useState(0);
  const containerRef = useRef(null);

  const formatContent = (text) => {
    let formattedText = text;

    boldwords.forEach(word => {
      const regex = new RegExp(`\\b${word}\\b`, 'g');
      formattedText = formattedText.replace(regex, `<b>${word}</b>`);
    });

    lineBreaks.forEach(lineBreak => {
      const regex = new RegExp(lineBreak, 'g');
      formattedText = formattedText.replace(regex, `${lineBreak}<br>`);
    });

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  const calculateVisibleChars = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const avgCharWidth = 8;
      const targetVisibleChars = Math.floor((containerWidth * percent) / 100 / avgCharWidth);
      setVisibleChars(targetVisibleChars);
    }
  };

  useEffect(() => {
    calculateVisibleChars();
    window.addEventListener("resize", calculateVisibleChars);
    return () => window.removeEventListener("resize", calculateVisibleChars);
  }, [content, percent]);

  const toggleExpand = () => setExpanded(!expanded);

  const previewContent = formatContent(content?.slice(0, visibleChars));
  const fullContent = formatContent(content);

  return (
    <div className={`expandable-container ${containerClassname}`} style={containerStyle}>
      {title && (
        <label className={`flex fs-16 fw-7 ${titleClassname}`} style={{ fontWeight: 900, marginBottom: 10, ...titleStyle }}>
          {title}
        </label>
      )}
      <div ref={containerRef} className={`expandable-content ${contentClassname}`} style={contentStyle}>
        {expanded ? fullContent : previewContent }
        {!expanded && content.length > visibleChars && (
          <small className="expand-toggle-label" style={{ display: "inline" }} onClick={toggleExpand}>
            ... {readMoreComponent}
          </small>
        )}
        {expanded && (
          <small className="expand-toggle-label less" style={{ display: "inline" }} onClick={toggleExpand}>
            {readLessComponent}
          </small>
        )}
      </div>
    </div>
  );
}
