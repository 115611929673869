import React, { useEffect, useState } from "react";
import APIServices from "../service/APIService";
import { API } from "../constants/api_url";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ReportListing = () => {
    const admin_data = useSelector(state => state.user.admindetail)
    const [data, setData] = useState([])
    const bg = ['#F5C37B33', '#5B8FF71A', '#FF9D7C1A', '#29C76F1A', '#41AA951A', '#F5C37B33', '#5B8FF71A', '#FF9D7C1A', '#29C76F1A', '#41AA951A', '#F5C37B33', '#5B8FF71A', '#FF9D7C1A', '#29C76F1A', '#41AA951A']
    const navigate = useHistory()
    useEffect(() => {
        APIServices.get(API.Report_Name_Ones + `?filter=${encodeURIComponent(JSON.stringify({ 'include': ['reportNameTwos'] }))}`).then((res) => {
            let loc = res.data.filter((i) => { return i.reportNameTwos !== undefined && i.reportNameTwos.length !== 0 })
            let data_ = []
            loc.forEach((i => {
                i.reportNameTwos.forEach((j) => {
                    if (admin_data.information.report.includes(j.id)) {
                        let index = data_.findIndex((k) => { return i.id === k.id })
                        if (index === -1) {
                            data_.push({ id: i.id, title: i.title, reports: [j] })
                        } else {
                            console.log(data_)
                            data_[index].reports.push(j)
                        }
                    }
                })

            }))
            setData(data_)
        })
    }, [])
    return (
        <div className="grid">
            <div className="col-12">
                <div className="grid">
                    {data.map((item, index) => {
                        return (
                            <div className="mb-1 p-0 col-3 ">
                                <div className="m-1 p-0 card" >
                                    <div className="report-display-card-container " style={{ background: bg[index] }}>
                                        <div className="p-4">
                                            <div className="flex mb-2"> <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.1987 1.26898V5.39111C11.1987 5.95002 11.1987 6.22948 11.3098 6.44295C11.4076 6.63073 11.5636 6.7834 11.7555 6.87908C11.9737 6.98785 12.2593 6.98785 12.8305 6.98785H17.0431M13.2384 11.9776H5.07947M13.2384 15.9695H5.07947M7.11921 7.98574H5.07947M11.1987 1H5.89536C4.18183 1 3.32506 1 2.67057 1.32631C2.09487 1.61335 1.62681 2.07136 1.33348 2.63469C1 3.27512 1 4.11349 1 5.79022V16.169C1 17.8458 1 18.6841 1.33348 19.3246C1.62681 19.8879 2.09487 20.3459 2.67057 20.633C3.32506 20.9593 4.18183 20.9593 5.89536 20.9593H12.4225C14.1361 20.9593 14.9928 20.9593 15.6473 20.633C16.223 20.3459 16.6911 19.8879 16.9844 19.3246C17.3179 18.6841 17.3179 17.8458 17.3179 16.169V6.98778L11.1987 1Z" stroke={bg[index].slice(0, 7)} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            </div>
                                            <span className="fs-18 clr-navy " >{item.title}</span>
                                        </div>
                                    </div>
                                    
                                    <div>{
                                        item.reports.map((report, ind) => {
                                            return (
                                                <div onClick={() => { return report.extra !== null ? navigate.push({ pathname: report.extra }) : null }}  className='flex hover-blue grid m-0 p-2 justify-content-between align-items-center'>

                                                <label className="flex fw-4 fs-16" >{ report.title}</label>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.92438 13.4538C8.82389 13.4543 8.72431 13.4351 8.63148 13.3974C8.53866 13.3597 8.45447 13.3043 8.38385 13.2343C8.24061 13.0939 8.16016 12.9037 8.16016 12.7054C8.16016 12.507 8.24061 12.3168 8.38385 12.1764L13.9625 6.71758L8.38385 1.25872C8.24874 1.11684 8.17519 0.929174 8.17868 0.735269C8.18218 0.541363 8.26245 0.356356 8.4026 0.219222C8.54274 0.0820893 8.73181 0.00353784 8.92997 0.000116609C9.12813 -0.00330462 9.31991 0.0686714 9.46491 0.200882L15.5841 6.18866C15.7274 6.329 15.8078 6.51924 15.8078 6.71758C15.8078 6.91593 15.7274 7.10616 15.5841 7.2465L9.46491 13.2343C9.39429 13.3043 9.3101 13.3597 9.21728 13.3974C9.12446 13.4351 9.02488 13.4543 8.92438 13.4538Z" fill="#315975"/>
<path d="M8.92438 13.4538C8.82389 13.4543 8.72431 13.4351 8.63148 13.3974C8.53866 13.3597 8.45447 13.3043 8.38385 13.2343C8.24061 13.0939 8.16016 12.9037 8.16016 12.7054C8.16016 12.507 8.24061 12.3168 8.38385 12.1764L13.9625 6.71758L8.38385 1.25872C8.24874 1.11684 8.17519 0.929174 8.17868 0.735269C8.18218 0.541363 8.26245 0.356356 8.4026 0.219222C8.54274 0.0820893 8.73181 0.00353784 8.92997 0.000116609C9.12813 -0.00330462 9.31991 0.0686714 9.46491 0.200882L15.5841 6.18866C15.7274 6.329 15.8078 6.51924 15.8078 6.71758C15.8078 6.91593 15.7274 7.10616 15.5841 7.2465L9.46491 13.2343C9.39429 13.3043 9.3101 13.3597 9.21728 13.3974C9.12446 13.4351 9.02488 13.4543 8.92438 13.4538Z" fill="black" fill-opacity="0.2"/>
<path d="M15.043 7.46569H0.764901C0.562036 7.46569 0.367481 7.38684 0.224034 7.24647C0.0805874 7.10611 0 6.91573 0 6.71722C0 6.51872 0.0805874 6.32834 0.224034 6.18797C0.367481 6.04761 0.562036 5.96875 0.764901 5.96875H15.043C15.2459 5.96875 15.4405 6.04761 15.5839 6.18797C15.7274 6.32834 15.8079 6.51872 15.8079 6.71722C15.8079 6.91573 15.7274 7.10611 15.5839 7.24647C15.4405 7.38684 15.2459 7.46569 15.043 7.46569Z" fill="#315975"/>
<path d="M15.043 7.46569H0.764901C0.562036 7.46569 0.367481 7.38684 0.224034 7.24647C0.0805874 7.10611 0 6.91573 0 6.71722C0 6.51872 0.0805874 6.32834 0.224034 6.18797C0.367481 6.04761 0.562036 5.96875 0.764901 5.96875H15.043C15.2459 5.96875 15.4405 6.04761 15.5839 6.18797C15.7274 6.32834 15.8079 6.51872 15.8079 6.71722C15.8079 6.91573 15.7274 7.10611 15.5839 7.24647C15.4405 7.38684 15.2459 7.46569 15.043 7.46569Z" fill="black" fill-opacity="0.2"/>
</svg>

                                            
                                            </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        )
                    })

                    }


                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ReportListing, comparisonFn);
