import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const data = [
  {
    year: "2019",
    Male_FullTime: 50,
    Female_FullTime: 45,
    Male_PartTime: 35,
    Female_PartTime: 30,
  },
  {
    year: "2020",
    Male_FullTime: 60,
    Female_FullTime: 50,
    Male_PartTime: 40,
    Female_PartTime: 35,
  },
  {
    year: "2021",
    Male_FullTime: 45,
    Female_FullTime: 42,
    Male_PartTime: 28,
    Female_PartTime: 25,
  },
  {
    year: "2022",
    Male_FullTime: 70,
    Female_FullTime: 60,
    Male_PartTime: 50,
    Female_PartTime: 48,
  },
  {
    year: "2023",
    Male_FullTime: 55,
    Female_FullTime: 52,
    Male_PartTime: 35,
    Female_PartTime: 33,
  },
];

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color, marginRight: 20 }}
        >
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          <span style={{ color: "#555", fontSize: "16px" }}>
            {entry.value === "Male_FullTime"
              ? "Male Full Time"
              : entry.value === "Female_FullTime"
              ? "Female Full Time"
              : entry.value === "Male_PartTime"
              ? "Male Part Time"
              : "Female Part Time"}
          </span>
        </li>
      ))}
    </ul>
  );
};

const SocialChart1 = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Employees by Contract Type and Gender{" "}
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideLeft",
                  offset: 0,
                  style: { textAnchor: "middle", fill: "#555" },
                }}
              />{" "}
              <Tooltip />
              <Legend />
              <Bar
                dataKey="Male_FullTime"
                fill="#BDD1F7"
                barSize={30}
                name="Male"
              />
              <Bar
                dataKey="Female_FullTime"
                name="Female"
                fill="#FF9D7C"
                barSize={30}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data} tableClassName="font-lato">
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Male Full Time"
              style={{ minWidth: "8%" }}
              field="Male_FullTime"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Female Full Time"
              style={{ minWidth: "8%" }}
              field="Female_FullTime"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Male Part Time"
              style={{ minWidth: "8%" }}
              field="Male_PartTime"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Female Part Time"
              style={{ minWidth: "8%" }}
              field="Female_PartTime"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default SocialChart1;
