import React, { useEffect, useState, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { canvasPreview } from "../../components/canvasPreview";

import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"
import { IoMdArrowDropright, IoMdCheckboxOutline } from "react-icons/io";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { Button } from "primereact/button";
import { Route, useHistory, useLocation } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OrderList } from "primereact/orderlist";
import useForceUpdate from "use-force-update";
import { ListBox } from "primereact/listbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import $ from 'jquery'
import axios from "axios";
import IATACodes from "../../assets/dummy/IATACodes";
import { AutoComplete as AC } from "primereact/autocomplete";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import readXlsxFile from "read-excel-file";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { setRef } from "@fullcalendar/core";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2";
import "react-image-crop/dist/ReactCrop.css";
import exportAsImage from "../../components/exportAsImage";
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";

import { useSelector } from "react-redux";

import cx from "classnames";
import { InputNumber } from 'primereact/inputnumber'
import { render } from "react-dom";
import { MultiSelect } from "primereact/multiselect";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import { Chips } from "primereact/chips";
import { DateTime } from "luxon";
const google = window.google;

// (0.79/1000)

const AddClient = () => {
    const [data, setData] = useState([]);
    const [selectedConfigIndex, setSelectedConfigIndex] = useState(null);
    const [adddialog, setAddDialog] = useState(false);
    const [cropdialog, setCropDialog] = useState(false);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [config, setConfig] = useState({ location: { countrylist: [], newcountry: "", newcity: "", newlocation: "", selectedcountry: "", citylist: [], selectedcity: "", locationlist: [], selectedlocation: "" } });
    const [selectedconfig, setSelectedConfig] = useState({ name: "Location" });
    const [configdialog, setConfigDialog] = useState(false);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [client, setClient] = useState({userPortalUrl:'',adminPortalUrl:'',supplierPortalUrl:'',ssoLogin:false, coginfo:{"REACT_APP_AWS_DOMAIN":'',"REACT_APP_AWS_REGION":'',"REACT_APP_AWS_CLIENT_ID":'',"REACT_APP_REDIRECT_URL":'',"REACT_APP_AWS_USER_POOL_ID":'',"REACT_APP_SUB_DOMAIN":''}, access: { navigos: false, materiality: false }, tierLabel: ['Country', 'City', 'Site'], fyStartMonth: null, companyname: "", enterpriseid: '', escalation_mail_ids: [], currency: null, companycontact: "", startdate: null, contactpersonmail: "", website: "", contactperson: "", contactpersonno: "", companyaddress: "", config: [], email: "", companylogo: null, report: [], user_remainder_mail_date: 1, user_escalation_mail_date: 1, others_escalation_mail_date: 1, reviewer_remainder_mail_date: 1, reviewer_escalation_mail_date: 1, approver_remainder_mail_date: 1, approver_escalation_mail_date: 1 });
    const [submitted, setSubmitted] = useState(false);
    const [filter, setFilter] = useState("");
    const [apidata, setAPiData] = useState([]);
    const forceUpdate = useForceUpdate();
    const dt = useRef(null);
    const [loctree, setLocTree] = useState([])
    const selector = useSelector((state) => state.user.admindetail);
    let configmenu = [{ name: "Location" }, { name: "Module" }];
    const currencyList = [{ name: 'INDIA', value: 'INR' }, { name: 'Singapore', value: 'SGD' }, { name: 'Australia', value: 'AUD' }, { name: 'United Kingdom', value: 'GBP' }, { name: 'United States', value: 'USD' }, { name: 'United Arab Emirates', value: 'AED' }, { name: 'Kuwait', value: 'KWD' }]
    const groupedReports = [
        {
            label: 'Sustainability',

            items: [
                { label: 'GRI', value: 11 },
                { label: 'SASB', value: 12 }

            ]
        },
        {
            label: 'Climate Change',

            items: [
                { label: 'GHG Inventory', value: 21 },
                { label: 'TCFD', value: 22 },
            ]
        },
        {
            label: 'Stock Exchange',

            items: [
                { label: 'Boursa Kuwait', value: 31 },
                { label: 'Bursa Malaysia', value: 32 },
                { label: 'HKEX', value: 33 },
                { label: 'NASDAQ', value: 34 },
                { label: 'SGX', value: 35 },
                { label: 'SEBI (BRSR)', value: 36 }

            ]
        },
        {
            label: 'Rating Agencies',

            items: [
                { label: 'CDP', value: 41 },
                { label: 'EcoVadis', value: 42 },
                { label: 'MSCI', value: 43 },
                { label: 'S&P', value: 44 },
                { label: 'Sustainalytics', value: 45 },


            ]
        },
        {
            label: 'Customer Specific',

            items: [
                { label: 'MCfS', value: 51 },
                { label: 'Unilever', value: 52 }


            ]
        },
        {
            label: 'Investor Specific',

            items: [
                { label: 'Goldman Sachs', value: 61 },
                { label: 'EQT', value: 62 }


            ]
        },
        {
            label: 'Enterprise Sustainability',

            items: [
                { label: 'ESG Report', value: 71 },
                { label: 'CSR Report', value: 72 }


            ]
        },
        {
            label: 'Regulatory Requirements',

            items: [
                { label: 'MOEFCC', value: 81 }


            ]
        }
    ];
    const [standardlist, setStandardList] = useState([])
    const groupedReportTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.title}</div>
            </div>
        );
    };
    const selectedReportTemplate = (option) => {
        console.log(option)
        if (option) {
            return (
                <div className="country-item country-item-value" style={{
                    margin: 10,
                    display: 'flex',
                    alignItems: 'center'
                }}>

                    <div>{option}</div>
                </div>
            );
        }

        return 'Select Report';
    }
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const exportExcel = ()=>{
        let clientList = [],excelToData=[]
         for(const item of apidata){
            if(item.role === 'clientadmin'){
              clientList.push({'Enterprise Name':item.information.companyname,id:item.id,'Enterprise Email':item.email, "user": apidata.filter(i => i.clientId === item.id && (i.role=== "clientuser"|| i.role === 'clientsupplier') ).map(i=> ({"User Name":i.information.empname,"User Email":i.email}))  })
            }
         }
         for(const item of clientList){
            if(item.user){
                for (const item2 of item.user){
                    let obj={'Enterprise Name':item['Enterprise Name'],id:item['id'],'Enterprise Email':item['Enterprise Email']}
                    excelToData.push({...obj, ...item2})
                }
            }else{
                let obj={'Enterprise Name':item['Enterprise Name'],id:item['id'],'Enterprise Email':item['Enterprise Email']}

                excelToData.push(obj)
            }
          
         }
         const wsData = [
            [],
            ['','Enterprise Name', 'id', 'Enterprise Email', 'User Name', 'User Email'] // Headers start from 2nd row and 2nd column
          ];
        
          // Adding data starting from 3rd row and 2nd column
          excelToData.forEach(item => {
            const row = ['', item['Enterprise Name'], item['id'], item['Enterprise Email'], item['User Name'] || '', item['User Email'] || ''];
            wsData.push(row);
          });
        
          // Create worksheet
          const ws = XLSX.utils.aoa_to_sheet(wsData);
        
          // Create workbook and add the worksheet
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'EiSqr');
        
          // Exporting the workbook
          XLSX.writeFile(wb, 'Admin_User_List.xlsx');
    }

    useEffect(() => {

        renderData();
    }, []);
    const renderData = () => {
        let datas = [];
        let promise = new Promise((resolve, error) => {
            APIServices.get(API.UserProfile).then((res) => {
                setAPiData(res.data);

                res.data.forEach((item, index) => {
                    if (item.role === "clientadmin") {
                        datas.push({
                            id: item.id,
                            userPortalUrl:item.userPortalUrl ? item.userPortalUrl:'' ,adminPortalUrl:item.adminPortalUrl ? item.adminPortalUrl:'',supplierPortalUrl:item.supplierPortalUrl ? item.supplierPortalUrl:'',ssoLogin:(item.ssoLogin !== undefined && item.ssoLogin !== null) ? item.ssoLogin :false, 
                            contactpersonmail: item.information.contactpersonmail,
                            contactpersonno: item.information.contactpersonno,
                            companyname: item.information.companyname,
                            contactperson: item.information.contactperson,
                            email: item.email,
                            currency: item.information.currency === undefined ? null : item.information.currency,
                            config: item.information.config,
                            companylogo: item.information.companylogo, item
                        });
                    }
                    if (index === res.data.length - 1) {
                        resolve(datas);
                    }
                });
            });
        });

        promise.then((d) => {
            setData(d);
            forceUpdate();
        });

        APIServices.get(API.Report_Name_Ones + `?filter=${encodeURIComponent(JSON.stringify({ 'include': ['reportNameTwos'] }))}`).then((res) => {
            setStandardList(res.data.filter((i) => { return i.reportNameTwos !== undefined && i.reportNameTwos.length !== 0 }))
        })

        let folder = {
            name: "", children: []
        }
        // APIServices.all(endpoints.map((endpoint) => APIServices.get(endpoint))).then(
        //     (data) => {

        //         data[0].data.forEach((i) => {
        //             folder.children.push({ name: i.title, id: i.id, children: [] })
        //         })
        //         folder.children.forEach((j) => {
        //             j.children = data[1].data.filter((scope) => { return scope.moduleNameId === j.id }).map((scopei) => { let d = { id: scopei.id, name: scopei.title, children: [] }; return d })
        //             console.log(j)
        //         })
        //         folder.children.forEach((j) => {
        //             j.children.forEach((k) => {
        //                 k.children = data[2].data.filter((topic) => { return topic.scopeNameId === k.id }).map((topici) => { let d = { id: topici.id, name: topici.title }; return d })

        //             })
        //         })
        //         setLocTree(folder)
        //     }
        // );
    };
    const CheckBoxIcon = ({ variant, ...rest }) => {
        switch (variant) {
            case "all":
                return <TbCheckbox style={{ fontSize: 19 }} {...rest} />;
            case "none":
                return <MdCheckBoxOutlineBlank style={{ fontSize: 19 }} {...rest} />;
            case "some":
                return <MdOutlineIndeterminateCheckBox style={{ fontSize: 19 }} {...rest} />;
            default:
                return null;
        }
    };
    const ArrowIcon = ({ isOpen, className }) => {
        const baseClass = "arrow";
        const classes = cx(
            baseClass,
            { [`${baseClass}--closed`]: !isOpen },
            { [`${baseClass}--open`]: isOpen },
            className
        );
        return <MdPlayArrow className={classes} />;
    };
    const updateTree = (data, i, j) => {
        console.log(data)
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="mr-2"
                        style={{ width: 100 }}
                        onClick={() => {
                            setAddDialog(true);
                        }}
                    />
                         {/* <Button
                        label="Export"
                        icon="pi pi-export"
                        className="p-button-success mr-2"
                        style={{ width: 100 }}
                        onClick={() => {
                            exportExcel(true);
                        }}
                    /> */}
                </div>
            </React.Fragment>
            <span className="block mt-2 md:mt-0 p-input-icon-left" style={{ width: "40%" }}>
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );

    const checkValidMailID = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };
    const checkValidMailID_2 = (email) => {
        if (email.trim().length === 0) {
            return true
        } else {
            return String(email)
                .toLowerCase()
                .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        }
    };
    const check_url = (url) => {
        if (url.trim().length !== 0) {
            var url_pattern = new RegExp("((http|https)(:\/\/))?([a-zA-Z0-9]+[.]{1}){2}[a-zA-z0-9]+(\/{1}[a-zA-Z0-9]+)*\/?", "i");

            if (url_pattern.exec(url) == null || url_pattern.exec(url).index > 0) {
                return false;
            } else {
                return true;

            }
        } else {
            return true
        }
    }
    const addNewClient = () => {
        if (client.id === undefined) {
            let loc = data;
            setSubmitted(true);
            console.log(client)
            if ( client.fyStartMonth !== null && client.tierLabel && client.tierLabel.length === 3 && client.adminPortalUrl && isValidURL(client.adminPortalUrl) && client.userPortalUrl && isValidURL(client.userPortalUrl) && ( client.supplierPortalUrl ? isValidURL(client.supplierPortalUrl) : true ) &&
                client.access !== undefined && client.access !== null && (client.access.navigos || client.access.materiality) &&
                checkValidMailID(client.email.trim()) && client.startdate !== null &&
                client.currency != null &&
                client.enterpriseid.trim().length !== 0 &&
                //  checkValidMailID_2(client.contactpersonmail.trim()) &&
                check_url(client.website.trim()) &&
                client.companylogo !== null &&
                client.companyname.trim().length !== 0 &&
                (client.contactpersonno.trim().length === 0 || client.contactpersonno.trim().length > 7) &&

                // client.companyaddress.trim().length !== 0 &&
                // client.companycontact.trim().length > 7 &&
                client.contactperson.trim().length !== 0 &&
                (client.companycontact.trim().length === 0 || client.companycontact.trim().length > 7)
                // client.contactpersonno.trim().length > 7 &&
                // client.config.length !== 0

            ) {


                let obj = client;
                let newObj = { information: {} };
                Object.keys(obj).forEach((i) => {
                    if(i !== 'fyStartMonth' && i !== 'tierLabel' && i !== 'access' && i !== 'coginfo'&& i !== 'supplierPortalUrl'  && i !== 'userPortalUrl'  && i !== 'adminPortalUrl'  && i !== 'ssoLogin' ){
                        if (i === "email") {
                            newObj["email"] = obj[i];
                        } else {
                            if (i === 'companycontact' || i === 'contactpersonno') {
                                if (obj[i].trim().length > 7) {
                                    newObj.information[i] = obj[i];
                                } else {
                                    newObj.information[i] = ""
                                }
                            } else {
                                newObj.information[i] = obj[i];
                            }
    
                        }
                    }
                 
                });
                newObj.information['empname'] = 'Enterprise Admin'
                newObj["role"] = "clientadmin";
                newObj["access"] = client.access
                newObj["supplierPortalUrl"] = client.supplierPortalUrl
                newObj["adminPortalUrl"] = client.adminPortalUrl
                newObj["userPortalUrl"] = client.userPortalUrl
                newObj["ssoLogin"] = client.ssoLogin



                newObj["coginfo"] = client.coginfo
                newObj['tierLabel'] = client.tierLabel
                newObj['fyStartMonth'] = client.fyStartMonth

                console.log(newObj)
                //build
                APIServices.post(API.NewUserProfile, newObj)
                    .then((res) => {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: `Client added successfully`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        loc.push(client);
                        setData(loc);
                        renderData();
                        forceUpdate();
                    })
                    .catch((e) => {
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `Email Id exist / check internet connection `,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });

                setAddDialog(false);
                setClient({userPortalUrl:'',adminPortalUrl:'',supplierPortalUrl:'',ssoLogin:false,coginfo:{"REACT_APP_AWS_DOMAIN":'',"REACT_APP_AWS_REGION":'',"REACT_APP_AWS_CLIENT_ID":'',"REACT_APP_REDIRECT_URL":'',"REACT_APP_AWS_USER_POOL_ID":'',"REACT_APP_SUB_DOMAIN":''}, access: { navigos: false, materiality: false }, tierLabel: ['Country', 'City', 'Site'], fyStartMonth: null, companyname: "", enterpriseid: '', escalation_mail_ids: [], startdate: null, currency: null, companycontact: "", contactpersonmail: "", website: "", contactperson: "", contactpersonno: "", companyaddress: "", config: [], email: "", companylogo: null, report: [], user_remainder_mail_date: 1, user_escalation_mail_date: 1, others_escalation_mail_date: 1, reviewer_remainder_mail_date: 1, reviewer_escalation_mail_date: 1, approver_remainder_mail_date: 1, approver_escalation_mail_date: 1 });

                forceUpdate();
                setSubmitted(false);
            }
        } else {
            setSubmitted(true);
            if (
                client.fyStartMonth !== null && client.tierLabel && client.tierLabel.length === 3 && client.adminPortalUrl && isValidURL(client.adminPortalUrl) && client.userPortalUrl && isValidURL(client.userPortalUrl) && ( client.supplierPortalUrl ? isValidURL(client.supplierPortalUrl) : true ) &&
                client.access !== undefined && client.access !== null && (client.access.navigos || client.access.materiality) &&
                checkValidMailID(client.email.trim()) && client.startdate !== null &&
                client.currency != null &&
                client.enterpriseid.trim().length !== 0 &&
                // checkValidMailID(client.contactpersonmail.trim()) &&
                check_url(client.website.trim()) &&
                client.companylogo !== null &&
                client.companyname.trim().length !== 0 &&
                // client.companyaddress.trim().length !== 0 &&
                // client.companycontact.trim().length > 7 &&
                (client.companycontact.trim().length === 0 || client.companycontact.trim().length > 7) &&

                client.contactperson.trim().length !== 0 &&
                // client.contactpersonno.trim().length > 7 &&
                (client.contactpersonno.trim().length === 0 || client.contactpersonno.trim().length > 7)
            ) {



                let obj = client;
                let newObj = { information: {} };
               
                Object.keys(obj).forEach((i) => {
                    if(i !== 'fyStartMonth' && i !== 'tierLabel' && i !== 'access' && i !== 'coginfo'  && i !== 'supplierPortalUrl'  && i !== 'userPortalUrl'  && i !== 'adminPortalUrl'  && i !== 'ssoLogin' ){
                        if (i === "email" || i === "id") {
                           
                        } else {
                            if (i === 'companycontact' || i === 'contactpersonno') {
                                if (obj[i].trim().length > 7) {
                                    newObj.information[i] = obj[i];
                                } else {
                                    newObj.information[i] = ""
                                }
                            } else {
                                newObj.information[i] = obj[i];
                            }
    
                        }
                    }
                });
                newObj["access"] = client.access
                newObj['tierLabel'] = client.tierLabel
                newObj["coginfo"] = client.coginfo
                newObj['fyStartMonth'] = client.fyStartMonth
                newObj["role"] = "clientadmin";
                newObj["supplierPortalUrl"] = client.supplierPortalUrl
                newObj["adminPortalUrl"] = client.adminPortalUrl
                newObj["userPortalUrl"] = client.userPortalUrl
                newObj["ssoLogin"] = client.ssoLogin
                newObj.information['empname'] = 'Enterprise Admin'
                //build
                console.log(newObj)
                APIServices.patch(API.UserProfile_Edit(client.id), newObj)
                    .then((res) => {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: `Client Updated successfully`,
                            showConfirmButton: false,
                            timer: 1500,
                        });

                        renderData();
                        forceUpdate();
                    })
                    .catch((e) => {
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });

                setAddDialog(false);
                setClient({userPortalUrl:'',adminPortalUrl:'',supplierPortalUrl:'',ssoLogin:false, coginfo:{"REACT_APP_AWS_DOMAIN":'',"REACT_APP_AWS_REGION":'',"REACT_APP_AWS_CLIENT_ID":'',"REACT_APP_REDIRECT_URL":'',"REACT_APP_AWS_USER_POOL_ID":'',"REACT_APP_SUB_DOMAIN":''},access: { navigos: false, materiality: false }, tierLabel: ['Country', 'City', 'Site'], fyStartMonth: null, companyname: "", enterpriseid: '', escalation_mail_ids: [], companycontact: "", currency: null, startdate: null, contactpersonmail: "", website: "", contactperson: "", contactpersonno: "", companyaddress: "", config: [], email: "", companylogo: null, report: [], user_remainder_mail_date: 1, user_escalation_mail_date: 1, others_escalation_mail_date: 1, reviewer_remainder_mail_date: 1, reviewer_escalation_mail_date: 1, approver_remainder_mail_date: 1, approver_escalation_mail_date: 1 });

                forceUpdate();
                setSubmitted(false);
            }
        }
    };
    const addDialogFooter = () => {
        return (
            <div>
                <Button
                    label="Submit"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={() => {
                        addNewClient();
                    }}
                />
            </div>
        );
    };
    const showModules = () => {

        // $('module_tree_div').jstree({
        //     'core': {
        //         'data': [
        //             { "id": "ajson1", "parent": "#", "text": "Simple root node" },
        //             { "id": "ajson2", "parent": "#", "text": "Root node 2" },
        //             { "id": "ajson3", "parent": "ajson2", "text": "Child 1" },
        //             { "id": "ajson4", "parent": "ajson2", "text": "Child 2" },
        //         ]
        //     }
        // });
    }
    const updateDataConfig = (data2) => {
        let id = data[selectedConfigIndex].id;
        let apidatalocal = apidata;
        let apiIndex = apidata.findIndex((i) => {
            return i.id === id;
        });

        let newObj = apidatalocal[apiIndex];
        delete newObj.username;
        delete newObj.email;

        newObj.information.config = data2;
        newObj.company = selector.information.companyname;

        let promise = new Promise((resolve, error) => {
            APIServices.patch(API.UserProfile_Edit(id), newObj).then((res) => {
                if (res.status === 200) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
        promise.then((status) => {
            if (status) {
                renderData();
            }
        });
    };
    const updateConfig = () => {
        if (config.location.countrylist.length !== 0) {
            let error = 0;
            config.location.countrylist.forEach((i) => {
                if (i.city.length !== 0) {
                    i.city.forEach((j) => {
                        if (j.location.length === 0) {
                            error = 1;
                        }
                    });
                } else {
                    error = 1;
                }
            });
            if (error === 0) {
                if (selectedConfigIndex !== null) {
                    let loc = data;
                    loc[selectedConfigIndex].config = [config];
                    updateDataConfig([config]);
                } else {
                    let loc2 = client;
                    loc2.config = [config];
                    setClient(loc2);
                }
                setSelectedConfigIndex(null);
                setConfigDialog(false);
                forceUpdate();
            } else {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Missing City/Location for country",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } else {
            let loc2 = client;
            loc2.config = [];
            setClient(loc2);
            setSelectedConfigIndex(null);
            setConfigDialog(false);
            forceUpdate();
        }
    };
    const addDialogFooter2 = (rowData) => {
        return (
            <div>
                <Button
                    label="Submit"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={() => {
                        updateConfig("1");
                    }}
                />
            </div>
        );
    };
    const cropandsave = async () => {
        let canvas = previewCanvasRef.current;
        let URLs = canvas.toDataURL("image/png");
        let img = document.createElement("img");
        img.src = URLs;
        img.style.height = canvas.height;
        img.style.width = canvas.width;

        setTimeout(() => {
            let newBlob = "";
            // atob to base64_decode the data-URI
            var image_data = atob(URLs.split(",")[1]);
            // Use typed arrays to convert the binary data to a Blob
            var arraybuffer = new ArrayBuffer(image_data.length);
            var view = new Uint8Array(arraybuffer);
            for (var i = 0; i < image_data.length; i++) {
                view[i] = image_data.charCodeAt(i) & 0xff;
            }
            try {
                // This is the recommended method:
                var blob = new Blob([arraybuffer], { type: "application/octet-stream" });
            } catch (e) {
                // The BlobBuilder API has been deprecated in favour of Blob, but older
                // browsers don't know about the Blob constructor
                // IE10 also supports BlobBuilder, but since the `Blob` constructor
                //  also works, there's no need to add `MSBlobBuilder`.
                var bb = new (window.WebKitBlobBuilder || window.MozBlobBuilder)();
                bb.append(arraybuffer);
                var blob = bb.getBlob("application/octet-stream"); // <-- Here's the Blob
            }

            // Use the URL object to create a temporary URL
            var url = (window.webkitURL || window.URL).createObjectURL(blob);

            fetch(url)
                .then((res) => res.blob())
                .then((myBlob) => {
                    myBlob.name = Date.now() + "STTESG" + ".jpeg";
                    myBlob.lastModified = new Date();
                    const myFile = new File([myBlob], Date.now() + "STTESG" + ".png", {
                        type: myBlob.type,
                    });

                    uploadFilesApi(myFile);
                    // logs: Blob { size: 1024, type: "image/jpeg" }
                });
        }, 1000);
    };
    useEffect(() => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0);
        }
    }, [completedCrop, crop]);
    const cropDialogFooter = () => {
        return (
            <div>
                <>
                    <Button
                        label="Cancel"
                        icon="pi pi-times"
                        className="p-button-text"
                        onClick={() => {
                            let loc = client;
                            loc.img = null;
                            setClient(loc);
                            setCropDialog(false);
                        }}
                    />
                    <Button
                        label="Crop & Save"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={() => {
                            cropandsave();
                        }}
                    />
                </>
            </div>
        );
    };
    const nameTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.companyname}
            </>
        );
    };
    const logoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                <img src={rowData.companylogo} width="50" />
            </>
        );
    };
    const editTemplate = (rowData) => {

        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <i onMouseOut={(e) => { e.currentTarget.style.color = '#495057' }} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'green' }} onClick={() => { editClient(rowData) }} className='material-icons'>edit</i>
                <i onMouseOut={(e) => { e.currentTarget.style.color = '#495057' }} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'red' }} onClick={() => { deleteClient(rowData) }} className='material-icons'>delete</i>
            </div>
        );
    }
    const configTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                <i
                    onClick={() => {
                        openConfig(rowData);
                    }}
                    className="material-icons"
                >
                    settings
                </i>
            </>
        );
    };
    const uploadFilesApi = async (file) => {
        let formData = new FormData();
        formData.append("file", file);
        APIServices.post(API.FilesUpload,
            formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
            mode: "no-cors"
        }
        ).then((res) => {
            if (res.status === 200) {
                let loc = client;
                loc.companylogo = API.Docs + res.data.files[0].originalname;

                setClient(client);
                setCropDialog(false);
                // resolve(`https://api.acuizen.com/docs/${file.companyname}`)
            } else {
            }
        });
    };
    console.log(client)
    const addConfigCountry = () => {
        if (config.location.newcountry !== "") {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => {
                return i.country.trim().toLowerCase() === config.location.newcountry.trim().toLowerCase();
            });

            if (index === -1) {
                loc.location.countrylist.push({ country: config.location.newcountry, city: [] });
                loc.location.newcountry = "";
                setConfig(loc);
                forceUpdate();
            }
        }
    };
    const addConfigCity = () => {
        if (config.location.newcity !== "") {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => {
                return i.country.trim().toLowerCase() === config.location.selectedcountry.trim().toLowerCase();
            });

            let index2 = loc.location.countrylist[index].city.findIndex((i) => {
                return i.city.trim().toLowerCase() === config.location.newcity.trim().toLowerCase();
            });
            if (index2 === -1) {
                loc.location.countrylist[index].city.push({ city: config.location.newcity, location: [] });
                loc.location.newcity = "";
                setConfig(loc);
                forceUpdate();
            }
        }
    };
    const addConfigLocation = () => {
        if (config.location.newlocation !== "") {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => {
                return i.country.trim().toLowerCase() === config.location.selectedcountry.trim().toLowerCase();
            });

            let index2 = loc.location.countrylist[index].city.findIndex((i) => {
                return i.city.trim().toLowerCase() === config.location.selectedcity.trim().toLowerCase();
            });

            let index3 = loc.location.countrylist[index].city[index2].location.findIndex((i) => {
                return i.location.trim().toLowerCase() === config.location.newlocation.trim().toLowerCase();
            });

            if (index3 === -1) {
                loc.location.countrylist[index].city[index2].location.push({ location: config.location.newlocation });
                loc.location.newlocation = "";
                setConfig(loc);
                forceUpdate();
            }
        }
    };
    const openConfig = (config) => {
        setSelectedConfigIndex(
            data.findIndex((item) => {
                return item.companyname === config.companyname;
            })
        );

        if (config.config.length === 0) {
            setConfig({ location: { countrylist: [], newcountry: "", newcity: "", newlocation: "", selectedcountry: "", citylist: [], selectedcity: "", locationlist: [], selectedlocation: "" } });
        } else {
            setConfig(config.config[0]);
        }

        setConfigDialog(true);
    };
    const contactTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.contactperson}
            </>
        );
    };
    const contactEmailTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.contactpersonmail}
            </>
        );
    };
    const contactNoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.contactpersonno}
            </>
        );
    };
    const editClient = (client) => {
        let stdlist = JSON.parse(JSON.stringify(standardlist))
        let stdList = []
        stdlist.forEach((i) => {
            stdList.push(...i.reportNameTwos)
        })
        let fyStartMonth = client.item.fyStartMonth ? DateTime.fromISO(client.item.fyStartMonth,{zone:'utc'}).toLocal().toJSDate()  : null
        let tierLabel = client.item.tierLabel ? client.item.tierLabel : ['Country', 'City', 'Site']
        let access = (client.item.access === undefined || client.item.access === null) ? { navigos: false, materiality: false } : client.item.access
        let coginfo = client.item.coginfo ? client.item.coginfo : {"REACT_APP_AWS_DOMAIN":'',"REACT_APP_AWS_REGION":'',"REACT_APP_AWS_CLIENT_ID":'',"REACT_APP_REDIRECT_URL":'',"REACT_APP_AWS_USER_POOL_ID":'',"REACT_APP_SUB_DOMAIN":''}
        client.item.information.report = stdList.filter((i) => { return client.item.information.report.includes(i.id) }).map((j) => { return j.id })
        console.log(client)
        setClient({
            coginfo, access, tierLabel, fyStartMonth,userPortalUrl:client.userPortalUrl,adminPortalUrl:client.adminPortalUrl,supplierPortalUrl:client.supplierPortalUrl,ssoLogin:client.ssoLogin,
            id: client.item.id, currency: client.item.information.currency === undefined ? null : client.item.information.currency, startdate: moment(client.item.information.startdate).local()._d, companyname: client.item.information.companyname, companycontact: client.item.information.companycontact, contactpersonmail: client.item.information.contactpersonmail, website: client.item.information.website, contactperson: client.item.information.contactperson, contactpersonno: client.item.information.contactpersonno, companyaddress: client.item.information.companyaddress, config: client.item.information.config, email: client.item.email, companylogo: client.item.information.companylogo, report: client.item.information.report, enterpriseid: client.item.information.enterpriseid === undefined ? '' : client.item.information.enterpriseid, escalation_mail_ids: client.item.information.escalation_mail_ids === undefined ? [] : client.item.information.escalation_mail_ids,
            user_remainder_mail_date: client.item.information.user_remainder_mail_date === undefined ? 15 : getJSObject(client.item.information.user_remainder_mail_date),
            user_escalation_mail_date: client.item.information.user_escalation_mail_date === undefined ? 20 : getJSObject(client.item.information.user_escalation_mail_date),
            others_escalation_mail_date: client.item.information.others_escalation_mail_date === undefined ? 25 : getJSObject(client.item.information.others_escalation_mail_date),
            reviewer_remainder_mail_date: client.item.information.reviewer_remainder_mail_date === undefined ? 15 : getJSObject(client.item.information.reviewer_remainder_mail_date),
            reviewer_escalation_mail_date: client.item.information.reviewer_escalation_mail_date === undefined ? 20 : getJSObject(client.item.information.reviewer_escalation_mail_date),
            approver_remainder_mail_date: client.item.information.approver_remainder_mail_date === undefined ? 15 : getJSObject(client.item.information.approver_remainder_mail_date),
            approver_escalation_mail_date: client.item.information.approver_escalation_mail_date === undefined ? 20 : getJSObject(client.item.information.approver_escalation_mail_date),
        });
        setAddDialog(true)
    }
    const getJSObject = (date_) => {
        return date_
    }
    const deleteClient = (client) => {
        alert('Delete feature is disabled by tech team')

        // Swal.fire({
        //     title: 'Delete Enterprise ? ' + client.companyname,
        //     text: "You won't be able to reuse mail id " + client.email,
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes, delete Client!'
        // }).then((result) => {
        //     if (result.isConfirmed) {

        //         APIServices.delete( API.UserProfile_Edit(client.id) )
        //             .then((res) => {


        //                 Swal.fire({
        //                     position: 'center',
        //                     icon: 'success',
        //                     title: `Client deleted successfully`,
        //                     showConfirmButton: false,
        //                     timer: 1500
        //                 })

        //                 renderData()
        //             }).catch((e) => {

        //                 Swal.fire({
        //                     position: 'center',
        //                     icon: 'error',
        //                     title: `Something went wrong`,
        //                     showConfirmButton: false,
        //                     timer: 1500
        //                 })

        //             })
        //     }
        // })
    }
    const emailTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.email}
            </>
        );
        
    };
    function isValidURL(url) {
        const regex = /^(https?:\/\/)(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|(\d{1,3}\.){3}\d{1,3})(:\d+)?(\/[^\s]*)?$/;
        return regex.test(url);
      }
    const updateClient = (obj, val) => {
        let loc = client;
        loc[obj] = val;
        setClient(loc);
        forceUpdate();
    };
    const updateClientCogInfo = (obj, val) => {
        let loc = client;
        loc.coginfo[obj] = val;
        setClient(loc);
        forceUpdate();
    };
    const onRoleChange = (e, obj) => {
        let loc = client
        loc.report = e.value
        setClient(loc)
        console.log(loc)
        forceUpdate()
    }
    const onAccessChange = (val, obj) => {
        let loc = client
        loc.access[obj] = val
        setClient(loc)
        console.log(loc)
        forceUpdate()
    }
    const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: "%",
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );
    };
    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 1));
    };
    const getCountryIndex = () => {
        let index = config.location.countrylist.findIndex((i) => {
            return i.country === config.location.selectedcountry;
        });

        return index;
    };
    const getCityIndex = () => {
        let index = config.location.countrylist[getCountryIndex()].city.findIndex((i) => {
            return i.city === config.location.selectedcity;
        });
        return index;
    };
    return (
        <div className="grid p-fluid">
            {selector.role === "eisqradmin" ? (
                <div className="col-12 md:col-12">
                    <div className="card ">
                        <div
                            className="col-12 md:col-12"
                            style={{
                                textDecoration: "underline",
                                textAlign: "center",
                                textDecorationColor: "green",
                            }}
                        >
                            <h5>Add Client/Enterprise</h5>
                        </div>

                        <div>
                            <DataTable
                                ref={dt}
                                value={data}
                                dataKey="id"
                                paginator

                                scrollable
                                rows={20}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                globalFilterFields={["month", "location", "city", "country"]}
                                globalFilter={filter}
                                emptyMessage="No records found."
                                header={header}
                                responsiveLayout="scroll"
                            >
                                <Column field="companyname" header="Company Name" sortable body={nameTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                <Column field="email" header="Enterprise Admin mail id" sortable body={emailTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                <Column field="contactperson" header="Enterprise Admin name" sortable body={contactTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                {/* <Column field="contactpersonmail" header="Admin mail-id" sortable body={contactEmailTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column> */}
                                {/* <Column field="contactpersonno" header="Admin contact no" sortable body={contactNoTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column> */}

                                {/* <Column field="config" header="Config" sortable body={configTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column> */}

                                <Column field="companylogo" header="Logo" sortable body={logoTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                <Column header="Action" sortable body={editTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>

                            </DataTable>
                            <Dialog
                                visible={adddialog}
                                style={{ width: "450px" }}
                                header="Add Client"
                                modal
                                className="p-fluid"
                                footer={addDialogFooter}
                                onHide={() => {
                                    setAddDialog(false);
                                }}
                            >
                                <div className="field">
                                    <label htmlFor="refdate">Company Name <span style={{ color: 'red' }}> *</span></label>
                                    <InputText
                                        type={"text"}
                                        disabled={client.id !== undefined}
                                        value={client.companyname}
                                        onChange={(e) => {
                                            updateClient("companyname", e.target.value);
                                        }}
                                        placeholder="Company name"
                                    />
                                    {submitted && client.companyname.trim().length === 0 && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Company name is mandatory
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Enterprise ID  <span style={{ color: 'red' }}> *</span></label>
                                    <InputText
                                        type={"text"}

                                        value={client.enterpriseid}
                                        onChange={(e) => {
                                            updateClient("enterpriseid", e.target.value);
                                        }}
                                        placeholder="Enterprise ID"
                                    />
                                    {submitted && client.enterpriseid.trim().length === 0 && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Enterpriseid is mandatory
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                <Checkbox inputId="rep" name="pizza" value={client.ssoLogin} onChange={(e) => { updateClient('ssoLogin', e.checked) }} checked={client.ssoLogin === true} />
                                <label htmlFor="rep" className="ml-2">SSO Login</label>
                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Admin Portal </label>
                                <InputText type={'text'} value={client.adminPortalUrl} onChange={(e) => { updateClient('adminPortalUrl', e.target.value) }} placeholder="location name" />
                                {submitted && !isValidURL(client.adminPortalUrl.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Admin Portal URL is mandatory</small>}


                            </div>
                            <div className="field">
                                <label htmlFor="refdate">User Portal </label>
                                <InputText type={'text'} value={client.userPortalUrl} onChange={(e) => { updateClient('userPortalUrl', e.target.value) }} placeholder="location name" />

                                {submitted && !isValidURL(client.userPortalUrl.trim()) && <small className="p-invalid" style={{ color: 'red' }}>User Portal URL is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Supplier Portal </label>
                                <InputText type={'text'} value={client.supplierPortalUrl} onChange={(e) => { updateClient('supplierPortalUrl', e.target.value) }} placeholder="location name" />
                                {submitted && ((client.supplierPortalUrl && client.supplierPortalUrl.trim().length) ? !isValidURL(client.supplierPortalUrl.trim()) : false  ) && <small className="p-invalid" style={{ color: 'red' }}>URL is InValid</small>}



                            </div>
                                <div className="field">
                                    <label htmlFor="refdate">AWS Domain</label>
                                    <InputText
                                        type={"text"}

                                        value={client.coginfo.REACT_APP_AWS_DOMAIN}

                                        onChange={(e) => {
                                            updateClientCogInfo("REACT_APP_AWS_DOMAIN", e.target.value);
                                        }}
                                        placeholder="Enterprise ID"
                                    />
                                    {submitted && client.enterpriseid.trim().length === 0 && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Enterpriseid is mandatory
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Sub Domain  </label>
                                    <InputText
                                        type={"text"}

                                        value={client.coginfo.REACT_APP_SUB_DOMAIN}

                                        onChange={(e) => {
                                            updateClientCogInfo("REACT_APP_SUB_DOMAIN", e.target.value);
                                        }}
                                        placeholder="REACT_APP_SUB_DOMAIN"
                                    />
                                 
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">AWS Region  </label>
                                    <InputText
                                        type={"text"}

                                        value={client.coginfo.REACT_APP_AWS_REGION}

                                        onChange={(e) => {
                                            updateClientCogInfo("REACT_APP_AWS_REGION", e.target.value);
                                        }}
                                        placeholder="Enterprise ID"
                                    />
                                 
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">AWS Client ID  </label>
                                    <InputText
                                        type={"text"}

                                        value={client.coginfo.REACT_APP_AWS_CLIENT_ID}

                                        onChange={(e) => {
                                            updateClientCogInfo("REACT_APP_AWS_CLIENT_ID", e.target.value);
                                        }}
                                        placeholder="REACT_APP_AWS_CLIENT_ID"
                                    />
                                 
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">AWS Redirect URL </label>
                                    <InputText
                                        type={"text"}

                                        value={client.coginfo.REACT_APP_REDIRECT_URL}
                                        onChange={(e) => {
                                            updateClientCogInfo("REACT_APP_REDIRECT_URL", e.target.value);
                                        }}
                                        placeholder="REACT_APP_REDIRECT_URL"
                                    />
                                    
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">AWS User Pool Id </label>
                                    <InputText
                                        type={"text"}

                                        value={client.coginfo.REACT_APP_AWS_USER_POOL_ID}
                                        onChange={(e) => {
                                            updateClientCogInfo("REACT_APP_AWS_USER_POOL_ID", e.target.value);
                                        }}
                                        placeholder="AWS_USER_POOL_ID"
                                    />
                                  
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Select Access  <span style={{ color: 'red' }}> *</span></label>
                                    <div className="flex">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="rep" name="pizza" value={client.access.navigos} onChange={(e) => { onAccessChange(e.checked, 'navigos') }} checked={client.access.navigos === true} />
                                            <label htmlFor="rep" className="ml-2">Navigos</label>
                                        </div>
                                        <div style={{ marginLeft: 10 }} className="flex align-items-center">
                                            <Checkbox inputId="view" name="pizza" value={client.access.materiality} onChange={(e) => { onAccessChange(e.checked, 'materiality') }} checked={client.access.materiality === true} />
                                            <label htmlFor="view" className="ml-2">Materiality</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <label >Start of Financial Year <span style={{ color: 'red' }}> *</span></label>

                                    <Calendar disabled={client.fyStartMonth !== null || client.fyStartMonth === undefined } value={client.fyStartMonth} placeholder="date" view='month' onChange={(e) => {
                                        updateClient("fyStartMonth", e.value);
                                    }}   dateFormat="MM" />
                                    {submitted && client.fyStartMonth === null && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Start of Financial Year is mandatory
                                        </small>
                                    )}
                                </div>

                                <div className="field">
                                    <label htmlFor="refdate">Data Entry Start Month & Year <span style={{ color: 'red' }}> *</span></label>

                                    <Calendar value={client.startdate} placeholder="date" dateFormat="M-yy" onChange={(e) => {
                                        updateClient("startdate", e.value);
                                    }} />
                                    {submitted && client.startdate === null && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                           Data Entry  Start Month & Year is mandatory
                                        </small>
                                    )}
                                </div>

                               
                                <div className="field">
                                    <label >Tier Label <span style={{ color: 'red' }}> *</span></label>

                                    <Chips value={client.tierLabel} max={3} onChange={(e) => { updateClient("tierLabel", e.value) }} keyfilter="alphanum" />
                                    {submitted && client.tierLabel === null || client.tierLabel.length !== 3 && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            3 Tier Label Required
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Select Currency Code<span style={{ color: 'red' }}> *</span></label>

                                    <Dropdown value={client.currency} options={currencyList} itemTemplate={(item) => { return <>{item.value + '-' + item.name} </> }} optionValue="value" optionLabel="value" onChange={(e) => {
                                        updateClient("currency", e.value);
                                    }} />
                                    {submitted && client.currency === null && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Currency is mandatory
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Super Admin E-Mail id <span style={{ color: 'red' }}> *</span></label>
                                    <InputText
                                        type={"email"}
                                        disabled={client.id !== undefined}
                                        value={client.email}
                                        onChange={(e) => {
                                            updateClient("email", e.target.value);
                                        }}
                                        placeholder="Company Mail ID"
                                    />
                                    {submitted && !checkValidMailID(client.email) && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Company Mail Id is mandatory
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Company contact No</label>
                                    <InputText
                                        minLength={10}
                                        maxLength={10}
                                        type={"number"}
                                        value={client.companycontact}
                                        onChange={(e) => {
                                            updateClient("companycontact", e.target.value);
                                        }}
                                        placeholder="Company Contact No"
                                    />
                                    {submitted && (client.companycontact.trim().length !== 0 && client.companycontact.trim().length < 8) && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Company contact no is invalid, length should be greater than 7
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Enterprise Admin name <span style={{ color: 'red' }}>*</span></label>
                                    <InputText
                                        type={"text"}
                                        value={client.contactperson}
                                        onChange={(e) => {
                                            updateClient("contactperson", e.target.value);
                                        }}
                                        placeholder="Admin name"
                                    />
                                    {submitted && client.contactperson.trim().length === 0 && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Super  Admin Name is mandatory
                                        </small>
                                    )}
                                </div>
                                {/* <div className="field">
                                    <label htmlFor="refdate">Admin Mail ID</label>
                                    <InputText
                                        type={"email"}
                                        value={client.contactpersonmail}
                                        onChange={(e) => {
                                            updateClient("contactpersonmail", e.target.value);
                                        }}
                                        placeholder="Admin Mail ID"
                                    />
                                    {submitted && !checkValidMailID(client.contactpersonmail) && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Admin Mail ID is mandatory
                                        </small>
                                    )}
                                </div> */}
                                <div className="field">
                                    <label htmlFor="refdate">Enterprise Admin contact no</label>
                                    <InputText
                                        minLength={10}
                                        maxLength={10}
                                        type={"number"}
                                        value={client.contactpersonno}
                                        onChange={(e) => {
                                            updateClient("contactpersonno", e.target.value);
                                        }}
                                        placeholder="Admin Number"
                                    />
                                    {submitted && (client.contactpersonno.trim().length !== 0 && client.contactpersonno.trim().length < 8) && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            Enterprise Admin contact no is invalid, length should be greater than 7
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Website</label>
                                    <InputText
                                        type={"text"}
                                        value={client.website}
                                        onChange={(e) => {
                                            updateClient("website", e.target.value);
                                        }}
                                        placeholder="Company website"
                                    />
                                    {submitted && !check_url(client.website.trim()) && (
                                        <small className="p-invalid" style={{ color: "red" }}>
                                            website format is invalid, eg www.eisqr.com / mail.eisqr.com
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Address</label>
                                    <InputTextarea
                                        type={"text"}
                                        value={client.companyaddress}
                                        onChange={(e) => {
                                            updateClient("companyaddress", e.target.value);
                                        }}
                                        placeholder="Company name"
                                    />

                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Date of the Month for 1st Reminder to Reporters</label>
                                    <InputNumber min={1} max={28} value={client.user_remainder_mail_date} onChange={(e) => updateClient("user_remainder_mail_date", e.value === null ? 1 : e.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Date of the Month for 2nd Reminder to Reporters</label>
                                    <InputNumber min={1} max={28} value={client.user_escalation_mail_date} onChange={(e) => updateClient("user_escalation_mail_date", e.value === null ? 1 : e.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Date of the Month for 1st Reminder to Reviewers</label>
                                    <InputNumber min={1} max={28} value={client.reviewer_remainder_mail_date} onChange={(e) => updateClient("reviewer_remainder_mail_date", e.value === null ? 1 : e.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Date of the Month for 2nd Reminder to Reviewers</label>
                                    <InputNumber min={1} max={28} value={client.reviewer_escalation_mail_date} onChange={(e) => updateClient("reviewer_escalation_mail_date", e.value === null ? 1 : e.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Date of the Month for 1st Reminder to Approvers</label>
                                    <InputNumber min={1} max={28} value={client.approver_remainder_mail_date} onChange={(e) => updateClient("approver_remainder_mail_date", e.value === null ? 1 : e.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Date of the Month for 2nd Reminder to Approvers</label>
                                    <InputNumber min={1} max={28} value={client.approver_escalation_mail_date} onChange={(e) => updateClient("approver_escalation_mail_date", e.value === null ? 1 : e.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Date of the Month for Summary Mail to Desginated Escalation Mail ID's </label>
                                    <InputNumber min={1} max={28} value={client.others_escalation_mail_date} onChange={(e) => updateClient("others_escalation_mail_date", e.value === null ? 1 : e.value)} />

                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Escalation Mail ID's ( should be in lowercase )</label>
                                    <Chips onAdd={(e) => { if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(e.value)) { console.log(e.value); return false } }} allowDuplicate={false} value={client.escalation_mail_ids} onChange={(e) => updateClient("escalation_mail_ids", e.value)} separator="," />
                                    <small className="flex justify-content-end">
                                        use , to add mail id
                                    </small>
                                </div>

                                <div className="field" style={{ marginBottom: 10 }}>
                                    <label htmlFor="refdate">Report Config <span style={{ color: 'red' }}></span></label>
                                    <div style={{ marginTop: '20px !important' }}>



                                        <MultiSelect style={{ minWidth: '100%' }} value={client.report} options={standardlist} onChange={(e) => onRoleChange(e, 'role')} optionLabel="title"
                                            optionValue="id" optionGroupLabel="title" optionGroupChildren="reportNameTwos" optionGroupTemplate={groupedReportTemplate}
                                            placeholder="Select Report" display="chip" className="w-full md:w-20rem" />


                                    </div>



                                </div>

                                <div className="field">
                                    <label htmlFor="refdate">Logo <span style={{ color: 'red' }}>*</span></label>

                                    {client.companylogo === null ? (
                                        <>
                                            <FileUpload
                                                mode="basic"
                                                customUpload
                                                uploadHandler={(files) => {
                                                    let loc = client;
                                                    loc["img"] = files.files[0].objectURL;
                                                    setClient(loc);
                                                    setCropDialog(true);
                                                }}
                                                name="demo[]"
                                                auto={true}
                                                accept="image/*"
                                                maxFileSize={1000000}
                                            />
                                            {submitted && client.companylogo === null && (
                                                <small className="p-invalid" style={{ color: "red" }}>
                                                    Company Logo Required
                                                </small>
                                            )}
                                        </>
                                    ) : (
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <span style={{ display: "flex", flexDirection: "row", color: "green", alignItems: "center" }}>
                                                <img style={{ width: "200px", height: "auto" }} src={client.companylogo} />
                                            </span>
                                            <span
                                                style={{ display: "flex", flexDirection: "row", color: "red", alignItems: "center" }}
                                                onClick={() => {
                                                    let local = client;
                                                    local.companylogo = null;
                                                    setClient(local);
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons">close</i>
                                                <Button label="Reset" className="p-button-text" />
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </Dialog>
                            <Dialog
                                visible={cropdialog}
                                style={{ width: "450px" }}
                                header="Crop Logo"
                                modal
                                className="p-fluid"
                                footer={cropDialogFooter}
                                onHide={() => {
                                    setCropDialog(false);
                                }}
                            >
                                <ReactCrop crop={crop}  onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={(c) => setCompletedCrop(c)} aspect={1}>
                                    <img ref={imgRef} alt="Crop me" src={client.img} onLoad={onImageLoad} />
                                </ReactCrop>
                                {completedCrop && (
                                    <div>
                                        <h6>preview</h6>
                                        <canvas
                                            id="croppedimg"
                                            ref={previewCanvasRef}
                                            style={{
                                                border: "1px solid black",
                                                objectFit: "contain",
                                                width: completedCrop.width,
                                                height: completedCrop.height,
                                            }}
                                        />
                                    </div>
                                )}
                            </Dialog>
                            <Dialog
                                visible={configdialog}
                                style={{ width: "75%" }}
                                header="Config Client"
                                modal
                                className="p-fluid"
                                footer={addDialogFooter2}
                                onHide={() => {
                                    setConfigDialog(false);
                                    setSelectedConfigIndex(null);
                                }}
                            >
                                <div className="field">
                                    <label htmlFor="refdate">Select setting menu</label>
                                    <Dropdown style={{ width: "450px" }} value={selectedconfig} onChange={(e) => setSelectedConfig(e.value)} options={configmenu} optionLabel="name" placeholder="Select" />

                                    {selectedconfig.name === "Location" && (
                                        <div>
                                            <div className="col-12">
                                                <div className="grid">
                                                    <div className="col-4">
                                                        <div className="grid">
                                                            <div className="col-10">
                                                                <InputText
                                                                    type={"text"}
                                                                    value={config.location.newcountry}
                                                                    onChange={(e) => {
                                                                        let loc = config;
                                                                        loc.location.newcountry = e.target.value;
                                                                        setConfig(loc);
                                                                        forceUpdate();
                                                                    }}
                                                                    placeholder="Enter country"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <Button
                                                                    icon="pi pi-plus"
                                                                    className="p-button-success mr-2"
                                                                    onClick={() => {
                                                                        addConfigCountry();
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-12"
                                                            style={{
                                                                border: "1px solid grey",
                                                                height: "25vh",
                                                                overflow: "scroll",
                                                            }}
                                                        >
                                                            {config.location.countrylist.length === 0 ? (
                                                                <text style={{ padding: 5 }}>{"Add Country"}</text>
                                                            ) : (
                                                                config.location.countrylist.map((item, cyindex) => {
                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                let loc = config;
                                                                                loc.location.selectedcountry = item.country;
                                                                                loc.location.selectedcity = "";
                                                                                loc.location.selectedlocation = "";
                                                                                setConfig(loc);
                                                                                forceUpdate();
                                                                            }}
                                                                            style={{ color: item.country === config.location.selectedcountry && "white", background: item.country === config.location.selectedcountry && "grey", borderBottom: "0.5px ridge grey" }}
                                                                        >
                                                                            <EditText
                                                                                onSave={(e) => {
                                                                                    if (e.value.trim().length !== 0) {
                                                                                        let loc = config;
                                                                                        loc.location.selectedcountry = e.value;
                                                                                        loc.location.countrylist[cyindex].country = e.value;
                                                                                        setConfig(loc);
                                                                                    }
                                                                                }}
                                                                                name="textbox3"
                                                                                // value={item.country}
                                                                                defaultValue={item.country}
                                                                                editButtonProps={{ style: { marginLeft: "5px", width: 16 } }}
                                                                                showEditButton
                                                                            />
                                                                        </div>
                                                                    );
                                                                })
                                                            )}
                                                        </div>
                                                    </div>

                                                    {config.location.selectedcountry !== "" && (
                                                        <div className="col-4">
                                                            <div className="grid">
                                                                <div className="col-10">
                                                                    <InputText
                                                                        type={"text"}
                                                                        value={config.location.newcity}
                                                                        onChange={(e) => {
                                                                            let loc = config;
                                                                            loc.location.newcity = e.target.value;
                                                                            setConfig(loc);
                                                                            forceUpdate();
                                                                        }}
                                                                        placeholder="Enter City"
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <Button
                                                                        icon="pi pi-plus"
                                                                        className="p-button-success mr-2"
                                                                        onClick={() => {
                                                                            addConfigCity();
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="col-12"
                                                                style={{
                                                                    border: "1px solid grey",
                                                                    height: "25vh",
                                                                    overflow: "scroll",
                                                                }}
                                                            >
                                                                {config.location.countrylist[
                                                                    config.location.countrylist.findIndex((i) => {
                                                                        return i.country === config.location.selectedcountry;
                                                                    })
                                                                ].length === 0 ? (
                                                                    <text style={{ padding: 5 }}>{"Add City"}</text>
                                                                ) : (
                                                                    config.location.countrylist[
                                                                        config.location.countrylist.findIndex((i) => {
                                                                            return i.country === config.location.selectedcountry;
                                                                        })
                                                                    ]["city"].map((item, ctindex) => {
                                                                        return (
                                                                            <div
                                                                                onClick={() => {
                                                                                    let loc = config;
                                                                                    loc.location.selectedcity = item.city;
                                                                                    loc.location.selectedlocation = "";
                                                                                    setConfig(loc);
                                                                                    forceUpdate();
                                                                                }}
                                                                                style={{ color: item.city === config.location.selectedcity && "white", background: item.city === config.location.selectedcity && "grey", borderBottom: "0.5px ridge grey" }}
                                                                            >
                                                                                <EditText
                                                                                    onSave={(e) => {
                                                                                        if (e.value.trim().length !== 0) {
                                                                                            let loc = config;
                                                                                            loc.location.selectedcity = e.value;
                                                                                            loc.location.countrylist[
                                                                                                config.location.countrylist.findIndex((i) => {
                                                                                                    return i.country === config.location.selectedcountry;
                                                                                                })
                                                                                            ].city[ctindex].city = e.value;
                                                                                            setConfig(loc);
                                                                                        }
                                                                                    }}
                                                                                    name="textbox3"
                                                                                    // value={item.country}
                                                                                    defaultValue={item.city}
                                                                                    editButtonProps={{ style: { marginLeft: "5px", width: 16 } }}
                                                                                    showEditButton
                                                                                />
                                                                            </div>
                                                                        );
                                                                    })
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {config.location.selectedcity !== "" && config.location.selectedcountry !== "" && config.location.countrylist[getCountryIndex()].city.length !== 0 && (
                                                        <div className="col-4">
                                                            <div className="grid">
                                                                <div className="col-10">
                                                                    <InputText
                                                                        type={"text"}
                                                                        value={config.location.newlocation}
                                                                        onChange={(e) => {
                                                                            let loc = config;
                                                                            loc.location.newlocation = e.target.value;
                                                                            setConfig(loc);
                                                                            forceUpdate();
                                                                        }}
                                                                        placeholder="Enter site/location"
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <Button
                                                                        icon="pi pi-plus"
                                                                        className="p-button-success mr-2"
                                                                        onClick={() => {
                                                                            addConfigLocation();
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="col-12"
                                                                style={{
                                                                    border: "1px solid grey",
                                                                    height: "25vh",
                                                                    overflow: "scroll",
                                                                }}
                                                            >
                                                                {config.location.countrylist[
                                                                    config.location.countrylist.findIndex((i) => {
                                                                        return i.country === config.location.selectedcountry;
                                                                    })
                                                                ].city[getCityIndex()] === undefined ||
                                                                    config.location.countrylist[
                                                                        config.location.countrylist.findIndex((i) => {
                                                                            return i.country === config.location.selectedcountry;
                                                                        })
                                                                    ].city[getCityIndex()].length === 0 ? (
                                                                    <text style={{ padding: 5 }}>{"Add Site/Location"}</text>
                                                                ) : (
                                                                    config.location.countrylist[
                                                                        config.location.countrylist.findIndex((i) => {
                                                                            return i.country === config.location.selectedcountry;
                                                                        })
                                                                    ].city[getCityIndex()]["location"].map((item, locindex) => {
                                                                        return (
                                                                            <div
                                                                                onClick={() => {
                                                                                    let loc = config;
                                                                                    loc.location.selectedlocation = item.location;
                                                                                    setConfig(loc);
                                                                                    forceUpdate();
                                                                                }}
                                                                                style={{ borderBottom: "0.5px ridge grey" }}
                                                                            >
                                                                                <EditText
                                                                                    onSave={(e) => {
                                                                                        if (e.value.trim().length !== 0) {
                                                                                            let loc = config;
                                                                                            loc.location.countrylist[
                                                                                                config.location.countrylist.findIndex((i) => {
                                                                                                    return i.country === config.location.selectedcountry;
                                                                                                })
                                                                                            ].city[getCityIndex()].location[locindex].location = e.value;
                                                                                            setConfig(loc);
                                                                                        }
                                                                                    }}
                                                                                    name="textbox3"
                                                                                    // value={item.country}
                                                                                    defaultValue={item.location}
                                                                                    editButtonProps={{ style: { marginLeft: "5px", width: 16 } }}
                                                                                    showEditButton
                                                                                />
                                                                            </div>
                                                                        );
                                                                    })
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {selectedconfig.name === "Module" && (

                                        <div className="col-12">

                                            <Checkbox inputId="cb1" value={"Climate Change"} disabled={true} checked={true}></Checkbox>
                                            <label htmlFor="cb1" className="p-checkbox-label">
                                                Climate Change
                                            </label>

                                            {/* <TreeView
                                                data={loctreedata}
                                                aria-label="Checkbox tree"
                                                multiSelect
                                                selectedIds={config.selectedLocationIds}
                                                propagateSelect
                                                propagateSelectUpwards
                                                togglableSelect
                                                nodeRenderer={({
                                                    element,
                                                    isBranch,
                                                    isExpanded,
                                                    isSelected,
                                                    isHalfSelected,
                                                    getNodeProps,
                                                    level,
                                                    handleSelect,
                                                    handleExpand,
                                                    treeState
                                                }) => {
                                                    updateTree(treeState, 1, getNodeProps)
                                                    return (
                                                        <div
                                                            {...getNodeProps({ onClick: handleExpand })}
                                                            style={{ marginLeft: 40 * (level - 1) }}
                                                        >
                                                            {isBranch && <ArrowIcon isOpen={isExpanded} />}
                                                            <CheckBoxIcon
                                                                className="checkbox-icon"
                                                                onClick={(e) => {
                                                                    handleSelect(e);
                                                                    e.stopPropagation();
                                                                }}
                                                                variant={
                                                                    isHalfSelected ? "some" : isSelected ? "all" : "none"
                                                                }
                                                            />
                                                            <span className="name">{element.name}</span>
                                                        </div>
                                                    );
                                                }}
                                            /> */}
                                        </div>
                                    )}
                                </div>
                            </Dialog>
                        </div>

                        {/* 
                    <div className="col-12 md:col-12">

                        <Button label="Save" className="mr-2 mb-2" style={{ marginTop: 10, width: 100 }} onClick={(e) => { addClientApi() }}></Button>

                    </div> */}
                    </div>
                </div>
            ) : (
                <div className="card col-12">You have no rights to access this page</div>
            )}
        </div>
    );
};

export default AddClient;